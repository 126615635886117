<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
      <ion-buttons slot="start">
        <ion-button (click)="cerrarSinParametros()">
          <ion-icon slot="icon-only" name="lc-icon-arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
        <ion-title>{{ 'txPartesSearchModalTitle' | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cerrarConParametros()">
                <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ion-list>
        <ion-item>
            <ion-label>{{'txPartesSearchModalCriterio' | translate}}</ion-label>
            <ion-select [(ngModel)]="criterio" [okText]="'txAceptar' | translate" [cancelText]="'txCancelar' | translate" [compareWith]="compareBuscarFn">
                <ion-select-option value="{{constants.PARTES_BUSCAR_X_CUALQUIERA}}">{{'txPartesSearchModalCriterioCualquiera' | translate}}</ion-select-option>
                <ion-select-option value="{{constants.PARTES_BUSCAR_X_CODIGO_PARTE}}">{{'txPartesSearchModalCriterioCodigoParte' | translate}}</ion-select-option>
                <ion-select-option value="{{constants.PARTES_BUSCAR_X_CODIGO_ELEMENTO}}">{{'txPartesSearchModalCriterioCodigoElemento' | translate}}</ion-select-option>
                <ion-select-option value="{{constants.PARTES_BUSCAR_X_NOMBRE_ELEMENTO}}">{{'txPartesSearchModalCriterioNombreElemento' | translate}}</ion-select-option>
                <ion-select-option value="{{constants.PARTES_BUSCAR_X_CODIGO_MODELO_PARTE}}">{{'txPartesSearchModalCriterioCodigoModeloParte' | translate}}</ion-select-option>
                <ion-select-option value="{{constants.PARTES_BUSCAR_X_NOMBRE_MODELO_PARTE}}">{{'txPartesSearchModalCriterioNombreModeloParte' | translate}}</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item>
            <ion-label color="primary" position="fixed">{{'txPartesSearchModalValor' | translate}}</ion-label>
            <ion-input [(ngModel)]="valorSearch" type="text" (keyup.enter)="cerrarConParametros()" clearInput></ion-input>
        </ion-item>
    </ion-list>

</ion-content>
