import {Component, ViewChild} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import * as Data from '../../config/constantes';
import {LcInputNumberComponent} from '../../components/lc-input-number/lc-input-number';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {Subscription} from "rxjs";


@Component({
  selector: 'page-parte-resultado-operario',
  templateUrl: 'parte-resultado-operario.html',
})
export class ParteResultadoOperarioPage {

  @ViewChild(LcInputNumberComponent,{static: true}) lcinput: LcInputNumberComponent;

  respuesta = "";
  operariDescripcio = "";
  cerrando =  false;
  indice = -1;

  private backButtonListener?: Subscription;

  public constants = Data;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              public _html: HtmlUtilsProvider,
              public alertCtrl: AlertController,
              public platform: Platform,
              public _ptp: PartesTabProvider,
              public _pg: PersistenciaGeneralProvider,
              public _ui: UiMessagesProvider,
              public modalCtrl: ModalController,
              ) {

    // console.log(navParams);

    if(navParams.get("respuesta") && navParams.get("respuesta") != ""){
      this.respuesta = navParams.get("respuesta");
    }

    if(navParams.get("operariDescripcio") && navParams.get("operariDescripcio") != ""){
      this.operariDescripcio = navParams.get("operariDescripcio");
    }

    this.indice = navParams.get("indice");
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }
   /*** fin trabajo con el boton atras */

   cerrarConParametros = (elimina: boolean) => {
    if(!this.cerrando){

      if(elimina){
        this.confirmarEliminar(this.indice);
      }else{
        let data: any;
        this.cerrando = true;

        if (!this.lcinput.anyError()) {
          data = {
            respuesta: this.lcinput.getValue() != null ? this.lcinput.getValue() : null
          }

          this.modalCtrl.dismiss(data);
        }
      }
    }

    this.cerrando = false;
	}

	cerrarSinParametros(){
		this.modalCtrl.dismiss();
  }

  async confirmarEliminar(idx: number){

    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txPreguntaConfirmaEliminacion", "txYes", "txNo");

    alert.onDidDismiss().then(({data}) => {
          if(data.accepted){
            this._ptp.parte.operaris.splice(idx, 1);
            //alert.dismiss();
            this.cerrarSinParametros();
            return false
          }
          return false;
    });
  }


}
