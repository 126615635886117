<ion-header>
    <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
    <respuesta-fase-content [respuestaPageActions]="this">
        <ion-list lines="none">
            <ion-radio-group [(ngModel)]="valorEdicio">
                <ion-item>
                    <ion-label class="correcto">{{'txPartesEstadoCorrecto' | translate}}</ion-label>
                    <ion-radio mode="md" slot="start" color="colorCorrecto" color-checked="primary"
                        value="1"></ion-radio>
                </ion-item> <ion-item> <ion-label class="incorrecto">{{'txPartesEstadoIncorrecto' |
                        translate}}</ion-label>
                    <ion-radio mode="md" slot="start" color="colorIncorrecto" color-checked="primary"
                        value="0"></ion-radio>
                </ion-item>

                <ion-item>
                    <ion-label class="sin-asignar">{{'txSinAsignarStr' | translate}}</ion-label>
                    <ion-radio mode="md" slot="start" color="colorSinAsignar" color-checked="primary"
                        value="-1"></ion-radio>
                </ion-item>
            </ion-radio-group>
        </ion-list>
    </respuesta-fase-content>
</ion-content>

<ion-footer>
    <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
