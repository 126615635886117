import {Injectable, NgZone} from '@angular/core';
import {PersistenciaGeneralProvider} from '../persistencia-configuracion/persistencia-general.provider';
import {PartesTabProvider} from '../partes-tab/partes-tab.provider';
import {AlertController, LoadingController, MenuController, Platform} from '@ionic/angular';
import {UiMessagesProvider} from '../ui-messages/ui-messages';
import {AjaxClientProvider, ErrorConsulta} from '../ajax-client/ajax-client.provider';
import {ExternalLinksProvider} from '../external-links/external-links.provider';
import {HtmlUtilsProvider} from '../html-utils/html-utils';
import {WsUtilsProvider} from '../utils-ws/ws-utils';
import {NetworkProvider} from '../network/network.provider';
import {InAppBrowser, InAppBrowserOptions} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {PostParams} from '../../interfaces/ajax.interface';
import {
  LINK_ARXIUTECNIC,
  LINK_FITXATECNICA,
  PARAM_STRCONN,
  PARAM_TANCAR_CONNEXIO,
  TIMEOUT_CONSULTAS_WS_DEFECTO_SEG
} from '../../config/constantes';
import {StatusProvider} from '../status/status.provider';
import {TranslateProvider} from '../translate/translate.provider';
import {Router} from '@angular/router';
import {AppRoutes} from '../../config/app-routes';
import {EventService} from '../events/event.service';
import {App} from '@capacitor/app';


@Injectable({
  providedIn: 'root',
})
export class HelperProvider {

  activePage = '';
  urlFitxaTecnica = '';
  urlArxiuTecnic = '';
  baseUrl = '';

  constructor(public _status: StatusProvider,
              public _pg: PersistenciaGeneralProvider,
              private menuCtrl: MenuController,
              private alertCtrl: AlertController,
              private _translate: TranslateProvider,
              public events: EventService,
              private _netProv: NetworkProvider,
              private _ui: UiMessagesProvider,
              public loadingCtrl: LoadingController,
              public _wsUtils: WsUtilsProvider,
              public _ajax: AjaxClientProvider,
              public _extLinks: ExternalLinksProvider,
              public _htmlUtils: HtmlUtilsProvider,
              public iab: InAppBrowser,
              public platform: Platform,
              public _ptp: PartesTabProvider,
              private router: Router,
              private ngZone: NgZone
  ) {


    this.events.subscribe('urlFitxaTecnica:created', (urlFitxaTecnica) => {
      this.urlFitxaTecnica = urlFitxaTecnica;
    });

    this.events.subscribe('urlArxiuTecnic:created', (urlArxiuTecnic) => {
      this.urlArxiuTecnic = urlArxiuTecnic;
    });

    this.events.subscribe('baseUrlOk:created', (baseUrlOk) => {
      this.baseUrl = baseUrlOk;
    });
  }

  cambiarProyecto() {
    if (!this.validaAccesoConexion()) {
      return;
    }

    if (this._ptp.editando) {
      this._ui.showToastDefault('txNoSePuedeCambiarPresa');
      return;
    }

    if (!this.validateChangeOffline()) {
      return;
    }

    this._status.doRadioProyectos(this._pg.proyectosDisponibles, this._pg.workingOffline ? 'txSeleccionePresaTrabajoOffline' : 'txSeleccionarProyectoLogin')
      .then(async (data) => {
        if (!data) {
          return false;
        }

        if (this._pg.proyectoSeleccionado == null) {
          return false;
        }


        await this._status.guardarProyectoSeleccionado(this._pg.proyectoSeleccionado);

        // console.log('objeto proyecto: ' + JSON.stringify(this._pg.proyectoSeleccionado));
        this.reloadActive();

      });

  }

  abrirPaginaClick(url: string, closeMenu: boolean) {
    if (url == AppRoutes.inventarioPage || url == AppRoutes.partesPage) {
      if (!this._netProv.appOnline && !this._pg.workingOffline) {
        this._ui.showToastDefault('txSinConexion');
        return false;
      }

      if (!this.validateChangeOffline()) {
        return;
      }
    }

    if (!this._ptp.editando) {
      this.activePage = url;

      if (closeMenu) {
        this.menuCtrl.close();
      }
      if (url == AppRoutes.partesPage) {this.events.publish('reloadCurrentPage', url);};

      // In some rare cases this is out of the Angular Zone breaking change detection and page loading
      if (NgZone.isInAngularZone()) {
        this.router.navigate(['/', url]);
      } else {
        this.ngZone.run(() => {
          this.router.navigate(['/', url]);
        });
      }

    }
  }

  checkActive(url: string): boolean {
    return this.activePage == url;
  }

  async cerrarSesion() {
    const alert = await this._ui.showYesNoAlert({}, 'txCerrarSesionTitle', 'txCerrarSesionMessage', 'txYes', 'txNo');

    alert.onDidDismiss().then(async ({data}) => {
      if (data.accepted) {
        await this._pg.deleteConfiguracioServidor();
        await this._pg.updateProjecteSeleccionat(-1, this._pg.usuarioLoginModel.idUsuari);
        this._pg.usuarioLoginModel.idProjecteSeleccionat = null;
        this._pg.proyectoSeleccionado = undefined;
        this.logoutProcess().then(() => {
          this._netProv.resetBaseUrlOk();
          this._pg.resetAjustesConfiguracionUsuario();
          //this.appCtrl.getRootNav().setRoot(LoginPage);
          this.router.navigate(['/', AppRoutes.cargarConfiguracionPage]);
        });
      }
    });
  }

  logoutProcess() {
    return new Promise(async (resolve, reject) => {
      const loading = await this._ui.showLoading('txCerrarSesionLoading');
      if (this._netProv.appOnline) {
        const params: PostParams[] = [];
        const paramStrLogin: PostParams = {
          nombre: PARAM_STRCONN,
          valor: this._wsUtils.getStrConn()
        };
        params.push(paramStrLogin);
        this._ajax.consultarWS(PARAM_TANCAR_CONNEXIO, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
          .subscribe(
            (datos: any) => {
              loading.dismiss();
              // console.log(JSON.stringify(datos));
              resolve(true);
            },
            (errConsulta: ErrorConsulta) => {
              loading.dismiss();
              // this._ui.showToastDefault(errConsulta.mensaje);
              // console.error('ERROR: ' + errConsulta.categoria + ' ' + errConsulta.categoria);
              resolve(false);
            }
          );
      } else {
        loading.dismiss();
        resolve(true);
      }
    });
  }

  async openExternalLinks(paramLink: number) {
    const loading = await this._ui.showLoading('txEsperePorFavor');

    switch (paramLink) {
      case LINK_FITXATECNICA: {
        this._extLinks.openExternalLinksProcessFichaTecnica()
          .then(() => {

            loading.dismiss();
            if (this.platform.is('ios')) {
              this.getInapBrowser(this.urlFitxaTecnica);
            } else {
              this._htmlUtils.getBrowser(this.urlFitxaTecnica, this.baseUrl);
            }

          })
          .catch(() => loading.dismiss());
        break;
      }
      case LINK_ARXIUTECNIC: {
        this._extLinks.openExternalLinksProcessArchivoTecnico()
          .then(() => {

            loading.dismiss();
            if (this.platform.is('ios')) {
              this.getInapBrowser(this.urlArxiuTecnic);
            } else {
              this._htmlUtils.getBrowser(this.urlArxiuTecnic, this.baseUrl);
            }

          })
          .catch(() => loading.dismiss());
        ;
        break;
      }
      default: {
        loading.dismiss();
        break;
      }
    }
  }

  private getInapBrowser(url: string) {

    let descargando = false;

    if (url > '') {
      const options: InAppBrowserOptions = {
        zoom: 'no',
        location: 'no',
        hideurlbar: 'yes',
        hidenavigationbuttons: 'yes',
        hardwareback: 'yes',
        beforeload: 'yes'
      };

      const browser = this.iab.create(this.baseUrl + url, '_blank', options);
      browser.on('loadstop').subscribe((params) => {
        const urlevent = params.url;
        // console.log('loadstop----> ' + urlevent);

        if (!descargando) {
          if (urlevent.includes('descarregarFitxer.php')) {
            descargando = true;
            browser.executeScript({
              code: 'alert(\'descarga iniciada...\');'
            });

            this._htmlUtils.downloadFromWeb(urlevent)
              .then(res => {
                if (res) {
                  descargando = false;
                  browser.executeScript({
                    code: 'alert(\'archivo ' + res + ' descargado\');'
                  });
                }
              })
              .catch((err) => {
                descargando = false;
                // console.error(JSON.stringify(err));
              });
          }
        }

      });
    }
  }


  validaAccesoConexion() {
    if (!this._netProv.appOnline && !this._pg.workingOffline) {
      this._ui.showToastDefault('txSinConexion');
      return false;
    }

    return true;
  }

  validateChangeOffline() {
    let anySync = null;
    if (this._pg.workingOffline) {
      anySync = this._status.checkAnyDamOffline();

      if (!anySync) {
        const msg = this._translate.traducir('txNoPresasSincronizadas');
        this._ui.showOkAlert('txAtencionStr', msg, 'txAceptar');
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async exitProcessMenu() {
      this._ui.showOkAlert('txAtencionStr', 'Función no disponible en Navegador', 'txAceptar');
  }


  /**
   * Reloads current page
   * The only pages that need to be reloaded are the lists accessible from the menu
   */
  reloadActive() {
    if (this.router.url !== AppRoutes.configuracionPage && this.router.url !== AppRoutes.homePage) {
      this.events.publish('reloadCurrentPage', 'reload');
    }

  }

  exitApp() {
    if (this.platform.is('android')) {
      App.exitApp().then();
    } else if (this.platform.is('ios')) {
      const auxNav = (navigator as any);
      auxNav.app.exitApp();
    }
  }
}
