import {Injectable} from "@angular/core";
import {PermissionService} from "@app/core/permission/domain/permission.service";


@Injectable({
  providedIn: 'root'
})
export class SetPermissionUseCase {
  constructor(
    private service: PermissionService
  ) {}

  execute(serverProtocol: string): void {
    this.service.save(serverProtocol, Date())
  }
}
