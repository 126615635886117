import {Component} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {ElementoTabProvider} from '../../services/elemento-tab/elemento-tab.provider';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {ElementoAnotacionPage} from '../elemento-anotacion/elemento-anotacion';

@Component({
  selector: 'page-elemento-anotaciones',
  templateUrl: 'elemento-anotaciones.html',
  styleUrls: ['elemento-anotaciones.scss'],
})
export class ElementoAnotacionesPage {
  constructor(public navCtrl: NavController,
              public _etp: ElementoTabProvider,
              public modalCtrl: ModalController,
              public _ui: UiMessagesProvider,
              public _pg: PersistenciaGeneralProvider) {
  }

  ionViewDidEnter() {
    // console.log('ionViewDidEnter ElementoAnotacionesPage');
  }

  async showAnotacionModal(anotacion, idx){
    if(this._etp.editando){
      if(anotacion == null){
          anotacion = {
            idAnotacioElemento: null,
            dataHoraInici: null,
            dataHoraFinal: null,
            text: null,
            nomUsuari: this._pg.usuarioLoginModel.login,
            editatApp: 1
          }
      }

      const modal = await this.modalCtrl.create({
        component: ElementoAnotacionPage,
        componentProps: {
          idAnotacioElemento  : anotacion.idAnotacioElemento,
          dataHoraInici : anotacion.dataHoraInici,
          dataHoraFinal : anotacion.dataHoraFinal,
          text: anotacion.text,
          nomUsuari: this._pg.usuarioLoginModel.login,
          editatApp: 1,
          indice: idx
        },
      })
      modal.present();
    }
  }

  filterBy(prop: string) {
    return this._etp.elemento.anotacions.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  checkPermissions(annotation: any): boolean {
    if (this._etp.editando) {
      if(annotation.diesPerAntiga == null){
        return true;
      } else{
        let millis = new Date().getTime() - new Date(annotation.dataHoraInici).getTime();
        let days = millis / (1000 * 60 * 60 * 24);
        if (days > annotation.diesPerAntiga) {
          return this._etp.permissions[2];
        } else {
          return true;
        }
      }
    }
    return false;
  }
}
