import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Permission} from "@app/core/permission/domain/permission";
import {ProtocolDAO} from "@app/core/permission/domain/protocol.DAO";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _permission$ = new BehaviorSubject<Permission>(null);

  constructor(private dao: ProtocolDAO) {
    this.fetchAndUpdatePermission();
  }

  getPermission$() {
    return this._permission$.asObservable()
  }

  async get(): Promise<Permission> {
    try {
      const protocol = await this.dao.get();
      if (protocol.length >= 2) {
        // Assuming protocol[0] is already in the correct format for Permission instantiation
        const permission = new Permission(parseInt(protocol[0]), protocol[1]);
        this._permission$.next(permission); // Update the BehaviorSubject with the latest permission
        return permission;
      } else {
        // Handle the case where the protocol array doesn't have the expected length
        // console.error("Protocol data is not in the expected format:", protocol);
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch permissions:", error);
      return null; // In case of an error, return null or handle as appropriate
    }
  }

  private fetchAndUpdatePermission(): void {
    this.get().then(permission => {
      if (permission) {
        this._permission$.next(permission);
      }
    }).catch(error => console.error("Failed to initialize permissions:", error));
  }

  async save(serverProtocol: string, date: string): Promise<void> {
    await this.dao.save(serverProtocol, date);
    await this.fetchAndUpdatePermission();
  }
}
