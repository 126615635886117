<ion-content class="ion-no-padding">
  <partes-tab-encabezado [isEdit]="_ptp.editando"></partes-tab-encabezado>

  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      {{'txParteResultado' | translate}}
    </ion-card-header>
    <ion-card-content class="no-padding">
      <ion-list class="ion-no-padding">
        <ion-item lines="none" (click)="showResultadoModal()">
          <ion-col class="ion-text-left">
            <b>{{ 'txPartesResultado' | translate }}</b>
          </ion-col>
          <ion-col class="ion-text-nowrap"  size="auto">
            <img class="icon-align-middle" [src]="_ptp.parte.srcImgEstadoParteToResult"/>
            <span [ngStyle]="{'color': _ptp.parte.colorEstadoParteToResult }">
              <strong [ngClass]="{'no-value': _ptp.parte.txEstadoParteToResult === 'txPartesEstadoSinSeleccionar'}">
                {{ _ptp.parte.txEstadoParteToResult | translate }}
              </strong>
            </span>
          </ion-col>
            <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"></ion-icon>
        </ion-item>
        <ion-item lines="none" id="execution-date">
          <ion-col class="ion-text-left">
            <b>{{ "txFecha" | translate }}</b>
          </ion-col>
          <ion-col>
            <ng-container *ngIf="_ptp.editando; else viewDate">
              <strong class="ion-text-nowrap">
                {{ executionDate | dateFormat: true : true }}
              </strong>
            </ng-container>
            <ng-template #viewDate>
              <strong class="ion-text-nowrap" *ngIf="_ptp.parte.dataHoraExecucio; else noDate">
                {{ _ptp.parte.dataHoraExecucio | dateFormat: true : true }}
              </strong>
              <ng-template #noDate>
                <span class="no-value">{{ 'txSinValorStr' | translate }}</span>
              </ng-template>
            </ng-template>
          </ion-col>
            <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"></ion-icon>
        </ion-item>
        <ion-item lines="none"
          *ngIf="_ptp.parte.incidencies && _ptp.parte.incidencies.length > 0 && _ptp.parte.tipusEstatParteRealitzat === constants.PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA">
          <label>
            <strong>{{ 'txIncidencias' | translate}}</strong>
            <ion-grid *ngFor="let inc of _ptp.parte.incidencies">
              <ion-row>
                <ion-col>
                  <div style="display:table-row;">
                    <img style="display:table-cell; width: auto !important; min-width:32px" slot="start"
                         [src]="inc.srcFitxerLocalTipologia"
                         onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';">
                    <span style="display:table-cell; vertical-align: middle">{{ inc.codi }} {{ inc.nom }} </span>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row style="padding-left: 3rem;">
                <ion-col>
                  <div style="display:table-row;">
                    <img style="display:table-cell; width: auto !important; min-width:32px" slot="start"
                         [src]="inc.srcFitxerLocalIconaElement"
                         onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';">
                    <span
                      style="display:table-cell; vertical-align: middle">{{ inc.codiElement }} {{ inc.nomElement }}
                      <p>{{inc.rutaElement}}</p>
                                    </span>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row style="padding-left: 3rem;">
                <ion-col>
                  <span>{{ 'txInicioStr' | translate }} </span>
                  <span>{{ inc.dataHoraInici }}</span>
                </ion-col>
                <ion-col *ngIf="inc.dataHoraFinal > ''">
                  <span>{{ 'txFin' | translate }} </span>
                  <span>{{ inc.dataHoraFinal }}</span>
                </ion-col>
              </ion-row>
              <ion-row style="padding-left: 3rem;">
                <ion-col>
                  <div style="display:table-row;">
                    <img style="display:table-cell; width: auto !important; min-width:32px" slot="start"
                         [src]="inc.srcFitxerLocalEstat"
                         onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';">
                    <span style="display:table-cell; vertical-align: middle">{{ inc.nomEstat }}</span>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </label>
        </ion-item>
        <ion-item lines="none" (click)="presentPromptObservations()">
          <ion-col>
            <strong>{{ 'txPartesObservaciones' | translate}}: </strong>
            <span *ngIf="_ptp.parte.observacionsVersioParte>''">{{ _ptp.parte.observacionsVersioParte }}</span>
          </ion-col>
          <ion-col class="ion-text-nowrap" *ngIf="!(_ptp.parte.observacionsVersioParte>'')">
            {{ 'txPartesResultadoSinObservaciones' | translate }}
          </ion-col>
            <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      <label>{{'txPartesOperarios' | translate}}</label>
      <ion-button (click)="showAddOperarioModal()" color="light" class="ion-float-right" *ngIf="_ptp.editando">
        <ion-icon slot="icon-only" name="lc-icon-add"></ion-icon>
      </ion-button>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item lines="none" (click)="showEditTime(operario, i)"
                  *ngFor="let operario of _ptp.parte.operaris; let i = index">
          <ion-label>
            <label>{{ operario.descripcio }}</label>
            <ion-grid>
              <ion-row>
                <ion-row class="text-detalle-parte ion-padding-start">
                  <span>{{ 'txPartesTiempoMinutos' | translate }}: </span>
                  <span *ngIf="operario.tempsMinuts>0">{{ operario.tempsMinuts }} </span>
                  <span *ngIf="!(operario.tempsMinuts > 0)"
                        class="text-no-existe no-value">{{ 'txPartesTiempoNoDefinido' | translate }} </span>
                </ion-row>
              </ion-row>
            </ion-grid>
          </ion-label>
          <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"></ion-icon>
        </ion-item>
        <ion-item lines="none" *ngIf="_ptp.parte.operaris.length === 0">
          <ion-label>
            <label class="text-no-existe no-value">{{ 'txPartesSinOperarios' | translate}}</label>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</ion-content>

<ion-popover trigger="execution-date" show-backdrop="true" size="cover" side="top" class="popover" *ngIf="_ptp.editando">
  <ng-template>
    <ion-datetime
      [ngModel]="executionDate"
      (ngModelChange)="executionDateChange($event)"
      size="cover"
      hour-cycle="h23"
      [doneText]="'txAceptarConfiguracion' | translate"
      [cancelText]="'txCancelarConfiguracion' | translate"
      [firstDayOfWeek]="MONDAY"
      [locale]="locale"
      [clearText]="'txLimpiar' | translate">
      <span slot="time-label">{{'txTimeLabel'|translate}}</span>
      <span slot="title">{{ executionDate | dateFormat: true : true }}</span>
    </ion-datetime>
  </ng-template>
</ion-popover>
