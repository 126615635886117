import {Injectable} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {UiMessagesProvider} from '../ui-messages/ui-messages';
import {HtmlUtilsProvider} from '../html-utils/html-utils';
import {NetworkProvider} from '../network/network.provider';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {PATH_UPDATE, UPDATE_HOURS_ADVICE} from '../../config/constantes';
import moment from 'moment';


@Injectable({
  providedIn: 'root',
})
export class UpdateAppProvider {

  potActualitzar = false;
  ultimoAvisoUpdateApp: any = null;
  mostrandoAlert = false;

  constructor(public alertCtrl: AlertController,
              public platform: Platform,
              public device: Device,
              public _np: NetworkProvider,
              public _htmlUtils: HtmlUtilsProvider,
              public _ui: UiMessagesProvider) {
  }



  /**
   * Mensaje que se muestra cuando se detecta que se puede actualizar la app
   * en caso de indicar 'si' al modale se redirige a la página de descarga.
   * En caso de posponer se setea el valor para ultimoAvisoUpdateApp con la fecha y hora actual.
   */
  private async availableUpdate(){
    if(!this.mostrandoAlert){
      this.mostrandoAlert = true;

      const alert = await this._ui.showYesNoAlert({backdropDismiss: false}, "txAtencionStr", "txActualizarAppPuede", "txYes", "txRecordarMasTarde");
      await alert.onDidDismiss().then( ({data}) => {
        if(data.accepted){
          this.potActualitzar = false;
          this.ultimoAvisoUpdateApp = null;
          this.navigateToDownload();
        }else{
          this.ultimoAvisoUpdateApp = this._htmlUtils.getDateToMoment(this._htmlUtils.generateManualDate());
        }

        this.mostrandoAlert = false;
        // console.log("seteo ultimoAvisoUpdateApp a: " + this.ultimoAvisoUpdateApp);
        return false;
      });
    }
  }


  /**
   * Mensaje que se muestra cuando se detecta que se debe actualizar la app obligatoriamente
   * en este caso la opción es solo una, se redirige a la página de descarga de la app
   */
  async shouldUpdate(){
    const alert = await this._ui.showOkAlert("txAtencionStr", "txActualizarAppDebe", "txAceptar");
    (await alert.onDidDismiss()).data(async (res) => {
      this.navigateToDownload();
    });
    return await alert.present();
  }

    /*
    Método que calcula si hay diferencia en tiempo. retorna true si corresponde advertir
  */
 needUpdateAdvice(){
  if(!this.potActualitzar){
    return false;
  }

  if(this.ultimoAvisoUpdateApp > ''){
    const nowDate = this._htmlUtils.getDateToMoment(this._htmlUtils.generateManualDate());
    const updateAdviceDate = this._htmlUtils.getDateToMoment(this.ultimoAvisoUpdateApp);
    const duration = moment.duration(nowDate.diff(updateAdviceDate));
    const hours = duration.asHours();
    // console.log("hours advice -> " + hours);

    if(hours > UPDATE_HOURS_ADVICE){
      this.availableUpdate();
    }
  }else{
    this.availableUpdate();
  }
}

  private navigateToDownload(){
    // console.log("navego hasta pagina de descarga");
    const platform = this._htmlUtils.getPlatform();
    this._htmlUtils.getBrowserFullUrl(this._np.baseUrlOk + PATH_UPDATE + platform);
  }

}
