import {PartDAO} from "@app/core/part/domain/part.DAO";
import {Part} from "@app/core/part/domain/part";
import {StorageProvider} from "@app/services/storage/storage.provider";
import {Injectable} from "@angular/core";
import {Platform} from "@ionic/angular";
import { PersistenciaGeneralProvider } from '../../../services/persistencia-configuracion/persistencia-general.provider';
import { FileProvider } from '../../../services/file/file.provider';
import { Directory, Encoding } from '@capacitor/filesystem';

@Injectable()
export class PartStorageDAO implements PartDAO {
  constructor(
    private storage: StorageProvider,
    public platform: Platform,
    private _pg: PersistenciaGeneralProvider,
    private _fp: FileProvider
  ) {
  }

  async save(part: Part): Promise<Part> {
    this.savePartNoPlanOnIndexedDB(part.idModelElement, part);
    return part;
  }

  async get(partID: number): Promise<Part> {
    const data = await this.getPartNoPlanOnIndexedDB(partID);
    return data;
  }

  async saveIdList(idList: number[]): Promise<number[]> {
    this.saveIdListOnIndexedDB(idList);
    return idList;
  }

  async getIdList(): Promise<number[]> {
    try {
    const directorySaveOnIndexedDB = Directory.Documents;
    const data = await this._pg.getFileOnIndexedDB('parte_no_plan_id_list', directorySaveOnIndexedDB, Encoding.UTF8);

    return JSON.parse(data.data);

    }
    catch (error) {
      // console.error('error get id list', error);
      return [];
    }
  }

  private async saveIdListOnIndexedDB(idParte: number[]) {
    const path = 'parte_no_plan_id_list';
    const data = JSON.stringify(idParte);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      // console.error('error save', errorSave);
    }
  }

  private async savePartNoPlanOnIndexedDB(parteId: any, parte: any) {
    const path = 'parte_no_plan_' + parteId;
    const data = JSON.stringify(parte);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      // console.error('error save', errorSave);
    }
  }

  private async getPartNoPlanOnIndexedDB(parteId: any) {
    const path = 'parte_no_plan_' + parteId;
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      const data = await this._pg.getFileOnIndexedDB(path, directorySaveOnIndexedDB, Encoding.UTF8);
      return JSON.parse(data.data);
    } catch (errorSave) {
      // console.error('error get getPartNoPlanOnIndexedDB', errorSave);
    }
  }

}
