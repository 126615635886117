<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
      <ion-buttons slot="start">
        <ion-button (click)="cerrarSinParametros()">
          <ion-icon slot="icon-only" name="lc-icon-arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
        <ion-title>{{'txPartesConfigModalTitle' | translate}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cerrarConParametros()">
                <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-radio-group [(ngModel)]="_criterioOrdenar">
        <ion-list-header>
          <ion-label>
            {{'txPartesConfigModalOrdernarPor' | translate}}
          </ion-label>
        </ion-list-header>
        <ion-item>
            <ion-label>{{'txPartesConfigModalOrdernarPorPosicionArbol' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" value="1"></ion-radio>
        </ion-item>
        <ion-item>
            <ion-label>{{'txPartesConfigModalOrdernarPorCodigoYNombre' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" value="2"></ion-radio>
        </ion-item>
    </ion-radio-group>

    <ion-radio-group *ngIf="!_pg.workingOffline" [(ngModel)]="_criterioMostrar">
        <ion-list-header>
          <ion-label>
            {{'txPartesConfigModalMostrarPartes' | translate}}
          </ion-label>
        </ion-list-header>
        <ion-item>
            <ion-label>{{'txPartesConfigModalMostrarPartesPorHacerYPasados' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" value="2"></ion-radio>
        </ion-item>
        <ion-item>
            <ion-label>{{'txPartesConfigModalMostrarPartesPorHacerYFuturos' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" value="1"></ion-radio>
        </ion-item>
        <ion-item>
            <ion-label>{{'txPartesConfigModalMostrarPartesSoloPorHacer' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" value="3"></ion-radio>
        </ion-item>
    </ion-radio-group>

    <ion-radio-group [(ngModel)]="_criterioExpandirContraer">
        <ion-list-header>
          <ion-label>
            {{'txExpandirContraerElementos' | translate}}
          </ion-label>
        </ion-list-header>
        <ion-item>
            <ion-label>{{'txMostrarExpandido' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" value="1"></ion-radio>
        </ion-item>
        <ion-item>
            <ion-label>{{'txMostrarContraido' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" value="2"></ion-radio>
        </ion-item>
    </ion-radio-group>

</ion-content>
