<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-buttons slot="start">
            <ion-button class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button" (click)="cerrarSinParametros()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ 'txParteResultadoParte' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ion-list>
        <ion-item (click)="cerrarConParametros(estado)" *ngFor="let estado of estados">
          <ion-label>
            <div class="estado-parte ion-text-wrap">
              <img class="icon-align-middle" [src]="estado.srcImgEstadoParte"> {{estado.txEstadoParte}}
            </div>
          </ion-label>
        </ion-item>
    </ion-list>

</ion-content>
