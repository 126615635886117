import {HelperProvider} from './../helper/helper.provider';
import {HttpLC} from '../http-lc/http-lc';
import {Injectable} from '@angular/core';
import {FileProvider} from '../file/file.provider';
import {AlertController, LoadingController} from '@ionic/angular';
import {TranslateProvider} from '../translate/translate.provider';
import {UiMessagesProvider} from '../ui-messages/ui-messages';
import {PostParams} from '@app/interfaces/ajax.interface';
import {
  PARAM_GET_ELEMENT_APP,
  PARAM_ID_ELEMENT,
  PARAM_STRCONN,
  TIMEOUT_CONSULTAS_WS_DEFECTO_SEG
} from '@app/config/constantes';
import {WsUtilsProvider} from '../utils-ws/ws-utils';
import {AjaxClientProvider, ErrorConsulta} from '../ajax-client/ajax-client.provider';
import {PersistenciaGeneralProvider} from '../persistencia-configuracion/persistencia-general.provider';
import {SyncProvider} from '../sync/sync.provider';
import {AppRoutes} from "@app/config/app-routes";
import {GetPermissionUseCase} from "@app/core/permission/application/get-permission.use-case";
import { ElementInfo } from '@app/core/element/domain/element-info';


@Injectable({
  providedIn: 'root',
})
export class ElementoTabProvider {

  elemento: any;
  editando: any;
  permissions: boolean[] = [];
  alertPopup: HTMLIonAlertElement = null;
  isOpenPopup = false;
  isOpenAtrasEditando = false;
  elementCanBeEdited = false;

  constructor(
    public httpLC: HttpLC,
    public alertCtrl: AlertController,
    public _translate: TranslateProvider,
    private loadingCtrl: LoadingController,
    private _messages: UiMessagesProvider,
    public _wsUtils: WsUtilsProvider,
    private _ajax: AjaxClientProvider,
    public _pg: PersistenciaGeneralProvider,
    public _sync: SyncProvider,
    public _fp: FileProvider,
    private _hp: HelperProvider,
    private getPermissionUseCase: GetPermissionUseCase
  ) {
    // console.log('Hello ElementoTabProvider Provider');

    this.editando = false;
  }

  async presentPromptCodiElement() {
    if (this.editando) {
      this.isOpenPopup = true;
      this.alertPopup = await this.alertCtrl.create({
        header: this._translate.traducir('txCodigoDelElemento'),
        inputs: [
          {
            name: 'codiElement',
            placeholder: this._translate.traducir('txCodigoSinPuntosStr'),
            value: this.elemento.codiElement
          }
        ],
        buttons: [
          {
            text: this._translate.traducir('txCancelar'),
            role: 'cancel',
            handler: data => {
              // console.log('Cancel clicked');
            }
          },
          {
            text: this._translate.traducir('txAceptar'),
            handler: data => {
              if (data.codiElement && data.codiElement > '') {
                this.elemento.codiElement = data.codiElement;
              }
              this.alertPopup.dismiss();
              this.isOpenPopup = false;
              return false;
            }
          }
        ]
      });
      this.alertPopup.present();
    }
  }

  async presentPromptNomElement() {
    if (this.editando) {
      this.isOpenPopup = true;
      this.alertPopup = await this.alertCtrl.create({
        header: this._translate.traducir('txNombreDelElemento'),
        inputs: [
          {
            name: 'nomElement',
            placeholder: this._translate.traducir('txNombreStr'),
            value: this.elemento.nomElement
          }
        ],
        buttons: [
          {
            text: this._translate.traducir('txCancelar'),
            role: 'cancel',
            handler: data => {
              // console.log('Cancel clicked');
            }
          },
          {
            text: this._translate.traducir('txAceptar'),
            handler: data => {
              if (data.nomElement && data.nomElement > '') {
                this.elemento.nomElement = data.nomElement;
              }
              this.alertPopup.dismiss();
              this.isOpenPopup = false;
              return false;
            }
          }
        ]
      });
      this.alertPopup.present();
    }
  }

  /**
   * Manejo de pagina de visualización
   */

  getElement(idElemento: any) {
    if (!this._pg.workingOffline) {
      this.getProcessOnline(idElemento);
    } else {
      this.getProcessOffline(idElemento);
    }
  }

  async getProcessOffline(idElemento: any) {
    event.stopPropagation();

    const loading = await this._messages.showLoading("txPartesDescargandoElemento");
    const elementInfo: ElementInfo = {
      idElement: idElemento,
      idProjecte: this._pg.proyectoSeleccionado.idProjecte
    };
    this._sync.getElement(elementInfo).then(() => {
      this.elemento = this._sync.localElement;
      loading.dismiss();
      this.gotoInfo();
    })
      .catch((err: any) => {
        loading.dismiss();
      });
  }

  async getProcessOnline(idElemento: any) {
    event.stopPropagation();

    //TODO: Aqui se podría definir buscar el parte en la BBDD local
    const loading = await this._messages.showLoading("txPartesDescargandoElemento");
    const params: PostParams[] = this.getParamsToGetElement(idElemento);
    // console.log("PARAMETROS ELEMENTO: " + JSON.stringify(params));
    this.descargarElementoFromServer(PARAM_GET_ELEMENT_APP, params)
      .then((res) => {
        if (res) {
          loading.dismiss();
          this.gotoInfo();
        } else {
          loading.dismiss();
        }
      })
      .catch(err => {
        // console.error(JSON.stringify(err))
        loading.dismiss();
      });
  }

  async gotoInfo() {
    this._hp.abrirPaginaClick(AppRoutes.elementoTabsPage, false);
  }

  private getParamsToGetElement(idElemento: any) {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    };
    params.push(paramStrConn);
    // console.log("this._wsUtils.getStrConn() --> " + this._wsUtils.getStrConn());

    //idElement
    if (idElemento != null && idElemento != "") {
      const idElement: PostParams = {
        nombre: PARAM_ID_ELEMENT,
        valor: idElemento
      };
      params.push(idElement);
    }

    return params;
  }

  descargarElementoFromServer(paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {

      this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
        .subscribe((res: any) => {
            if (res != null) {
              this.elemento = res;
              resolve(true);
            } else {
              resolve(false);
            }

          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);
            resolve(false);
          });

    })
      .catch(err => {
        // console.error("error en descargarLlistaPartesFromServer: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });


    return promesa;
  }

  async getPermissions(){
    const currentPerms = await this.getPermissionUseCase.execute();
    // Server doesnt support editElements permission
    if (!currentPerms.editElements) {
      // permission is expected to be an array of boolean values by the legacy code
      this.permissions = [false, false, false, false];
      return;
    }
    const permissions = this.elemento.permisos.split("|");
    this.permissions = [];
    for(const permission of permissions){
      if(permission == 1) {
        this.permissions.push(true);
      } else {
        this.permissions.push(false);
      }
    }
  }
}
