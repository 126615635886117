import { FitxerEnviarData } from "./fitxer-enviar-data-domain";

export interface ValorObsevacionsFaseData {
    observacions: string;
    valor: string;
    fitxerModificat?: boolean;
    tamany?: string; // Por ejemplo "5 MB"
    nomFitxerLocal?: string;
    fitxerEnviar?: FitxerEnviarData;
    valorPreview?: string;
}

export enum TipusAccioCambiarFase {
    FASE_PREV = "prev",
    FASE_NEXT = "next",
    FASE_CURRENT = "current",
    FASE_CANCEL = "cancel"
}
