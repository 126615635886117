import { Component, Input } from '@angular/core';
import { TipusAccioCambiarFase } from '@app/domains/valor-observacions-fase-data-domain';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';
import { FaseActualProvider } from '@app/services/geme/fase-actual.provider';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'respuesta-fase-footer',
  templateUrl: './respuesta-fase-footer.component.html',
})
export class RespuestaFaseFooterComponent {

  @Input() public respuestaPageActions: RespuestaPageActions;

  constructor(
    private modalCtrl: ModalController,
    public faseActual: FaseActualProvider,
    public _pg: PersistenciaGeneralProvider,
  ) { }

  movePrev(){
    if(this.faseActual.existsFasePrev && this.respuestaPageActions.checkValorIsOk()){
      this.cerrarConParametros(TipusAccioCambiarFase.FASE_PREV);
    }
  }

  moveNext(){
    if(this.faseActual.existsFaseNext && this.respuestaPageActions.checkValorIsOk()){
      this.cerrarConParametros(TipusAccioCambiarFase.FASE_NEXT);
    }
  }

  cerrarConParametros(tipusAccio: TipusAccioCambiarFase){
    if (this.respuestaPageActions.checkValorIsOk()){
      this.respuestaPageActions.setValorFaseActual();
      this.modalCtrl.dismiss(tipusAccio);
    }
	}

}
