<ion-content class="ion-no-padding">
  <partes-tab-encabezado [isEdit]="_ptp.editando"></partes-tab-encabezado>


  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      <label>{{'txPartesCostesExternos' | translate}}</label>
      <ion-button (click)="showCosteExternoModal(null, -1)" color="light" class="ion-float-right"
                  *ngIf="_ptp.editando">
        <ion-icon slot="icon-only" name="lc-icon-add"></ion-icon>
      </ion-button>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item lines="none" *ngFor="let costo of _ptp.parte.costos; let i = index" (click)="showCosteExternoModal(costo, i)">
          <ion-label>
            <label>{{ costo.descripcio }}</label>
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-md="4" class="text-detalle-parte ion-padding-start">
                  <span>{{ 'txPartesCosteUnitario' | translate }}: </span>
                  <span>{{ costo.costUnitari | formatoNumerico}} </span>
                </ion-col>
                <ion-col size="12" size-md="4" class="text-detalle-parte ion-padding-start">
                  <span>{{ 'txCantidad' | translate }}: </span>
                  <span>{{ costo.quantitat | formatoNumerico}} </span>
                </ion-col>
                <ion-col size="12" size-md="4" class="text-detalle-parte ion-padding-start">
                  <span>{{ 'txTotal' | translate }}: </span>
                  <span>{{ (costo.costUnitari * costo.quantitat) | formatoNumerico }} </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-label>

          <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"></ion-icon>
        </ion-item>
        <ion-item lines="none" *ngIf="_ptp.parte.costos.length === 0">
          <ion-label>
            <label class="text-no-existe">{{ 'txPartesSinCostesExternos' | translate}}</label>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      <label>{{'txPartesArchivosAdjuntos' | translate}}</label>
      <ion-button (click)="attachFile()" color="light" class="ion-float-right" *ngIf="_ptp.editando">
        <ion-icon slot="icon-only" name="lc-icon-add"></ion-icon>
      </ion-button>
      <ion-button (click)="takePhoto()" color="light" class="ion-float-right" *ngIf="_ptp.editando">
        <ion-icon slot="icon-only" name="lc-icon-camera"></ion-icon>
      </ion-button>
      <!-- <button ion-button icon-only color="light" float-right *ngIf="_ptp.editando">
          <ion-icon name="lc-icon-clip"></ion-icon>
      </button> -->
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <img hidden id="preview" #preview>
        <ion-item lines="none" (click)="goToFile(adjunt, preview)" *ngFor="let adjunt of _ptp.parte.adjunts; let i = index">
          <ion-label>
            <label>{{ adjunt.nom }}</label>
            <!-- <img *ngIf="isImage(adjunt.nomFitxerLocal)" id="preview" #preview> -->
            <ion-grid>
              <ion-row>
                <ion-col size="12" class="ion-padding-start text-detalle-parte">
                <span
                  *ngIf="adjunt.idDocument > 0 || adjunt.idGemeAdjunt > 0">{{ 'txPartesTamanyoFichero' | translate }}
                  : </span>
                  <span
                    [ngClass]="{'fichero-noenviado': adjunt.tamany === 'Fichero no enviado'}">{{ adjunt.tamany }} </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-label>
          <ion-icon style="color: blue" [name]="getIcon(adjunt)" slot="start"></ion-icon>
          <ion-icon name="lc-icon-trash" slot="end" *ngIf="_ptp.editando" (click)="eliminarAdjunto(i)"></ion-icon>
          <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"></ion-icon>
        </ion-item>
        <ion-item lines="none" *ngIf="_ptp.parte.adjunts.length === 0">
          <ion-label>
            <label class="text-no-existe">{{ 'txPartesSinArchivosAdjuntos' | translate}}</label>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</ion-content>
