<ion-header>
    <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
    <respuesta-fase-content [respuestaPageActions]="this">
        <lc-input-number #lc placeholder="txMinutos" [decimal]="false" [negative]="false" [(value)]="respuestaMinutos"></lc-input-number>
        <lc-input-number #lc placeholder="txSegundos" [decimal]="false" [negative]="false" [(value)]="respuestaSegundos"></lc-input-number>
    </respuesta-fase-content>
</ion-content>

<ion-footer>
    <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
