<ion-content class="ion-no-padding">
  <partes-tab-encabezado [isEdit]="_ptp.editando"></partes-tab-encabezado>

  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      {{'txPartesInfoParaEjecucion' | translate}}
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item lines="none">
          <ion-label>
            <span style="white-space: pre-wrap" [innerHTML]="_ptp.parte.infoExecucio | safeHtml"></span>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      <label>{{'txPartesMaterialesHerramientas' | translate}}</label>
      <ion-button (click)="showAddMaterialModal()" color="light" class="ion-float-right" *ngIf="_ptp.editando">
        <ion-icon slot="icon-only" name="lc-icon-add"></ion-icon>
      </ion-button>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item *ngFor="let eina of _ptp.parte.eines" lines="none">
          <ion-label>
            <label>{{ (eina.codi > '') ? eina.codi + " - " + eina.descripcio : eina.descripcio}}</label>
            <ion-grid>
              <ion-row>
                <ion-col class="ion-padding-start ion-text-left text-detalle-parte">
                  <span>{{ 'txCantidad' | translate}}: </span>
                  <span *ngIf="eina.quantitat>0">{{eina.quantitat}}</span>
                  <span *ngIf="!(eina.quantitat > 0)"
                        class="text-no-existe">{{ 'txPartesSinCantidadHerramienta' | translate}}</span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-label>
        </ion-item>
        <ion-item *ngFor="let material of _ptp.parte.materials; let i = index"
                  (click)="showMaterialModal(material, i)" lines="none">
          <ion-label>
            <label>{{ (material.codi > '') ? material.codi + " - " + material.descripcio : material.descripcio}}</label>
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-md="6" class="ion-padding-start ion-text-left text-detalle-parte">
                  <span>{{ 'txPartesMaterialPrevisto' | translate}}: </span>
                  <span *ngIf="material.quantitatPrevista>0">{{material.quantitatPrevista}}</span>
                  <span *ngIf="!(material.quantitatPrevista > 0)"
                        class="text-no-existe">{{ 'txPartesSinMaterialPrevisto' | translate}}</span>
                </ion-col>
                <ion-col size="12" size-md="6" class="ion-padding-start text-detalle-parte ion-text-md-right">
                  <span>{{ 'txPartesMaterialUtilizado' | translate}}: </span>
                  <span *ngIf="material.quantitatUtilitzada>0">{{material.quantitatUtilitzada}}</span>
                  <span *ngIf="!(material.quantitatUtilitzada > 0)"
                        class="text-no-existe">{{ 'txPartesSinMaterialUtilizado' | translate }}</span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-label>
          <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"></ion-icon>
        </ion-item>
        <ion-item *ngIf="!_ptp.parte.eines || (_ptp.parte.eines.length === 0 && _ptp.parte.materials.length === 0)" lines="none">
          <ion-label>
            <label class="text-no-existe">{{ 'txPartesSinMaterialesHerramientas' | translate}}</label>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</ion-content>
