<ion-header>
    <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
    <respuesta-fase-content [respuestaPageActions]="this">
    <ion-list>
        <ion-item id="fechaElement">
            <ion-label>{{ 'txFecha' | translate }}</ion-label>
                <ion-label class="ion-text-end">{{getDateUserFormat(respuestaFecha)}}</ion-label>
        </ion-item>
            <ion-item *ngIf="constants.PARTES_RESPUESTA_FECHA_HORA === faseActual.fase.tipusRespostaFase" id="timeChange">
            <ion-label>{{ 'txTimeLabel' | translate }}</ion-label>
            <ion-label class="ion-text-end">{{getTimeAsString(respuestaHora)}}</ion-label>
        </ion-item>
    </ion-list>
    </respuesta-fase-content>
</ion-content>

<ion-popover trigger="fechaElement" [keepContentsMounted]="true" #datePopover class="date-popover">
  <ng-template>
    <ion-datetime #dateInput
                  presentation="date"
                  max="2050-12-31"
                  [(ngModel)]="respuestaFecha"
                  [firstDayOfWeek]="MONDAY"
                  [locale]="locale"
                  (ngModelChange)="datePopover.dismiss()"
                  (ionCancel)="datePopover.dismiss()">
      <ion-buttons slot="buttons">
        <ion-button color="danger" (click)="resetDate(); datePopover.dismiss()">{{'txLimpiar' | translate}}</ion-button>
        <ion-button color="primary" (click)="dateInput.cancel()">{{'txCancelarConfiguracion' | translate}}</ion-button>
        <ion-button color="primary" (click)="dateInput.confirm()">{{'txAceptarConfiguracion' | translate}}</ion-button>
      </ion-buttons>
    </ion-datetime>
  </ng-template>
</ion-popover>

<ion-popover trigger="timeChange" [keepContentsMounted]="true" #timePopover class="time-popover">
  <ng-template>
    <ion-datetime presentation="time"
                  #timeInput
                  hour-cycle="h23"
                  [firstDayOfWeek]="MONDAY"
                  [locale]="locale"
                  [(ngModel)]="respuestaHora"
                  (ngModelChange)="timePopover.dismiss()"
                  (ionCancel)="timePopover.dismiss()">
      <ion-buttons slot="buttons">
        <ion-button color="danger" (click)="resetTime(); timePopover.dismiss()">{{'txLimpiar' | translate}}</ion-button>
        <ion-button color="primary" (click)="timeInput.cancel()">{{'txCancelarConfiguracion' | translate}}</ion-button>
        <ion-button color="primary" (click)="timeInput.confirm()">{{'txAceptarConfiguracion' | translate}}</ion-button>
      </ion-buttons>
    </ion-datetime>
  </ng-template>
</ion-popover>

<ion-footer>
  <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
