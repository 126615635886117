<ion-header>
  <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
  <respuesta-fase-content [respuestaPageActions]="this">
    <ion-list>
          <ion-radio-group [(ngModel)]="valorEdicio">
        <ion-item>
                <ion-label [ngClass]="{'positivo': faseActual.fase.tipusRespostaFase === 2 , 'negativo': faseActual.fase.tipusRespostaFase === 4 } ">{{'txSiStr' | translate}}</ion-label>
                <ion-radio mode="md" slot="start" [color]="faseActual.fase.tipusRespostaFase === 2 ? 'colorCorrecto' : 'colorIncorrecto'" value="1"></ion-radio>
        </ion-item>

        <ion-item>
                <ion-label [ngClass]="{'negativo': faseActual.fase.tipusRespostaFase === 2 , 'positivo': faseActual.fase.tipusRespostaFase === 4 } ">{{'txNoStr' | translate}}</ion-label>
                <ion-radio mode="md" slot="start" [color]="faseActual.fase.tipusRespostaFase === 2 ? 'colorIncorrecto' : 'colorCorrecto'" value="0"></ion-radio>
        </ion-item>

        <ion-item>
          <ion-label class="sin-asignar">{{'txSinAsignarStr' | translate}}</ion-label>
          <ion-radio mode="md" slot="start" color="colorSinAsignar" value="-1"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </respuesta-fase-content>
</ion-content>

<ion-footer>
  <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
