<ion-header>
    <respuesta-fase-header [respuestaPageActions]="this">
            <ion-button (click)="attachPDF()">
                <ion-icon slot="icon-only" name="lc-icon-clip"></ion-icon>
            </ion-button>
    </respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
    <respuesta-fase-content [respuestaPageActions]="this">
    <ion-list>
        <ion-item (click)="goToPdf()">
                <ion-icon name="lc-icon-clip" [ngClass]="{'with-pdf': valorEdicio > '' , 'without-pdf': (!(valorEdicio > '')) } " slot="start"></ion-icon>
          <ion-label>
                  <span class="with-pdf" *ngIf="valorEdicio > ''">{{'txConPdfAsignado' | translate}}</span>
                  <em *ngIf="(!(valorEdicio > ''))" class="without-pdf">{{'txSinPdfAsignado' | translate}}</em>
          </ion-label>
        </ion-item>
    </ion-list>
    </respuesta-fase-content>
</ion-content>

<ion-footer>
    <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
