<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
    <ion-buttons slot="start">
      <ion-button
        class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button"
        (click)="cerrarSinParametros()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'txPartesNotPlanned' | translate}}
      <p class="subtitle hide-when-big-screen" >{{_pg.proyectoSeleccionado.nom}}</p>
    </ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="cerrarConParametros()">
        <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <ion-list lines="none">
    <ion-item>
      <img slot="start" [src]="element.srcIcona" style="width: 2.286em;" onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';" alt="">
      <ion-label>
        <p style="padding-left:8px">
          <strong><span *ngIf="element.codiElement && element.codiElement!= ''">{{element.codiElement}} - </span>
            {{element.nomElement}}</strong>
        </p>
        <p style="padding-left:8px" class="ion-text-wrap">{{element.rutaElement}}</p>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        <p class="ion-text-wrap">
          {{partParent.codiModel}} - {{partParent.nomModel}}
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        <ion-text class="ion-text-wrap">
          {{message}}
        </ion-text>
      </ion-label>
    </ion-item>
    <ion-item *ngIf="!withAdvancedOptions">
      <ion-button slot="end" size="default" [color]="'colorTema1'" (click)="advancedOptions()">
        <ion-label class="ion-padding-horizontal ion-text-uppercase">
          {{'txPartesMoreOptions' | translate}}
        </ion-label>
      </ion-button>
    </ion-item>

    <ion-list [ngClass]="withAdvancedOptions? '': 'hidden'" lines="inset">
      <ion-item>
        <ion-label>
          <h2 class="ion-text-wrap">
            {{'txPartesNotPlannedAdvancedMessage' | translate}}
          </h2>
        </ion-label>
      </ion-item>

      <app-custom-date-selector [(date)]="date" [text]="'txDateSelection'" [id]="'datetime'" [clearButton]="false"></app-custom-date-selector>

      <ion-item class="ion-padding-top">
        <ion-label position="floating">{{'txPartesEstimatedExecutionTime' | translate}}</ion-label>
        <ion-input [(ngModel)]="estimatedDays" type="number" inputmode="number"
                   autofocus="true" (ngModelChange)="checkCharacters($event)" ></ion-input>
      </ion-item>
    </ion-list>

  </ion-list>
</ion-content>
