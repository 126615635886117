import {Subscription} from 'rxjs';
import {Component} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import moment from "moment";

@Component({
  selector: 'page-parte-anotacion',
  templateUrl: 'parte-anotacion.html',
  styleUrls: ['parte-anotacion.scss']
})
export class ParteAnotacionPage {


  cantidad = "";
  indice = -1;

  respuestaFecha: any;
  respuestaHora: any = "";
  tipoRespuesta: any;
  text: any;
  dataHoraInici: any;
  dataInici = moment().format();

  idAnotacioParte: any = null;
  nomUsuari: any;
  editatApp: any;

  private backButtonListener?: Subscription;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public _htmlUtils: HtmlUtilsProvider,
    public alertCtrl: AlertController,
    public _ptp: PartesTabProvider,
    public modalCtrl: ModalController,
    public _htmlUtil: HtmlUtilsProvider,
    public platform: Platform,
    public _pg: PersistenciaGeneralProvider,
    public _ui: UiMessagesProvider) {

    // console.log(navParams);


    this.tipoRespuesta = _htmlUtil.getParam(navParams, "tipoRespuesta");
    this.idAnotacioParte = _htmlUtil.getParam(navParams, "idAnotacioParte");
    this.nomUsuari = _htmlUtil.getParam(navParams, "nomUsuari");
    this.editatApp = _htmlUtil.getParam(navParams, "editatApp");
    this.dataInici = _htmlUtil.getDateParam(navParams, "dataHoraInici");
    this.text = _htmlUtil.getParam(navParams, "text");
    this.indice = _htmlUtil.getParam(navParams, "indice");
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }

  /*** fin trabajo con el boton atras */

  cerrarConParametros(elimina: boolean) {

    if (elimina) {
      this.confirmarEliminar(this.indice);
    } else {
      if (this.text && this.text != '' && this.dataInici) {
        const anotacion = {
          idAnotacioParte: this.idAnotacioParte,
          dataHoraInici: this.sanitizeDataHoraInici(this.dataInici),
          text: this.text,
          nomUsuari: this.nomUsuari,
          editatApp: this.editatApp,
        }

        if (this.indice >= 0) {
          this._ptp.parte.anotacions[this.indice] = anotacion;
        } else {
          this._ptp.parte.anotacions.push(anotacion);
        }

        this._ptp.parteHasDifference = true;
        this.modalCtrl.dismiss();

        this._ptp.setDraft();
      } else {
        this._ui.showToastDefault('txMensajeCamposObligatorios');
      }
    }
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }

  async confirmarEliminar(idx: number) {

    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txPreguntaConfirmaEliminacion", "txYes", "txNo");

    alert.onDidDismiss().then(({data}) => {
      if (data.accepted) {
        this._ptp.parte.anotacions.splice(idx, 1);
        // alert.dismiss();

        this._ptp.parteHasDifference = true;
        this._ptp.setDraft();
        this.cerrarSinParametros();
        return false;
      }
      return false;
    });
  }


  private sanitizeDataHoraInici(fecha: string) {
    const result = moment(fecha).format('YYYY-MM-DD hh:mm:ss');
    // console.log("fecha sanitize: " + result);
    return result;
  }

}
