<table class="tabla-fixed">
    <tr>
        <td style="width:38px">
            <img [src]="phaseImage">
        </td>
        <td>
            <p style="padding-left:3px;margin: 0 0 2px">
                <strong><span *ngIf="element.codiElement > ''">{{element.codiElement}}
                    - </span>
                {{element.nomElement}}</strong>
            </p>
            <p style="padding-left:3px;margin: 0 0 2px" class="ion-text-wrap">{{element.rutaElement}}</p>
        </td>
    </tr>
</table>