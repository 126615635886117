<ion-header>
  <ion-toolbar color="colorTema1">
    <ion-title>
      Debug
    </ion-title>

    <ion-buttons slot="start">
      <ion-menu-toggle>
        <ion-button>
          <ion-icon slot="icon-only" name="lc-icon-menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <ion-grid>
    <ion-row>
      <ion-col>
        Estado de la Red
      </ion-col>
      <ion-col>
        <ng-container>

        </ng-container>
        <h5 *ngIf="np.appOnline">Conectado</h5>
        <h5 *ngIf="!np.appOnline">Desconectado</h5>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="np.appOnline && np.appOnline != null  && np.networkType != 'none'">
      <ion-col>
        Tipo de Red
      </ion-col>
      <ion-col>
        <h5>{{np.networkType}}</h5>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="np.baseUrlOk != ''">
      <ion-col>
        URL Conexión
      </ion-col>
      <ion-col>
        <h5>{{np.baseUrlOk}}</h5>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- <ion-button expand="block" (click)="copiarBDClick()">
    Copiar Base de Datos
  </ion-button> -->

  <ion-button expand="block">
    Copiar Base de Datos
  </ion-button>

  <ion-item>
    <ion-input [(ngModel)]="resultado" type="text" readonly></ion-input>
  </ion-item>


  <ion-button expand="block" (click)="showSpinner(1000, 'txAccediendoLogin')">
    Spinner Msg corto 1 segundo
  </ion-button>

  <ion-button expand="block" (click)="showSpinner(5000, 'txAccediendoLogin')">
    Spinner Msg corto 5 segundos
  </ion-button>

  <ion-button expand="block" (click)="showSpinner(10000, 'txUsuarioPassIncorrectosLogin')">
    Spinner Msg largo 10 segundos
  </ion-button>

</ion-content>
