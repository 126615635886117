<ion-list lines="none">
  <ion-item *ngIf="faseActual.fase.element">
      <element-individual [element]="faseActual.fase.element"></element-individual>
  </ion-item>

  <ion-item [ngSwitch]="faseActual.fase.tipusRespostaFase">
    <ng-container *ngSwitchCase="typeTitle">
      <h4>{{ faseActual.fase.descripcio }}</h4>
    </ng-container>
    <ng-container *ngSwitchCase="typeSubtitle">
      <ion-subtitle>{{ faseActual.fase.descripcio }}</ion-subtitle>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ion-label>
        <p class="texto-descripcion">{{ faseActual.fase.descripcio }}</p>
      </ion-label>
    </ng-container>
  </ion-item>
  <div  *ngIf="faseActual.fase.nomFitxerLocalImatge>''" class="image-with-loader">
    <ion-spinner class="mt-4" [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'" *ngIf="isLoading"></ion-spinner>
    <img
      [src]="faseActual.fase.nomFitxerLocalImatge | formatLinkAsync:'3' | async"
      #preview
      (load)="onImageDidLoad()"
      onerror="this.onerror=null;this.src='assets/imgs/no_image.png';">
  </div>

  <ng-content></ng-content>

  <ion-item class="texto-observaciones mt-5"  lines="inset" *ngIf="showTextObservations">
      <ion-label position="floating">{{ 'txPartesObservaciones' | translate }}</ion-label>
      <ion-input [(ngModel)]="faseActual.valorObservacionsEdicio.observacions" type="text" (keyup.enter)="clickAceptar()"></ion-input>
  </ion-item>
</ion-list>
