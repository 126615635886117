import {Injectable} from '@angular/core';

import {Storage} from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageProvider {
  private _storage: Storage | null = null;
  private readonly initPromise: Promise<void>;

  constructor(private storage: Storage) {
    // Store the promise, so we can wait for it in each method, this way we ensure that the storage is ready and only
    // once per instance.
    this.initPromise = this.init();
  }

  private async init(): Promise<void> {
    this._storage = await this.storage.create();
  }

  async set(key: string, value: string): Promise<string> {
    await this.initPromise;
    return this._storage!.set(key, value);
  }

  async get(key: string): Promise<string> {
    await this.initPromise;
    return this._storage!.get(key);
  }

  async remove(key: string): Promise<string> {
    await this.initPromise;
    return this._storage!.remove(key);
  }
}
