import {Component} from '@angular/core';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {ParteAnotacionPage} from '../parte-anotacion/parte-anotacion';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';


@Component({
  selector: 'page-parte-anotaciones',
  templateUrl: 'parte-anotaciones.html',
})
export class ParteAnotacionesPage {
  constructor(
    public navCtrl: NavController,
    public _ptp: PartesTabProvider,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public _ui: UiMessagesProvider,
    public _pg: PersistenciaGeneralProvider) {
  }

  ionViewDidEnter() {
    // console.log('ionViewDidEnter ParteAnotacionesPage');
  }

  async showAnotacionModal(anotacion, idx) {

    if (!this._ptp.getPermisoPuedeEditarAnotacionsPartes()) {

      const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txNoTienePermisoEditarAnotaciones", "txOk", null, null, false);
      alert.onDidDismiss().then(() => false);


      return false;
    }

    if (this._ptp.editando) {
      if (anotacion == null) {
        anotacion = {
          idAnotacioParte: null,
          dataHoraInici: null,
          text: null,
          nomUsuari: this._pg.usuarioLoginModel.login,
          editatApp: 1
        }
      }

      const modal = await this.modalCtrl.create({
        component: ParteAnotacionPage,
        componentProps: {
          idAnotacioParte: anotacion.idAnotacioParte,
          dataHoraInici: anotacion.dataHoraInici,
          text: anotacion.text,
          nomUsuari: this._pg.usuarioLoginModel.login,
          editatApp: 1,
          indice: idx
        }
      })
      modal.present();
    }
  }

  filterBy(prop: string) {
    return this._ptp.parte.anotacions.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

}
