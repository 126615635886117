import { Pipe, PipeTransform } from '@angular/core';
import { FileProvider } from '@app/services/file/file.provider';
import { HtmlUtilsProvider } from '@app/services/html-utils/html-utils';
import { NetworkProvider } from '@app/services/network/network.provider';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';
import { WsUtilsProvider } from '@app/services/utils-ws/ws-utils';
import { Directory, Filesystem} from '@capacitor/filesystem';
import {
  ARXIU_TECNIC_DOCUMENT,
  GEME_ADJUNT,
  GEME_IMATGE_FASES,
  GEME_IMATGE_RESPOSTES_FASE,
  GEME_PDF_RESPOSTES_FASE,
  LOCAL_IMAGE
} from '../../config/constantes';

@Pipe({
  name: 'formatLinkAsync'
})


export class FormatLinkAsyncPipe implements PipeTransform {

  constructor(public _np: NetworkProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public _fp: FileProvider,
    public _wsUtils: WsUtilsProvider) {}

  async transform(file: string, types: string) {
    if(types === "IMAGES") {
      const result = await Filesystem.readFile({
        path: `images/${file}`,
        directory: Directory.Data
      });

      return `data:image/png;base64,${result.data}`;
    }

    let url = "";

    if (types === LOCAL_IMAGE) {
      return this._htmlUtils.normalizarImageSrc(file);
    }

    if (!this._pg.workingOffline) {
      url = `${this._np.baseUrlOk}?op=GET_FITXER_APP&strConn=${this._wsUtils.getStrConn()}&tipus=${types}&fitxer=${file}`;
    } else {

      switch (types) {
        case GEME_ADJUNT: {
          url = await this._htmlUtils.normalizarImageSrcIndexed(this._fp.pathDestinoAdjunts + '/' + file, Directory.Documents);
          break;
        }
        case ARXIU_TECNIC_DOCUMENT: {
          url = await this._htmlUtils.normalizarImageSrcIndexed(this._fp.pathDestinoArxiutecnic + '/' + file, Directory.Documents);
          break;
        }
        case GEME_IMATGE_FASES: {
          url = await this._htmlUtils.normalizarImageSrcIndexed(this._fp.pathDestinoImatgesfases + '/' + file, Directory.Documents);
          break;
        }
        case GEME_IMATGE_RESPOSTES_FASE: {
          url = await this._htmlUtils.normalizarImageSrcIndexed(this._fp.pathDestinoImatgesrespostesfases + '/' + file, Directory.Documents);
          break;
        }
        case GEME_PDF_RESPOSTES_FASE: {
          this._fp.showPdf(this._fp.pathDestinoPdfsrespostesfases + '/' + file);
          return;
        }
        default: {
          let urlImage = '';
          if(this._fp.pathDestinoGemeImatgeElement.length > 0) {
            urlImage = this._fp.pathDestinoGemeImatgeElement + '/' + file;
          } else {
            urlImage = this._fp.dirImatgeElement + '/' + file;
          }

          url = await this._htmlUtils.normalizarImageSrcRandomAsync(urlImage, Directory.Documents);
          break;
        }
      }
    }
    return url;
  }

}
