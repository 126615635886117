import { Component, Input } from '@angular/core';
import { PartesTabProvider } from '../../services/partes-tab/partes-tab.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { FileProvider } from '../../services/file/file.provider';
import { Platform, ModalController, LoadingController, NavController} from '@ionic/angular';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { UiMessagesProvider } from '../../services/ui-messages/ui-messages';
import { WsUtilsProvider } from '../../services/utils-ws/ws-utils';
import { AjaxClientProvider } from '../../services/ajax-client/ajax-client.provider';
import { ElementoTabProvider } from '../../services/elemento-tab/elemento-tab.provider';
import moment from "moment/moment";
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { Directory } from '@capacitor/filesystem';

@Component({
  selector: 'partes-tab-encabezado',
  templateUrl: 'partes-tab-encabezado.html'
})
export class PartesTabEncabezadoComponent {

  @Input() isEdit = false;

  codigoStr: string;
  fechaEstStr: string;
  fechaEjecucionStr: string;
  partePropuestoStr: string;
  estadoParteStr: string;
  parteStatusTraslated: string;
  executionDate: string;

  imagenElementoNormalizada: string;
  imagenTipoTrabajoNormalizada: string;

  constructor(
    public _ptp: PartesTabProvider,
    public navCtrl: NavController,
    public _html: HtmlUtilsProvider,
    public _translate: TranslateProvider,
    public _messages: UiMessagesProvider,
    public _wsUtils: WsUtilsProvider,
    public _etp: ElementoTabProvider,
    public _ajax: AjaxClientProvider,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public _fp: FileProvider,
    public _gp: PersistenciaGeneralProvider,
    private platform: Platform
  ) {

    //elemento
    const route = `${this._fp.pathDestinoGemeIcones}/${this._ptp.parte.nomFitxerIconaElement}`;

    this._gp.getImageToDB(route).then((imageIcon) => {
      this.imagenElementoNormalizada = imageIcon;
    });
    this.codigoStr = this._translate.traducir("txCodigoStr");
    this.fechaEstStr = this._translate.traducir("txFechaEstStr");
    this.fechaEjecucionStr = this._translate.traducir("txFecha");
    this.partePropuestoStr = this._translate.traducir("txPartesPartePropuesto");
    this.estadoParteStr = this._translate.traducir("txPartesEstadoParte");

    this.executionDate = this._ptp.parte.dataHoraExecucio === '' ? '' : moment(this._ptp.parte.dataHoraExecucio).format();

	  // tipo de trabajo
    if (this._ptp.parte.nomFitxerLocalTipologiaTreball > ""){
        this._html.normalizarImageSrcIndexed(this._fp.pathDestinoGemeTipologies + "/" + this._ptp.parte.nomFitxerLocalTipologiaTreball, Directory.Documents).then((imageIcon) => {
          this.imagenTipoTrabajoNormalizada = imageIcon;
        });
    }else{
      this.imagenTipoTrabajoNormalizada = null;
    }
    // console.log("_partesTabProvider.parte:", this._ptp.parte);
    // console.log("this.imagenTipoTrabajoNormalizada", this.imagenTipoTrabajoNormalizada);
  }

}
