import {ModalController, Platform} from "@ionic/angular";
import {UiMessagesProvider} from "@app/services/ui-messages/ui-messages";
import {PersistenciaGeneralProvider} from "@app/services/persistencia-configuracion/persistencia-general.provider";
import {TranslateProvider} from "@app/services/translate/translate.provider";
import {PartRepository} from "@app/core/part/infrastructure/part.repository";
import {Part} from "@app/core/part/domain/part";
import moment from "moment/moment";
import {Injectable} from "@angular/core";
import {SyncProvider} from "@app/services/sync/sync.provider";
import {DATE_FORMAT, TIME_FORMAT} from "@app/config/constantes";

@Injectable()
export class ParteCreateNotPlannedViewModel {

  static Mapper = class {
    // To map the part to add it to the list of "Other Parts"
    static MapPart(part: Part): Record<string, any> {
      return {
        codiParte: part.codiParte,
        dataHoraExecucio: part.dataHoraExecucio,
        dataHoraFinalForqueta: part.dataHoraFinalForqueta,
        dataHoraInicialForqueta: part.dataHoraInicialForqueta,
        dataHoraPlanificacio: part.dataHoraPlanificacio,
        forqueta: part.forqueta,
        idParte: part.idParte,
        idVersioParte: part.idVersioParte,
        tipusEstatParteRealitzat: part.tipusEstatParteRealitzat,
      }
    }
    // To map the part when in offline mode
    static MapPartOffline(viewModel: ParteCreateNotPlannedViewModel, model: Part, translate: TranslateProvider, estimatedDays: number, pg: PersistenciaGeneralProvider, date: string): Part {
      if (model.dataHoraExecucio === "") {
        model.dataHoraExecucio = null;
      }
      model.creatSensePlanificacio = 1;
      model.dataHoraPlanificacio = viewModel.changeFormatDate(date);
      model.dataHoraPlanParteSensePlanificacio = model.dataHoraPlanificacio;
      model.dataHoraInicialForqueta = model.dataHoraPlanificacio;
      model.dataHoraFinalForqueta = viewModel.changeFormatDate(new Date(new Date(date).getTime() - (estimatedDays * 24 * 60 * 60 * 1000)).toString());
      model.forqueta = (estimatedDays * 24).toString() + " " + translate.traducir('txHours');
      model.numeroRevisio = 0;
      model.tempsMinutsParteSensePlanificacio = (estimatedDays * 24 * 60) - 1;
      model.idParte = pg.getValueIdNotPlanned();
      model.codiParte = null;
      model.noPlanId = viewModel.generateRandomID();
      return model;
    }
  }

  constructor(public modalCtrl: ModalController,
              public platform: Platform,
              public _ui: UiMessagesProvider,
              public _pg: PersistenciaGeneralProvider,
              public translate: TranslateProvider,
              private partRepo: PartRepository,
              private sync: SyncProvider) {
  }

  //Function that send the new part created if online, or saves it in indexedDB if offline
  async confirmSave(estimatedDays: number, date: string, part: any, partParent: Part) {
    if (estimatedDays != null && (estimatedDays < 31 && estimatedDays > 0)) {
      await this.showAlertConfirmSave(estimatedDays, date, part, partParent);
    } else {
      if (estimatedDays == null) {
        this._ui.showToastDefault("txPartesNotPlannedEstimatedDaysEmpty");
      } else if (estimatedDays < 1) {
        this._ui.showToastDefault("txPartesNotPlannedMinDaysWarning");
      } else if (estimatedDays > 31) {
        this._ui.showToastDefault("txPartesNotPlannedMaxDaysWarning");
      }
    }
  }

  async showAlertConfirmSave(estimatedDays: number, date: string, part: any, partParent: Part) {
    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txPartesNotPlannedCreateWarning", "txYes", "txNo");
    let answer = await alert.onDidDismiss();
    if (answer.data.accepted) {
      part.idModelElement = partParent.idModelElement;
      part.dataHoraPlanParteSensePlanificacio = this.changeFormatDate(date);
      part.tempsMinutsParteSensePlanificacio = (estimatedDays * 24 * 60) - 1;
      if (!this._pg.workingOffline) {
        this.partRepo.createNotPlannedPart(part).then((model) => {
          this.modalCtrl.dismiss(model);
        });
      } else {
        this.saveOfflineNotPlannedPart(part, estimatedDays, date);
      }
      alert.present();
    }
  }

  saveOfflineNotPlannedPart(part: any, estimatedDays: number, date: string) {
    if (!part.partes) {
      part.partes = part.ultimsPartes;
      delete part.ultimsPartes;
    }
    this.partRepo.getNotPlannedPart(part.idModelElement).then(async (model) => {
      let formattedModel = ParteCreateNotPlannedViewModel.Mapper.MapPartOffline(this, model, this.translate, estimatedDays, this._pg, date);
      part.partes.push(ParteCreateNotPlannedViewModel.Mapper.MapPart(formattedModel));
      this.partRepo.getIdList().then(async (idList) => {
        if (idList == null) {
          await this.partRepo.saveIdList([formattedModel.idParte]);
        } else {
          idList.push(formattedModel.idParte);
          await this.partRepo.saveIdList(idList);
        }
      });
      await this.sync.setDetalleParte(formattedModel.idParte, formattedModel);
      this.modalCtrl.dismiss([formattedModel, part]);
    });
  }

  generateRandomID(): number {
    return Math.floor(1000 * Math.random());
  }

  // Set dates with the format the server accepts
  public changeFormatDate(fecha: string): string {
    let date = moment(fecha).format(DATE_FORMAT);
    let time = moment(fecha).format(TIME_FORMAT);
    return [date, time].join(' ');
  }

  setDefaultMessage() {
    return this.translate.traducir('txPartesNotPlannedDefaultMessage');
  }

  setCustomMessage(days: number, date: string, plural) {
    return this.translate.traducirWithParams('txPartesNotPlannedCustomMessage', {date: this.changeFormatDate(date), days: days, plural: plural});
  }
}
