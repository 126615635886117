import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { FaseActualProvider } from '../../services/geme/fase-actual.provider';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';
import * as Data from '../../config/constantes';
import {
  DATE_USER_FORMAT,
  PARTES_RESPUESTA_FECHA_HORA,
  TIME_FORMAT,
  TIME_WITH_SECONDS_FORMAT
} from '../../config/constantes';
import moment from 'moment';

@Component({
  templateUrl: 'respuesta-fecha-hora.html',
  styleUrls: ['respuesta-fecha-hora.scss'],
})
export class RespuestaFechaHoraPage implements RespuestaPageActions {
  locale = 'es';
  readonly MONDAY = 1;

  respuestaFecha: string = null;
  respuestaHora: string = null;

  public constants = Data;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    public translate: TranslateProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public faseActual: FaseActualProvider,
  ) {

    if (faseActual.valorObservacionsEdicio.valor > "") {
      const valorActualStr = faseActual.valorObservacionsEdicio.valor;
      let aux = valorActualStr.substring(0, 10);
      aux = aux.split("/").reverse().join("-");
      this.respuestaFecha = new Date(aux).toISOString();

      if (PARTES_RESPUESTA_FECHA_HORA === this.faseActual.fase.tipusRespostaFase) {
        const timeStr = valorActualStr.substring(11, 19);
        const time = moment(timeStr, TIME_WITH_SECONDS_FORMAT, true);
        if (time.isValid()) {
          // parse to iso to avoid ionic mangling the time
          this.respuestaHora = time.format();
        } else {
          console.warn("El valor de hora no se pudo interpretar como hora. valor de fecha hora: " + valorActualStr);
        }
      }
    }
    this.locale = this.translate.currentLang;
  }

  resetDate(): void {
    this.respuestaFecha = null;
  }

  resetTime(): void {
    this.respuestaHora = null;
  }
  getDateUserFormat(date: string): string {
    return date ? moment(date).format(DATE_USER_FORMAT) : '';
  }
  getTimeAsString(time: string): string {
    return time ? moment(time).format(TIME_FORMAT) : '';
  }

  checkValorIsOk(): boolean {
    return true;
  }

  setValorFaseActual() {
    let valor = null;

    if (this.respuestaFecha) {
      const date = moment(this.respuestaFecha).format(DATE_USER_FORMAT);
      const time = moment(this.respuestaHora).set({ seconds: 0, milliseconds: 0 }).format(TIME_WITH_SECONDS_FORMAT);

      if (this.faseActual.fase.tipusRespostaFase === PARTES_RESPUESTA_FECHA_HORA) {
        valor = this.respuestaFecha && this.respuestaFecha.length > 0 ? date + " " + time : null;
      } else {
        valor = this.respuestaFecha && this.respuestaFecha.length > 0 ? date : null;
      }
    } else {
      valor = null;
    }

    this.faseActual.valorObservacionsEdicio.valor = valor;
  }
}
