import {Injectable} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {filter, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private event$ = new Subject<Event>();
  private subscriptions: Record<string, Subscription[]> = {};
  constructor() {
  }

  publish(name: string, data: any): void {
    this.event$.next({
      name,
      data
    });
  }

  subscribe(name: string, handler: (data: any) => void): Subscription {
    const subs = this.event$.pipe(
      filter(event => event.name === name),
      map(event => event.data)
    ).subscribe(handler);
    this.subscriptions[name] = this.subscriptions[name] || [];
    this.subscriptions[name].push(subs);
    return subs;
  }

  unsubscribe(name: string) {
    this.subscriptions[name].forEach(subs => subs.unsubscribe());
  }
}

interface Event {
  name: string;
  data: any;
}
