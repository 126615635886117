<div class="wrapper">
    <aside class="aside aside-1">
        <table class="tabla-fixed">
            <tr>
                <td style="width:38px">
                  <img *ngIf="imagenElementoNormalizada" [src]="imagenElementoNormalizada | safeHtml: 'resourceUrl'">
                </td>
                <td>
                    <p class="ion-text-left" style="padding-left:3px;margin: 0 0 2px">
                        <strong><span *ngIf="_etp && _etp.elemento.codiElement !== ''">{{_etp.elemento.codiElement}}
                                    - </span>
                                {{_etp.elemento.nomElement}}</strong>
                    </p>
                    <p class="ion-text-left ion-text-wrap" style="padding-left:3px;margin: 0 0 2px" >{{_etp.elemento.rutaElement}}</p>
                </td>
            </tr>
        </table>
    </aside>
    <aside class="aside aside-2">
        <table *ngIf="_etp.elemento.esDePlaEmergencia === 1 || _etp.elemento.esCritic === 1">
            <tr>
                <td *ngIf="_etp.elemento.esDePlaEmergencia === 1">
                    <table>
                        <tr>
                            <td style="width:38px">
                                <ion-icon name="lc-icon-element-pla-emergencia" class="critico-emergencia-class"></ion-icon>
                            </td>
                            <td>
                                <p style="padding-left:3px;margin: 0 0 2px" class="ion-text-wrap">{{elementoPlanEmergencia}}</p>
                            </td>
                        </tr>
                    </table>
                </td>
                <td *ngIf="_etp.elemento.esCritic === 1">
                    <table>
                        <tr>
                            <td style="width:38px">
                                <ion-icon name="lc-icon-element-critic" class="critico-emergencia-class"></ion-icon>
                            </td>
                            <td>
                                <p style="padding-left:3px;margin: 0 0 2px" class="ion-text-wrap">{{elementoCritico}}</p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </aside>
</div>
