<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
      <ion-buttons slot="start">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="lc-icon-arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
    <ion-title>
      {{ 'txListaPartes' | translate }}
      <p class="subtitle hide-when-big-screen">{{_pg.proyectoSeleccionado.nom}}</p>
    </ion-title>
  </ion-toolbar>


  <ion-item-divider id="encabezado" #encabezado class="header-elements" style="min-height: 10rem" id="header-data">
    <img slot="start" [src]="fullData.modelElement.srcIcona" style="width: 2.286em;"
         onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';">
    <ion-label>
      <div class="header-elements ion-text-center">{{'txPartesRelacionModeloElemento' | translate}}</div>

      <p style="padding-left:8px">
        <strong><span
          *ngIf="fullData.modelElement.codiElement && fullData.modelElement.codiElement !== ''">{{fullData.modelElement.codiElement}}
          - </span>
          {{fullData.modelElement.nomElement}}</strong>
      </p>
      <p style="padding-left:8px" class="ion-text-wrap">{{fullData.modelElement.rutaElement}}</p>
      <p style="padding-left:8px" class="ion-text-wrap"><label class="ion-text-wrap">{{fullData.modelElement.codiModel}}
        - {{fullData.modelElement.nomModel}}</label></p>
    </ion-label>
  </ion-item-divider>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-list id="lista" #lista>
    <ion-item-group>
      <ion-item id="item{{fullData.modelElement.idModelElement}}" *ngFor="let parte of fullData.partes"
                (click)="viewReport(parte, 'item'+fullData.modelElement.idModelElement)">
        <ion-label>
          <table class="tabla-fixed">
            <tr>
              <td class="codigo-parte" style="width:9em; height:0px;">
                <span *ngIf="parte.esFuturo">{{'txPartesPartePropuesto' | translate}}</span>
                <span *ngIf="!parte.esFuturo">{{'txPartesParteStr' | translate}}
                  {{parte.codiParte}}</span>
              </td>
              <td class="estado-parte move-width">
                <img class="icon-align-middle" [src]="parte.srcImgEstadoParte"> <span> {{parte.txEstadoParte | translate}}</span>
              </td>
            </tr>
            <tr>
              <td class="codigo-parte" style="min-width:9em; height:0px;" *ngIf="parte.dataHoraExecucio">
                <span>{{ parte.dataHoraExecucio | dateFormat: true : true }}</span>
              </td>
              <td colspan="3" class="horquilla-parte">
                {{parte.forqueta}}
              </td>
            </tr>
          </table>
        </ion-label>
      </ion-item>
    </ion-item-group>
  </ion-list>

  <label *ngIf="fullData.partes.length === 0 && consultaWSRealizada"
         class="text-no-existe">{{'txPartesNoEncontrados' | translate}}</label>

  <ion-infinite-scroll (ionInfinite)="doInfinite($event) " [disabled]="!hayMasElementos ">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
  <label *ngIf="! hayMasElementos"
         class="text-no-existe ion-float-right">{{'txPartesNoMasElementos' | translate}}</label>
</ion-content>
