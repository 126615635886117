import {Observable} from "rxjs";
import {AjaxClientProvider} from "@app/services/ajax-client/ajax-client.provider";
import {PostParams} from "@app/interfaces/ajax.interface";
import {PARAM_GET_TIPOLOGIES_ESTAT_ELEMENTS, PARAM_ID_GRUPO_GESTIO, PARAM_STRCONN} from "@app/config/constantes";
import {Injectable} from "@angular/core";
import {WsUtilsProvider} from "@app/services/utils-ws/ws-utils";
import {PersistenciaGeneralProvider} from "@app/services/persistencia-configuracion/persistencia-general.provider";
import {ElementState} from "@app/core/element/domain/element-state";
import {GetElementsStateTypologiesWebService} from "@app/core/element/domain/get-elements-state-typologies.web-service";


@Injectable()
export class GetElementStateTypologiesHttpWebService implements GetElementsStateTypologiesWebService{
  constructor(
    private _ajax: AjaxClientProvider,
    public _wsUtils: WsUtilsProvider,
    public _pg: PersistenciaGeneralProvider,
  ) {
  }

  getParams(): PostParams[] {
    return [
      {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      },
      {
        nombre: PARAM_ID_GRUPO_GESTIO,
        valor: this._pg.proyectoSeleccionado.idGrupoGestio.toString()
      }
    ]
  }


  execute(): Observable<ElementState[]> {
    const operation = PARAM_GET_TIPOLOGIES_ESTAT_ELEMENTS;
    const params = this.getParams();

    return this._ajax.consultarWS(operation, params, 0);
  }
}
