import {ElementState} from "@app/core/element/domain/element-state";
import {Element} from "@app/core/element/domain/element";
import { ElementInfo } from "./element-info";

export abstract class ElementDAO {
  abstract save(element: Element): Promise<Element>;
  abstract get(elementInfo: ElementInfo): Promise<Element>;
  abstract getElementState(): Promise<ElementState[]>;
  abstract saveElementState(element:  ElementState[]): Promise<ElementState[]>;
  abstract saveElementInfos(elementInfos: ElementInfo[]): Promise<ElementInfo[]>;
  abstract getElementInfos(): Promise<ElementInfo[]>;
}
