<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
    <ion-buttons slot="start">
      <ion-button
        class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button"
        (click)="cerrarSinParametros()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'txDescripcionDelElemento' | translate}}</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="cerrarConParametros()">
        <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item>
    <ion-label>
      <ion-textarea [(ngModel)]="descripcioElement" rows="20" cols="20"
                    placeholder="{{'txDescripcionDelElementoIngresar' | translate}}"></ion-textarea>
    </ion-label>
  </ion-item>
</ion-content>
