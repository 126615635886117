import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'lc-input-number',
  templateUrl: 'lc-input-number.html',
  styleUrls: ['lc-input-number.scss']
})
export class LcInputNumberComponent {

  @Input() enterFunction: Function;
  @Input() value: string;
  @Output() valueChange = new EventEmitter();
  @Input() decimal: boolean;
  @Input() negative: boolean;
  @Input() name: string;
  @Input() placeholder: string;

  errorNumeroEntero = false;
  errorNumeroEnteroNoNegativo = false;
  errorNumerodecimalParam = false;
  errorNumerodecimalParamNoNegativo = false;
  inputExitError = false;
  isIos = false;

  constructor(public _html: HtmlUtilsProvider,
              public platform: Platform) {
    // console.log('Hello LcInputNumberComponent Component');
  }

  getValue(){
    return  this.value == undefined || this.value === '' || Number.isNaN(Number(this.value)) ? null : Number(this.value);
  }

  emitValue(): void {
    this.valueChange.emit(this.value);
  }

  anyError(){
    if(this.value && !this.decimal && this.negative){ //es entero
      this.errorNumeroEntero = isNaN(Number(this.value)) || (!(this.value == '') && !this._html.isInt(Number(this.value)));
      return this.errorNumeroEntero;

    }else if(this.value && !this.decimal && !this.negative){ //es entero no negativo
      this.errorNumeroEnteroNoNegativo = ((isNaN(Number(this.value)) || (!(this.value == '') && !this._html.isInt(Number(this.value)))) || Number(this.value) < 0);
      return this.errorNumeroEnteroNoNegativo;

    }else if(this.value && this.decimal && this.negative){ //es decimalParam
      this.errorNumerodecimalParam = this.errorNumerodecimalParam = isNaN(Number(this.value)) || (!(this.value == '') && (!this._html.isFloat(parseFloat(String(this.value))) && !this._html.isInt(Number(this.value)))) ;
      return this.errorNumerodecimalParam;

    }else if(this.value && this.decimal && !this.negative){ //es decimalParam no negativo
      this.errorNumerodecimalParamNoNegativo = ((isNaN(Number(this.value)) || (!(this.value == '') && (!this._html.isFloat(parseFloat(String(this.value))) && !this._html.isInt(Number(this.value))))) || parseFloat(this.value) < 0);
      return this.errorNumerodecimalParamNoNegativo;

    }else{
      this.errorNumeroEntero = false;
      this.errorNumeroEnteroNoNegativo = false;
      this.errorNumerodecimalParam = false;
      this.errorNumerodecimalParamNoNegativo = false;
      return false;
    }
  }

  onBlurMethod(){
    this.inputExitError = this.anyError();
    this.valueChange.emit(this.value);

  }
}
