import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { PersistenciaGeneralProvider } from '../persistencia-configuracion/persistencia-general.provider';
import { DEBUG } from '@app/config/constantes';
import { EventService } from '../events/event.service';
import { HttpLC } from '@app/services/http-lc/http-lc';
import { ConfiguracioServidorDB } from '@app/domains/configuracio-servidor-domain';
import { Network as NetworkCapacitor } from '@capacitor/network';
@Injectable({
  providedIn: 'root'
})
export class NetworkProvider {

  appOnline = false;
  networkType: string = null;
  urlBases = [];
  urlConfiguracion: string | undefined;
  baseUrlOk = '';
  detectoDesconexion = false;


  constructor(
    private network: Network,
    public httpLC: HttpLC,
    private zone: NgZone,
    private platform: Platform,
    public events: EventService,
    public _pg: PersistenciaGeneralProvider) {

    platform.ready().then(() => {

      this.platform.resume.subscribe((res) => {
        // console.log('resume en NetworkProvider');

        this.zone.run(async () => {
          // console.log('entre por zone run resume en NetworkProvider');
          await this.ping();
        });
      });
    });
  }

  private getConection = async (i: any) => {
    try {
      return await new Promise((resolve, reject) => {
        this.baseUrlOk = '';
        if (this.baseUrlOk === '') {
          // console.log('intentando conectar con: ' + this.urlBases[i]);
          this.httpLC.get(this.urlBases[i])
            .subscribe(
              (value) => {
                this.baseUrlOk = this.urlBases[i];
                this.events.publish('baseUrlOk:created', this.baseUrlOk);
                // console.log('conectado a >>>>>> : ' + this.baseUrlOk);
                this.detectoDesconexion = false;
              },
              (err) => {
                // console.error('error >>>>>> : URL: ' + this.urlBases[i] + 'JSON ERR---> ' + JSON.stringify(err));
              },
              () => {
                // console.log('this.appOnline ---> ' + this.appOnline);
                resolve(true);
              }
            );
        }
      });
    } catch (err_1) {
      // console.error('error al obtener la conexión: ' + JSON.stringify(err_1));
    }
  };


  async ping() {
    // console.log('this.network.type >>> ' + this.network.type);
    this.networkType = this.network.type;
    await this.setAppOnline();

    if (this.baseUrlOk === '') {
      // console.log('this.baseUrlOk.baseUrlOk >>> ' + this.baseUrlOk);
      if (this._pg.urlBases.length > 0) {
        this.urlBases = this._pg.urlBases;

        for (let i = 0; i < this.urlBases.length; i++) {
          await this.getConection(i);

          if (this.baseUrlOk !== '') {
            this.detectoDesconexion = false;
            break;
          }
        }
      } else {
        this._pg.getConfiguracioServidorFromDb()
          .then(async (config: ConfiguracioServidorDB) => {
            // console.log('carga config desde ping...', config);

            for (let i = 0; i < this.urlBases.length; i++) {
              await this.getConection(i);

              if (this.baseUrlOk !== '') {
                break;
              }
            }

          }).catch(err => {
            // console.error(JSON.stringify(err))
          });
      }
    }
  }

  resetConnection() {
    this.appOnline = false;
    this.networkType = null;
    this.baseUrlOk = '';
  }

  resetBaseUrlOk() {
    this.baseUrlOk = '';
    this.urlBases = [];
  }


  async setAppOnline() {
    const status = await NetworkCapacitor.getStatus();

      if(!status.connected && !navigator.onLine) {
        // segunda validación
        this.appOnline = false;
      }

      this.appOnline = navigator.onLine;
      return;
  }
}
