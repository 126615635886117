import { Injectable } from '@angular/core';

import { AlertController, Platform } from '@ionic/angular';

import { File } from '@awesome-cordova-plugins/file/ngx';
import {
  GALLERY_REGEX_TOREPLACE_FROM_OPT1,
  GALLERY_REGEX_TOREPLACE_FROM_OPT2,
  GALLERY_REPLACE_TO_OPT1,
  GALLERY_REPLACE_TO_OPT2,
  PATH_ASSETS
} from '../../config/constantes';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { UiMessagesProvider } from '../ui-messages/ui-messages';
import { TranslateProvider } from '../translate/translate.provider';
import { Directory, Filesystem } from "@capacitor/filesystem";
import { PersistenciaGeneralProvider } from '../persistencia-configuracion/persistencia-general.provider';

@Injectable({
  providedIn: 'root'
})
export class FileProvider {

  path = '';
  dirImgTemp = 'temp';
  dirLog = 'Logs';
  dirResources = 'Resources';
  // pathDestinoTemp = ';
  pathDestinoTemp = this.dirImgTemp;
  fileErrorLog = 'idam_log_events.txt';
  fileErrorLogOld = 'idam_log_events_old.txt';
  pathOs = '';

  dirGeme = 'geme';

  dirIcones = 'icones';
  dirTipologies = 'iconestreball';
  dirEstats = 'iconesestats';
  dirEstatsincidencies = 'iconesestatsincidencies';
  dirIncidencies = 'iconesIncidencies';
  dirImatgeElement = 'imatgeselements';

  dirAdjunts = 'adjunts';
  dirImatgesfases = 'imatgesfases';
  dirImatgesrespostesfases = 'imatgesrespostesfases';
  dirPdfsrespostesfases = 'pdfsrespostesfases';
  dirArxiutecnic = 'arxiutecnic';

  pathDestinoGemeIcones = 'gemeicons';
  pathDestinoGemeTipologies = 'gemetypologies';
  pathDestinoGemeEstats = 'gemestates';
  pathDestinoGemeEstatsincidencies = 'gemestatesincidences';
  pathDestinoGemeIncidencies = 'gemeincidences';
  pathDestinoGemeImatgeElement = 'gemeimageelement';

  pathDestinoAdjunts = 'attachments';
  pathDestinoImatgesfases = 'imagesphases';
  pathDestinoImatgesrespostesfases = 'imagesanswersphases';
  pathDestinoPdfsrespostesfases = 'pdfanswersphases';
  pathDestinoArxiutecnic = 'tecnicalfile';


  constructor(private file: File,
    private fileOpener: FileOpener,
    private alertCtrl: AlertController,
    private _messages: UiMessagesProvider,
    public _pg: PersistenciaGeneralProvider,
    public _translate: TranslateProvider,
    private platform: Platform) {
    // console.log('Hello FileProvider Provider');


    this.platform.ready().then(() => {

    });

  }

  //eliminar archivos de imagen descargados
  eliminarArchivosImagen() {

    return new Promise((resolve, reject) => {

      this.platform.ready().then(() => {

        this.file.checkDir(this.path, this.dirImgTemp)
          .then((success) => {

            if (success) {

              this.file.removeRecursively(this.path, this.dirImgTemp)
                .then((res) => {
                  resolve('Eliminado correctamente');
                })
                .catch(err => {
                  // console.error(JSON.stringify(err, Object.getOwnPropertyNames(err)));
                  resolve('No se ha Eliminado nada');
                });
            } else {
              resolve('No se ha Eliminado nada');
            }

          })
          .catch(err => {
            // console.error(JSON.stringify(err, Object.getOwnPropertyNames(err)));
            resolve('No se ha Eliminado nada');
          });
      });

    });

  }

  async toBase64Image(filepath: string) {
    try {
      const response = await Filesystem.readFile({
        path: filepath,
        directory: Directory.Data
      });
      return 'data:image/*;charset=utf-8;base64,' + response.data;
    } catch (err) {
      // console.log('error tobase64Image', err)
    }
  }

  toBase64ImageIndicatingTheDirectory(filepath: string, directory: Directory): Promise<string> {
    return Filesystem.readFile({
      path: filepath,
      directory
    }).then(base64 => 'data:image/*;charset=utf-8;base64,' + base64.data)
      .catch(err => 'Error: ' + JSON.stringify(err));
  }

  writeFileBase(content: string, filename: string) {
      // this.dirImgTemp
      return Filesystem.writeFile({
        path: `${this.pathDestinoTemp}/${filename}`,
        data: content,
        directory: Directory.Data
      });
  }

  deleteFileBase(filename: string, directory?: Directory) {
    // desktop
    return Filesystem.deleteFile({
      path: `${this.pathDestinoTemp}/${filename}`,
      directory: directory ? directory : Directory.Data
    });
  }


  //escribe un archivo recibe como parámetro el contenido y el nombre con el cual se guardará
  writeFile(content: string, filename: string) {

    fetch(content, {
      method: 'GET'
    }).then(res => res.blob()).then(blob => {
      this.file.writeFile(this.pathDestinoTemp, filename, blob, {
        replace: true
      })
        .then(res => {

          // console.log('archivo guardado->');
          // console.log(this.pathDestinoTemp + '/' + filename);

          this._messages.showOkAlert(
            undefined,
            'Guardado: ' + this.pathDestinoTemp + '/ ' + filename,
            'OK'
          );

        }).catch(err => {
          // console.error('error writeFile FOTO:' + JSON.stringify(err));
        });
    }).catch(err => {
      // console.error('error writeFile FOTO:' + JSON.stringify(err));
    });
  }

  base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  //escribe un archivo recibe como parámetro el contenido y el nombre con el cual se guardará
  writeImageFile(content: string, pathDestino: string, filename: string, shouldReplace: boolean = false) {

    const _thisCtx = this;
    const promisesArray: Array<any> = [];

    promisesArray.push(new Promise((resolve, reject) => {
      // console.log('writeFile( file provider filename--> ' + filename);

        // console.warn("File is not available on this device");
        const route = `${pathDestino}/${filename}`;
        this._pg.saveOnIndexedDB(route, content, Directory.Documents).then(() => {
          resolve(true);
        }).catch(err => {
          console.error('errore save', err);
          resolve(true);
        });
    }));

    return Promise.all(promisesArray);

  }

  private getMetadata(resolve: any, fileEntry: any, fileUri: any, result: any) {
    fileEntry.getMetadata((metadata) => {
      const mbResult = this.bytesToMb(metadata.size);
      result.fileUri = fileUri;
      result.size = mbResult;
      resolve(result);
    }, err => {
      // console.error('error en getMetadata: ' + fileUri + ' - ERR - ' + JSON.stringify(err));
    });
  }

  bytesToMb(bytes: any) {
    return bytes / Math.pow(1024, 2);
  }

  getFullPathErrorLogFile() {
    return this.pathOs + this.dirLog + '/' + this.fileErrorLog;
  }

  showPdf(filename: string, errorMsg?: string) {
    const msg = errorMsg ? errorMsg : 'txFicheroNoDisponibleOffline';
    this.fileOpener.open(filename, 'application/pdf')
      .then(() => {
        // console.log('File is opened')
      })
      .catch((e) => {
        // console.error('error showPdf() path: ' + filename, JSON.stringify(e));
        this._messages.showToastDefault(msg);
      });
  }

  showAssetsPdf(filename: string, path: string): void {
    const pdfUrl = `${path}${filename}`;

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  private validateNotFound(resolve: any, fileUri: any, result: any, replaceFrom: any, replaceTo: any) {
    const fileToParamRe = fileUri.replace(replaceFrom, replaceTo);
    // console.log('obtengo nuevamente la ruta:: ' + fileToParamRe);
    this.file.resolveLocalFilesystemUrl(fileToParamRe)
      .then(fileEntry => {
        this.getMetadata(resolve, fileEntry, fileToParamRe, result);
      })
      .catch(error => {
        // console.error('error en fileEntry: fileToParamRe: ' + fileToParamRe + ' - ERR - ' + JSON.stringify(error));

        // console.error('valor de validacion si es la última opcion: ' + (replaceFrom == GALLERY_REGEX_TOREPLACE_FROM_OPT2));
        if (replaceFrom == GALLERY_REGEX_TOREPLACE_FROM_OPT2) {
          resolve(false);
          return;
        }
        this.validateNotFound(resolve, fileUri, result, GALLERY_REGEX_TOREPLACE_FROM_OPT2, GALLERY_REPLACE_TO_OPT2);
      });
  }

}
