import {Component} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import * as Data from '../../config/constantes';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';


/**
 * Generated class for the PartesSearchModalPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-partes-search-modal',
  templateUrl: 'partes-search-modal.html',
  styleUrls: ['partes-search-modal.scss']
})
export class PartesSearchModalPage {

  criterio: number = null;
  valorSearch = '';

  constants = Data;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              public _pg: PersistenciaGeneralProvider,
              private modalCtrl: ModalController,
  ) {
    if (navParams.get('criterio') && navParams.get('criterio') != '') {
      this.criterio = navParams.get('criterio');
    } else {
      this.criterio = this.constants.PARTES_BUSCAR_X_CUALQUIERA; //6: cualquiera
    }
    this.valorSearch = navParams.get('valorSearch');
  }


  cerrarConParametros() {

    // Ionic inst accepting default value
    if (this.criterio == null) {this.criterio = Data.PARTES_BUSCAR_X_CUALQUIERA;}

    const data = {
      criterio: this.criterio,
      valorSearch: this.valorSearch
    };

    const callback = this.navParams.get('callback');

    callback(data).then(() => {
      this.modalCtrl.dismiss();
    });
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }

  compareBuscarFn(e1: string, e2: string): boolean {
    if (e1 == '') {
      // Ionic inst accepting default value
      e1 = String(Data.PARTES_BUSCAR_X_CUALQUIERA);
    }
    return e1 && e2 ? e1 === e2 : e1 === e2;
  }

}
