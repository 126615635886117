<ion-content class="ion-no-padding">
  <elemento-tab-encabezado></elemento-tab-encabezado>

  <div>
    <div class="wrapper-img" *ngIf="validateShow()">
      <aside class="contenido" aria-label="image vertical mode">
        <img style="width: 400px; height:400px; display: block;" class="show-image" *ngIf="validateShow()"
             [src]="urlImage" #preview
             (error)="preview.src = 'assets/imgs/no_image_ele.png'" alt="">
      </aside>
    </div>
    <aside class="aside aside-2-img" *ngIf="validateShow()" aria-label="image buttons and info">
      <p class="ion-text-center">
        {{imagenes[idx].nom}}
      </p>
      <p class="ion-text-center">
        {{'txImagen' | translate}} {{idx + 1}} {{'txDeStr' | translate}} {{imagenes.length}}
      </p>
      <ion-row class="ion-text-center">
        <ion-col>
          <ion-icon name="lc-icon-arrow-back" [ngClass]="{'sin-movimiento': (!(idx > 0))}"
            (click)="backClick()"></ion-icon>
          </ion-col>
          <ion-col *ngIf="_etp.editando && _etp.permissions[0]">
            <ion-icon name="lc-icon-edit" class="icon-edit"
              (click)="editImage()"></ion-icon>
          </ion-col>
        <ion-col>
          <ion-icon name="lc-icon-arrow-forward" [ngClass]="{'sin-movimiento': (!(idx < imagenes.length - 1))}"
            (click)="forwardClick()"></ion-icon>
        </ion-col>
      </ion-row>
    </aside>
  </div>

  <!--inicio ladscape-->
  <div id="container-land">
    <div class="column left">
    </div>
    <div class="column center">
      <div class="wrapper-img-land" *ngIf="validateShow()">
        <aside class="contenido" aria-label="image landscape mode">
          <img style="width: 400px; height:400px; display: block;" class="show-image" *ngIf="validateShow()"
               [src]="urlImage" #preview
               (error)="preview.src = 'assets/imgs/no_image_ele.png'" alt="">
        </aside>
      </div>
    </div>
    <div class="column right" text-center *ngIf="validateShow()">
      <p class="ion-text-center">
        {{imagenes[idx].nom}}
      </p>
      <p class="ion-text-center">
        {{'txImagen' | translate}} {{idx + 1}} {{'txDeStr' | translate}} {{imagenes.length}}
      </p>
      <p class="ion-text-center">
        <ion-icon name="lc-icon-arrow-back" [ngClass]="{'sin-movimiento': (!(idx > 0))}"
                  (click)="backClick()"></ion-icon>
        <ion-icon name="lc-icon-arrow-forward" [ngClass]="{'sin-movimiento': (!(idx < imagenes.length - 1))}"
                  (click)="forwardClick()"></ion-icon>
        <ion-icon name="lc-icon-edit" class="icon-edit" *ngIf="_etp.editando && _etp.permissions[0]" (click)="editImage()"></ion-icon>
      </p>
    </div>
  </div>
  <!--fin ladscape-->
  <div class="elements-add-image">
    <div class="sin-imagenes" *ngIf="imagenes.length === 0">
      <label class="text-no-existe">{{ 'txSinImagenes' | translate}}</label>
    </div>
    <ion-button expand="block" color="primary" class="ion-margin" (click)="editImage()" *ngIf="imagenes.length === 0 && _etp.editando && _etp.permissions[0]">
      <span class="text-no-existe">{{"txAddImages" | translate}}</span>
    </ion-button>
  </div>

  <div class="sin-imagenes" *ngIf="_pg.workingOffline && _pg.usuarioLoginModel.syncImatgesElements !== 1">
    <label class="text-no-existe">{{ 'txNoImagenOffline' | translate}}</label>
  </div>
</ion-content>
