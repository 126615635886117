import { StorageProvider } from '../../services/storage/storage.provider';
import { Component, OnInit } from '@angular/core';
import {
  MenuController,
  Platform,
  AlertController
} from '@ionic/angular';
import { NetworkProvider } from '../../services/network/network.provider';
import { EncryptProvider } from '../../services/encrypt/encrypt.provider';
import { UiMessagesProvider } from '../../services/ui-messages/ui-messages';
import {
  PARAM_STRLOGIN,
  PARAM_GET_CONNEXIO,
  CLAVE_ENCRIPTACION_BBDD,
  ERROR_CATEGORIA_LOGIN_INCORRECTO,
  PARTES_CONFIG_ORDENAR_X_POSICION_ARBOL,
  PARTES_CONFIG_MOSTRAR_X_HACER_Y_PASADOS,
  PARTES_CONFIG_MOSTRAR_EXPANDIDO,
  SYNC_IMATGES_ELEMENTS,
  TIMEOUT_CONSULTAS_WS_DEFECTO_SEG
} from '../../config/constantes';
import { HttpLC } from '../../services/http-lc/http-lc';
import { AjaxClientProvider, ErrorConsulta } from '../../services/ajax-client/ajax-client.provider';
import { PostParams } from '../../interfaces/ajax.interface';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { TranslateService } from '@ngx-translate/core';
import { WsUtilsProvider } from '../../services/utils-ws/ws-utils';
import { ResourcesProvider } from '../../services/resources/resources.provider';
import { UsuariDB, UsuarioLogin } from '../../domains/usuario-domain';
import { ExternalLinksProvider } from '../../services/external-links/external-links.provider';
import { FileProvider } from '../../services/file/file.provider';
import { StatusProvider } from '../../services/status/status.provider';
import { UpdateAppProvider } from '../../services/update-app/update-app.provider';
import { SyncProvider } from '../../services/sync/sync.provider';
import { AppRoutes } from 'src/app/config/app-routes';
import { EventService } from '../../services/events/event.service';
import { Proyecto } from '@app/domains/proyecto-domain';
import { Directory, Encoding } from '@capacitor/filesystem';

import I18n from '@app/i18n/i18n';

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss'],
})
export class LoginPage implements OnInit {

  usuario = '';
  password = '';

  usuarioLogin: UsuarioLogin;

  recordarUsuarioPass = false;
  defaultStoragePassord = '****';

  constructor(
    public alertCtrl: AlertController,
    private menuCtrl: MenuController,
    private _ntProv: NetworkProvider,
    private _encrypt: EncryptProvider,
    private _ui: UiMessagesProvider,
    public httpLC: HttpLC,
    private _ajax: AjaxClientProvider,
    public _htmlUtils: HtmlUtilsProvider,
    private platform: Platform,
    private translateService: TranslateService,
    private _status: StatusProvider,
    public _pg: PersistenciaGeneralProvider,
    private _wsUtils: WsUtilsProvider,
    private _resources: ResourcesProvider,
    private _sync: SyncProvider,
    private storage: StorageProvider,
    public _extLinks: ExternalLinksProvider,
    public _fp: FileProvider,
    public _updateApp: UpdateAppProvider,
    public events: EventService,
    public i18n: I18n) {

  }
  async ngOnInit(): Promise<void> {
    await this.menuCtrl.enable(false);
    await this.cargarStorage();
  }

  appVersion(): string{
    return this._pg.versionApp;
  }

  /**
   * Handles the login click event.
   */
  async loginClick(): Promise<void> {
    // Check if login fields are valid
    if (!this.areFieldsValid()) {
      return;
    }

    // Execute login
    const loginResult = await this.loginProcess();

    // Ensure that login is correct
    if (!loginResult) {
      return;
    }

    // Store user credentials and fetch user favorites
    this.storeCredentials();
    await this.fetchFavorites();

    // Synchronize data if online
    if (!this._pg.workingOffline) {
      await this.synchronizeData();
    }

    // Prompt the user to select a project
    const selectedProject = await this.promptUserToSelectProject();

    if (selectedProject) {
      this.selectAndEnter(selectedProject);
    }
  }

  /**
   * Synchronizes data, downloading remote data and sending local data.
   */
  async synchronizeData(): Promise<void> {
    await this._resources.downloadRemoteData();
    await this._sync.sendLocalData();
  }

  /**
   * Prompts the user to select a project.
   * If there's only one project, it's automatically selected.
   * If there are multiple projects, the user is prompted to select one.
   *
   * @returns The selected project or null if none is selected.
   */
  async promptUserToSelectProject(): Promise<Proyecto | null> {
    // Ensure that there are available projects
    const projects = this._pg.proyectosDisponibles;
    // console.log("promptUserToSelectProject", JSON.stringify(projects));

    // Check if there are available projects
    if (projects.length === 0) {
      // Show a toast message indicating that no projects are available
      // console.log("txNoExistenProyectosDisponibles");
      this._ui.showToastDefault('txNoExistenProyectosDisponibles');
      return null;
    }
    // Get the user-selected project based on their previous selection
    // eslint-disable-next-line max-len
    let selectedProject: Proyecto | null = this._pg.proyectosDisponibles.find(x => x.idProjecte === this._pg.usuarioLoginModel.idProjecteSeleccionat);

    if (!selectedProject) {
      // Automatically select a project if only one project exists or let the user select it manually
      selectedProject = projects.length === 1 ? projects[0] : await this.selectProject();
    }

    return selectedProject;
  }

  /**
   *
   *
   * Selects a project and performs login.
   *
   *
   *
   * @returns The selected project, or null if no project is selected.
   */
  private async selectProject(): Promise<Proyecto | null> {
    const data = await this._status.doRadioProyectos(this._pg.proyectosDisponibles, 'txSeleccionarProyectoLogin');
    if (!data) {
      return null;
    }

    if (!this._pg.proyectoSeleccionado) {
      return null;
    }
    await this._status.guardarProyectoSeleccionado(this._pg.proyectoSeleccionado);
    this.ingresar();
    return this._pg.proyectoSeleccionado;
  }

  /**
   *
   *
   * Selects a project and performs login.
   *
   *
   *
   * @param proyecto - The selected project.
   */
  private selectAndEnter(proyecto: Proyecto): void {
    this._pg.proyectoSeleccionado = proyecto;
    this.events.publish('proyectoSeleccionadoMenu:created', [this._pg.proyectoSeleccionado, true]);
    this.ingresar();
  }



  loginProcess() {

    return new Promise(async (resolve, reject) => {
      const loading = await this._ui.showLoading('txAccediendoLogin');

      if (this._ntProv.appOnline) {

        const params: PostParams[] = [];

        const paramStrLogin: PostParams = {
          nombre: PARAM_STRLOGIN,
          // eslint-disable-next-line max-len
          valor: this._wsUtils.getStrLogin(this.usuario, this.correspondeLeerStorage() ? this._encrypt.decryptWithXor(this.usuarioLogin.password, CLAVE_ENCRIPTACION_BBDD) : this._wsUtils.getMd5Password(this.password))
        };

        params.push(paramStrLogin);

        this._ajax.consultarWS(PARAM_GET_CONNEXIO, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
          .subscribe(
            async (datos: any) => {
              loading.dismiss();
              const usuari = await this._pg.getUsuariByUserAndPass(this.usuario, this.getEncryptOrStoragePassword());
              // console.log('imprimo res de getUsuari en Login -_>>>>> ' + JSON.stringify(usuari));

              let lang = null;
              if (usuari != null && usuari.idioma && usuari.idioma.length > 0) {
                lang = usuari.idioma;
                this._pg.setMemoryLanguage(usuari.idioma);
              } else {
                lang = this._pg.defaultLanguaje;
              }

              this.translateService.use(lang);

              // console.log('imprimo LANG que voy a persistir con el usuario :  ' + lang);

              const data: UsuariDB = {
                idUsuari: datos.idUsuari,
                login: this.usuario.toLowerCase(),
                passwordXOR: this.getEncryptOrStoragePassword(),
                idConnexioUsuariApp: datos.idConnexioUsuariApp,
                idProjecteSeleccionat: usuari != null ? usuari.idProjecteSeleccionat : null,
                idioma: lang,
                partesTipusOrdre: usuari != null ? usuari.partesTipusOrdre : PARTES_CONFIG_ORDENAR_X_POSICION_ARBOL,
                partesTipusMostrar: usuari != null ? usuari.partesTipusMostrar : PARTES_CONFIG_MOSTRAR_X_HACER_Y_PASADOS,
                partesTipusView: usuari != null ? usuari.partesTipusView : PARTES_CONFIG_MOSTRAR_EXPANDIDO,
                syncImatgesElements: usuari != null ? usuari.syncImatgesElements : SYNC_IMATGES_ELEMENTS,
                ultimaSincronizacion: usuari != null ? usuari.ultimaSincronizacion : null
              };

              await this._pg.insertUsuari(data);

              //una vez guardado los datos trabajo con con proyectos que tiene el usuario
              this._pg.agregarProyectosDispnibles(datos.projectes);
              await this._pg.actualizarProyectosSicorresponde();
              await this._pg.refrescarProyectosUsuario(data.idUsuari, datos.projectes);
              await this._pg.selectProjectesDisponibles(data.idUsuari);

              resolve(true);
            },
            (errConsulta: ErrorConsulta) => {
              loading.dismiss();
              if (errConsulta.categoria === ERROR_CATEGORIA_LOGIN_INCORRECTO) {
                this._ui.showToastDefault('txUsuarioPassIncorrectosLoginGeneral');
              } else {
                this._ui.showToastDefault(errConsulta.mensaje);
              }
              // console.error('ERROR: ' + errConsulta.categoria + ' ' + errConsulta.categoria);
              resolve(false);
            }
          );

      } else {
        //Si no hay conexión al servidor: se debe validar con la BDD si el usuario es correcto
        const usuari = await this._pg.getUsuariByUserAndPass(this.usuario, this.getEncryptOrStoragePassword());
        if (usuari && usuari != null) {
            await this._pg.agregarProyectosDispniblesOffLine(usuari.idUsuari);
            loading.dismiss();
            resolve(true);
        } else {
          this._ui.showToastDefault('txUsuarioPassIncorrectosLogin');
          loading.dismiss();
          resolve(false);
        }
      }

    });

  }


  ingresar() {
    this.events.publish('setRoot:created', AppRoutes.homePage);
    this.menuCtrl.enable(true);

    //control de actualización de app
    this._updateApp.needUpdateAdvice();
  }

  /**
   * Checks if the user input fields (usuario and password) are valid.
   * Displays a toast message and returns false if any field is invalid.
   *
   * @returns True if both fields are valid, otherwise false.
   */
  private areFieldsValid() {
    if (this.usuario.length <= 1 || this.password.length <= 1) {
      // Display a default toast message to inform the user to complete the fields
      this._ui.showToastDefault('txCompletarUsuarioPassLogin');
      return false;
    }

    return true;
  }


  ionViewWillLeave() {
    this.platform.ready().then(async () => {
        const config = await this._pg.getConfiguracioServidorFromDb();
        // console.log('carga config desde login...', config);
    });
  }


  /**
   * Stores user credentials in indexedDB based on user preferences.
   * If `recordarUsuarioPass` is true, it saves the user's login and password securely.
   * If `recordarUsuarioPass` is false, it removes any stored credentials.
   */
  async storeCredentials() {
    // console.log('valor de this.recordarUsuarioPass: ' + this.recordarUsuarioPass);
    // Check if either the username or password is empty, and if so, do nothing.
    if (this.usuario === '' || this.password === '') {
      // console.log('salgo nomas');
      return;
    }

    if (!this.recordarUsuarioPass) {
      // console.log('elimino... ');
      this.removeCredentialsFromIndexedDB();
    } else {
      this.usuarioLogin = {
        // console.log('comienzo proceso de guardado en storage... ');

        // Prepare user credentials for storage
        login: this.usuario,
        password: this.getEncryptOrStoragePassword()
      };
        await this.saveCredentialsOnIndexedDB(this.usuarioLogin);
    }
  }

  private async removeCredentialsFromIndexedDB() {
    const directorySaveOnIndexedDB = Directory.Documents;
    await this._pg.removeFileOnIndexedDB('user_start', directorySaveOnIndexedDB);
  }

  private async saveCredentialsOnIndexedDB(usuarioLogin: any) {
    const path = 'user_start';
    const data = JSON.stringify(usuarioLogin);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      console.error('error save', errorSave);
    }
  }

  cargarStorage() {
    const promesa = new Promise<void>((resolve, reject) => {
      this.getCredentialsFromIndexedDB().then((data) => {
        if (data) {
          this.usuarioLogin = data;
          this.usuario = this.usuarioLogin.login;
          this.password = this.defaultStoragePassord;
          this.recordarUsuarioPass = true;
        }
      });
    });

    return promesa;

  }

  async getCredentialsFromIndexedDB() {
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      const data = await this._pg.getFileOnIndexedDB('user_start', directorySaveOnIndexedDB, Encoding.UTF8);
      return JSON.parse(data.data);
    } catch (error) {
      return null;
    }
  }

  private getEncryptOrStoragePassword(): string {
    if (this.correspondeLeerStorage()) {
      return this.usuarioLogin.password;
    } else {
      return this._encrypt.encryptWithXor(this._wsUtils.getMd5Password(this.password), CLAVE_ENCRIPTACION_BBDD);
    }
  }


  private correspondeLeerStorage() {
    return this.recordarUsuarioPass && this.usuarioLogin && this.usuarioLogin.password && this.password === this.defaultStoragePassord;
  }

  toggleValidarReset() {
    if (!this.recordarUsuarioPass && this.usuarioLogin && this.usuarioLogin.password) {
      this.password = '';
    }
  }

  /**
   * Fetches the user's favorite items from the local database.
   * These favorites are associated with the user's ID.
   * If favorites exist, they are retrieved and processed.
   * Any errors during the process are logged to the console.
   */
  private async fetchFavorites() {
    await this._pg.getFavsDB(this._pg.usuarioLoginModel.idUsuari);
  }


}
