<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-buttons slot="start">
            <ion-button class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button" (click)="cerrarSinParametros()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{'txPartesCosteExterno' | translate}}</ion-title>
        <ion-buttons slot="end">
            <ion-button *ngIf="indice >= 0" (click)="eliminar()">
                <ion-icon slot="icon-only" name="lc-icon-trash"></ion-icon>
            </ion-button>
            <ion-button (click)="cerrarConParametros()">
                <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ion-list>
        <ion-item>
            <ion-label position="floating">{{ 'txDescripcion' | translate }} *</ion-label>
            <ion-input [(ngModel)]="descripcio" type="text" (keyup.enter)="_htmlUtils.asignarFocus(lc)"></ion-input>
        </ion-item>
    </ion-list>

    <lc-input-number #lc name="costUnitari" placeholder="txPartesCosteUnitarioRequired" (amountChange)="costUnitari = $event" [enterFunction]="nothing" [decimal]="true" [negative]="false" [(value)]="costUnitari"></lc-input-number>
    <lc-input-number #lc name="quantitat" placeholder="txCantidadRequired" (amountChange)="quantitat= $event" [enterFunction]="nothing" [decimal]="true" [negative]="false" [(value)]="quantitat"></lc-input-number>

    <p *ngIf="costUnitari > 0 && quantitat > 0">
        {{'txTotal' | translate}}: {{(costUnitari * quantitat) | formatoNumerico }}
    </p>

    <p class="campos-obligatorios">
        * {{'txCamposObligatorios' | translate}}
    </p>
</ion-content>
