import {Subscription} from 'rxjs';
import {Component, QueryList, ViewChildren} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {LcInputNumberComponent} from '../../components/lc-input-number/lc-input-number';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';

@Component({
  selector: 'page-parte-costes-externos',
  templateUrl: 'parte-costes-externos.html',
  styleUrls: ['parte-costes-externos.scss']
})
export class ParteCostesExternosPage {


  @ViewChildren('lc') lccomponents: QueryList<LcInputNumberComponent>;

  indice = -1;
  costUnitari: any;
  quantitat: any;
  descripcio: any;

  private backButtonListener?: Subscription;

  constructor(public navCtrl: NavController,
              public _htmlUtils: HtmlUtilsProvider,
              public _ptp: PartesTabProvider,
              public _ui: UiMessagesProvider,
              public alertCtrl: AlertController,
              public platform: Platform,
              public _pg: PersistenciaGeneralProvider,
              public navParams: NavParams,
              public modalCtrl: ModalController,
              ) {

                // console.log(navParams);

                if(navParams.get("descripcio")){
                  this.descripcio = navParams.get("descripcio");
                }

                if(navParams.get("costUnitari")){
                  this.costUnitari = navParams.get("costUnitari");
                }

                if(navParams.get("quantitat")){
                  this.quantitat = navParams.get("quantitat");
                }

                this.indice = navParams.get("indice");
  }


   /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }
 /*** fin trabajo con el boton atras */

  cerrarConParametros(){

    let existsError = false;

    this.lccomponents.forEach(input => {
      existsError = input.anyError();
      if(existsError){
        return;
      }
      input.emitValue();
    });

    if (!existsError) {
        if(this.descripcio && this.descripcio != '' && this.costUnitari && this.costUnitari >= 0 && this.quantitat && this.quantitat >=0){
            const costo = {
              descripcio: this.descripcio,
              costUnitari: this.costUnitari,
              quantitat: this.quantitat
            }

            if(this.indice >= 0){
              this._ptp.parte.costos[this.indice] = costo;
            }else{
              this._ptp.parte.costos.push(costo);
            }

            this._ptp.parteHasDifference = true;
            this.modalCtrl.dismiss();
        }else{
            this._ui.showToastDefault('txMensajeCamposObligatorios');
        }
    }

  }

  cerrarSinParametros(){
		this.modalCtrl.dismiss();
  }

  eliminar(){
    this.confirmarEliminar(this.indice);
  }

  async confirmarEliminar(idx: number){
    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txPreguntaConfirmaEliminacion", "txYes", "txNo");
    alert.onDidDismiss().then((res) => {
          if(res.data.accepted){
            this._ptp.parte.costos.splice(idx, 1);
            //alert.dismiss();

            this._ptp.parteHasDifference = true;
            this.cerrarSinParametros();
            return false;
          }
          return false;
    });
  }

  nothing = () => {
    // console.log("sin accion");
	};

}
