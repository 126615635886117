<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">

    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>

    <ion-title>{{'txElementoStr' | translate}}
      <p class="subtitle hide-when-big-screen">{{_pg.proyectoSeleccionado.nom}}</p>
    </ion-title>

    <ion-buttons *ngIf="_etp.elementCanBeEdited" slot="end">
        <ng-container *ngIf="(serverProtocolVersion | async) as permissions">
          <ion-button *ngIf="permissions.editElements && !_etp.editando" (click)="editar()">
            <ion-icon name="lc-icon-edit"></ion-icon>
          </ion-button>
        </ng-container>
        <ion-button *ngIf="_etp.editando" (click)="aceptar()">
          <ion-icon name="lc-icon-check"></ion-icon>
        </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-tabs swipeTab (ionTabsDidChange)="transition($event)" (tabChange)="onTabChange($event)" #elementoTabs>
    <ion-tab-bar>
      <ion-tab-button tab="elemento-datos">
        <ion-label>
          {{'txElementoDatosBasicos' | translate}}
        </ion-label>
      </ion-tab-button>
      <ion-tab-button tab="elemento-imagenes">
        <ion-label>
          {{'txElementoImagenes' | translate}} ({{_etp.elemento.imatges.length}})
        </ion-label>
      </ion-tab-button>
      <ion-tab-button tab="elemento-anotaciones">
        <ion-label>
          {{'txElementoAnotaciones' | translate}}  ({{_etp.elemento.anotacions.length}})
        </ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>

