import { Component } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';

@Component({
  selector: 'page-partes-config-modal',
  templateUrl: 'partes-config-modal.html',
  styleUrls: ['partes-config-modal.scss'],
})
export class PartesConfigModalPage {

  _criterioOrdenar = '';
  _criterioMostrar = '';
  _criterioExpandirContraer = '';


  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              public _pg: PersistenciaGeneralProvider,
              private modalCtrl: ModalController,
              ) {

                if(navParams.get('criterioOrdenar') && navParams.get('criterioOrdenar') > ''){
                  this._criterioOrdenar = String(navParams.get('criterioOrdenar'));

                }else{
                  this._criterioOrdenar = String(this._pg.usuarioLoginModel.partesTipusOrdre);
                }

                if( navParams.get('criterioMostrar') &&  navParams.get('criterioMostrar') > ''){
                  this._criterioMostrar = String(navParams.get('criterioMostrar'));
                }else{
                  this._criterioMostrar = String(this._pg.usuarioLoginModel.partesTipusMostrar);
                }

                if( navParams.get('criterioExpandirContraer') &&  navParams.get('criterioExpandirContraer') > ''){
                  this._criterioExpandirContraer = String(navParams.get('criterioExpandirContraer'));
                }else{
                  this._criterioExpandirContraer = String(this._pg.usuarioLoginModel.partesTipusView);
                }


  }



  cerrarConParametros(){

    const data = {
      criterioOrdenar: this._criterioOrdenar,
      criterioMostrar: this._criterioMostrar,
      criterioExpandirContraer: this._criterioExpandirContraer
    };

    const callback = this.navParams.get('callback');

    callback(data).then(async ()=>{
      await this._pg.updateFilterConfig(
        parseInt(this._criterioOrdenar, 10), parseInt(this._criterioMostrar, 10), parseInt(this._criterioExpandirContraer, 10)
      );

      this.modalCtrl.dismiss();
    });
  }

  cerrarSinParametros(){
    this.modalCtrl.dismiss();
  }

  compareOrdenarFn(e1: string, e2: string): boolean {
    return e1 && e2 ? e1 === e2 : e1 === e2;
  }

}
