import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {PromptLabels} from "@app/image-picker/image-controller.service";

interface NativeLabels {
    promptLabelHeader: string;
    promptLabelCancel: string;
    promptLabelPhoto: string;
    promptLabelPicture: string;
  }

/**
 * Wrapper for Capacitor Camera plugin.
 */
export class ImagePickerNativeController {
  static readonly QUALITY = 50;
  static readonly ALLOW_EDITION = false;

  private labels: NativeLabels

  /**
   * Maps the labels to the labels used by the capacitor camera plugin.
   * @param labels
   */
  set translations(labels: PromptLabels) {
    this.labels = {
      promptLabelHeader: labels.labelHeader,
      promptLabelCancel: labels.labelCancel,
      promptLabelPhoto: labels.labelGallery,
      promptLabelPicture: labels.labelCamera,

    };
  }

  /**
   * Asks the user to select an image from the camera or the gallery and returns the native full path of the image.
   */
  async getImageByPrompt(): Promise<string> {
    return this.getImage(CameraSource.Prompt, this.labels);
  }

  /**
   * Asks the user to select an image from the camera and returns the native full path of the image.
   */
  async getImageFromCamera(): Promise<string> {
    return this.getImage(CameraSource.Camera, this.labels);
  }

  /**
   * Asks the user to select an image from the gallery and returns the native full path of the image.
   */
  async getImageFromGallery(): Promise<string> {
    return this.getImage(CameraSource.Photos, this.labels);
  }

  /**
   * Asks the user to select an image and returns the native full path of the image.
   * @param source
   * @param labels
   */
  async getImage(source: CameraSource, labels: NativeLabels ): Promise<string> {
    const image = await Camera.getPhoto({
      quality: ImagePickerNativeController.QUALITY,
      allowEditing: ImagePickerNativeController.ALLOW_EDITION,
      resultType: CameraResultType.Uri,
      source,
      ...labels,
    });
    return image.path;
  }
}
