import {Component} from '@angular/core';
import {NetworkProvider} from 'src/app/services/network/network.provider';
import {FileProvider} from 'src/app/services/file/file.provider';
import {UiMessagesProvider} from 'src/app/services/ui-messages/ui-messages';


@Component({
  selector: 'page-debug',
  templateUrl: 'debug.html',
})
export class DebugPage {

  np: any = null;
  resultado = "";

  constructor(private _np: NetworkProvider,
              private _fp: FileProvider,
              private _ui: UiMessagesProvider) {

                // console.log("constructor de debugPage");
                this.np = this._np;
  }

  ionViewDidEnter() {
    // console.log('ionViewDidEnter DebugPage');
  }

  async showSpinner(duarcionMs: number, msg){
    await this._ui.showLoading(msg, duarcionMs);
  }

}
