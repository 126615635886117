import { Component } from '@angular/core';
import { NavController, NavParams, LoadingController, ModalController } from '@ionic/angular';
import { StructTree } from '../../components/tree-view/struct';
import { PostParams } from '../../interfaces/ajax.interface';
import { NetworkProvider } from '../../services/network/network.provider';
import { UiMessagesProvider } from '../../services/ui-messages/ui-messages';
import { AjaxClientProvider, ErrorConsulta } from '../../services/ajax-client/ajax-client.provider';
import { PARAM_GET_ARBRE_PROJECTE, PARAM_STRCONN, PARAM_ID_PROJECTE, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG } from '../../config/constantes';
import { WsUtilsProvider } from '../../services/utils-ws/ws-utils';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { FileProvider } from '../../services/file/file.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { TreeViewProvider } from '../../services/tree-view/tree-view.provider';
import { PartesTabProvider } from '../../services/partes-tab/partes-tab.provider';
import { SyncProvider } from '../../services/sync/sync.provider';

/**
 * Generated class for the PartesTreeviewModalPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-partes-treeview-modal',
  templateUrl: 'partes-treeview-modal.html',
  styleUrls: ['partes-treeview-modal.scss']
})
export class PartesTreeviewModalPage {

  screenWidth: any = 0;
  incluirSubElementos = true;
  nodesTree: Array<StructTree> = [];

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              //public viewCtrl: ViewController,
              public _np: NetworkProvider,
              public _ajax: AjaxClientProvider,
              public _messages: UiMessagesProvider,
              public _wsUtils: WsUtilsProvider,
              public _pg: PersistenciaGeneralProvider,
              public _fp: FileProvider,
              public _htmlUtils: HtmlUtilsProvider,
              public _tv: TreeViewProvider,
              public _ptp: PartesTabProvider,
              public _sync: SyncProvider,
              public loadingCtrl: LoadingController,
              private modalCtrl: ModalController,
              ) {

    this.incluirSubElementos = navParams.get("incluirSubElementos");
    this.getElementos();

  }

  cerrarConParametros(){

    const data = {
      incluirSubElementos: this.incluirSubElementos,
      result: this._tv.elemetSelected
    }

    const callback = this.navParams.get("callback")

    callback(data).then(()=>{
       this.modalCtrl.dismiss();
    });
  }

  cerrarSinParametros(){
    this.modalCtrl.dismiss();
  }

  getElementos(){
    if(!this._pg.workingOffline){
      this.getProcessOnline();
    }else{
      this.getProcessOffline();
    }
  }

  async getProcessOnline(){
    const loading = await this._messages.showLoading("txDescargandoElementos");

    const params: PostParams[] = [];

    //strConn
    const paramStrLogin: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    }
    params.push(paramStrLogin);

    //idProjecte
    const paramIdProjecte: PostParams = {
      nombre: PARAM_ID_PROJECTE,
      valor: String(this._pg.proyectoSeleccionado.idProjecte)
    }
    params.push(paramIdProjecte);

    this.descargarElementos( PARAM_GET_ARBRE_PROJECTE, params)
    .then( (res )=> {
        loading.dismiss();
    })
    .catch(err => {
        // console.log(JSON.stringify(err))
        loading.dismiss();
    });
  }

  getProcessOffline(){
    this._sync.getArbre(this._pg.proyectoSeleccionado.idProjecte).then( (res: any)=>{
      this.nodesTree = this._tv.buildTree(this._sync.localArbre);
    });
  }

  descargarElementos(paramAccion: string, params: PostParams[]){
      const promesa = new Promise ( (resolve, reject) => {
          if(!this._pg.workingOffline){
            this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
            .subscribe( (res: any)=>{

                // console.log("ELEMENTOS");

                this.nodesTree = this._tv.buildTree(res.arbre);

                resolve(true);

            },
            (errConsulta: ErrorConsulta) => {
              this._messages.showToastDefault(errConsulta.mensaje);
              // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);
              resolve(false);
            });
          }else{
            resolve(true);
          }
      })
      .catch(err => {
          // console.error("error en descargarLlistaPartesFromServer: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });


      return promesa;
  }
}
