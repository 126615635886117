<ion-item id="{{id}}">
  <ion-label>{{ text | translate }} </ion-label>
  <ion-label slot="end">{{ date | dateFormat: true: true}}</ion-label>
</ion-item>

<ion-popover trigger="{{id}}" show-backdrop="false" [keepContentsMounted]="true" size="cover" #datePopover>
  <ng-template>
    <ion-datetime
      [(ngModel)]="date"
      (ngModelChange)="dateNgModelChange($event, datePopover)"
      size="cover"
      hour-cycle="h23"
      [doneText]="'txAceptarConfiguracion' | translate"
      [cancelText]="'txCancelarConfiguracion' | translate"
      [firstDayOfWeek]="MONDAY"
      [locale]="locale"
      [clearText]="'txLimpiar' | translate"
      [showClearButton]="clearButton">
      <span slot="time-label">{{'txTimeLabel'|translate}}</span>
    </ion-datetime>
  </ng-template>
</ion-popover>
