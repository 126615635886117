<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-buttons slot="start">
            <ion-button class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button" (click)="cerrarSinParametros()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{'txMaterial' | translate}}</ion-title>
        <ion-buttons slot="end">
            <ion-button *ngIf="indice >= 0" (click)="cerrarConParametros(true)">
                <ion-icon slot="icon-only" name="lc-icon-trash"></ion-icon>
            </ion-button>
            <ion-button (click)="cerrarConParametros(false)">
                <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-list *ngIf="indice >= 0">
        <ion-item>
          <ion-label>
            <span>{{materialCodiDescripcio}}</span>
          </ion-label>
        </ion-item>
    </ion-list>
    <lc-input-number placeholder="txUsado" [decimal]="true" [negative]="false" [enterFunction]="cerrarConParametrosToComponent" [(value)]="cantidad"></lc-input-number>
</ion-content>
