<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
    <ion-buttons slot="start">
      <ion-button
        class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button"
        (click)="cerrarSinParametros()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'txImagesEdit' | translate}}</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="cerrarConParametros()">
        <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">

  <ion-item lines="full">
    <ion-label>
      <ion-button (click)="takePhoto()" expand="block">
      {{'txAdd' | translate}}
      </ion-button>
    </ion-label>
  </ion-item>

  <ion-item lines="full" class="ion-no-padding" *ngFor="let image of images">
    <ion-thumbnail slot="start" style="width: 80px; height: 80px;">
        <img *ngIf="image.idImatgeElement; else localImage" [src]="image.nomFitxerLocal | formatLinkAsync: '2' | async"
        #preview (error)="preview.src = 'assets/imgs/no_image_ele.png'" alt="">
      <ng-template #localImage>
        <img [src]="image.nomFitxerLocal | formatLinkAsync: 'IMAGES' | async"
          #preview (error)="preview.src = 'assets/imgs/no_image_ele.png'" alt="">
      </ng-template>
    </ion-thumbnail>
    <ion-label>
      {{image.nom}}
      <ion-row class="ion-text-center">
        <ion-col *ngIf="images.length > 1">
          <ion-icon name="lc-icon-arrow-up" (click)="upClick(image)"></ion-icon>
        </ion-col>
        <ion-col *ngIf="images.length > 1">
          <ion-icon name="lc-icon-arrow-down" (click)="downClick(image)"></ion-icon>
        </ion-col>
        <ion-col>
          <ion-icon name="close-outline" (click)="deleteImage(image)"></ion-icon>
        </ion-col>
        <ion-col>
          <ion-icon name="lc-icon-edit" (click)="changeName(image)"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-label>
  </ion-item>
</ion-content>
