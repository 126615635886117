<ion-content class="ion-no-padding">
  <partes-tab-encabezado [isEdit]="_ptp.editando"></partes-tab-encabezado>

  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      <label>{{'txParteAnotaciones' | translate}} ({{_ptp.parte.anotacions.length}})</label>
      <ion-button (click)="showAnotacionModal(null, -1)" color="light" class="ion-float-right"
                  *ngIf="_ptp.editando">
        <ion-icon slot="icon-only" name="lc-icon-add"></ion-icon>
      </ion-button>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item lines="none" *ngFor="let anotacion of filterBy('dataHoraInici'); let i = index"
                  (click)="showAnotacionModal(anotacion, i)">
          <ion-label>
            <label>{{ anotacion.text }}</label>
            <ion-grid>
              <ion-row>
                <ion-col size="12" class="ion-padding-start text-detalle-parte">
                  <span>{{ 'txUsuario' | translate }}: </span>
                  <span>{{ anotacion.nomUsuari }} </span>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" class="ion-padding-start text-detalle-parte">
                  <span>{{ 'txFecha' | translate }}: </span>
                  <span>{{ anotacion.dataHoraInici | dateFormat:true:false}} </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-label>
          <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"></ion-icon>
        </ion-item>
        <ion-item lines="none" *ngIf="_ptp.parte.anotacions.length === 0">
          <ion-label>
            <label class="text-no-existe">{{ 'txPartesSinAnotaciones' | translate}}</label>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</ion-content>
