import {Observable, Subscription} from 'rxjs';
import {Component, ViewChild} from '@angular/core';
import {IonBackButtonDelegate, IonTabs, Platform} from '@ionic/angular';
import {UiMessagesProvider} from '@app/services/ui-messages/ui-messages';
import {SwipeTabDirective} from '@app/directives/swipe-tab/swipe-tab.directive';
import {PostParams} from '@app/interfaces/ajax.interface';
import {ElementoTabProvider} from '@app/services/elemento-tab/elemento-tab.provider';
import {PersistenciaGeneralProvider} from '@app/services/persistencia-configuracion/persistencia-general.provider';
import {NavigationUtilsService} from "@app/services/navigation-utils/navigation-utils.service";
import {ListenPermissionUseCase} from "@app/core/permission/application/listen-permission.use-case";
import {Permission} from "@app/core/permission/domain/permission";
import {ElementRepository} from "@app/core/element/infrastructure/element.repository";


@Component({
  selector: 'page-elemento-tabs',
  templateUrl: 'elemento-tabs.html',
  styleUrls: ['elemento-tabs.scss'],
})
export class ElementoTabsPage {


  @ViewChild(SwipeTabDirective) swipeTabDirective: SwipeTabDirective;
  @ViewChild('elementoTabs') tabRef: IonTabs;
  @ViewChild(IonBackButtonDelegate) backButton?: IonBackButtonDelegate;

  private backButtonListener?: Subscription;
  serverProtocolVersion?: Observable<Permission>;

  constructor(
    public _ui: UiMessagesProvider,
    public _etp: ElementoTabProvider,
    public _pg: PersistenciaGeneralProvider,
    public platform: Platform,
    private navUtils: NavigationUtilsService,
    public lpu: ListenPermissionUseCase,
    private elementRepo: ElementRepository
  ) {

  }

  transition($event: { tab: string }) {
    this.swipeTabDirective.onTabInitialized($event.tab);
  }

  onTabChange(index: string) {
    this.tabRef.select(index);
  }

  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
    this.setBackButtonAction();
    this._etp.getPermissions();
    this.serverProtocolVersion = this.lpu.execute();
  }

  setBackButtonAction() {
    this.backButton.onClick = (ev) => {
      this.cerrarSinParametros()
      ev.preventDefault()
    }
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {

    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
     this.cerrarSinParametros()
    });
  }

  async aceptar() {

    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txElementoAdvertenciaGuardar", "txYes", "txNo");
    alert.onDidDismiss().then(async ({data}) => {
      if (data.accepted) {
        const loading = await this._ui.showLoading("txSavingElement");
        this.guardarElemento().then(() => {
          loading.dismiss();
          this._etp.editando = false;
          this.navUtils.navigateBack();
          this._ui.showToastDefault(this._pg.workingOffline ? "txElementSendInSync" : "txElementSentSuccess");
        });
      }
    });
    alert.present();
  }

  cerrarSinParametros() {
    if (this._etp && this._etp.editando) {
      this.atrasEditando();
    } else {
      this.navUtils.navigateBack();
    }

  }

  editar() {
    this._etp.editando = true;
  }

  async atrasEditando() {
    if (this._etp.isOpenPopup) {
      this._etp.isOpenPopup = false;
      this._etp.alertPopup.dismiss();
    } else {
      if (!this._etp.isOpenAtrasEditando) {
        this._etp.isOpenAtrasEditando = true;
        const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txSeguroCancelarEdicion", "txYes", "txNo");

        alert.onDidDismiss().then(({data}) => {
          this._etp.isOpenAtrasEditando = false;
          if (data.accepted) {
            this._etp.editando = false;
            this.navUtils.navigateBack();
          }
        });
      }
    }
  }

  // private prepararElemento(){
  //   console.log("click preparar elemento");
  // }

  async guardarElemento() {
    await this.elementRepo.updateElement(this._etp.elemento, this._pg.workingOffline);
  }

  saveInServer(paramAccion: string, params: PostParams[]) {
    // console.log("click saveInServer elemento");
  }


}
