import {StorageProvider} from '@app/services/storage/storage.provider';
import {Injectable, Injector} from '@angular/core';
import {ElementDAO} from '@app/core/element/domain/element.DAO';
import {ElementState} from '@app/core/element/domain/element-state';
import {Element} from '@app/core/element/domain/element';
import {PersistenciaGeneralProvider} from '@app/services/persistencia-configuracion/persistencia-general.provider';
import {SyncProvider} from '@app/services/sync/sync.provider';
import { ElementInfo } from '../domain/element-info';
import { Directory, Encoding } from '@capacitor/filesystem';

/**
 * Element DAO implementation for the indexedDB storage
 * We store the elements in indexedDB
 */
@Injectable()
export class ElementStorageDAO implements ElementDAO {

  private static readonly ELEMENT_STATE_KEY = 'element_states';

  private static readonly ELEMENT_IDS_KEY = 'sync_element_ids';

  constructor(
    private storage: StorageProvider,
    private pg: PersistenciaGeneralProvider,
    private injector: Injector
  ) {
  }

  /**
   * Save the element in indexedDB
   * Uses the legacy sync service to respect the old storage of the elements
   * Required to load changes in the elements when loading an element in offline mode
   *
   * @param element
   */
  async save(element: Element): Promise<Element> {
    // Call injector to avoid circular dependency
    const sync = this.injector.get(SyncProvider);
    // call legacy sync service to respect the old storage of the elements
    await sync.setElement(element);
    return element;
  }

  /**
   * Get the element from the indexedDB
   *
   * @param elementId
   */
  async get(elementInfo: ElementInfo): Promise<Element> {
    const sync = this.injector.get(SyncProvider);
    // call legacy sync service to respect the old storage of the elements
    await sync.getElement(elementInfo);
    return sync.localElement;
  }

  async saveElementState(element: ElementState[]): Promise<ElementState[]> {
    await this.pg.saveOnIndexedDB(ElementStorageDAO.ELEMENT_STATE_KEY, JSON.stringify(element), Directory.Documents, Encoding.UTF16);
    return element;
  }

  async getElementState(): Promise<ElementState[]> {
    try {
      const response = await this.pg.getFileOnIndexedDB(ElementStorageDAO.ELEMENT_STATE_KEY, Directory.Documents);
      return JSON.parse(response?.data);
    } catch (error) {
      console.error('Error getting element state', error);
      return [];
    }
  }

  async saveElementInfos(elementInfos: ElementInfo[]): Promise<ElementInfo[]> {
    await this.pg.saveOnIndexedDB(ElementStorageDAO.ELEMENT_IDS_KEY, JSON.stringify(elementInfos), Directory.Documents, Encoding.UTF16);
    return elementInfos;
  }

  async getElementInfos(): Promise<ElementInfo[]> {
    try {
      const response = await this.pg.getFileOnIndexedDB(ElementStorageDAO.ELEMENT_IDS_KEY, Directory.Documents);
      const elements_ids = response?.data;
      return elements_ids ? JSON.parse(elements_ids) : [];
    } catch (error) {
      return [];
    }
  }
}
