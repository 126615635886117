<ion-header>
    <ion-toolbar color="colorTema1" *ngIf="showPage">
        <ion-title>
            {{ i18n.txServerConfiguration | translate }}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" *ngIf="showPage">
  <h6>{{ i18n.txServerConfigurationMessage | translate }}</h6>
  <ion-list class="ion-align-items-center" lines="none">

    <ion-item class="ion-margin-vertical">
      <ion-label class="ion-text-wrap">
        <strong>{{ i18n.txConfigurationLink | translate }}</strong>
        <br>
        {{ i18n.txConfigurationLinkMessage | translate }}
      </ion-label>
    </ion-item>

    <ion-item class="ion-margin-top">
      <ion-label class="ion-text-wrap">
        <strong>{{ i18n.txQrCode | translate }}</strong>
        <br>
        <ion-text>
          {{ i18n.txQrCodeMessage | translate }}
        </ion-text>
      </ion-label>
    </ion-item>

    <ion-button expand="block" color="primary" (click)="carregarQr()">
      {{ i18n.txQrScan | translate }}
    </ion-button>

    <ion-item class="ion-margin-top">
      <ion-text (click)="mostrarModal()">
        <strong><u>{{ i18n.txManualInsert | translate }}</u></strong>
      </ion-text>
    </ion-item>

    <ion-item class="ion-margin-top">            
      <ion-text slot="end"><small>{{i18n.txAppVersion | translate}} {{appVersion()}}</small></ion-text>
    </ion-item>
  </ion-list>
</ion-content>
