import { Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { PartesTabProvider } from '../../services/partes-tab/partes-tab.provider';
import * as Data from '../../config/constantes';
import {
  FICHERO_NO_ENVIADO,
  PARTES_RESPUESTA_CORRECTO_INCORRECTO,
  PARTES_RESPUESTA_DECIMAL,
  PARTES_RESPUESTA_DECIMAL_NO_NEGATIVO,
  PARTES_RESPUESTA_ENTERO,
  PARTES_RESPUESTA_ENTERO_NO_NEGATIVO,
  PARTES_RESPUESTA_FECHA,
  PARTES_RESPUESTA_FECHA_HORA,
  PARTES_RESPUESTA_HECHO,
  PARTES_RESPUESTA_TITULO,
  PARTES_RESPUESTA_SUBTITULO,
  PARTES_RESPUESTA_IMAGEN_ADJUNTA,
  PARTES_RESPUESTA_LISTA_OPCIONES,
  PARTES_RESPUESTA_NO_SI,
  PARTES_RESPUESTA_PDF_ADJUNTO,
  PARTES_RESPUESTA_SI_NO,
  PARTES_RESPUESTA_TIEMPO,
  TIPUS_FITXER_5
} from '../../config/constantes';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { RespuestaCorrectoIncorrectoPage } from '../respuesta-correcto-incorrecto/respuesta-correcto-incorrecto';
import { RespuestaEnteroDecimalPage } from '../respuesta-entero-decimal/respuesta-entero-decimal';
import { RespuestaHechoPage } from '../respuesta-hecho/respuesta-hecho';
import { RespuestaNoSiPage } from '../respuesta-no-si/respuesta-no-si';
import { RespuestaFechaHoraPage } from '../respuesta-fecha-hora/respuesta-fecha-hora';
import { RespuestaTiempoPage } from '../respuesta-tiempo/respuesta-tiempo';
import { RespuestaListaOpcionesPage } from '../respuesta-lista-opciones/respuesta-lista-opciones';
import { RespuestaImagenAdjuntaPage } from '../respuesta-imagen-adjunta/respuesta-imagen-adjunta';
import { FileProvider } from '../../services/file/file.provider';
import { FormatLinkPipe } from '../../pipes/imagen/format-link.pipe';
import { NetworkProvider } from '../../services/network/network.provider';
import { WsUtilsProvider } from '../../services/utils-ws/ws-utils';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { RespuestaPdfAdjuntoPage } from "../respuesta-pdf-adjunto/respuesta-pdf-adjunto";
import { FaseActualProvider } from '@app/services/geme/fase-actual.provider';
import { TipusAccioCambiarFase } from '../../domains/valor-observacions-fase-data-domain';
import { FaseEnParteData } from '@app/domains/fase-en-parte-data-domain';
import { RespuestaTituloPage } from '../respuesta-titulo/respuesta-titulo';
import { RespuestaTextoPage } from '../respuesta-texto/respuesta-texto';
import { RespuestaSubtituloPage } from '../respuesta-subtitulo/respuesta-subtitulo';


/**
 * Generated class for the ParteFasesPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-parte-fases', // No cambiar nombre, ver swipe-tab.directive
  templateUrl: 'parte-fases.html',
  styleUrls: ['parte-fases.scss']
})
export class ParteFasesPage {
  public backButtonActionSubscription: Subscription;

  public constants = Data;

  alertNamePdf: any;
  imagenElementoNormalizada: string;
  public phasesImages = {};

  public isLoading = true;  // Initially set to true when image is being fetched

  constructor(public navCtrl: NavController,
    public alertCtrl: AlertController,
    public _ptp: PartesTabProvider,
    public _fp: FileProvider,
    public _np: NetworkProvider,
    public _ws: WsUtilsProvider,
    public _translate: TranslateProvider,
    public platform: Platform,
    public _htmlUtils: HtmlUtilsProvider,
    public _pg: PersistenciaGeneralProvider,
    public modalCtrl: ModalController,
    private faseActual: FaseActualProvider) {
    this.downloadImages();
  }

  onImageDidLoad() {
    this.isLoading = false;
  }

  ionViewDidEnter() {
    // console.log('ionViewDidEnter ParteFasesPage');
  }

  checkVisible(elm) {
    if (!elm) {return false;}
    const rect = elm.getBoundingClientRect();
    return rect.bottom > 0 || rect.height > 0 || rect.left > 0 || rect.right > 0 || rect.top > 0 || rect.width > 0 || rect.x > 0 || rect.y > 0;
  }



  parseRespuestaTiempo(valor) {
    const totalSegundos = parseInt(valor, 10);
    const parteMinutos = Math.floor(totalSegundos / 60);
    const parteSegundos = totalSegundos % 60;
    const texto = parteMinutos + 'm' + " " + parteSegundos + 's'; // TODO: traducir "m" y "s" como minutos y segundos abreviados

    return texto;
  }

  async goToRespuesta(event: Event, fase: FaseEnParteData, idx: number, validaVisible: boolean, prevNext: boolean = false) {
    if (this._ptp.editando) {
      if (!validaVisible) {
        event.stopPropagation();
      }

      this.faseActual.setFaseActual(idx, this._ptp.parte);
      switch (fase.tipusRespostaFase) {
        case PARTES_RESPUESTA_HECHO:

          if (!prevNext && validaVisible && this.checkVisible(document.getElementById("hecho-visible"))) {
            return;
          }

          this.presentModalFase(idx, RespuestaHechoPage);
          break;

        case PARTES_RESPUESTA_TITULO:
          this.presentModalFase(idx, RespuestaTituloPage);
          break;

        case Data.PARTES_RESPUESTA_TEXTO:
          this.presentModalFase(idx, RespuestaTextoPage);
          break;
        case PARTES_RESPUESTA_SUBTITULO:
          this.presentModalFase(idx, RespuestaSubtituloPage);
          break;

        case PARTES_RESPUESTA_CORRECTO_INCORRECTO:

          if (!prevNext && validaVisible && this.checkVisible(document.getElementById("correcto-visible"))) {
            return;
          }

          this.presentModalFase(idx, RespuestaCorrectoIncorrectoPage);

          break;
        case PARTES_RESPUESTA_SI_NO:
          if (!prevNext && validaVisible && this.checkVisible(document.getElementById("sino-visible"))) {
            return;
          }

          this.presentModalFase(idx, RespuestaNoSiPage);

          break;
        case PARTES_RESPUESTA_NO_SI:

          if (!prevNext && validaVisible && this.checkVisible(document.getElementById("nosi-visible"))) {
            return;
          }

          this.presentModalFase(idx, RespuestaNoSiPage);

          break;
        case PARTES_RESPUESTA_ENTERO:
        case PARTES_RESPUESTA_ENTERO_NO_NEGATIVO:
        case PARTES_RESPUESTA_DECIMAL:
        case PARTES_RESPUESTA_DECIMAL_NO_NEGATIVO:

          this.presentModalFase(idx, RespuestaEnteroDecimalPage);

          break;
        case PARTES_RESPUESTA_TIEMPO:

          this.presentModalFase(idx, RespuestaTiempoPage);

          break;
        case PARTES_RESPUESTA_FECHA:
        case PARTES_RESPUESTA_FECHA_HORA:

          this.presentModalFase(idx, RespuestaFechaHoraPage);

          break;
        case PARTES_RESPUESTA_IMAGEN_ADJUNTA:

          this.presentModalFase(idx, RespuestaImagenAdjuntaPage);

          break;
        case PARTES_RESPUESTA_PDF_ADJUNTO:

          this.presentModalFase(idx, RespuestaPdfAdjuntoPage);

          break;
        case PARTES_RESPUESTA_LISTA_OPCIONES:

          this.presentModalFase(idx, RespuestaListaOpcionesPage);

          break;
      }
    }

  }

  validatePrevNext(dataReturn: any, idx: any) {
    //verifico si presiono algún botón next o prev
    if (dataReturn.type === TipusAccioCambiarFase.FASE_PREV) {
      const prevCount = this.getPrevIndex(idx - 1);
      this.goToRespuesta(null, this._ptp.parte.fases[prevCount], prevCount, true, true);
    } else if (dataReturn.type === TipusAccioCambiarFase.FASE_NEXT) {
      const nextCount = this.getNextIndex(idx + 1);
      this.goToRespuesta(null, this._ptp.parte.fases[nextCount], nextCount, true, true);
    }
  }

  cambiarFasePrevNext(dataReturn: TipusAccioCambiarFase) {
    //verifico si presiono algún botón next o prev
    if (dataReturn === TipusAccioCambiarFase.FASE_PREV && this.faseActual.existsFasePrev) {
      const prevIndex = this.faseActual.indexFase - 1;
      this.goToRespuesta(null, this._ptp.parte.fases[prevIndex], prevIndex, true, true);
    } if (dataReturn === TipusAccioCambiarFase.FASE_NEXT && this.faseActual.existsFaseNext) {
      const nextIndex = this.faseActual.indexFase + 1;
      this.goToRespuesta(null, this._ptp.parte.fases[nextIndex], nextIndex, true, true);
    }
  }

  async presentModalFase(idxFase: number, respuestaFaseComponent) {
    const modal = await this.modalCtrl.create({
      component: respuestaFaseComponent,
    });
    modal.present();

    modal.onDidDismiss().then(
      ({ data }) => {
        const accio: TipusAccioCambiarFase = data;
        if (accio && accio !== TipusAccioCambiarFase.FASE_CANCEL) {
          let ficheroAEliminar = null;
          if (!this.faseActual.valorObservacionsEdicio.fitxerEnviar && this.faseActual.fase.valor) {
            ficheroAEliminar = this.faseActual.valorObservacionsEdicio.valor;
          }
          this._ptp.parteHasDifference = true;
          this._ptp.parte.fases[idxFase].valor = this.faseActual.valorObservacionsEdicio.valor;
          this._ptp.parte.fases[idxFase].observacions = this.faseActual.valorObservacionsEdicio.observacions;
          this._ptp.parte.fases[idxFase].nomSeleccioOpcio = this.faseActual.getNomSeleccioOpcioEdicio();
          this._ptp.parte.fases[idxFase].editadaEnApp = true;

          if (PARTES_RESPUESTA_PDF_ADJUNTO === this.faseActual.fase.tipusRespostaFase
            && this.faseActual.valorObservacionsEdicio.fitxerModificat) {
            this._ptp.parte.fases[idxFase].tamany = this.faseActual.valorObservacionsEdicio.tamany;
            this._ptp.parte.fases[idxFase].nomFitxerLocal = this.faseActual.valorObservacionsEdicio.nomFitxerLocal;
            this._ptp.parte.fases[idxFase].fitxerEnviar = this.faseActual.valorObservacionsEdicio.fitxerEnviar;

          }
          if (PARTES_RESPUESTA_IMAGEN_ADJUNTA === this.faseActual.fase.tipusRespostaFase
            && this.faseActual.valorObservacionsEdicio.fitxerModificat) {
            this._ptp.parte.fases[idxFase].fitxerEnviar = this.faseActual.valorObservacionsEdicio.fitxerEnviar;
            this._ptp.parte.fases[idxFase].valorPreview = this.faseActual.valorObservacionsEdicio.valorPreview;
          }
          this._ptp.setDraft();
          this.cambiarFasePrevNext(data);
        }
      }
    );
  }

  getPrevIndex(start: number) {
    if (start < 0) {
      return 0;
    }
    return start;
  }

  getNextIndex(start: number) {
    if (start > this._ptp.parte.fases.length - 1) {
      return this._ptp.parte.fases.length - 1;
    }
    return start;
  }

  async goToPdf(fase: any){
    if(fase.tamany == FICHERO_NO_ENVIADO){
      event.stopPropagation();
      this._fp.showPdf(fase.nomFitxerLocal);
      return;
    }

    if (fase.valor > '') {
      const ifp = new FormatLinkPipe(this._np, this._pg, this._htmlUtils, this._fp, this._ws, this.platform);
      const link = await ifp.transform(fase.valor, TIPUS_FITXER_5);

      if (link) {
        this._htmlUtils.getBrowserFullUrl(link);
      }
    }
  }

  parteHasDifference() {
    this._ptp.parteHasDifference = true;
    this._ptp.setDraft();
  }

  compareValor(o1, o2) {
    // eslint-disable-next-line eqeqeq
    return o1.id == o2.id;
  }

  private downloadImages() {
    this._ptp.parte.fases.forEach(fase => {
      if (fase.element) {
        const route = `${this._fp.pathDestinoGemeIcones}/${fase.element.nomFitxerIconaElement}`;

        this._pg.getImageToDB(route).then((image) => {
          this.phasesImages[fase.element.idElement] = image;
        }).catch((error) => {
          this.phasesImages[fase.element.idElement] = null;
        });
      }
    });
  }

}
