import {Injectable} from "@angular/core";
import {Permission} from "@app/core/permission/domain/permission";
import {PermissionService} from "@app/core/permission/domain/permission.service";


@Injectable({
  providedIn: 'root'
})
export class GetPermissionUseCase {
  constructor(
    private service: PermissionService
  ) {
  }


  async execute(): Promise<Permission> {
    return await this.service.get();
  }
}
