import {Injectable} from '@angular/core';
import {NavController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class NavigationUtilsService {
  private storage: Record<string, any> = {}

  constructor(private navController: NavController) {
  }

  set(key: string, value: any): void {
    this.storage[key] = value
  }

  get(key: string): any {
    return this.storage[key]
  }

  async navigateBack(): Promise<void> {
    await this.navController.pop();
  }
}
