import {StorageProvider} from './../storage/storage.provider';
import {Injectable} from '@angular/core';
import {TranslateProvider} from '../translate/translate.provider';
import {GemeProvider} from '../geme/geme.provider';
import {
  PARTES_COLOR_ESTADO_NULO,
  PARTES_TIPO_ESTADO_PLAN_FUTURO,
  PARTES_TIPO_ESTADO_PLAN_PENDIENTE,
  PARTES_TIPO_ESTADO_PLAN_REALIZABLE
} from '../../config/constantes';
import {Platform} from '@ionic/angular';
import {PersistenciaGeneralProvider} from '../persistencia-configuracion/persistencia-general.provider';
import {HtmlUtilsProvider} from '../html-utils/html-utils';
import { ParteData } from '@app/domains/parte-data-domain';
import { Directory, Encoding } from '@capacitor/filesystem';


@Injectable({
  providedIn: 'root',
})
export class PartesTabProvider {

  parte: ParteData;
  parteDraft: any;
  parteHasDifference: boolean = false;
  //elemento: any;
  editando: any;
  parametrosTreeView: any;
  estadoOriginal: any = null;

  isOpenAtrasEditando: boolean = false;
  isOpenDesbloqueando: boolean = false;
  isOpenWarnings: boolean = false;
  isOpenNameImage: boolean = false;
  isOpenObservacionesParte: boolean = false;

  elementoSeleccionado: any;
  elementoRelacionadoSeleccionado: any;

  constructor(public platform: Platform,
    public storage: StorageProvider, // TODO: (App2u) Fet un servei nostre com diu la documentació.
    public translate: TranslateProvider,
    public _pg: PersistenciaGeneralProvider,
    public _gemeProvider: GemeProvider,
    public _htmlUtils: HtmlUtilsProvider) {
    // console.log('Hello PartesTabProvider Provider');

    this.editando = false;

  }

  calcularEstadoParte(codigoTipoEstadoParteRealizado: number, tieneIncidenciasAbiertasModelElement: boolean, fechaHoraPlanificacion: Date, fechaHoraInicioHorquillaParte: Date, fechaHoraFinalHorquillaParte: Date){
    this.parte.codigoEstadoParte = this._gemeProvider.getTipoEstadoParte(codigoTipoEstadoParteRealizado, tieneIncidenciasAbiertasModelElement, fechaHoraPlanificacion, fechaHoraInicioHorquillaParte, fechaHoraFinalHorquillaParte);
    this.parte.colorEstadoParte = this._gemeProvider.getColorEstadoParte(codigoTipoEstadoParteRealizado);
    this.parte.txEstadoParte = this.translate.traducir(this._gemeProvider.getTextoEstadoParte(codigoTipoEstadoParteRealizado));


    if(this.parte.codigoEstadoParte === PARTES_TIPO_ESTADO_PLAN_FUTURO ||
        this.parte.codigoEstadoParte === PARTES_TIPO_ESTADO_PLAN_REALIZABLE ||
          this.parte.codigoEstadoParte === PARTES_TIPO_ESTADO_PLAN_PENDIENTE){

          this.parte.codigoEstadoParteToResult = null;
          this.parte.colorEstadoParteToResult = PARTES_COLOR_ESTADO_NULO;
          this.parte.txEstadoParteToResult = "txPartesEstadoSinSeleccionar";
    }else{
          this.parte.codigoEstadoParteToResult = this.parte.codigoEstadoParte;
          this.parte.colorEstadoParteToResult = this.parte.colorEstadoParte;
          this.parte.txEstadoParteToResult = this.parte.txEstadoParte;
    }
  }

  esParteFuturo(){
    return this.parte.idParte == null || this.parte.esFuturo;
  }

  parteTieneIncidencias(){
    return this.parte.teIncidenciesObertes == 1
  }

  //indica si el usuario puede o no editar el parte
  getPermisoPuedeEditarPartes(){
    const permisos = this.parte.permisos.split("|");
    return permisos[0] === "1";
  }

  //indica si el usuario puede crear o modificar anotaciones del parte
  getPermisoPuedeEditarAnotacionsPartes(){
    const permisos = this.parte.permisos.split("|");
    return permisos[1] === "1";
  }

  //el usuairo puede cambiar el estado de bloqueo del parte,
  //es decir si está bloqueado puede desbloquearlo y viceversa
  getPermisoPuedeEditarBloqueigParte(){
    const permisos = this.parte.permisos.split("|");
    return permisos[6] === "1";
  }


  setDraft(){
    this.saveDraftOnIndexedDB();
  }

  private async saveDraftOnIndexedDB() {
    const path = 'draft_' + this._pg.usuarioLoginModel.idUsuari;
    const data = JSON.stringify(this.parte);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      console.error('error save', errorSave);
    }
  }

  async removeDraft(){
    const directorySaveOnIndexedDB = Directory.Documents;
    await this._pg.removeFileOnIndexedDB('draft_' + this._pg.usuarioLoginModel.idUsuari, directorySaveOnIndexedDB);

  }

  getDraft() {
    const promesa = new Promise((resolve, reject) => {

      this.getDraftFromIndexedDB().then((data) => {
        if (data) {
          this.parteDraft = data;
        }
      });
    });

    return promesa;

  }

  async getDraftFromIndexedDB() {
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      const data = await this._pg.getFileOnIndexedDB('draft_' + this._pg.usuarioLoginModel.idUsuari, directorySaveOnIndexedDB, Encoding.UTF8);
      return JSON.parse(data.data);
    } catch (error) {
      // console.error('error get', error);
      return [];
    }
  }

}
