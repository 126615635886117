export class Permission {
  editElements = false;
  createNotPlannedReports = false; // Partes no planificados
  showObjectTypesElements = false;
  date: string;
  version: number;

  constructor(serverProtocol: number, date: string) {
    if (serverProtocol >= 1) {
      this.editElements = true;
      this.createNotPlannedReports = true;
    }
    if (serverProtocol >= 2) {
      this.showObjectTypesElements = true
    }
    this.date = date;
    this.version = serverProtocol;
  }
}
