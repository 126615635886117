import {ProtocolDAO} from "@app/core/permission/domain/protocol.DAO";
import {Injectable} from "@angular/core";
import { PersistenciaGeneralProvider } from '../../../services/persistencia-configuracion/persistencia-general.provider';
import { FileProvider } from '../../../services/file/file.provider';
import { Directory, Encoding } from '@capacitor/filesystem';


@Injectable()
export class PermissionIndexedDBDAO implements ProtocolDAO {

  constructor(
    public _pg: PersistenciaGeneralProvider,
    public _fp: FileProvider
  ){

  }

  async get(): Promise<string[]> {
    try {

      const protocol_Version_Server = await this.getPermissionFromIndexedDB('Protocol_Version_Server');
      const protocol_Version_Server_Date = await this.getPermissionFromIndexedDB('Protocol_Version_Server_Date');

      if (protocol_Version_Server && protocol_Version_Server_Date) {
        return [protocol_Version_Server, JSON.stringify(protocol_Version_Server_Date)];
      }
      return []; // Return an empty array if either value is falsy.
    } catch (error) {
      return []; // Handle any errors that might occur during the fetching process.
    }
  }


  async getPermissionFromIndexedDB(path: string) {
    const directorySaveOnIndexedDB = Directory.Documents;
    const data = await this._pg.getFileOnIndexedDB(path, directorySaveOnIndexedDB, Encoding.UTF8);
    return data.data;
  }

  async save(serverProtocol: string, date: string): Promise<void> {
    const directorySaveOnIndexedDB = Directory.Documents;
    await this._pg.saveOnIndexedDB('Protocol_Version_Server', serverProtocol, directorySaveOnIndexedDB, Encoding.UTF8);
    await this._pg.saveOnIndexedDB('Protocol_Version_Server_Date', date, directorySaveOnIndexedDB, Encoding.UTF8);
  }

}
