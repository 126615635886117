import {Subscription} from 'rxjs';
import {Component} from '@angular/core';
import { AlertController, NavController, NavParams, Platform } from '@ionic/angular';
import {TranslateProvider} from '../../services/translate/translate.provider';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import { FaseActualProvider } from '../../services/geme/fase-actual.provider';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';
import {FICHERO_NO_ENVIADO, MAX_FILE_SIZE, TIPUS_FITXER_5} from '../../config/constantes';
import {FileChooser} from '../../services/file-chooser/file-chooser.provider';
import { PartesTabProvider } from '../../services/partes-tab/partes-tab.provider';
import { FileProvider } from '@app/services/file/file.provider';
import { UiMessagesProvider } from '@app/services/ui-messages/ui-messages';
import { NetworkProvider } from '@app/services/network/network.provider';
import { WsUtilsProvider } from '@app/services/utils-ws/ws-utils';
import { FormatLinkPipe } from '@app/pipes/imagen/format-link.pipe';
import { FitxerEnviarData } from '@app/domains/fitxer-enviar-data-domain';

@Component({
  templateUrl: 'respuesta-pdf-adjunto.html',
  styleUrls: ['respuesta-pdf-adjunto.scss']
})
export class RespuestaPdfAdjuntoPage implements RespuestaPageActions {

  valorEdicio: string;
  tamany = "";
  fitxerEnviar: FitxerEnviarData;
  modified = false;
  nomFitxerLocal = "";

  private backButtonActionSubscription?: Subscription;


  constructor(public navCtrl: NavController,
              public navParams: NavParams,
    public translate: TranslateProvider,
              public _fp: FileProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public faseActual: FaseActualProvider,
    public fileChooser: FileChooser,
    public alertCtrl: AlertController,
              public _ui: UiMessagesProvider,
    public _ptp: PartesTabProvider,
              public _np: NetworkProvider,
    public _html: HtmlUtilsProvider,
              public _ws: WsUtilsProvider,
    public platform: Platform,
  ) {

    if (faseActual.valorObservacionsEdicio.valor > "") {
      this.valorEdicio = faseActual.valorObservacionsEdicio.valor;
    } else {
      this.valorEdicio = "";
  }
  }

  attachPDF() {
    this.fileChooser.open()
      .then(file => {
        const size = this._fp.bytesToMb(file.size);

        if (size > MAX_FILE_SIZE) {
          const msg = this.translate.traducirWithParams("txAvisoNosePuedeSubirArchivo", { max: MAX_FILE_SIZE });
          this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
          return;
        }

        const filename = file.name;

        const fitxerEnviar: FitxerEnviarData = {
          contingutFitxer: this.getFilenamePdf(),
          extensioFitxer: filename.split('.').pop()
        };

        if (fitxerEnviar.extensioFitxer !== "pdf") {
          const msg = this.translate.traducir("txSoloPdf");
          this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
          return;
        }

        const respuesta = fitxerEnviar.contingutFitxer + "." + fitxerEnviar.extensioFitxer;

        this._fp.writeFileBase(file.data, respuesta)
          .then(() => {
            this.presentPromptNomFilePdf(respuesta, fitxerEnviar);
          });
      })
      .catch(e => {
        if (e.message === FileChooser.PICKER_CANCELLED_ERROR) {
          return;
        }
        // console.error("error en getFileSize: " + JSON.stringify(e, Object.getOwnPropertyNames(e)));
        const msg = this.translate.traducir("txOcurrioErrorAdjuntarArchivo");
        this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
      });
  }


  private getFilenamePdf() {
    return "pdf_fase_parte_" + this.faseActual.idParte + "_" + this.faseActual.fase.idFaseModel;
  }

  private async presentPromptNomFilePdf(respuesta: any, fitxerEnviar: FitxerEnviarData) {
    if (this._ptp.editando) {
      const alertNamePdf = await this.alertCtrl.create({
        header: this.translate.traducir('txNombredelFichero'),
        inputs: [
          {
            name: 'nom',
            placeholder: this.translate.traducir('txNombredelFichero'),
            value: this.translate.traducir('txArchivoStr')
          }
        ],
        backdropDismiss: false,
        buttons: [
          {
            text: this.translate.traducir('txCancelar'),
            role: 'cancel',
            handler: data => {
              // console.log('Cancel clicked');
              // console.log('fichero no agregada');
            }
          },
          {
            text: this.translate.traducir('txAceptar'),
            handler: data => {
              if (data.nom > '') {
                const fullpath = this._fp.pathDestinoTemp + "/" + respuesta;

                this.modified = true;
                this.tamany = FICHERO_NO_ENVIADO;
                this.nomFitxerLocal = fullpath;
                this.valorEdicio = respuesta;
                this.fitxerEnviar = fitxerEnviar;

                alertNamePdf.dismiss();
                return false;
              } else {
                this._ui.showToastDefault('txEspecificarNombreImagen');
                return false;
              }
            }
          }
        ]
      });
      this.backButtonActionSubscription = this.platform.backButton.subscribeWithPriority(102, () => {
        alertNamePdf.dismiss();
      });
      alertNamePdf.onDidDismiss().then(() => {
        if (this.backButtonActionSubscription){
          this.backButtonActionSubscription.unsubscribe();
  }
    });
      alertNamePdf.present();
    }
  }

  goToPdf() {
    if (this.tamany === FICHERO_NO_ENVIADO) {
      event.stopPropagation();
      this._fp.showPdf(this.nomFitxerLocal);
      return;
    }

    if (this.valorEdicio > '') {
      const ifp = new FormatLinkPipe(this._np, this._pg, this._html, this._fp, this._ws, this.platform);
      ifp.transform(this.valorEdicio, TIPUS_FITXER_5);
    }
  }

  checkValorIsOk(): boolean {
    return true;
  }

  setValorFaseActual() {
    this.faseActual.valorObservacionsEdicio.valor = this.valorEdicio;
    this.faseActual.valorObservacionsEdicio.fitxerModificat = this.modified;
    this.faseActual.valorObservacionsEdicio.tamany = FICHERO_NO_ENVIADO;
    this.faseActual.valorObservacionsEdicio.nomFitxerLocal = this.nomFitxerLocal;
    this.faseActual.valorObservacionsEdicio.fitxerEnviar = this.fitxerEnviar;
  }

}
