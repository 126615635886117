// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlConfiguracion: 'https://2647536e9f3c906e98e0a913e8e31452app.lafcarr.com/WSApp/',
  SENTRY_DSN: 'https://373686d1413bef4e2e482921993de69e@o4506280055996416.ingest.sentry.io/4506280058355712',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
