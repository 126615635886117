import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IonPopover} from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';
import moment from "moment/moment";
import {DATE_TIME_FORMAT} from "@app/config/constantes";

@Component({
  selector: 'app-custom-date-selector',
  templateUrl: './custom-date-selector.component.html'
})
export class CustomDateSelectorComponent implements OnInit {

  @Input() date: string;
  @Input() text: string;
  @Input() clearButton = false;
  @Output() dateChange = new EventEmitter();
  @Output() emitClearButtonOnChange = new EventEmitter();

  @Input() id: string;

  @Input() showClearButton: boolean;
  locale = 'es';
  readonly MONDAY = 1;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    // needed in order to change the language of the datepicker months and days
    this.locale = this.translate.currentLang;
  }

  public dateNgModelChange(dataPopover: string, datePopover: IonPopover) {
    if (dataPopover === undefined) {
      datePopover.dismiss();
      this.eliminateDate();
    } else{
      // set seconds and milliseconds to 0 because the user hasn't selected this data
      const momentDate = moment(dataPopover, DATE_TIME_FORMAT).set({second: 0, millisecond: 0});
      const date = momentDate.format();
      this.dateChange.emit(date);
    }
  }

  public eliminateDate() {
    this.emitClearButtonOnChange.emit();
  }
}
