import {Part} from "@app/core/part/domain/part";
import {AjaxClientProvider} from "@app/services/ajax-client/ajax-client.provider";
import {WsUtilsProvider} from "@app/services/utils-ws/ws-utils";
import {PostParams} from "@app/interfaces/ajax.interface";
import {
  PARAM_DATA_HORA_PLANIFICACIO,
  PARAM_GEN_PARTE_NO_PLAN_APP,
  PARAM_ID_GEME_MODEL_ELEMENT,
  PARAM_STRCONN,
  PARAM_TEMPS_EXECUCIO_MINUTS
} from "@app/config/constantes";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CreateNotPlannedPartWebService {

  constructor(private ajax: AjaxClientProvider,
              private _wsUtils: WsUtilsProvider,) {}

  getParams(part: Part): PostParams[] {
    return [
      {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      },
      {
        nombre: PARAM_ID_GEME_MODEL_ELEMENT,
        valor: part.idModelElement.toString()
      },
      {
        nombre: PARAM_DATA_HORA_PLANIFICACIO,
        valor: part.dataHoraPlanParteSensePlanificacio
      },
      {
        nombre: PARAM_TEMPS_EXECUCIO_MINUTS,
        valor: part.tempsMinutsParteSensePlanificacio.toString()
      },
    ]
  }

  execute(part: Part): Observable<Part> {
    const params = this.getParams(part);

    return this.ajax.consultarWS(PARAM_GEN_PARTE_NO_PLAN_APP, params, 0);
  }
}
