<ion-app>
  <ion-menu type="overlay" contentId="content">
    <ion-header>
      <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-title>{{ 'txMenuMenu' | translate }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="background-menu">
        <ion-row>
          <ion-col class="ion-text-left">
            <label>
              <ion-icon name="lc-icon-contact" class="icono-menu-usuario-login icon-align-middle"></ion-icon>
              <div class="ml-1 align-middle-vertical">
                <span class="text-usuario ion-text-capitalize">{{userLogin}}</span>
              </div>
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-right">
            <ion-chip [ngClass]="{'conectado-chip': !_pg.workingOffline , 'desconectado-chip': _pg.workingOffline }"
                      (click)="mostrarModalStatus()">
              <ion-icon [ngClass]="{'conectado-icon': !_pg.workingOffline , 'desconectado-icon': _pg.workingOffline }"

                        [name]="showStatusIcon()"></ion-icon>
              <ion-label
                [ngClass]="{'conectado': !_pg.workingOffline , 'desconectado': _pg.workingOffline }">{{showStatus()}} </ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-right">
            <ion-chip (click)="_hp.cambiarProyecto()">
              <ion-label>{{proyectoActualStr}} </ion-label>
              <ion-icon name="lc-icon-edit" color="primary"></ion-icon>
            </ion-chip>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-list lines="inset" class="list-menu">
        <ion-item [class.active-page-menu]="_hp.checkActive(appRoutes.homePage)"
                  (click)="_hp.abrirPaginaClick(appRoutes.homePage, true)">
          <ion-icon name="lc-icon-home" class="icon-align-text-bottom"></ion-icon>
          <ion-label class="ml-2">
            {{ 'txInicioMenu' | translate }}
          </ion-label>
        </ion-item>
        <div class="grupo-opciones-menu">{{nomModulGeme}}</div>
        <ion-item class="ion-no-margin">
          <ion-label>
            <ion-list class="ion-no-margin">
              <ion-item lines="none" [class.active-page-menu]="_hp.checkActive(appRoutes.inventarioPage)"
                        (click)="_hp.abrirPaginaClick(appRoutes.inventarioPage, true)">
                <ion-icon name="lc-icon-inventario" class="icon-align-text-bottom"></ion-icon>
                <ion-label class="ml-2">{{ 'txInventarioMenu' | translate }}</ion-label>
              </ion-item>
              <ion-item lines="none" [class.active-page-menu]="_hp.checkActive(appRoutes.partesPage)"
                        (click)="_hp.abrirPaginaClick(appRoutes.partesPage, true)">
                <ion-icon name="lc-icon-parte" class="icon-align-text-bottom"></ion-icon>
                <ion-label class="ml-2">{{ 'txPartesMenu' | translate }}</ion-label>
              </ion-item>
              <ion-item button="true" lines="none" [hidden]="true">
                <ion-icon name="lc-icon-incidencia" class="icon-align-text-bottom"></ion-icon>
                <ion-label class="ml-2">{{ 'txIncidenciasMenu' | translate }}</ion-label>
              </ion-item>
            </ion-list>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="constantsClient.MOSTRAR_ACCESO_FT && nomModulFitxaTecnica > ''"
                  (click)="_hp.openExternalLinks(constants.LINK_FITXATECNICA)">
          <ion-icon name="lc-icon-ficha-tecnica" class="icon-align-text-bottom"></ion-icon>
          <ion-label class="ml-2">{{nomModulFitxaTecnica}}</ion-label>
        </ion-item>
        <ion-item *ngIf="constantsClient.MOSTRAR_ACCESO_AT && nomModulArxiuTecnic > ''"
                  (click)="_hp.openExternalLinks(constants.LINK_ARXIUTECNIC)">
          <ion-icon name="lc-icon-archivo-tecnico" class="icon-align-text-bottom"></ion-icon>
          <ion-label class="ml-2">{{nomModulArxiuTecnic}}</ion-label>
        </ion-item>
        <ion-item [class.active-page-menu]="_hp.checkActive(appRoutes.configuracionPage)"
                  (click)="_hp.abrirPaginaClick(appRoutes.configuracionPage, true)">
          <ion-icon name="lc-icon-config" class="icon-align-text-bottom"></ion-icon>
          <ion-label class="ml-2">{{ 'txConfiguracionConfiguracion' | translate }}</ion-label>
        </ion-item>
        <ion-item (click)="_fp.showAssetsPdf(constants.FILENAME_MANUAL_USUARIO, constants.PATH_MANUAL_USUARIO);">
          <ion-icon name="lc-icon-archivo-tecnico" class="icon-align-text-bottom"></ion-icon>
          <ion-label class="ml-2">{{ 'txManual' | translate }}</ion-label>
        </ion-item>
        <ion-item (click)="_hp.exitProcessMenu()">
          <ion-icon name="lc-icon-sign-out" class="icon-align-text-bottom"></ion-icon>
          <ion-label class="ml-2">{{ 'txSalirStr' | translate }}</ion-label>
        </ion-item>
        <!-- Se comenta a futuro se determinará si se utiliza o no -->
        <!-- <button ion-item tappable (click)="_hp.cerrarSesion()">
            <ion-icon name="lc-icon-sign_out" class="icon-align-text-bottom"></ion-icon>
            {{ 'txCerrarSesion' | translate }}
        </button> -->
        <!-- <button ion-item tappable *ngIf="isDebug" [class.active-page-menu]="_hp.checkActive(debugPage)" (click)="_hp.abrirPaginaClick(debugPage, true)">
            <ion-icon name="lc-icon-config" class="icon-align-text-bottom"></ion-icon>
            Debug
        </button> -->
      </ion-list>
    </ion-content>
  </ion-menu>
  <app-splash-screen></app-splash-screen>
  <ion-router-outlet id="content" [swipeGesture]="false"></ion-router-outlet>
</ion-app>
