import {Injectable} from '@angular/core';
import {
  PARTES_COLOR_ESTADO_APLAZADO,
  PARTES_COLOR_ESTADO_APLAZADO_INCIDENCIA,
  PARTES_COLOR_ESTADO_CORRECTO,
  PARTES_COLOR_ESTADO_INCORRECTO,
  PARTES_COLOR_ESTADO_NULO,
  PARTES_COLOR_ESTADO_PLAN_FUTURO,
  PARTES_COLOR_ESTADO_PLAN_PENDIENTE,
  PARTES_COLOR_ESTADO_PLAN_REALIZABLE,
  PARTES_TIPO_ESTADO_APLAZADO,
  PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA,
  PARTES_TIPO_ESTADO_CORRECTO,
  PARTES_TIPO_ESTADO_INCORRECTO,
  PARTES_TIPO_ESTADO_PLAN_FUTURO,
  PARTES_TIPO_ESTADO_PLAN_PENDIENTE,
  PARTES_TIPO_ESTADO_PLAN_REALIZABLE
} from '../../config/constantes';

@Injectable({
  providedIn: 'root',
})
export class GemeProvider {

  constructor() {
    // console.log('Hello PartesProvider Provider');
  }

  getTipoEstadoParte(codigoTipoEtadoParteRealizado: number, tieneIncidenciasAbiertasModelElement: boolean, fechaHoraPlanificacion: Date, fechaHoraInicioHorquillaParte: Date, fechaHoraFinalHorquillaParte: Date) {
    if (codigoTipoEtadoParteRealizado != null) { // Parte realitzado
      return codigoTipoEtadoParteRealizado;
    } else { // Parte no realizado
      if (fechaHoraPlanificacion == null || fechaHoraInicioHorquillaParte == null || fechaHoraFinalHorquillaParte == null) {
        return null;
      } else {
        if (tieneIncidenciasAbiertasModelElement) {
          return PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA;
        } else {
          const ahora = new Date();
          if (ahora < fechaHoraInicioHorquillaParte) {
            return PARTES_TIPO_ESTADO_PLAN_FUTURO
          } else if (ahora > fechaHoraFinalHorquillaParte) {
            return PARTES_TIPO_ESTADO_PLAN_PENDIENTE;
          } else {
            return PARTES_TIPO_ESTADO_PLAN_REALIZABLE;
          }
        }
      }
    }
  }

  getSrcImgEstadoParte(codigoTipoEstadoParte: number) {
    const pathBase = "assets/imgs/geme/";
    switch (codigoTipoEstadoParte) {
      case PARTES_TIPO_ESTADO_CORRECTO:
        return pathBase + "icoCorrecto.png";
      case PARTES_TIPO_ESTADO_INCORRECTO:
        return pathBase + "icoIncorrecto.png";
      case PARTES_TIPO_ESTADO_APLAZADO:
        return pathBase + "icoAplazado.png";
      case PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA:
        return pathBase + "icoAplazadoIncidencia.png";
      case PARTES_TIPO_ESTADO_PLAN_FUTURO:
        return pathBase + "icoPlanFuturo.png";
      case PARTES_TIPO_ESTADO_PLAN_REALIZABLE:
        return pathBase + "icoPlanRealizable.png";
      case PARTES_TIPO_ESTADO_PLAN_PENDIENTE:
        return pathBase + "icoPlanPendiente.png";
      default:
        return "";
    }
  }

  getSrcImgEstadoParteToResult(codigoTipoEstadoParte: number) {
    const pathBase = "assets/imgs/geme/";
    switch (codigoTipoEstadoParte) {
      case PARTES_TIPO_ESTADO_PLAN_FUTURO:
      case PARTES_TIPO_ESTADO_PLAN_REALIZABLE:
      case PARTES_TIPO_ESTADO_PLAN_PENDIENTE:
        return pathBase + "icoNinguno.png";
      case PARTES_TIPO_ESTADO_CORRECTO:
        return pathBase + "icoCorrecto.png";
      case PARTES_TIPO_ESTADO_INCORRECTO:
        return pathBase + "icoIncorrecto.png";
      case PARTES_TIPO_ESTADO_APLAZADO:
        return pathBase + "icoAplazado.png";
      case PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA:
        return pathBase + "icoAplazadoIncidencia.png";
      default:
        return "";
    }
  }

  getTextoEstadoParte(codigoTipoEstadoParte: number){
    switch (codigoTipoEstadoParte) {
      case PARTES_TIPO_ESTADO_CORRECTO:
        return "txPartesEstadoCorrecto";
      case PARTES_TIPO_ESTADO_INCORRECTO:
        return "txPartesEstadoIncorrecto";
      case PARTES_TIPO_ESTADO_APLAZADO:
        return "txPartesEstadoAplazado";
      case PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA:
        return "txPartesEstadoAplazadoIncidencia";
      case PARTES_TIPO_ESTADO_PLAN_FUTURO:
        return "txPartesEstadoPlanFuturo";
      case PARTES_TIPO_ESTADO_PLAN_REALIZABLE:
        return "txPartesEstadoPlanRealizable";
      case PARTES_TIPO_ESTADO_PLAN_PENDIENTE:
        return "txPartesEstadoPlanPendiente";
      default:
        return "";
    }
  }

  getColorEstadoParte(codigoTipoEstadoParte: number){
    switch (codigoTipoEstadoParte) {
      case PARTES_TIPO_ESTADO_CORRECTO:
        return PARTES_COLOR_ESTADO_CORRECTO;
      case PARTES_TIPO_ESTADO_INCORRECTO:
        return PARTES_COLOR_ESTADO_INCORRECTO;
      case PARTES_TIPO_ESTADO_APLAZADO:
        return PARTES_COLOR_ESTADO_APLAZADO;
      case PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA:
        return PARTES_COLOR_ESTADO_APLAZADO_INCIDENCIA;
      case PARTES_TIPO_ESTADO_PLAN_FUTURO:
        return PARTES_COLOR_ESTADO_PLAN_FUTURO;
      case PARTES_TIPO_ESTADO_PLAN_REALIZABLE:
        return PARTES_COLOR_ESTADO_PLAN_REALIZABLE;
      case PARTES_TIPO_ESTADO_PLAN_PENDIENTE:
        return PARTES_COLOR_ESTADO_PLAN_PENDIENTE;
      default:
        return PARTES_COLOR_ESTADO_NULO;
    }
  }

}
