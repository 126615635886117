import { Component, QueryList, ViewChildren } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { FaseActualProvider } from '../../services/geme/fase-actual.provider';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';
import { LcInputNumberComponent } from '@app/components/lc-input-number/lc-input-number';

@Component({
  templateUrl: 'respuesta-tiempo.html',
})
export class RespuestaTiempoPage implements RespuestaPageActions {

  @ViewChildren('lc') lccomponents: QueryList<LcInputNumberComponent>;

  respuestaMinutos = "";
  respuestaSegundos = "";
  valorEdicio: string;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    public translate: TranslateProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public faseActual: FaseActualProvider,
  ) {

    if (faseActual.valorObservacionsEdicio.valor > "") {
      this.valorEdicio = faseActual.valorObservacionsEdicio.valor;
      const min = Math.floor(Number(this.valorEdicio) / 60);
      const seg = Number(this.valorEdicio) - min * 60;

      this.respuestaMinutos = String(min);
      this.respuestaSegundos = String(seg);
    } else {
      this.valorEdicio = "";
    }
  }

  checkValorIsOk(): boolean {
    let existsError = false;

    for (const input of this.lccomponents) {
      existsError = existsError || input.anyError();
      input.emitValue();
    }
    return !existsError;
  }

  setValorFaseActual() {
    if (this.checkValorIsOk()) {
      let valor: string;
      if (!this.respuestaMinutos && !this.respuestaSegundos || (this.respuestaMinutos === '' && this.respuestaSegundos === '')) {
        valor = null;
      } else {
        valor = String(60 * Number(this.respuestaMinutos) + Number(this.respuestaSegundos));
      }
      this.faseActual.valorObservacionsEdicio.valor = valor;
    }
    console.warn("Se ha llamado setValorFaseActual mientras los inputs no tenían valores asignados correctamente");
    return null;
  }

}
