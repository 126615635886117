import { Component, Input } from '@angular/core';
import { StructTree } from './struct';
import { Platform} from '@ionic/angular';
import { TreeViewProvider } from '../../services/tree-view/tree-view.provider';


@Component({
  selector: 'tree-view',
  templateUrl: 'tree-view.html',
  styleUrls: ['tree-view.scss'],
})
export class TreeViewComponent {

  @Input('executeEvent') executeEvent: Function;
  @Input() nodesTree: Array<StructTree>;

  elementoDescargado: any = null;

  constructor(public _tv: TreeViewProvider,
              private platform: Platform) {
    // console.log('Hello TreeViewComponent Component');


      this.exapandirPadres();

  }

  elementClick(item, isFolder){

    if(!this.executeEvent){
      const elem = document.getElementById(item.idGemeElement);

      // console.log(this.getParents(elem));



      this._tv.elemetSelected = item;
      this._tv.parents = item.padres;

      // console.log(JSON.stringify(item));
    }else{
      this.executeEvent(item);
    }

  }

  exapandirPadres(){
    this.platform.ready().then(() => {
        if(this._tv.elemetSelected !== null){
          //this._tv.elemetSelected.expandRecursive(this._tv.elemetSelected);

          if(this.nodesTree){
            this.nodesTree.forEach(d => {
              //d.expandRecursive(d.padres);
              d.expanded = this._tv.parents.some(x => x === d.idGemeElement);
              d.iconSub = d.getIcon();
            })
          }
        }
    });
  }

  expandirRecursivo(){
      this.nodesTree.forEach(d => {
          d.expanded = this._tv.parents.some(x => x === d.idGemeElement);
      })
  }

  getParents(elem) {
    const parents = [];
    while(elem.parentNode && elem.parentNode.nodeName.toLowerCase() != 'body') {
      elem = elem.parentNode;
      parents.push(elem.id);
    }
    return parents;
  }

  limpiarSeleccionTreeFinalLink(){
    this.platform.ready().then(() => {
        const elements =  window.document.querySelectorAll('.treeFinalLink');
        for (let i = 0; i < elements.length; i++){
            elements[i].className = elements[i].className.replace("active-page-menu", "");
        }
    });
  }

  limpiarSeleccionSubMenu(){
    this.platform.ready().then(() => {
        const elements =  window.document.querySelectorAll('.treeMenu');
        for (let j = 0; j < elements.length; j++){
            elements[j].className = elements[j].className.replace("active-page-menu", "");
        }
    });
  }

  agregarSeleccion(item: any){
    this.platform.ready().then(() => {
        if(item != null){
          const element = document.getElementById(item.idGemeElement);
          if(element != null){
            element.classList.add("active-page-menu");
          }
        }
    });
  }

}
