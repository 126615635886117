import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { FaseActualProvider } from '../../services/geme/fase-actual.provider';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';

@Component({
  templateUrl: 'respuesta-correcto-incorrecto.html',
  styleUrls: ['respuesta-correcto-incorrecto.scss']
})
export class RespuestaCorrectoIncorrectoPage implements RespuestaPageActions {

  valorEdicio: string;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    public translate: TranslateProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public faseActual: FaseActualProvider,
  ) {

    if (faseActual.valorObservacionsEdicio.valor > "") {
      this.valorEdicio = faseActual.valorObservacionsEdicio.valor;
    } else {
      this.valorEdicio = "-1";
    }
  }

  checkValorIsOk(): boolean {
    return true;
  }

  setValorFaseActual() {
    this.faseActual.valorObservacionsEdicio.valor = this.valorEdicio === "-1" ? null : this.valorEdicio;
  }

}
