/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  IonContent,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  Platform
} from '@ionic/angular';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {PartesSearchModalPage} from '../partes-search-modal/partes-search-modal';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {PartesConfigModalPage} from '../partes-config-modal/partes-config-modal';
import {PostParams} from '../../interfaces/ajax.interface';
import * as Data from '../../config/constantes';
import {
  FILTRO_ARBOL,
  FILTRO_BUSQUEDA,
  PARAM_FILTRE,
  PARAM_GET_LLISTA_PARTES,
  PARAM_GET_PARTE,
  PARAM_ID_GEME_ELEMENT,
  PARAM_ID_MODEL_ELEMENT,
  PARAM_ID_PARTE,
  PARAM_ID_PROJECTE,
  PARAM_INCLOURE_FILLS,
  PARAM_INCLUDE_NOT_PLANNED_PARTS,
  PARAM_MODELS_ELEMENTS_FAV,
  PARAM_ROW_FINAL,
  PARAM_ROW_FINAL_VALUE,
  PARAM_ROW_FINAL_VALUE_LOCAL,
  PARAM_ROW_INICI,
  PARAM_STRCONN,
  PARAM_TIPUS_FILTRE,
  PARAM_TIPUS_MOSTRAR,
  PARAM_TIPUS_ORDRE,
  PARTES_CONFIG_MOSTRAR_COLAPSADO,
  PARTES_CONFIG_MOSTRAR_EXPANDIDO,
  PARTES_CONFIG_MOSTRAR_X_HACER_Y_PASADOS,
  PARTES_CONFIG_ORDENAR_X_POSICION_ARBOL,
  PARTES_TIPO_ESTADO_PLAN_FUTURO
} from '../../config/constantes';
import {WsUtilsProvider} from '../../services/utils-ws/ws-utils';
import {AjaxClientProvider, ErrorConsulta} from '../../services/ajax-client/ajax-client.provider';
import {FileProvider} from '../../services/file/file.provider';
import {NetworkProvider} from '../../services/network/network.provider';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {PartesTreeviewModalPage} from '../partes-treeview-modal/partes-treeview-modal';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {TreeViewProvider} from '../../services/tree-view/tree-view.provider';
import {GemeProvider} from '../../services/geme/geme.provider';
import {HelperProvider} from '../../services/helper/helper.provider';
import {ListaOtrosPartesPage} from '../lista-otros-partes/lista-otros-partes';
import {ElementoTabProvider} from '../../services/elemento-tab/elemento-tab.provider';
import {SyncProvider} from '../../services/sync/sync.provider';
import {StatusProvider} from '../../services/status/status.provider';
import {UpdateAppProvider} from '../../services/update-app/update-app.provider';
import {Subscription} from 'rxjs';
import {AppRoutes} from '../../config/app-routes';
import {EventService} from '../../services/events/event.service';
import {Router} from '@angular/router';
import {NavigationUtilsService} from '../../services/navigation-utils/navigation-utils.service';
import {GetPermissionUseCase} from '@app/core/permission/application/get-permission.use-case';
import {ParteCreateNotPlannedPage} from '@app/pages/parte-create-not-planned/parte-create-not-planned';
import { ElementInfo } from '@app/core/element/domain/element-info';
import { UsuariModelElementFavoritoDB } from '@app/domains/usuario-domain';

/**
 * Generated class for the PartesPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-partes',
  templateUrl: 'partes.html',
  styleUrls: ['partes.scss']
})
export class PartesPage implements OnInit, OnDestroy {

  screenWidth: any = 0;
  favOn = false;
  sincronizando = false;

  //modal config
  criterioOrdenar: number;
  criterioMostrar: number;
  criterioExpandirContraer: number;
  incluirDesuso = false;

  //modal search
  criterio: number = null; //1: por código de parte
  valorSearch = '';
  existeParteLocal = false;

  rowIniciLocal = 0;
  rowFinalLocal = 0;
  rowInici = 1;
  rowFinal = 0;

  //modal arbol
  idGemeElement: number = null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IncloureFills = true;
  modelsElementsFav: string;

  //lista que mantendrá los elementos en memoria
  elementos: any[] = [];
  hayMasElementos = true;

  consultaWSRealizada = false;
  public constants = Data;
  private backButtonListener?: Subscription;

  @ViewChild(IonContent) content: IonContent;
  private loadSubs?: Subscription;

  constructor(public navCtrl: NavController,
              public platform: Platform,
              public modalCtrl: ModalController,
              public _ui: UiMessagesProvider,
              public _wsUtils: WsUtilsProvider,
              private _ajax: AjaxClientProvider,
              private _messages: UiMessagesProvider,
              private loadingCtrl: LoadingController,
              public _fp: FileProvider,
              public _np: NetworkProvider,
              public actionSheetCtrl: ActionSheetController,
              private _translate: TranslateProvider,
              public _pg: PersistenciaGeneralProvider,
              public _htmlUtils: HtmlUtilsProvider,
              public _partesTabProvider: PartesTabProvider,
              public _etp: ElementoTabProvider,
              public _tv: TreeViewProvider,
              public menuCtrl: MenuController,
              public _hp: HelperProvider,
              public _sync: SyncProvider,
              public alertCtrl: AlertController,
              public _status: StatusProvider,
              public _network: NetworkProvider,
              public _updateApp: UpdateAppProvider,
              public _gemeProvider: GemeProvider,
              private events: EventService,
              private router: Router,
              private navUtils: NavigationUtilsService,
              private getPermissionUseCase: GetPermissionUseCase) {
    // This function shouldn't be here, the correct way is to place it inside the ionViewDidEnter function but,
    // when an item gets edited and sent to the server, the server also needs "rowFinal" value, which usually is 60,
    // but 60 is the incorrect value that we get because we placed the load inside the constructor, so it calculates
    // "rowFinal" 2 times instead of 1
    // the correct value is 30 and because of that there is a constant called "PARAM_ROW_FINAL_VALUE" which is 30 and
    // subtracts it from the "rowFinal" value
    // but when fixing this issue the server starts returning 400 error on other requests because function on this page
    // are used by other pages which is a bad practice
    // the easiest way is to leave it there until we understand what information does the server need
    this.load();
  }

  initParams(): void {
    this.screenWidth = 0;
    this.favOn = false;
    this.sincronizando = false;

    //modal config
    //1: obtener los elementos y partes de acuerdo a la posición del elemento en el árbol
    this.criterioOrdenar = this._pg.usuarioLoginModel.partesTipusOrdre > 0 ?
      this._pg.usuarioLoginModel.partesTipusOrdre : PARTES_CONFIG_ORDENAR_X_POSICION_ARBOL;
    //2: partes por hacer y pasados
    this.criterioMostrar = this._pg.usuarioLoginModel.partesTipusMostrar > 0 ?
      this._pg.usuarioLoginModel.partesTipusMostrar : PARTES_CONFIG_MOSTRAR_X_HACER_Y_PASADOS;
    //1: partes expandido
    this.criterioExpandirContraer = this._pg.usuarioLoginModel.partesTipusView > 0 ?
      this._pg.usuarioLoginModel.partesTipusView : PARTES_CONFIG_MOSTRAR_EXPANDIDO;

    this.incluirDesuso = false;

    //modal search
    this.criterio = null; //1: por código de parte
    this.valorSearch = '';
    this.existeParteLocal = false;

    this.rowIniciLocal = 0;
    this.rowFinalLocal = 0;
    this.rowInici = 1;
    this.rowFinal = 0;

    //modal arbol
    this.idGemeElement = null;
    this.IncloureFills = true;

    //lista que mantendrá los elementos en memoria
    this.elementos = [];
    this.hayMasElementos = true;

    this.consultaWSRealizada = false;
  }

  ngOnInit(): void {
    this.loadSubs = this.events.subscribe('reloadCurrentPage', () => this.load());
  }

  async load(): Promise<void> {
    this.initParams();
    this.criterioExpandirContraer = this._pg.usuarioLoginModel.partesTipusView;

    await this.getLlistaPartes(false, true);
    this.getFavsDB();
  }

  ionViewWillLeave() {
    // this.existeParteLocal = false;
    this.backButtonListener?.unsubscribe();
  }

  ngOnDestroy() {
    this.loadSubs?.unsubscribe();
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      if (this.sincronizando) {
        return;
      }
      this._hp.abrirPaginaClick(AppRoutes.homePage, false);
    });
  }

  /*** fin trabajo con el boton atras */

  ionViewDidEnter() {
    this._updateApp.needUpdateAdvice();

    this.initializeBackButtonCustomHandler();

    const elmnt = document.getElementById(this._partesTabProvider.elementoSeleccionado);

    if (elmnt) {
      elmnt.scrollIntoView({block: 'end'});
    }

    this.validateTimeSync();
  }

  validateTimeSync() {
    this._status.validateTimeSyncAction();
  }

  // callback Return Search...
  callbackReturnSearchFunction = (_params) => new Promise<void>((resolve, reject) => {
    if (_params) {
      this.resetIniciFinal();

      this.criterio = _params.criterio;
      this.valorSearch = _params.valorSearch;

      this.getLlistaPartes(false);
      // this._ui.showToastDefault(JSON.stringify(_params));
    } else {
      // console.log('Se cerró el modal sin parametros');
    }
    resolve();
  });

  async showSearchPage() {
    if (!this._hp.validaAccesoConexion()) {
      return;
    }

    this.existeParteLocal = false;

    const modal = await this.modalCtrl.create({
      component: PartesSearchModalPage,
      componentProps: {
        callback: this.callbackReturnSearchFunction,
        criterio: this.criterio,
        valorSearch: this.valorSearch
      }
    });
    modal.present();
  }


  // callback Return Config Function...
  callbackReturnConfigFunction = (_params) => new Promise<void>((resolve, reject) => {
    if (_params) {
      this.resetIniciFinal();

      this.criterioOrdenar = _params.criterioOrdenar;
      this.criterioMostrar = _params.criterioMostrar;
      this.criterioExpandirContraer = _params.criterioExpandirContraer;
      //this.incluirDesuso = dataReturn.incluirDesuso

      this.getLlistaPartes(false);
      // this._ui.showToastDefault(JSON.stringify(_params));
    } else {
      // console.log('Se cerró el modal sin parametros');
    }
    resolve();
  });

  async showConfigPage() {
    if (!this._hp.validaAccesoConexion()) {
      return;
    }

    this.existeParteLocal = false;
    const modal = await this.modalCtrl.create({
      component: PartesConfigModalPage,
      componentProps: {
        callback: this.callbackReturnConfigFunction,
        criterioOrdenar: this._pg.usuarioLoginModel.partesTipusOrdre,
        criterioMostrar: this._pg.usuarioLoginModel.partesTipusMostrar,
        criterioExpandirContraer: this._pg.usuarioLoginModel.partesTipusView,
        incluirDesuso: this.incluirDesuso
      },
      cssClass: 'modal-fullscreen'
    });
    modal.present();
  }


  // callback Return TreeView Function...
  callbackReturnTreeViewFunction = (_params) => new Promise<void>((resolve, reject) => {
    if (_params && _params.result) {
      this.resetIniciFinal();

      this.idGemeElement = _params.result.idGemeElement;
      this.IncloureFills = _params.incluirSubElementos;

      this.getLlistaPartes(false);
    } else {
      // console.log('Se cerró el modal sin parametros');
    }
    resolve();
  });

  async showArbolPage() {
    if (!this._hp.validaAccesoConexion()) {
      return;
    }

    this.existeParteLocal = false;
    const modal = await this.modalCtrl.create({
      component: PartesTreeviewModalPage,
      componentProps: {
        callback: this.callbackReturnTreeViewFunction,
        incluirSubElementos: this.IncloureFills,
      },
      cssClass: 'modal-fullscreen'
    });
    modal.present();
  }

  quitarFiltros(event: Event, tipo: number) {
    //quito filtros
    event.stopPropagation();
    this._partesTabProvider.parte = null;
    switch (tipo) {
      case FILTRO_ARBOL: {
        this.idGemeElement = null;
        this._tv.elemetSelected = null;
        this._tv.parents = [];
        break;
      }
      case FILTRO_BUSQUEDA: {
        this.valorSearch = null;
        this.existeParteLocal = false;
      }
    }

    //reinicio contadores
    this.resetIniciFinal();

    //invoco para recuperar elementos
    this.getLlistaPartes(false);
  }

  async getLlistaPartes(shouldBeReload: boolean, initial: boolean = false) {
    this.existeParteLocal = false;

    if (!this._pg.workingOffline) {
      // console.log('voy por online -> partes');
      await this.getProcessOnline(shouldBeReload, initial);
    } else {
      // console.log('voy por offline -> partes');
      await this.getProcessOffline(true);
    }
  }

  async getProcessOnline(shouldBeReload: boolean, initial: boolean = false) {
    this.sincronizando = true;
    this.consultaWSRealizada = false;
    const loading = await this._messages.showLoading('txPartesDescargandoPartes');

    const params: PostParams[] = await this.getParamsToGetItems(shouldBeReload, this.favOn);

    this.descargarLlistaPartesFromServer(params, true, shouldBeReload, this.criterioExpandirContraer)
      .then((res) => {
        loading.dismiss();
        this.consultaWSRealizada = true;
        this.sincronizando = false;
      })
      .then(() => {
        if (initial) {
          try {
            this.checkExistDraft();
          } catch (error) {
            // console.error('Error en checkExistDraft -> ' + error);
          }
        }
      })
      .catch(err => {
        // console.error(JSON.stringify(err));
        this.sincronizando = false;
        loading.dismiss();
      });
  }

  getProcessOffline(primeraBusqueda: boolean) {
    return new Promise(async (resolve, reject) => {
      const loading = await this._messages.showLoading('txPartesDescargandoPartes');

      this._sync.getPartesListado(this._pg.proyectoSeleccionado.idProjecte).then((res: any) => {

        this.prepararFiltrosPartesListado(primeraBusqueda)
          .then(() => {
            if (primeraBusqueda) {
              this.rowFinalLocal = PARAM_ROW_FINAL_VALUE_LOCAL;
              this.rowIniciLocal = 0;
            }
            this.elementos.push(...this.calcularPaginaElementos());
            // console.log('elementos length ' + this.elementos.length);
            this.agregarDatosVisualizacionElementsPartes(this.elementos)
              .then(() => {
                this.elementos.forEach(elem => {
                  elem.colapsado = this.criterioExpandirContraer === PARTES_CONFIG_MOSTRAR_COLAPSADO;
                  elem.partes.forEach(parte => {
                    parte.colapsado = this.criterioExpandirContraer === PARTES_CONFIG_MOSTRAR_COLAPSADO;
                  });
                });

                this.consultaWSRealizada = true;
                loading.dismiss();
                resolve(true);
              });
          });
      });
    });
  }

  calcularPaginaElementos() {
    // console.log('calcularPaginaElementos - this._sync.partesListado.length --> ' + this._sync.partesListado.length);
    this.rowFinalLocal = PARAM_ROW_FINAL_VALUE_LOCAL > this._sync.partesListado.length ? this._sync.partesListado.length : this.rowFinalLocal > 0 ? this.rowFinalLocal : PARAM_ROW_FINAL_VALUE_LOCAL;
    this.rowIniciLocal = this.rowIniciLocal >= this.rowFinalLocal ? 0 : this.rowIniciLocal;

    // console.log('rowInicilocal - ' + this.rowIniciLocal);
    // console.log('rowFinalLocal - ' + this.rowFinalLocal);
    const result = this._sync.partesListado.slice(this.rowIniciLocal, this.rowFinalLocal);
    this.rowIniciLocal += PARAM_ROW_FINAL_VALUE_LOCAL;
    this.rowFinalLocal = this.normalizarRowFinalLocal();
    this.checkMasElementosOffline();
    return this.sortReports(result);
  }

  normalizarRowFinalLocal() {
    return this.rowFinalLocal > this._sync.partesListado.length - 1 ? this._sync.partesListado.length - 1 : this.rowFinalLocal += PARAM_ROW_FINAL_VALUE_LOCAL;
  }

  async prepararFiltrosPartesListado(primeraBusqueda: boolean) {
    if (primeraBusqueda) {
      this.elementos = [];
    }

    //en caso de que haya seleccionado algo en el árbol de elementos
    if (this.idGemeElement) {
      const elementInfo: ElementInfo = {
        idElement:  this.idGemeElement,
        idProjecte: this._pg.proyectoSeleccionado.idProjecte
      };
      await this._sync.getElement(elementInfo);

      const selectElement = this._sync.localElement;
      let rutaBusqueda: string;
      if (selectElement && (selectElement.rutaElement).trim() === '') {
        rutaBusqueda = this._pg.proyectoSeleccionado.nom + ' \\ ' + selectElement.nomElement;
      } else {
        rutaBusqueda = this._pg.proyectoSeleccionado.nom + ' \\ ' + selectElement.rutaElement + ' \\ ' + selectElement.nomElement;
      }

      if (selectElement) {
        if (!this.IncloureFills) {
          this._sync.partesListado = this._sync.partesListado.filter((l: any) => l.idElement === this.idGemeElement);
        } else {
          this._sync.partesListado = this._sync.partesListado.filter((l: any) => l.rutaElement.startsWith(rutaBusqueda) || l.idElement === this.idGemeElement);
        }
      } else {
        this._sync.partesListado = [];
      }
    }

    //si el filtro de busqueda tiene algún texto
    if (this.valorSearch > '') {
      if (this.criterio === this.constants.PARTES_BUSCAR_X_CUALQUIERA || this.criterio === this.constants.PARTES_BUSCAR_X_CODIGO_PARTE) {

        //busco en el listado de partes de cada elemento si hay coincidencia por el codiParte
        //cuando encuentro resultado regreso
        if (this.buscarEnListadoPartes()) {
          this.useFavs();
          return;
        }

        //busco en ultimsPartes de cada parte de cada elemento si hay coincidencia abro el parte directamente
        if (this.buscarEnUltimsPartes()) {
          this.useFavs();
          return;
        }
      }

      //si no hay resultado para código de parte hago busqueda por filtros restantes
      if (this.buscarPorTodos()) {
        this.useFavs();
        return;
      }

      //finalmente si llego a este punto no hay coincidencias para los criterios usados.
      this._sync.partesListado = [];
    }

    this.useFavs();
    this.ordenarElementos();
  }

  private ordenarElementos() {
    if (this.criterioOrdenar == PARTES_CONFIG_ORDENAR_X_POSICION_ARBOL) {
      this._sync.partesListado.sort((a, b) => (b.strOrdrePerArbre < a.strOrdrePerArbre ? 1 : -1));
    } else {
      this._sync.partesListado.sort((a, b) => (b.ordrePerCodiNom < a.ordrePerCodiNom ? 1 : -1));
    }
  }

  private sortReports(arrayPartes: any[]): any[] {
    arrayPartes.forEach(e => e.partes.sort((parteA, parteB) => parteA.codiModel.localeCompare(parteB.codiModel)));
    return arrayPartes;
  }

  private useFavs() {
    if (this.favOn) {
      this.buscarEnFavoritos();
    }
  }

  private buscarPorTodos() {
    //busco por codigo elemento
    if (this.buscarPorCodigoElemento()) {
      return true;
    }

    //busco por nombre elemento
    if (this.buscarPorNombreElemento()) {
      return true;
    }

    //busco por codigo modelo parte
    if (this.buscarPorCodigoModeloParte()) {
      return true;
    }

    //busco por nombre modelo parte
    if (this.buscarPorNombreModeloParte()) {
      return true;
    }

    return false;
  }

  private buscarEnListadoPartes() {
    for (let i = 0; i < this._sync.partesListado.length; i++) {
      for (let j = 0; j < this._sync.partesListado[i].partes.length; j++) {
        if (this._sync.partesListado[i].partes[j].codiParte == this.valorSearch.toUpperCase()) {
          this._sync.partesListado = [this._sync.partesListado[i]];
          this._sync.partesListado[0].partes = [this._sync.partesListado[0].partes[j]];
          return true;
        }
      }
    }

    return false;
  }

  private buscarEnUltimsPartes() {
    for (let k = 0; k < this._sync.partesListado.length; k++) {
      for (let l = 0; l < this._sync.partesListado[k].partes.length; l++) {
        for (let m = 0; m < this._sync.partesListado[k].partes[l].ultimsPartes.length; m++) {
          if (this._sync.partesListado[k].partes[l].ultimsPartes[m].codiParte == this.valorSearch.toUpperCase()) {
            this.existeParteLocal = true;
            this._sync.getDetalleParte(this._sync.partesListado[k].partes[l].ultimsPartes[m].idParte)
              .then(() => {
                this.existeParteLocal = true;
                this.getParteProcessOffline(this._sync.localParte, false);
                return true;
              });
          }
        }
      }
    }

    return false;
  }

  private buscarPorCodigoElemento() {
    const listAux = [];
    if (this.criterio == this.constants.PARTES_BUSCAR_X_CUALQUIERA || this.criterio == this.constants.PARTES_BUSCAR_X_CODIGO_ELEMENTO) {
      for (let n = 0; n < this._sync.partesListado.length; n++) {
        if (this._sync.partesListado[n].codiElement.toUpperCase().includes(this.valorSearch.toUpperCase())) {
          listAux.push(this._sync.partesListado[n]);
        }
      }

      if (listAux.length > 0 || (listAux.length == 0 && this.criterio != this.constants.PARTES_BUSCAR_X_CUALQUIERA)) {
        this._sync.partesListado = listAux;
      }
    }

    return listAux.length > 0;
  }

  private buscarPorNombreElemento() {
    const listAux = [];
    if (this.criterio == this.constants.PARTES_BUSCAR_X_CUALQUIERA || this.criterio == this.constants.PARTES_BUSCAR_X_NOMBRE_ELEMENTO) {
      for (let p = 0; p < this._sync.partesListado.length; p++) {
        if (this._sync.partesListado[p].nomElement.toUpperCase().includes(this.valorSearch.toUpperCase())) {
          listAux.push(this._sync.partesListado[p]);
        }
      }
      if (listAux.length > 0 || (listAux.length == 0 && this.criterio != this.constants.PARTES_BUSCAR_X_CUALQUIERA)) {
        this._sync.partesListado = listAux;
      }
    }

    return listAux.length > 0;
  }

  private buscarPorCodigoModeloParte() {
    const listAux = [];
    if (this.criterio == this.constants.PARTES_BUSCAR_X_CUALQUIERA || this.criterio == this.constants.PARTES_BUSCAR_X_CODIGO_MODELO_PARTE) {
      for (let q = 0; q < this._sync.partesListado.length; q++) {
        for (let r = 0; r < this._sync.partesListado[q].partes.length; r++) {
          if (this._sync.partesListado[q].partes[r].codiModel.toUpperCase().includes(this.valorSearch.toUpperCase())) {
            listAux.push(this._sync.partesListado[q]);
            listAux[listAux.length - 1].partes = listAux[listAux.length - 1].partes.filter(p => p.codiModel.toUpperCase().includes(this.valorSearch.toUpperCase()));
            break;
          }
        }
      }
      if (listAux.length > 0 || (listAux.length == 0 && this.criterio != this.constants.PARTES_BUSCAR_X_CUALQUIERA)) {
        this._sync.partesListado = listAux;
      }
    }

    return listAux.length > 0;
  }

  private buscarPorNombreModeloParte() {
    const listAux = [];
    if (this.criterio == this.constants.PARTES_BUSCAR_X_CUALQUIERA || this.criterio == this.constants.PARTES_BUSCAR_X_NOMBRE_MODELO_PARTE) {
      for (let s = 0; s < this._sync.partesListado.length; s++) {
        for (let t = 0; t < this._sync.partesListado[s].partes.length; t++) {
          if (this._sync.partesListado[s].partes[t].nomModel.toUpperCase().includes(this.valorSearch.toUpperCase())) {
            listAux.push(this._sync.partesListado[s]);
            listAux[listAux.length - 1].partes = listAux[listAux.length - 1].partes.filter(p => p.nomModel.toUpperCase().includes(this.valorSearch.toUpperCase()));
            break;
          }
        }
      }
      if (listAux.length > 0 || (listAux.length == 0 && this.criterio != this.constants.PARTES_BUSCAR_X_CUALQUIERA)) {
        this._sync.partesListado = listAux;
      }
    }

    return listAux.length > 0;
  }


  private buscarEnFavoritos() {
    const listGral = [];
    for (let i = 0; i < this._sync.partesListado.length; i++) {
      for (let j = 0; j < this._sync.partesListado[i].partes.length; j++) {

        let encontro = false;
        if (this._pg.favs.some(e => e === this._sync.partesListado[i].partes[j].idModelElement)) {

          for (let r = 0; r < listGral.length; r++) {
            if (listGral[r].idElement === this._sync.partesListado[i].idElement) {
              listGral[r].partes.push(this._sync.partesListado[i].partes[j]);
              encontro = true;
              break;
            }
          }

          if (!encontro) {
            const copy = JSON.parse(JSON.stringify(this._sync.partesListado[i]));
            listGral.unshift(copy);
            listGral[0].partes = [this._sync.partesListado[i].partes[j]];
          }
        }
      }
    }

    this._sync.partesListado = listGral;
  }

  getParte(parte: any, isDraft) {
    if (!this._pg.workingOffline) {
      this.getParteProcessOnline(parte, isDraft);
    } else {
      this.getParteProcessOffline(parte, isDraft);
    }
  }

  async getParteProcessOnline(parte: any, isDraft) {
    const loading = await this._messages.showLoading('txPartesDescargandoParte');
    if (isDraft) {
      this._partesTabProvider.parte = this._partesTabProvider.parteDraft;
      this.navUtils.set(AppRoutes.parteTabsPage, this.callbackReturnShowReportFunction);
      this.router.navigate(['/', AppRoutes.parteTabsPage]);

      this._partesTabProvider.editando = true;
      await loading.dismiss();
    } else {
      const params: PostParams[] = this.getParamsToGetParte(parte);
      await this.descargarParteFromServer(PARAM_GET_PARTE, params)
        .then((res) => {
          if (res) {
            loading.dismiss();
            this.navUtils.set(AppRoutes.parteTabsPage, this.callbackReturnShowReportFunction);
            this.router.navigate(['/', AppRoutes.parteTabsPage]);
          } else {
            loading.dismiss();
          }
        })
        .catch(err => {
          // console.error(JSON.stringify(err));
          loading.dismiss();
        });
    }
  }

  async getParteProcessOffline(parte: any, isDraft: any) {
    const loading = await this._messages.showLoading('txRecuperandoParte');

    if (isDraft) {
      this._partesTabProvider.parte = this._partesTabProvider.parteDraft;
      this.navUtils.set(AppRoutes.parteTabsPage, this.callbackReturnShowReportFunction);
      this.router.navigate(['/', AppRoutes.parteTabsPage]);
      this._partesTabProvider.editando = true;
      loading.dismiss();
    } else {
      this._sync.getDetalleParte(parte.idParte)
        .then((res) => {
          if (res) {
            this.prepareReportToView(this._sync.localParte);
            this.navUtils.set(AppRoutes.parteTabsPage, this.callbackReturnShowReportFunction);
            this.router.navigate(['/', AppRoutes.parteTabsPage]);
          }
          loading.dismiss();
        });
    }
  }

  // callback Return ShowReport Function...
  callbackReturnShowReportFunction = () => new Promise<void>((resolve, reject) => {

    if ((!this._pg.workingOffline && this._np.appOnline) || this._pg.workingOffline) {
      this.getLlistaPartes(true);
      this.getParte(this._partesTabProvider.parte, false);
    } else {
      this._hp.abrirPaginaClick(AppRoutes.homePage, false);
    }

    resolve();
  });
  trackByFn(index, item) {
    return item.idElement;
  }

  private async getParamsToGetItems(shoulBeReload: boolean, onlyFavs: boolean) {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    };
    params.push(paramStrConn);

    //idProjecte
    const paramIdProjecte: PostParams = {
      nombre: PARAM_ID_PROJECTE,
      valor: String(this._pg.proyectoSeleccionado.idProjecte)
    };
    params.push(paramIdProjecte);

    //rowInici
    const paramRowInici: PostParams = {
      nombre: PARAM_ROW_INICI,
      valor: shoulBeReload ? '1' : String(this.rowInici)
    };
    params.push(paramRowInici);

    //rowFinal
    this.rowFinal = this.rowFinal > 0 ? this.rowFinal : PARAM_ROW_FINAL_VALUE;
    const paramRowFinal: PostParams = {
      nombre: PARAM_ROW_FINAL,
      valor: shoulBeReload ? String(this.rowFinal - PARAM_ROW_FINAL_VALUE) : String(this.rowFinal)
    };
    params.push(paramRowFinal);

    //tipusOrdre
    const paramTipusOrdre: PostParams = {
      nombre: PARAM_TIPUS_ORDRE,
      valor: String(this.criterioOrdenar)
    };
    params.push(paramTipusOrdre);

    //tipusMostrar
    const paramTipusMostrar: PostParams = {
      nombre: PARAM_TIPUS_MOSTRAR,
      valor: String(this.criterioMostrar)
    };
    params.push(paramTipusMostrar);

    //tipusFiltre - aplica misma validacion que para this.valorSearch
    if (this.valorSearch && this.valorSearch != '') {
      const paramTipusFiltre: PostParams = {
        nombre: PARAM_TIPUS_FILTRE,
        valor: String(this.criterio)
      };
      params.push(paramTipusFiltre);
    }

    //filtre
    if (this.valorSearch && this.valorSearch != '') {
      const paramFiltre: PostParams = {
        nombre: PARAM_FILTRE,
        valor: this.valorSearch
      };
      params.push(paramFiltre);
    }

    //idGemeElement
    if (this.idGemeElement && this.idGemeElement > 0) {
      const paramIdGemeElement: PostParams = {
        nombre: PARAM_ID_GEME_ELEMENT,
        valor: String(this.idGemeElement)
      };
      params.push(paramIdGemeElement);
    }

    //incloureFills
    const paramIncloureFills: PostParams = {
      nombre: PARAM_INCLOURE_FILLS,
      valor: this.IncloureFills ? '1' : '0'
    };
    params.push(paramIncloureFills);

    //paramModelsElementsFav
    if (onlyFavs) {
      const paramModelsElementsFav: PostParams = {
        nombre: PARAM_MODELS_ELEMENTS_FAV,
        valor: this.getFavsStr()
      };
      params.push(paramModelsElementsFav);
    }
    const permission = await this.getPermissionUseCase.execute();
    if (permission && permission.createNotPlannedReports) {
      const paramGet: PostParams = {
        nombre: PARAM_INCLUDE_NOT_PLANNED_PARTS,
        valor: '1'
      };
      params.push(paramGet);
    }

    return params;
  }

  private getParamsToGetParte(parte: any) {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    };
    params.push(paramStrConn);
    // console.log('this._wsUtils.getStrConn() --> ' + this._wsUtils.getStrConn());

    //idModelElement
    if (parte.idModelElement != null && parte.idModelElement != '') {
      const idModelElement: PostParams = {
        nombre: PARAM_ID_MODEL_ELEMENT,
        valor: parte.idModelElement
      };
      params.push(idModelElement);
    }
    // console.log('parte.idModelElement --> ' + parte.idModelElement);

    //idParte
    if (parte.idParte) {
      const idParte: PostParams = {
        nombre: PARAM_ID_PARTE,
        valor: parte.idParte
      };
      params.push(idParte);
      // console.log('parte.idParte --> ' + parte.idParte);
    }

    return params;
  }

  getFavsStr(): string {
    return this._pg.favs.join(',');
  }

  async getFavsDB() {
    await this._pg.getFavsDB(this._pg.usuarioLoginModel.idUsuari);
  }

  // eslint-disable-next-line max-len
  descargarLlistaPartesFromServer(params: PostParams[], primeraBusqueda: boolean, shouldBeReload: boolean, criterioExpandirContraer: number) {
    const promesa = new Promise((resolve, reject) => {

      if (primeraBusqueda && !shouldBeReload) {
        this.rowInici = 1;
      }

      if (primeraBusqueda || shouldBeReload) {
        this.elementos = [];
      }

      this._ajax.consultarWS(PARAM_GET_LLISTA_PARTES, params, 0)
        .subscribe((res: any) => {

            if (res.elementsPartes.length > 0) {
              this.agregarDatosVisualizacionElementsPartes(res.elementsPartes)
                .then(() => {
                  this.elementos.push(...res.elementsPartes);

                  if (!shouldBeReload) {
                    // console.log('ROW INICIO ANTES ....... ' + this.rowInici);
                    // console.log('ROW FINAL ANTES ....... ' + this.rowFinal);
                    this.rowInici += PARAM_ROW_FINAL_VALUE;
                    this.rowFinal += PARAM_ROW_FINAL_VALUE;
                  }

                  this.elementos.forEach(elem => {
                    elem.colapsado = criterioExpandirContraer === PARTES_CONFIG_MOSTRAR_COLAPSADO;
                    elem.partes.forEach(parte => {
                      parte.colapsado = criterioExpandirContraer === PARTES_CONFIG_MOSTRAR_COLAPSADO;
                    });
                  });

                  resolve(true);
                });
            } else {
              resolve(false);
            }

          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error('ERROR: ' + errConsulta.categoria + ' ' + errConsulta.categoria);
            resolve(false);
          });

    })
      .catch(err => {
        // console.error('error en descargarLlistaPartesFromServer: ' + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });


    return promesa;
  }


  descargarParteFromServer(paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {

      this._ajax.consultarWS(paramAccion, params, Data.TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
        .subscribe((res: any) => {
            if (res !== null) {
              this.prepareReportToView(res);
              resolve(true);
            } else {
              resolve(false);
            }

          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error('ERROR: ' + errConsulta.categoria + ' ' + errConsulta.categoria);
            resolve(false);
          });

    })
      .catch(err => {
        // console.error('error en descargarLlistaPartesFromServer: ' + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });


    return promesa;
  }

  async prepareReportToView(parteParam: any) {
    this._partesTabProvider.parte = parteParam;
    this._partesTabProvider.estadoOriginal = parteParam.tipusEstatParteRealitzat;
    const dPlan = new Date(parteParam.dataHoraPlanificacio);
    const dInicial = new Date(parteParam.dataHoraInicialForqueta);
    const dFinal = new Date(parteParam.dataHoraFinalForqueta);
    const tieneIncidenciasAbiertasModelElement = parteParam.teIncidenciesObertes === 1;

    const codigoEstadoParte = this._gemeProvider.getTipoEstadoParte(parteParam.tipusEstatParteRealitzat, tieneIncidenciasAbiertasModelElement, dPlan, dInicial, dFinal);
    parteParam.esFuturo = codigoEstadoParte === PARTES_TIPO_ESTADO_PLAN_FUTURO;
    parteParam.srcImgEstadoParte = this._gemeProvider.getSrcImgEstadoParte(codigoEstadoParte);
    parteParam.srcImgEstadoParteToResult = this._gemeProvider.getSrcImgEstadoParteToResult(codigoEstadoParte);
    this._partesTabProvider.parte.srcImgEstadoParte = parteParam.srcImgEstadoParte;
    this._partesTabProvider.parte.srcImgEstadoParteToResult = parteParam.srcImgEstadoParteToResult;
    parteParam.txEstadoParte = this._translate.traducir(this._gemeProvider.getTextoEstadoParte(codigoEstadoParte));

    this._partesTabProvider.calcularEstadoParte(codigoEstadoParte, tieneIncidenciasAbiertasModelElement, dPlan, dInicial, dFinal);

    if (this._partesTabProvider.parte.incidencies != null) {
      for (const inc of this._partesTabProvider.parte.incidencies) {
        inc.srcFitxerLocalTipologia = await this._htmlUtils.normalizarImageSrc(this._fp.pathDestinoGemeIncidencies + '/' + inc.nomFitxerLocalTipologia);
        inc.srcFitxerLocalIconaElement = await this._htmlUtils.normalizarImageSrc(this._fp.pathDestinoGemeIcones + '/' + inc.nomFitxerLocalIconaElement);
        inc.srcFitxerLocalEstat = await this._htmlUtils.normalizarImageSrc(this._fp.pathDestinoGemeEstatsincidencies + '/' + inc.nomFitxerLocalEstat);
      }
    }

    this._partesTabProvider.parteHasDifference = false;
  }


  async doInfinite(infiniteScroll) {
    if (!this._pg.workingOffline) {
      // console.log('Begin async operation');

      this.descargarLlistaPartesFromServer(await this.getParamsToGetItems(false, this.favOn), false, false, this.criterioExpandirContraer)
        .then((hayMas: boolean) => {
          this.hayMasElementos = hayMas;

          // console.log('Async operation has ended');
          infiniteScroll.target.complete();
        });
    } else {
      this.getProcessOffline(false)
        .then(() => {
          this.checkMasElementosOffline();
          infiniteScroll.target.complete();
        });
    }
  }

  checkMasElementosOffline() {
    if (this._sync.partesListado.length - 1 == this.rowFinalLocal) {
      this.hayMasElementos = false;
    } else {
      this.hayMasElementos = true;
    }
  }

  async agregarDatosVisualizacionElementsPartes(elementsPartes: any[]) {
    await this._sync.getPartesResumen(this._pg.proyectoSeleccionado.idProjecte);

    for (let j = 0; j < elementsPartes.length; j++) {
      const route = `${this._fp.pathDestinoGemeIcones}/${elementsPartes[j].nomFitxerIcona}`;

      this._pg.getImageToDB(route).then((imageIcon) => {
        elementsPartes[j].srcIcona = imageIcon;
      });

      for (let i = 0; i < elementsPartes[j].partes.length; i++) {
        const dPlan = new Date(elementsPartes[j].partes[i].dataHoraPlanificacio);
        const dInicial = new Date(elementsPartes[j].partes[i].dataHoraInicialForqueta);
        const dFinal = new Date(elementsPartes[j].partes[i].dataHoraFinalForqueta);
        const tieneIncidenciasAbiertasModelElement = elementsPartes[j].partes[i].teIncidenciesObertes == 1;

        const status = this._sync.partesResumen[elementsPartes[j].partes[i].idParte];
        const statusParam = (status && status.tipusEstatParteRealitzat) ? status.tipusEstatParteRealitzat : elementsPartes[j].partes[i].tipusEstatParteRealitzat;

        const codigoEstadoParte = this._gemeProvider.getTipoEstadoParte(statusParam, tieneIncidenciasAbiertasModelElement, dPlan, dInicial, dFinal);
        elementsPartes[j].partes[i].esFuturo = codigoEstadoParte == PARTES_TIPO_ESTADO_PLAN_FUTURO;
        elementsPartes[j].partes[i].srcImgEstadoParte = this._gemeProvider.getSrcImgEstadoParte(codigoEstadoParte);
        elementsPartes[j].partes[i].txEstadoParte = this._translate.traducir(this._gemeProvider.getTextoEstadoParte(codigoEstadoParte));
        elementsPartes[j].partes[i].favoritos = this._pg.favs.indexOf(elementsPartes[j].partes[i].idModelElement) !== -1;
      }
    }
  }


  private resetIniciFinal() {
    this.rowInici = 1;
    this.rowFinal = 0;
    this.rowIniciLocal = 0;
    this.rowFinalLocal = 0;
    this.hayMasElementos = true;
  }

  viewReport(parte: any, elemento: any, isDraft: boolean) {
    this._partesTabProvider.elementoSeleccionado = elemento;
    this.getParte(parte, isDraft);
  }

  swipeAddFavorite(swipeItem, parte: any) {
    setTimeout(function() {
      swipeItem.close();
    }, 500);
    this.addFavorite(parte);
  }

  swipeViewReport(swipeItem, parte: any, elemento: any) {
    setTimeout(function() {
      swipeItem.close();
    }, 500);
    this.viewReport(parte, elemento, false);
  }

  swipeShowList(swipeItem, parte: any, item: any) {
    setTimeout(function() {
      swipeItem.close();
    }, 500);
    this.showList(parte, item);
  }

  async addFavorite(parte: any) {
    if (parte.favoritos == null) {
      parte.favoritos = true;
    } else {
      parte.favoritos = !parte.favoritos;
    }
    await this.updateFavorito(parte);
    await this.getFavsDB();

    if (!parte.favoritos && this.favOn) {
      this.resetIniciFinal();
      this.getLlistaPartes(false);
    }
  }

  async showList(parte: any, item: any) {
    const modal = await this.modalCtrl.create({
      component: ListaOtrosPartesPage,
      componentProps: {
        parte,
        modelElement: item
      },
      cssClass: 'modal-fullscreen'
    });
    await modal.present();
  }

  eventoStopPropagation(event: Event) {
    event.stopPropagation();
  }

  async openMenu(event: Event, parte: any, elemento: any, htmlElement: any) {
    event.stopPropagation();
    const title = this._translate.traducir('txParteActionSheetTitulo');
    const verParte = this._translate.traducir('txParteActionSheetVerParte');
    const agregarFavoritos = this._translate.traducir('txParteAgregarQuitarFavoritos');
    const verOtrosPartes = this._translate.traducir('txPartesOtrosPartes');
    const cancelar = this._translate.traducir('txCancelar');
    const noPlanificado = this._translate.traducir('txPartesNotPlannedCreate');

    const customButtons = [
      {
        text: verParte,
        icon: 'lc-icon-parte',
        role: 'destructive',
        handler: () => {
          // console.log('verParte clicked');
          this.viewReport(parte, htmlElement, false);
        }
      },
      {
        text: agregarFavoritos,
        icon: 'lc-icon-star',
        handler: () => {
          // console.log('agregarFavoritos clicked');
          this.addFavorite(parte);
        }
      },
      {
        text: verOtrosPartes,
        icon: 'lc-icon-list',
        handler: () => {
          // console.log('verOtrosPartes clicked');
          this.showList(parte, elemento);
        }
      },
      {
        text: cancelar,
        role: 'cancel',
        icon: !this.platform.is('ios') ? 'close' : null,
        handler: () => {
          // console.log('Cancel clicked');
        }
      }];
    if (parte.esPotCrearNoPlan==1) {
      customButtons.push({
        text: noPlanificado,
        icon: 'lc-icon-parte-np',
        handler: () => {
          this.openCreateNotPlannedPart(elemento, parte);
        }
      });
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: title,
      buttons: customButtons
    });

    actionSheet.present();

  }

  colapsarDescolapsar(elemento) {
    if (elemento.colapsado == null) {
      elemento.colapsado = false;
    }
    elemento.colapsado = !elemento.colapsado;
    elemento.partes.forEach(parte => {
      parte.colapsado = elemento.colapsado;
    });
    // console.log('colapsarDescolapsar' + elemento.colapsado);
  }

  ocultarSiColapsado(parte) {
    return (parte.colapsado == true);
  }

  toggleFavoritos() {
    if (!this._hp.validaAccesoConexion()) {
      return;
    }

    this.favOn = !this.favOn;
    // console.log('click en favoritos');
    this.resetIniciFinal();
    this.getLlistaPartes(false);
  }

  async updateFavorito(parte: any) {
    const favorito: UsuariModelElementFavoritoDB = {
      idUsuari: this._pg.usuarioLoginModel.idUsuari,
      idModelElement: parte.idModelElement,
      favorit: parte.favoritos ? 1 : 0
    };
    await this._pg.insertUsuarisModelsElements(favorito);
  }


  checkExistDraft() {
    this._partesTabProvider.getDraft()
      .then(async (res: boolean) => {
        if (res) {
          const msg = this._translate.traducirWithParams('txEstabaEditando', {codParte: this._partesTabProvider.parteDraft.codiParte});
          const alert = await this._ui.showYesNoAlert({backdropDismiss: false}, 'txAtencionStr', msg, 'txYes', 'txNo');
          alert.onDidDismiss().then(({data}) => {
            if (!data.accepted) {
              this._partesTabProvider.removeDraft();
              return false;
            }

            this.viewReport(this._partesTabProvider.parteDraft, 'item' + this._partesTabProvider.parteDraft.idModelElement, true);
          });
        }
      });
  }

  async openCreateNotPlannedPart(elemento, parte): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ParteCreateNotPlannedPage,
      componentProps: {
        element: elemento,
        parte
      }
    });
    modal.present();
    //When part created, open its detail page
    // In offline mode, setting the original part with the new one on the list of "Other Parts"
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        if (this._pg.workingOffline) {
          parte = data.data[1];
          this.getParte(data.data[0], false);
        } else{
          this.getParte(data.data, false);
        }
      }
    });
  }
}
