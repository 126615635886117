import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'formatoNumerico',
})
export class FormatoNumericoPipe implements PipeTransform {

  transform(value: string | number, decimales?: string) {
    const idx = String(value).indexOf(".");
    if(idx >= 0){
        return  Number(value).toFixed(decimales ? Number(decimales) : 2);
    }
    return Number(value);
  }
}
