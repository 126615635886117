<ion-list>
  <div *ngFor="let node of nodesTree">
    <ion-item [id]="node.idGemeElement" lines="none"
              [ngClass]="{'active-page-menu': _tv.elemetSelected !== null && _tv.elemetSelected.idGemeElement === node.idGemeElement}">
      <img class="ion-no-margin" slot="start" (click)="elementClick(node, true)" [src]="node.nomFitxerIcona  | safeHtml: 'resourceUrl'"
           onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';" style="width:2.3em">
      <div class="ion-margin-horizontal" (click)="elementClick(node, true)">
        <ng-container *ngIf="node.codiElement !== ''">{{node.codiElement}} - </ng-container>{{node.nomElement}}
      </div>
      <ion-avatar slot="end" *ngIf="node.showIconSub">
        <ion-button color="light" style="width:2.3em" (click)="node.toggle();">
          <ion-icon slot="icon-only" [name]="node.iconSub"></ion-icon>
        </ion-button>
      </ion-avatar>
    </ion-item>

    <div class="treeSubMenu" *ngIf="node.expanded">
      <tree-view [executeEvent]="executeEvent" placeholder="TestingInput" [nodesTree]="node.subMenus"></tree-view>
    </div>
  </div>
</ion-list>
