import {Subscription} from 'rxjs';
import {Component} from '@angular/core';
import {LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {WsUtilsProvider} from '../../services/utils-ws/ws-utils';
import {AjaxClientProvider, ErrorConsulta} from '../../services/ajax-client/ajax-client.provider';
import {PostParams} from '../../interfaces/ajax.interface';
import {
  PARAM_FILTRE,
  PARAM_GET_MATERIALS_SELECCIO,
  PARAM_ID_GRUPO_GESTIO,
  PARAM_ROW_FINAL,
  PARAM_ROW_FINAL_VALUE_MATERIALS,
  PARAM_ROW_INICI,
  PARAM_STRCONN
} from '../../config/constantes';
import {NetworkProvider} from '../../services/network/network.provider';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {SyncProvider} from '../../services/sync/sync.provider';

@Component({
  selector: 'page-parte-material-add-modal',
  templateUrl: 'parte-material-add-modal.html',
  styleUrls: ['parte-material-add-modal.scss'],
})
export class ParteMaterialAddModalPage {



  respuesta = "";
  descripcion = "";

  valorSearch = "";

  rowInici = 1;
  rowFinal = 0;

  idGrupGestio: number = null;

  //lista que mantendrá los elementos en memoria
  materiales: any = [];
  hayMasElementos = true;
  consultaWSRealizada = false;

  private backButtonListener?: Subscription;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              public platform: Platform,
              public _ui: UiMessagesProvider,
              public _wsUtils: WsUtilsProvider,
              private _ajax: AjaxClientProvider,
              private _messages: UiMessagesProvider,
              private loadingCtrl: LoadingController,
              public _np: NetworkProvider,
              public _pg: PersistenciaGeneralProvider,
              public _sync: SyncProvider,
              //public viewCtrl: ViewController,
              public modalCtrl: ModalController,
  ) {

    if (navParams.get("respuesta") && navParams.get("respuesta") != "") {
      this.respuesta = navParams.get("respuesta");
    }

    if (navParams.get("descripcion") && navParams.get("descripcion") != "") {
      this.descripcion = navParams.get("descripcion");
    }

    this.getListaMateriales();
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }

  /*** fin trabajo con el boton atras */

  cerrarConParametros(meterial: any) {
    let data: any;
    data = {
      idMaterial: meterial.id,
      codi: meterial.codi,
      descripcio: meterial.descripcio,
      unitat: meterial.unitat,
      quantitatPrevista: null,
      quantitatUtilitzada: null
    }
    this.modalCtrl.dismiss(data);
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }

  getListaMateriales() {
    if (!this._pg.workingOffline) {
      this.getProcessListaMaterialesOnline();
    } else {
      this.getProcessListaMaterialesOffline();
    }
  }

  async getProcessListaMaterialesOnline() {
    const loading = await this._messages.showLoading("txDescargandoMateriales");
    const params: PostParams[] = this.getParamsToGetListaMateriales();

    this.descargarMaterialesFromServer(PARAM_GET_MATERIALS_SELECCIO, params, true)
      .then((res) => {
        loading.dismiss();
        this.consultaWSRealizada = true;
      })
      .catch(err => {
        // console.error(JSON.stringify(err))
        loading.dismiss();
      });
  }

  async getProcessListaMaterialesOffline() {
    const loading = await this._messages.showLoading("txRecuperandoMateriales");

    this._sync.getMaterials(this._pg.proyectoSeleccionado.idGrupoGestio)
      .then(() => {
        this.materiales = this._sync.localMaterials;
        loading.dismiss();
      })
      .catch(err => {
        // console.error(JSON.stringify(err))
        loading.dismiss();
      });
  }

  getParamsToGetListaMateriales() {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    }
    params.push(paramStrConn);

    //rowInici
    const paramRowInici: PostParams = {
      nombre: PARAM_ROW_INICI,
      valor: String(this.rowInici)
    }
    params.push(paramRowInici);

    //rowFinal
    this.rowFinal = this.rowFinal > 0 ? this.rowFinal : PARAM_ROW_FINAL_VALUE_MATERIALS;
    const paramRowFinal: PostParams = {
      nombre: PARAM_ROW_FINAL,
      valor: String(this.rowFinal)
    }
    params.push(paramRowFinal);

    //idGrupoGestio
    this.idGrupGestio = this._pg.proyectoSeleccionado.idGrupoGestio;
    const paramIdGrupGestio: PostParams = {
      nombre: PARAM_ID_GRUPO_GESTIO,
      valor: String(this.idGrupGestio)
    }
    params.push(paramIdGrupGestio);

    //filtre
    if (this.valorSearch && this.valorSearch != '') {
      const paramFiltre: PostParams = {
        nombre: PARAM_FILTRE,
        valor: this.valorSearch
      }
      params.push(paramFiltre);
    }
    return params;
  }

  descargarMaterialesFromServer(paramAccion: string, params: PostParams[], primeraBusqueda: boolean) {
    const promesa = new Promise((resolve, reject) => {

      if (primeraBusqueda) {
        this.rowInici = 1;
        this.rowFinal = PARAM_ROW_FINAL_VALUE_MATERIALS;
        this.materiales = [];
      }
      this._ajax.consultarWS(paramAccion, params, 0)
        .subscribe((res: any) => {
            if (res.materials.length > 0) {
              this.materiales.push(...res.materials);
              this.rowInici += PARAM_ROW_FINAL_VALUE_MATERIALS;
              this.rowFinal += PARAM_ROW_FINAL_VALUE_MATERIALS;

              resolve(true);
            } else {
              resolve(false);
            }
          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);
            resolve(false);
          });
    })
      .catch(err => {
        // console.error("error en descargarMaterialesFromServer: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });
    return promesa;
  }


  doInfinite(infiniteScroll) {
    if (!this._pg.workingOffline) {
      // console.log('Begin async operation');
      this.descargarMaterialesFromServer(PARAM_GET_MATERIALS_SELECCIO, this.getParamsToGetListaMateriales(), false)
        .then((hayMas: boolean) => {
          this.hayMasElementos = hayMas;
          // console.log('Async operation has ended');
          infiniteScroll.target.complete();
        });
    } else {
      // console.log('Begin async operation offline');
      this.hayMasElementos = false;
      // console.log('Async operation has ended offline');
      infiniteScroll.target.complete();
    }
  }

  onInput(ev: any) {
    this.rowInici = 1;
    this.rowFinal = PARAM_ROW_FINAL_VALUE_MATERIALS,
      this.getListaMateriales();
  }

}
