import {Subscription} from 'rxjs';
import {Component, ViewChild} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {LcInputNumberComponent} from '../../components/lc-input-number/lc-input-number';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';


@Component({
  selector: 'page-parte-material',
  templateUrl: 'parte-material.html',
})
export class ParteMaterialPage {
  @ViewChild(LcInputNumberComponent,{static: true}) lcinput: LcInputNumberComponent;


  cantidad = "";
  materialCodiDescripcio = "";
  indice = -1;
  cerrando =  false;

  private backButtonListener?: Subscription;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              public _htmlUtils: HtmlUtilsProvider,
              public alertCtrl: AlertController,
              public _ptp: PartesTabProvider,
              public platform: Platform,
              //public viewCtrl: ViewController,
              public _pg: PersistenciaGeneralProvider,
              public _ui: UiMessagesProvider,
              public modalCtrl: ModalController,
              ) {

                // console.log(navParams);

                if(navParams.get("cantidad")){
                  this.cantidad = navParams.get("cantidad");
                }

                if(navParams.get("materialCodiDescripcio")){
                  this.materialCodiDescripcio = navParams.get("materialCodiDescripcio");
                }

                this.indice = navParams.get("indice");
  }

    /*** trabajo con el boton atras */
    ionViewDidEnter() {
      this.initializeBackButtonCustomHandler();
    }

    ionViewWillLeave() {
      this.backButtonListener?.unsubscribe()
    }

    async initializeBackButtonCustomHandler(): Promise<void> {
      this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
        this.modalCtrl.dismiss();
      });
    }
   /*** fin trabajo con el boton atras */

  cerrarConParametros(elimina: boolean){

    if(elimina){
      this.confirmarEliminar(this.indice);
    }else{
      if (!this.lcinput.anyError()) {
        this._ptp.parte.materials[this.indice].quantitatUtilitzada = this.lcinput.getValue() != null ? this.lcinput.getValue() : null

        this._ptp.parteHasDifference = true;

        this._ptp.setDraft();
        this.modalCtrl.dismiss();
      }
    }
  }

  cerrarConParametrosToComponent = () => {
    if(!this.cerrando){
        this.cerrando = true;
        if (!this.lcinput.anyError()) {

          this._ptp.parteHasDifference = true;
          this._ptp.parte.materials[this.indice].quantitatUtilitzada = this.lcinput.getValue() != null ? this.lcinput.getValue() : null

          this._ptp.setDraft();
          this.modalCtrl.dismiss();
        }
    }
    this.cerrando = false;
  }

	cerrarSinParametros(){

    this._ptp.setDraft();
		this.modalCtrl.dismiss();
  }

  async confirmarEliminar(idx: number){

    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txPreguntaConfirmaEliminacion", "txYes", "txNo");

    alert.onDidDismiss().then((res) => {
          if(res.data.accepted){
            this._ptp.parte.materials.splice(idx, 1);
            //alert.dismiss();
            this.cerrarSinParametros();
            return false
          }
          return false;
    });
  }

}
