<ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
    <ion-buttons slot="start">
        <ion-button class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button" (click)="clickCancelar()">
            <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-title><span class="when-high-resolution">{{faseActual.tituloPrefix}} </span>{{faseActual.titulo}}</ion-title>
    <ion-buttons slot="end">
        
        <ng-content></ng-content>

        <ion-button (click)="clickAceptar()">
            <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>


