import {Component} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {NetworkProvider} from '../../services/network/network.provider';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {StatusProvider} from '../../services/status/status.provider';
import {SyncProvider} from '../../services/sync/sync.provider';
import {UpdateAppProvider} from '../../services/update-app/update-app.provider';
import {HelperProvider} from '../../services/helper/helper.provider';
import {Subscription} from 'rxjs';

@Component({
  selector: 'page-connection-status',
  templateUrl: 'connection-status.html',
  styleUrls: ['connection-status.scss'],
})
export class ConnectionStatusPage {

  private backButtonListener?: Subscription;

  syncImatgesElements: any;

  // ultimaSincronizacion: any;

  constructor(
    public platform: Platform,
    public _np: NetworkProvider,
    public _pg: PersistenciaGeneralProvider,
    public _sync: SyncProvider,
    public alertCtrl: AlertController,
    public _translate: TranslateProvider,
    public _ui: UiMessagesProvider,
    public _html: HtmlUtilsProvider,
    public modalCtrl: ModalController,
    public _status: StatusProvider,
    public _updateApp: UpdateAppProvider,
    public _hp: HelperProvider,
    public modalController: ModalController) {
    this.syncImatgesElements = this._pg.usuarioLoginModel.syncImatgesElements === 1 ? true : false;

    // this.ultimaSincronizacion = this._html.getDateToHuman(this._pg.usuarioLoginModel.ultimaSincronizacion);

  }

  ionViewWillLeave() {
    this._sync.minUpdate = null;
    this.backButtonListener?.unsubscribe();
  }

  async initializeBackButtonCustomHandler(): Promise<void> {

    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
        if (this._sync.sincronizando) {
          return;
        }
        this.modalController.dismiss();
      }
    );
  }

  ionViewDidEnter() {
    // console.log('ionViewDidEnter ConnectionStatusPage');
    this.syncImatgesElements = this._pg.usuarioLoginModel.syncImatgesElements == 1 ? true : false;

    if (this._pg.usuarioLoginModel.ultimaSincronizacion > '') {
      //this.ultimaSincronizacion = this._html.getDateToHuman(this._pg.usuarioLoginModel.ultimaSincronizacion);
    }

    this._updateApp.needUpdateAdvice();

    this.initializeBackButtonCustomHandler();
  }

  /*** fin trabajo con el boton atras */

  cerrarSinParametros() {
    this.modalController.dismiss();
    this._hp.reloadActive();
  }


  sincronizarBtn(changeMode: boolean) {
    if (!changeMode) {
      this._status.sincronizarAction(changeMode);
    } else {
      this._status.cambiarModo(false);
    }
  }
}
