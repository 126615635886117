import {Injectable} from '@angular/core';
import {NavParams, Platform} from '@ionic/angular';
import moment from 'moment';
import {FileTransfer, FileTransferObject} from '@awesome-cordova-plugins/file-transfer/ngx';
import {FileProvider} from '../file/file.provider';
import {PLATAFORMA_ANDROID, PLATAFORMA_IOS, PLATAFORMA_UNKNOW} from '../../config/constantes';
import {Capacitor} from "@capacitor/core";
import {DomSanitizer} from "@angular/platform-browser";
import { Directory } from '@capacitor/filesystem';
import { PersistenciaGeneralProvider } from '../persistencia-configuracion/persistencia-general.provider';


@Injectable({
  providedIn: 'root'
})
export class HtmlUtilsProvider {

  constructor(private transfer: FileTransfer,
              public _fp: FileProvider,
              public platform: Platform,
              private sanitizer: DomSanitizer,
              private _pg: PersistenciaGeneralProvider) {
    // console.log('Hello HtmlUtilsProvider Provider');

    moment.locale('es');
  }


  asignarFocus(el) {
    try {
      el.setFocus();
    } catch (error) {
      // console.error('ERROR: ' + JSON.stringify(error));
    }
  }

  normalizarImageSrcRandom(pathImg: string) {
    let result = '';
    if (this.platform.is('android')) {
      result = Capacitor.convertFileSrc(pathImg) + '?' + Math.random();
    } else if (this.platform.is('ios')) {
      result = this.sanitizer.bypassSecurityTrustUrl(Capacitor.convertFileSrc(pathImg)) as string;
    }
    return result;
  }

  async normalizarImageSrcRandomAsync(pathImg: string, directory: Directory) {
    try {
      const resultData = await this._pg.getFileOnIndexedDB(pathImg, directory);

      return `data:image/png;base64,${resultData.data}`;
    } catch (error) {
      console.error('ERROR: ', error);
    }
  }

  async normalizarImageSrc(pathImg: string) {
    return await this.normalizarImageSrcIndexed(pathImg, Directory.Documents);
  }

  async normalizarImageSrcIndexed(pathImg: string, directory: Directory) {
    try {
      const data = await this._pg.getFileOnIndexedDB(pathImg, directory);
      return `data:image/png;base64,${data.data}`;
    } catch (error) {
      console.error('ERROR: ', error);
      return '';
    }
  }

  soloEnteros(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode == 46) && !(charCode == 45)) {
      return false;
    }
    return true;
  }

  soloEnterosNoNegativos(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode == 46) || (charCode == 45)) {
      return false;
    }
    return true;
  }

  soloDecimales(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && !(charCode == 45) && !(charCode == 46)) {
      return false;
    }
    return true;
  }

  soloDecimalesNoNegativos(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && !(charCode == 46)) {
      return false;
    }
    return true;
  }

  isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  getMbFromBytes(bytes) {
    return bytes / Math.pow(1024, 2);
  }

  //devuelve la diferencia entre dos fechas (milisegundos) date.getTime()
  getMinuteDiff(dateA: any, dateB: any) {
    const result = (dateB - dateA) / 60000;
    // console.log('diff ---> ' + result);
    return result;
  }

  generateManualDate() {
    const m = new Date();
    const dateString =
      m.getFullYear() + '-' +
      ('0' + (m.getMonth() + 1)).slice(-2) + '-' +
      ('0' + m.getDate()).slice(-2) + ' ' +
      ('0' + m.getHours()).slice(-2) + ':' +
      ('0' + m.getMinutes()).slice(-2) + ':' +
      ('0' + m.getSeconds()).slice(-2);

    return dateString;
  }

  getDateToHuman(date: string) {

    if (date > '') {
      return moment(date).calendar();
    }

    return ' - ';
  }

  getDateToMoment(date: string) {
    return moment(date);
  }

  getClone(source) {
    if (Object.prototype.toString.call(source) === '[object Array]') {
      const clone = [];
      for (let i = 0; i < source.length; i++) {
        clone[i] = this.getClone(source[i]);
      }
      return clone;
    } else if (typeof (source) == 'object') {
      const clone = {};
      for (const prop in source) {
        if (source.hasOwnProperty(prop)) {
          clone[prop] = this.getClone(source[prop]);
        }
      }
      return (Object.keys(clone).length === 0 && clone.constructor === Object) ? null : clone;
    } else {
      return source;
    }
  }

  getBrowser(url: string, baseUrl: string) {
    if (url > '') {
      window.open(baseUrl + url, '_system');
    }
  }

  getBrowserFullUrl(url: string) {
    if (url > '') {
      window.open(url, '_system');
    }
  }


  getPlatform() {
    if (this.platform.is('android')) {
      return PLATAFORMA_ANDROID;
    } else if (this.platform.is('ios')) {
      return PLATAFORMA_IOS;
    } else {
      return PLATAFORMA_UNKNOW;
    }
  }


  downloadFromWeb(url: string) {
    return new Promise((resolve, reject) => {
      // console.log('entre a donwload from web');
      const fileTransfer: FileTransferObject = this.transfer.create();
      const r = Math.random().toString(36).substring(7);
      /*
      let filename = url[url.length -1];
      let fullname = "";
      switch(filename){
        case '1':
        case '2':
        case '3':
        case '4': {
          fullname = filename + ".jpg";
          break;
        }
        default:{
          fullname = filename + ".pdf";
        }
      }
      */

      const fullname = r + '.pdf';
      // console.log('NOMBRE DEL ARCHIVO FULLNAME ------> ' + fullname);

      //fileTransfer.download(url, this._fp.pathDestinoTemp + "/" + r + ".pdf").then((entry) => {
      fileTransfer.download(url, this._fp.pathDestinoTemp + '/' + fullname).then((entry) => {
        // console.log('download complete: ' + entry.toURL());
        resolve(fullname);
      }, (error) => {
        // console.error(JSON.stringify(error));
        resolve(false);
      });
    });
  }

  downloadFromWebUpdateApp(url: string) {
    return new Promise((resolve, reject) => {
      // console.log('entre a donwload from web');
      const fileTransfer: FileTransferObject = this.transfer.create();
      const r = Math.random().toString(36).substring(7);
      // console.log('imprimo r: ' + r);

      const fullname = r + (url.includes('plataforma=2') ? '.apk' : '.ipa');
      // console.log('NOMBRE DEL ARCHIVO FULLNAME ------> ' + fullname);

      //fileTransfer.download(url, this._fp.pathDestinoTemp + "/" + r + ".pdf").then((entry) => {
      fileTransfer.download(url, this._fp.pathDestinoTemp + '/' + fullname).then((entry) => {
        // console.log('download complete: ' + entry.toURL());
        resolve(fullname);
      }, (error) => {
        // console.error(JSON.stringify(error));
        resolve(false);
      });
    });
  }

  async getImageFase(nomFitxerIconaElement: string) {
    return await this.normalizarImageSrc(this._fp.pathDestinoGemeIcones + '/' + nomFitxerIconaElement);
  }

  getParam(navParams: NavParams, key: string) {
    if (navParams.get(key) && navParams.get(key) != "") {
      return navParams.get(key);
    }
  }

  /**
 * This function receives a navParams object and a key to retrieve a date.
 * If a valid date is provided in navParams, it formats and returns it in ISO 8601 format.
 * If no date is provided, it returns the current date in ISO 8601 format.
 *
 * @param navParams - The NavParams object containing parameters.
 * @param key - The key to retrieve the date from the NavParams object.
 * @returns A formatted date in ISO 8601 format.
 */
  getDateParam(navParams: NavParams, key: string) {
    const date = navParams.get(key);
    return date ? moment(date).format() : moment().format()
  }
}
