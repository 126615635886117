import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateProvider} from '../translate/translate.provider';
import {FullResponseHandler} from '@app/core/shared/full-response.handler';
import {CustomHandlerBuilder} from '@app/core/shared/full-response-handler.parameters';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {SetPermissionUseCase} from '@app/core/permission/application/set-permission.use-case';
import { HttpMethod } from '@app/app2u/handlers/base-handler.parameter';


@Injectable({
  providedIn: 'root'
})
export class HttpLC {

  constructor(public _translate: TranslateProvider, private _frh: FullResponseHandler, private _spu: SetPermissionUseCase) {}

  post(url: string, data: FormData, timeoutSeg: number): Observable<any> {
    const params = new CustomHandlerBuilder().setUrl(url).setMethod(HttpMethod.post).setBody(data).build();
    const timeoutErrorMsg = this._translate.traducir('txTimeout');
    const defaultErrorMsg = this._translate.traducirWithParams('txUrlNoAccesible', {url});

    return this._frh.post(params, timeoutSeg, timeoutErrorMsg , defaultErrorMsg)
      .pipe(
        map(response =>
          this.handleHeaders(response)
        ));
  }

  get(url: string): Observable<any> {
    const params = new CustomHandlerBuilder().setUrl(url).setMethod(HttpMethod.get).build();
    return this._frh.get(params)
      .pipe(
        map(response =>
          this.handleHeaders(response)
        ));
  }

  handleHeaders(response: HttpResponse<any>): HttpResponse<any> {
    const header: string = response.headers.get('VERSIO_PROTOCOL_SERVIDOR');
    this._spu.execute(header);
    return response.body;
  }
}
