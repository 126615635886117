import {Component} from '@angular/core';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {UiMessagesProvider} from '@app/services/ui-messages/ui-messages';
import {PersistenciaGeneralProvider} from "@app/services/persistencia-configuracion/persistencia-general.provider";
import {Subscription} from "rxjs";
import {TranslateProvider} from "@app/services/translate/translate.provider";
import moment from "moment/moment";
import {Part} from "@app/core/part/domain/part";
import {ParteCreateNotPlannedViewModel} from "@app/pages/parte-create-not-planned/parte-create-not-planned.view-model";
import {HtmlUtilsProvider} from "@app/services/html-utils/html-utils";
import { DATE_TIME_FORMAT } from '@app/config/constantes';

@Component({
  selector: 'page-elemento-anotacion',
  templateUrl: 'parte-create-not-planned.html',
  styleUrls: ['parte-create-not-planned.scss'],
  providers: [ParteCreateNotPlannedViewModel]
})
export class ParteCreateNotPlannedPage {

  element: any;
  partParent: Part;
  parte: Part;
  message: number;

  date = moment().format();
  estimatedDays: number = 1;

  withAdvancedOptions: boolean = false;

  private backButtonListener?: Subscription;

  constructor(public navParams: NavParams,
              public htmlUtils: HtmlUtilsProvider,
              public modalCtrl: ModalController,
              public platform: Platform,
              public _ui: UiMessagesProvider,
              public _pg: PersistenciaGeneralProvider,
              public translate: TranslateProvider,
              public viewModel: ParteCreateNotPlannedViewModel) {

    this.element = htmlUtils.getParam(navParams, "element");
    this.partParent = htmlUtils.getParam(navParams, "parte");
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
    this.message = this.viewModel.setDefaultMessage();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }

  /*** fin trabajo con el boton atras */

  async cerrarConParametros() {
    await this.viewModel.confirmSave(this.estimatedDays, this.date, this.parte, this.partParent);
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }

  public dateStartChange(data: string) {
    let plural: string= '';
    this.date = moment(data, DATE_TIME_FORMAT).format();
    if (this.estimatedDays > 1) {
      plural = 's';
    }
    if (this.estimatedDays) {
      this.viewModel.setCustomMessage(this.estimatedDays, this.date, plural);
    }
  }

  checkCharacters(days: number) {
    let plural: string = '';
    if (days != null) {
      if (days > 31){
        this._ui.showToastDefault('txPartesNotPlannedMaxDaysWarning');
        return;
      } else if (days < 1 || days == 0) {
        this._ui.showToastDefault('txPartesNotPlannedMinDaysWarning');
        return;
      }
      if (days > 1) {
        plural = 's';
      }
      this.viewModel.setCustomMessage(days, this.date, plural);
    } else {
      this.message = this.viewModel.setDefaultMessage();
    }
  }

  advancedOptions() {
    this.withAdvancedOptions = true;
    this.viewModel.setCustomMessage(this.estimatedDays, this.date, '');
  }
}
