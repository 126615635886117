import { Injectable } from '@angular/core';

@Injectable()
export default class I18n {
  txAccederBtnLogin = 'txAccederBtnLogin';
  txAccederCargarConfModal = 'txAccederCargarConfModal';
  txAccederModoSinConexion = 'txAccederModoSinConexion';
  txAccediendoLogin = 'txAccediendoLogin';
  txAccesosWeb = 'txAccesosWeb';
  txAcciones = 'txAcciones';
  txAceptar = 'txAceptar';
  txAceptarAlertSeleccionarProyectoLogin = 'txAceptarAlertSeleccionarProyectoLogin';
  txAceptarConfigModal = 'txAceptarConfigModal';
  txAceptarConfiguracion = 'txAceptarConfiguracion';
  txActualizarAppDebe = 'txActualizarAppDebe';
  txActualizarAppPuede = 'txActualizarAppPuede';
  txAgregarFechaFinal = 'txAgregarFechaFinal';
  txAnotacion = 'txAnotacion';
  txArbol = 'txArbol';
  txArbolElementos = 'txArbolElementos';
  txArchivoStr = 'txArchivoStr';
  txAtencionStr = 'txAtencionStr';
  txAtrasStr = 'txAtrasStr';
  txAvisoArchivosGrandes = 'txAvisoArchivosGrandes';
  txAvisoNoSePudoSincronizar = 'txAvisoNoSePudoSincronizar';
  txAvisoNosePuedeSubirArchivo = 'txAvisoNosePuedeSubirArchivo';
  txBienvenidoIdamCargarConf = 'txBienvenidoIdamCargarConf';
  txBienvenidoIdamTituloCargarConf = 'txBienvenidoIdamTituloCargarConf';
  txBuscar = 'txBuscar';
  txCambiarAFuncionamientoEnlinea = 'txCambiarAFuncionamientoEnlinea';
  txCambiarAModoSinConexión = 'txCambiarAModoSinConexión';
  txCamposObligatorios = 'txCamposObligatorios';
  txCancelar = 'txCancelar';
  txCancelarAlertSeleccionarProyectoLogin = 'txCancelarAlertSeleccionarProyectoLogin';
  txCancelarConfigModal = 'txCancelarConfigModal';
  txCancelarConfiguracion = 'txCancelarConfiguracion';
  txCantidad = 'txCantidad';
  txCantidadRequired = 'txCantidadRequired';
  txCerrarSesion = 'txCerrarSesion';
  txCerrarSesionLoading = 'txCerrarSesionLoading';
  txCerrarSesionMessage = 'txCerrarSesionMessage';
  txCerrarSesionTitle = 'txCerrarSesionTitle';
  txChangeServer = 'txChangeServer';
  txCodigoDelElemento = 'txCodigoDelElemento';
  txCodigoSinPuntosStr = 'txCodigoSinPuntosStr';
  txCodigoStr = 'txCodigoStr';
  txCompletarUsuarioPassLogin = 'txCompletarUsuarioPassLogin';
  txConPdfAsignado = 'txConPdfAsignado';
  txConectado = 'txConectado';
  txConfigSyncOpt1 = 'txConfigSyncOpt1';
  txConfigSyncOpt2 = 'txConfigSyncOpt2';
  txConfigSyncOpt3 = 'txConfigSyncOpt3';
  txConfigSyncOpt4 = 'txConfigSyncOpt4';
  txConfiguracionActualCorrectaCargarConf = 'txConfiguracionActualCorrectaCargarConf';
  txConfiguracionConfiguracion = 'txConfiguracionConfiguracion';
  txConfiguracionTituloCargarConfModal = 'txConfiguracionTituloCargarConfModal';
  txConfiguracionYaActualizadaCargarConf = 'txConfiguracionYaActualizadaCargarConf';
  txConfigurarManualmenteCargarConf = 'txConfigurarManualmenteCargarConf';
  txConfigurarMedianteUrlCargarConfModal = 'txConfigurarMedianteUrlCargarConfModal';
  txConfirmarEliminarAdjunto = 'txConfirmarEliminarAdjunto';
  txConfirmarSincronizacion = 'txConfirmarSincronizacion';
  txContrasenaLogin = 'txContrasenaLogin';
  txCorrectoStr = 'txCorrectoStr';
  txDeStr = 'txDeStr';
  txDebeCargarUrl = 'txDebeCargarUrl';
  txDescargaSegunConfiguracion = 'txDescargaSegunConfiguracion';
  txDescargandoConfigCargarConf = 'txDescargandoConfigCargarConf';
  txDescargandoDetallePartes = 'txDescargandoDetallePartes';
  txDescargandoElementos = 'txDescargandoElementos';
  txDescargandoFicherosAdicionales = 'txDescargandoFicherosAdicionales';
  txDescargandoImagen = 'txDescargandoImagen';
  txDescargandoImagenes = 'txDescargandoImagenes';
  txDescargandoListadoPartes = 'txDescargandoListadoPartes';
  txDescargandoMateriales = 'txDescargandoMateriales';
  txDescargandoOperarios = 'txDescargandoOperarios';
  txDescargandoRecursos = 'txDescargandoRecursos';
  txDescripcion = 'txDescripcion';
  txDescripcionDelElemento = 'txDescripcionDelElemento';
  txDescripcionDelElementoIngresar = 'txDescripcionDelElementoIngresar';
  txElementState = 'txElementState';
  txElementStateEdit = 'txElementStateEdit';
  txElementStateEditObservations = 'txElementStateEditObservations';
  txStateEditEmptyInputs = 'txStateEditEmptyInputs';
  txStateEditWrongDate = 'txStateEditWrongDate';
  txImagesEdit = 'txImagesEdit';
  txDescripcionUsuarioMenu = 'txDescripcionUsuarioMenu';
  txDeseaModoOffline = 'txDeseaModoOffline';
  txDisenoTemporalCargarConf = 'txDisenoTemporalCargarConf';
  txEditarStr = 'txEditarStr';
  txElementoAnotaciones = 'txElementoAnotaciones';
  txElementoCritico = 'txElementoCritico';
  txElementoDatosBasicos = 'txElementoDatosBasicos';
  txElementoImagenes = 'txElementoImagenes';
  txElementoPlanEmergencia = 'txElementoPlanEmergencia';
  txElementoSinAnotaciones = 'txElementoSinAnotaciones';
  txElementoStr = 'txElementoStr';
  txElementoAdvertenciaGuardar = 'txElementoAdvertenciaGuardar';
  txEliminar = 'txEliminar';
  txSendingReports = 'txSendingReports';
  txSendingElements = 'txSendingElements';
  txSendingItems = 'txSendingItems';
  txEnvioDeDatos = 'txEnvioDeDatos';
  txErrorAbrirFicheroPdf = 'txErrorAbrirFicheroPdf';
  txErrorAlSincronizar = 'txErrorAlSincronizar';
  txErrorCopiarFicheroParaAbrir = 'txErrorCopiarFicheroParaAbrir';
  txErrorDesconocidoServidor = 'txErrorDesconocidoServidor';
  txErrorNumeroDecimal = 'txErrorNumeroDecimal';
  txErrorNumeroDecimalNoNegativo = 'txErrorNumeroDecimalNoNegativo';
  txErrorNumeroEntero = 'txErrorNumeroEntero';
  txErrorNumeroEnteroNoNegativo = 'txErrorNumeroEnteroNoNegativo';
  txErrorGettingImageFromGalleryOrCamera = 'txErrorGettingImageFromGalleryOrCamera';
  txEsParteFuturo = 'txEsParteFuturo';
  txEspecificarNombreImagen = 'txEspecificarNombreImagen';
  txEsperePorFavor = 'txEsperePorFavor';
  txEstabaEditando = 'txEstabaEditando';
  txEstadoActual = 'txEstadoActual';
  txEstadoStr = 'txEstadoStr';
  txExpandirContraerElementos = 'txExpandirContraerElementos';
  txFecha = 'txFecha';
  txFechaEstStr = 'txFechaEstStr';
  txFechaFinalStr = 'txFechaFinalStr';
  txFechaInicioStr = 'txFechaInicioStr';
  txFicheroNoDisponibleOffline = 'txFicheroNoDisponibleOffline';
  txFin = 'txFin';
  txFuncionamientoEnLinea = 'txFuncionamientoEnLinea';
  txGuardandoParte = 'txGuardandoParte';
  txIdentificacionAccesoLogin = 'txIdentificacionAccesoLogin';
  txIdiomaEspanol = 'txIdiomaEspanol';
  txIdiomaIngles = 'txIdiomaIngles';
  txImagen = 'txImagen';
  txImagenStr = 'txImagenStr';
  txImportarParteEditado = 'txImportarParteEditado';
  txImportarParteNoEditado = 'txImportarParteNoEditado';
  txIncidencias = 'txIncidencias';
  txIncidenciasMenu = 'txIncidenciasMenu';
  txInicioMenu = 'txInicioMenu';
  txInicioRequired = 'txInicioRequired';
  txInicioStr = 'txInicioStr';
  txIntentandoConexion = 'txIntentandoConexion';
  txInventarioMenu = 'txInventarioMenu';
  txLimpiar = 'txLimpiar';
  txListaPartes = 'txListaPartes';
  txManual = 'txManual';
  txMaterial = 'txMaterial';
  txMaterialYaExiste = 'txMaterialYaExiste';
  txMaterialesNoEncontrados = 'txMaterialesNoEncontrados';
  txMaterialesNoMasElementos = 'txMaterialesNoMasElementos';
  txMensajeCamposObligatorios = 'txMensajeCamposObligatorios';
  txMenuMenu = 'txMenuMenu';
  txMinutos = 'txMinutos';
  txModificarMaterial = 'txModificarMaterial';
  txModificarOperario = 'txModificarOperario';
  txMostrarContraido = 'txMostrarContraido';
  txMostrarExpandido = 'txMostrarExpandido';
  txMsgSincronizarAlMenosUnaPresa = 'txMsgSincronizarAlMenosUnaPresa';
  txNecesitaSincronizar = 'txNecesitaSincronizar';
  txNo = 'txNo';
  txNoConexionAjaxClient = 'txNoConexionAjaxClient';
  txNoExistenProyectosDisponibles = 'txNoExistenProyectosDisponibles';
  txNoFuePosibleDescargarConfCargarConf = 'txNoFuePosibleDescargarConfCargarConf';
  txNoFuePosibleDescargarElementos = 'txNoFuePosibleDescargarElementos';
  txNoFuePosibleDescargarMateriales = 'txNoFuePosibleDescargarMateriales';
  txNoFuePosibleDescargarParte = 'txNoFuePosibleDescargarParte';
  txNoImagenOffline = 'txNoImagenOffline';
  txNoPdfIos = 'txNoPdfIos';
  txNoPresasSincronizadas = 'txNoPresasSincronizadas';
  txNoSeHaCambiadoModoOffline = 'txNoSeHaCambiadoModoOffline';
  txNoSePuedeCambiarPresa = 'txNoSePuedeCambiarPresa';
  txNoSePuedeEnviarParte = 'txNoSePuedeEnviarParte';
  txNoSePuedeSincronizar = 'txNoSePuedeSincronizar';
  txNoStr = 'txNoStr';
  txNoTienePermisoDesbloquearParte = 'txNoTienePermisoDesbloquearParte';
  txNoTienePermisoEditarAnotaciones = 'txNoTienePermisoEditarAnotaciones';
  txNoTienePermisoEditarParte = 'txNoTienePermisoEditarParte';
  txNombreDelElemento = 'txNombreDelElemento';
  txNombreStr = 'txNombreStr';
  txNombredelFichero = 'txNombredelFichero';
  txNumeroDecimal = 'txNumeroDecimal';
  txNumeroDecimalNoNegativo = 'txNumeroDecimalNoNegativo';
  txNumeroEntero = 'txNumeroEntero';
  txNumeroEnteroNoNegativo = 'txNumeroEnteroNoNegativo';
  txOcurrioErrorAdjuntarArchivo = 'txOcurrioErrorAdjuntarArchivo';
  txOfflineModeStr = 'txOfflineModeStr';
  txOk = 'txOk';
  txOpciones = 'txOpciones';
  txOperario = 'txOperario';
  txOperarioYaExiste = 'txOperarioYaExiste';
  txOperariosNoEncontrados = 'txOperariosNoEncontrados';
  txOperariosNoMasElementos = 'txOperariosNoMasElementos';
  txPagina = 'txPagina';
  txPantallaInicioStr = 'txPantallaInicioStr';
  txParteActionSheetTitulo = 'txParteActionSheetTitulo';
  txParteActionSheetVerParte = 'txParteActionSheetVerParte';
  txParteAdvertenciaGuardar = 'txParteAdvertenciaGuardar';
  txParteAgregarQuitarFavoritos = 'txParteAgregarQuitarFavoritos';
  txParteAnotaciones = 'txParteAnotaciones';
  txParteDatosAdicionales = 'txParteDatosAdicionales';
  txParteEnviadoExito = 'txParteEnviadoExito';
  txParteEnviaraEnSync = 'txParteEnviaraEnSync';
  txParteFaltaCantidadMateria = 'txParteFaltaCantidadMateria';
  txParteFaltaEstado = 'txParteFaltaEstado';
  txParteFases = 'txParteFases';
  txParteFasesIncompletas = 'txParteFasesIncompletas';
  txParteInfomacionRecursos = 'txParteInfomacionRecursos';
  txParteResultado = 'txParteResultado';
  txParteResultadoParte = 'txParteResultadoParte';
  txParteTieneIncidencias = 'txParteTieneIncidencias';
  txPartesArbolModalSubElementos = 'txPartesArbolModalSubElementos';
  txPartesArchivosAdjuntos = 'txPartesArchivosAdjuntos';
  txPartesConfigModalMostrarPartes = 'txPartesConfigModalMostrarPartes';
  txPartesConfigModalMostrarPartesDesuso = 'txPartesConfigModalMostrarPartesDesuso';
  txPartesConfigModalMostrarPartesPorHacerYFuturos = 'txPartesConfigModalMostrarPartesPorHacerYFuturos';
  txPartesConfigModalMostrarPartesPorHacerYPasados = 'txPartesConfigModalMostrarPartesPorHacerYPasados';
  txPartesConfigModalMostrarPartesSoloPorHacer = 'txPartesConfigModalMostrarPartesSoloPorHacer';
  txPartesConfigModalOrdernarPor = 'txPartesConfigModalOrdernarPor';
  txPartesConfigModalOrdernarPorCodigoYNombre = 'txPartesConfigModalOrdernarPorCodigoYNombre';
  txPartesConfigModalOrdernarPorPosicionArbol = 'txPartesConfigModalOrdernarPorPosicionArbol';
  txPartesConfigModalTitle = 'txPartesConfigModalTitle';
  txPartesCosteExterno = 'txPartesCosteExterno';
  txPartesCosteUnitario = 'txPartesCosteUnitario';
  txPartesCosteUnitarioRequired = 'txPartesCosteUnitarioRequired';
  txPartesCostesExternos = 'txPartesCostesExternos';
  txPartesDescargandoElemento = 'txPartesDescargandoElemento';
  txPartesDescargandoParte = 'txPartesDescargandoParte';
  txPartesDescargandoPartes = 'txPartesDescargandoPartes';
  txPartesEstadoAplazado = 'txPartesEstadoAplazado';
  txPartesEstadoAplazadoIncidencia = 'txPartesEstadoAplazadoIncidencia';
  txPartesEstadoCorrecto = 'txPartesEstadoCorrecto';
  txPartesEstadoIncorrecto = 'txPartesEstadoIncorrecto';
  txPartesEstadoParte = 'txPartesEstadoParte';
  txPartesEstadoPlanFuturo = 'txPartesEstadoPlanFuturo';
  txPartesEstadoPlanPendiente = 'txPartesEstadoPlanPendiente';
  txPartesEstadoPlanRealizable = 'txPartesEstadoPlanRealizable';
  txPartesEstadoSinSeleccionar = 'txPartesEstadoSinSeleccionar';
  txPartesFavoritos = 'txPartesFavoritos';
  txPartesFechaEjecucion = 'txPartesFechaEjecucion';
  txPartesHorquilla = 'txPartesHorquilla';
  txPartesInfoParaEjecucion = 'txPartesInfoParaEjecucion';
  txPartesMaterialPrevisto = 'txPartesMaterialPrevisto';
  txPartesMaterialUtilizado = 'txPartesMaterialUtilizado';
  txPartesMaterialesHerramientas = 'txPartesMaterialesHerramientas';
  txPartesMenu = 'txPartesMenu';
  txPartesNoEncontrados = 'txPartesNoEncontrados';
  txPartesNoMasElementos = 'txPartesNoMasElementos';
  txPartesObservaciones = 'txPartesObservaciones';
  txPartesObservacionesDelParte = 'txPartesObservacionesDelParte';
  txPartesOperarios = 'txPartesOperarios';
  txPartesOtrosPartes = 'txPartesOtrosPartes';
  txPartesPartePropuesto = 'txPartesPartePropuesto';
  txPartesParteStr = 'txPartesParteStr';
  txPartesRelacionModeloElemento = 'txPartesRelacionModeloElemento';
  txPartesRespuestaHecho = 'txPartesRespuestaHecho';
  txPartesRespuestaNoHecho = 'txPartesRespuestaNoHecho';
  txPartesRespuestaSinValor = 'txPartesRespuestaSinValor';
  txPartesResultado = 'txPartesResultado';
  txPartesResultadoFase = 'txPartesResultadoFase';
  txPartesResultadoFaseSinValor = 'txPartesResultadoFaseSinValor';
  txPartesResultadoSinObservaciones = 'txPartesResultadoSinObservaciones';
  txPartesSearchModalCriterio = 'txPartesSearchModalCriterio';
  txPartesSearchModalCriterioCodigoElemento = 'txPartesSearchModalCriterioCodigoElemento';
  txPartesSearchModalCriterioCodigoModeloParte = 'txPartesSearchModalCriterioCodigoModeloParte';
  txPartesSearchModalCriterioCodigoParte = 'txPartesSearchModalCriterioCodigoParte';
  txPartesSearchModalCriterioCualquiera = 'txPartesSearchModalCriterioCualquiera';
  txPartesSearchModalCriterioNombreElemento = 'txPartesSearchModalCriterioNombreElemento';
  txPartesSearchModalCriterioNombreModeloParte = 'txPartesSearchModalCriterioNombreModeloParte';
  txPartesSearchModalTitle = 'txPartesSearchModalTitle';
  txPartesSearchModalValor = 'txPartesSearchModalValor';
  txPartesSinAnotaciones = 'txPartesSinAnotaciones';
  txPartesSinArchivosAdjuntos = 'txPartesSinArchivosAdjuntos';
  txPartesSinCantidadHerramienta = 'txPartesSinCantidadHerramienta';
  txPartesSinCostesExternos = 'txPartesSinCostesExternos';
  txPartesSinMaterialPrevisto = 'txPartesSinMaterialPrevisto';
  txPartesSinMaterialUtilizado = 'txPartesSinMaterialUtilizado';
  txPartesSinMaterialesHerramientas = 'txPartesSinMaterialesHerramientas';
  txPartesSinOperarios = 'txPartesSinOperarios';
  txPartesTamanyoFichero = 'txPartesTamanyoFichero';
  txPartesTiempoMinutos = 'txPartesTiempoMinutos';
  txPartesTiempoNoDefinido = 'txPartesTiempoNoDefinido';
  txPartesTodos = 'txPartesTodos';
  txPartesVer = 'txPartesVer';
  txPartesNotPlanned = 'txPartesNotPlanned';
  txPartesNotPlannedCreate = 'txPartesNotPlannedCreate';
  txPartesNotPlannedDefaultMessage = 'txPartesNotPlannedDefaultMessage';
  txPartesNotPlannedCustomMessage = 'txPartesNotPlannedCustomMessage';
  txPartesNotPlannedAdvanced = 'txPartesNotPlannedAdvanced';
  txPartesNotPlannedAdvancedMessage = 'txPartesNotPlannedAdvancedMessage';
  txPartesMoreOptions = 'txPartesMoreOptions';
  txPartesEstimatedExecutionTime = 'txPartesEstimatedExecutionTime';
  txPartesNotPlannedMaxDaysWarning = 'txPartesNotPlannedMaxDaysWarning';
  txPartesNotPlannedMinDaysWarning = 'txPartesNotPlannedMinDaysWarning';
  txPartesNotPlannedCreateWarning = 'txPartesNotPlannedCreateWarning';
  txPartesNotPlannedEstimatedDaysEmpty = 'txPartesNotPlannedEstimatedDaysEmpty';
  txPreguntDesbloquearParte = 'txPreguntDesbloquearParte';
  txPreguntaConfirmaEliminacion = 'txPreguntaConfirmaEliminacion';
  txPresaActualMenu = 'txPresaActualMenu';
  txPresaStr = 'txPresaStr';
  txPresasASincronizar = 'txPresasASincronizar';
  txPresioneNuevamenteSalir = 'txPresioneNuevamenteSalir';
  txProblemaResumenPartes = 'txProblemaResumenPartes';
  txQuitarFiltrosStr = 'txQuitarFiltrosStr';
  txRecordarMasTarde = 'txRecordarMasTarde';
  txRecordarUsuarioLogin = 'txRecordarUsuarioLogin';
  txRecordarmelo = 'txRecordarmelo';
  txRecuperandoMateriales = 'txRecuperandoMateriales';
  txRecuperandoOperarios = 'txRecuperandoOperarios';
  txRecuperandoParte = 'txRecuperandoParte';
  txReintentandoConexion = 'txReintentandoConexion';
  txReintentar = 'txReintentar';
  txResponderFase = 'txResponderFase';
  txRutaStr = 'txRutaStr';
  txSalirApp = 'txSalirApp';
  txSalirStr = 'txSalirStr';
  txSeBuscoPorCodigoParte = 'txSeBuscoPorCodigoParte';
  txSeDescargaranSiguientesDatos = 'txSeDescargaranSiguientesDatos';
  txSeEnviaranModificacionesServidor = 'txSeEnviaranModificacionesServidor';
  txSeHaCambiadoModoOffline = 'txSeHaCambiadoModoOffline';
  txSegundos = 'txSegundos';
  txSeguroCancelarEdicion = 'txSeguroCancelarEdicion';
  txSeleccionarIdiomaConfiguracion = 'txSeleccionarIdiomaConfiguracion';
  txSeleccionarMaterial = 'txSeleccionarMaterial';
  txSeleccionarOperario = 'txSeleccionarOperario';
  txSeleccionarProyectoLogin = 'txSeleccionarProyectoLogin';
  txSeleccionePresaTrabajoOffline = 'txSeleccionePresaTrabajoOffline';
  txShowing = 'txShowing';
  txSiStr = 'txSiStr';
  txSinAsignarStr = 'txSinAsignarStr';
  txSinConexion = 'txSinConexion';
  txSinConexionStr = 'txSinConexionStr';
  txSinImagen = 'txSinImagen';
  txSinImagenSeleccionada = 'txSinImagenSeleccionada';
  txSinImagenes = 'txSinImagenes';
  txSinPdfAsignado = 'txSinPdfAsignado';
  txSinValorStr = 'txSinValorStr';
  txSincronizacionExitosa = 'txSincronizacionExitosa';
  txSincronizacionExitosayModoOffline = 'txSincronizacionExitosayModoOffline';
  txSincronizandoRecursos = 'txSincronizandoRecursos';
  txSincronizar = 'txSincronizar';
  txSincronizarAhora = 'txSincronizarAhora';
  txSincronizarMasTarde = 'txSincronizarMasTarde';
  txSoloImagen = 'txSoloImagen';
  txSoloPdf = 'txSoloPdf';
  txSyncTimeMessage = 'txSyncTimeMessage';
  txSynchronizeStr = 'txSynchronizeStr';
  txTextoAnotacionRequired = 'txTextoAnotacionRequired';
  txTiempo = 'txTiempo';
  txTimeout = 'txTimeout';
  txTituloRespuestaFase = 'txTituloRespuestaFase';
  txTituloRespuestaFasePrefix = 'txTituloRespuestaFasePrefix';
  txTituloRespuestaTotal = 'txTituloRespuestaTotal';
  txTotal = 'txTotal';
  txTrabajoEnLinea = 'txTrabajoEnLinea';
  txTrabajoSinConexion = 'txTrabajoSinConexion';
  txUltimaSincronizacion = 'txUltimaSincronizacion';
  txUltimaSincronizacionDeseaSincronizar = 'txUltimaSincronizacionDeseaSincronizar';
  txUrlNoAccesible = 'txUrlNoAccesible';
  txUrlYaConfigurada = 'txUrlYaConfigurada';
  txUsado = 'txUsado';
  txUsuario = 'txUsuario';
  txUsuarioLogin = 'txUsuarioLogin';
  txUsuarioPassIncorrectosLogin = 'txUsuarioPassIncorrectosLogin';
  txUsuarioPassIncorrectosLoginGeneral = 'txUsuarioPassIncorrectosLoginGeneral';
  txYes = 'txYes';
  txServerConfiguration = 'txServerConfiguration';
  txServerConfigurationMessage = 'txServerConfigurationMessage';
  txConfigurationLink = 'txConfigurationLink';
  txConfigurationLinkMessage = 'txConfigurationLinkMessage';
  txQrCode = 'txQrCode';
  txQrCodeMessage = 'txQrCodeMessage';
  txQrScan = 'txQrScan';
  txQrEmpty = 'txQrEmpty';
  txLinkEmpty = 'txLinkEmpty';
  txManualInsert = 'txManualInsert';
  txAbout = 'txAbout';
  txServerConnection = 'txServerConnection';
  txAppVersion = 'txAppVersion';
  txModify = 'txModify';
  txCrearParte = 'txCrearParte';
  txPermissionProtocol = 'txPermissionProtocol';
  txPermissionVersion = 'txPermissionVersion';
  txPermissionVersionDate = 'txPermissionVersionDate';
  txAdd = 'txAdd';
  txChangeNameImage = 'txChangeNameImage';
  txCamera = 'txCamera';
  txGallery = 'txGallery';
  txChooseCameraOrGallery = 'txChooseCameraOrGallery';
  txDateSelection = 'txDateSelection';
  txHours = 'txHours';
  txSavingElement = 'txSavingElement';
  txElementSendInSync = 'txElementSendInSync';
  txElementSentSuccess = 'txElementSentSuccess';
  txAddImages = 'txAddImages';
  txError = 'txError';
  txImageUnknownError = 'txImageUnknownError';
  txImageNotFound = 'txImageNotFound';
  txImageCouldNotBeSaved = 'txImageCouldNotBeSaved';
  txImageCouldNotBeLoaded = 'txImageCouldNotBeLoaded';
  txTimeLabel = 'txTimeLabel';
  txPartExecutionDateWarning = 'txPartExecutionDateWarning';
  txTypeCharacteristics = 'txTypeCharacteristics';
  txWithoutType = 'txWithoutType';
  txDownloadElemStateTypo = 'txDownloadElemStateTypo';
  txNewVersionIsAvailable = 'txNewVersionIsAvailable';
}
