export interface Authorization {
    getBasicAuthorization(): string;
}
export enum HttpMethod {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    patch = 'PATCH',
    delete = 'DELETE'
}
export enum ResponseType {
    json = 'json',
    blob = 'blob',
    arrayBuffer = 'arraybuffer',
    text = 'text'
}

export class HandlerParameters {
    readonly method: HttpMethod;
    readonly url: string;
    readonly header: Record<string, any>;
    readonly authorization?: Authorization;
    readonly body: Record<string, any>;
    readonly urlParams: Record<string, any>;
    readonly responseType: ResponseType;
    constructor(builder: HandlerBuilder) {
        this.method = builder._method;
        this.url = builder._url;
        this.header = builder._header;
        this.authorization = builder._authorization;
        this.body = builder._body;
        this.urlParams = builder._urlParams;
        this.responseType = builder._responseType;
    }
}

/**
 * Builder for BaseHandler Parameters
 * Default values are those needed in order to make a JSON request
 */
export class HandlerBuilder {
    CONTENT_TYPE: string;
    _method: HttpMethod;
    _url: string;
    _header: Record<string, any>;
    _authorization?: Authorization;
    _authorizationKey: string;
    _body: Record<string, any>;
    _urlParams: Record<string, any>;
    _responseType: ResponseType;
    constructor(builder: HandlerBuilder);

    constructor() {
        this.CONTENT_TYPE = 'application/json';
        this._method = HttpMethod.get;
        this._url = '';
        this._header = {};
        this._authorizationKey = 'Authorization';
        this._body = {};
        this._urlParams = {};
        this._responseType = ResponseType.json;
        this.addHeader('Content-type', this.CONTENT_TYPE);
    }
    build() {
        return new HandlerParameters(this);
    }
    setMethod(value) {
        this._method = value;
        return this;
    }
    setUrl(value) {
        this._url = value;
        return this;
    }
    setResponseType(value) {
        this._responseType = value;
        return this;
    }
    setAuthorization(value, valueKey) {
        this._authorization = value;
        if (valueKey){
            this._authorizationKey = valueKey;
        }
        this._header[this._authorizationKey] = this._authorization.getBasicAuthorization();
        return this;
    }
    setHeaders(value) {
        value['Content-type'] = this.CONTENT_TYPE;
        value[this._authorizationKey] = this._authorization;
        this._header = value;
        return this;
    }
    addHeader(key, value) {
        this._header[key] = value;
        return this;
    }
    setBody(value) {
        this._body = value;
        return this;
    }
    addBody(key, value) {
        this._body[key] = value;
        return this;
    }
    setUrlParams(urlParams) {
        this._urlParams = urlParams;
        return this;
    }
    addUrlParam(key, value) {
        this._urlParams[key] = value;
        return this;
    }
    removeContentType() {
        delete this._header['Content-type'];
        return this;
    }
}


