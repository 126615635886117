export const PARAM_OP = 'op';
export const PARAM_GUARDAR_PARTE_APP = 'GUARDAR_PARTE_APP';
export const PARAM_GET_CONFIG_SERV = 'GET_CONFIG_SERV_APP';
export const PARAM_GET_CONNEXIO = 'GET_CONNEXIO_APP';
export const PARAM_GET_LLISTA_PARTES  = 'GET_LLISTA_PARTES_APP';
export const PARAM_GET_PARTE  = 'GET_PARTE_APP';
export const PARAM_GET_ARBRE_PROJECTE  = 'GET_ARBRE_PROJECTE_APP';
export const PARAM_GET_ELEMENTS_PROJECTE_SYNC_APP  = 'GET_ELEMENTS_PROJECTE_SYNC_APP';
export const PARAM_GET_IMGS_ELE_SYNC_APP  = 'GET_IMGS_ELE_SYNC_APP';
export const PARAM_GET_FITXERS_PARTES_SYNC_APP  = 'GET_FITXERS_PARTES_SYNC_APP';
export const PARAM_GET_ICONES_ELE = 'GET_ICONES_ELE_APP';
export const PARAM_GET_ICONES_TIPOLOGIES_TREBALL = 'GET_ICONES_TIPOLOGIES_TREBALL_APP';
export const PARAM_GET_ICONES_ESTATS_ELEMENTS = 'GET_ICONES_ESTATS_ELEMENTS_APP';
export const PARAM_GET_ICONES_ESTATS_INCIDENCIES = 'GET_ICONES_ESTATS_INCIDENCIES_APP';
export const PARAM_GET_ICONES_TIPOLOGIES_INCIDENCIES = 'GET_ICONES_TIPOLOGIES_INCIDENCIES_APP';
export const PARAM_GET_TIPOLOGIES_ESTAT_ELEMENTS = 'GET_TIPOLOGIES_ESTAT_ELEMENTS_APP';
export const PARAM_GET_MATERIALS_SELECCIO  = 'GET_MATERIALS_SELECCIO_APP';
export const PARAM_GET_OPERARIS_SELECCIO  = 'GET_OPERARIS_SELECCIO_APP';
export const PARAM_GET_ELEMENT_APP  = 'GET_ELEMENT_APP';
export const PARAM_GET_LLISTA_PARTES_SYNC_APP  = 'GET_LLISTA_PARTES_SYNC_APP';
export const PARAM_GET_DETALLS_PARTES_SYNC_APP  = 'GET_DETALLS_PARTES_SYNC_APP';
export const PARAM_GET_OPERARIS_SYNC_APP   = 'GET_OPERARIS_SYNC_APP';
export const PARAM_GET_MATERIALS_SYNC_APP   = 'GET_MATERIALS_SYNC_APP';
export const PAGE_OBTENER_MODUL  = 'obtenirInfoModul.php';
export const PARAM_FITXATECNICA  = 'FITXATECNICA';
export const PARAM_ARXIUTECNIC  = 'ARXIUTECNIC';
export const PARAM_GET_PARTES_MODEL_ELEMENT_APP = 'GET_PARTES_MODEL_ELEMENT_APP';
export const LINK_FITXATECNICA  = 1;
export const LINK_ARXIUTECNIC  = 2;
export const PARAM_TANCAR_CONNEXIO = 'TANCAR_CONNEXIO_APP';
export const PARAM_STRLOGIN = 'strLogin';
export const PARAM_STRCONN = 'strConn';
export const PARAM_PARTE = 'parte';
export const PARAM_EXISTENTS = 'existents';
export const PARAM_IMATGES_FASES = 'imatgesFases';
export const PARAM_IMATGES_RESPOSTES_FASES = 'imatgesRespostesFases';
export const PARAM_PDP_RESPOSTES_FASES = 'pdfsRespostesFases';
export const PARAM_FITXERS_ADJUNTS = 'fitxersAdjunts';
export const PARAM_FIXTERS_ARXIU_TECNIC = 'fitxersArxiuTecnic';
export const PARAM_PARTES = 'partes';
export const PARAM_VERSIO = 'versio';
export const PARAM_ID_PROJECTE = 'idProjecte';
export const PARAM_PROJECTES = 'projectes';
export const PARAM_ID_MODUL = 'idModul';
export const PARAM_ROW_INICI = 'rowInici';
export const PARAM_TIPUS_ORDRE = 'tipusOrdre';
export const PARAM_TIPUS_MOSTRAR = 'tipusMostrar';
export const PARAM_TIPUS_FILTRE = 'tipusFiltre';
export const PARAM_FILTRE = 'filtre';
export const PARAM_ID_GEME_ELEMENT = 'idGemeElement';
export const PARAM_INCLOURE_FILLS = 'incloureFills';
export const PARAM_MODELS_ELEMENTS_FAV = 'modelsElementsFav';
export const PARAM_ID_MODEL_ELEMENT = 'idModelElement';
export const PARAM_ID_ELEMENT = 'idElement';
export const PARAM_ID_PARTE = 'idParte';
export const PARAM_ID_GRUPO_GESTIO = 'idGrupGestio';
export const PARAM_ROW_FINAL = 'rowFinal';
export const PARAM_ROW_FINAL_VALUE = 30;
export const PARAM_ROW_FINAL_VALUE_LOCAL = 10;
export const PARAM_ROW_FINAL_VALUE_RELATED = 30;
export const PARAM_ROW_FINAL_VALUE_MATERIALS = 30;
export const PARAM_ROW_FINAL_VALUE_OPERARIOS = 30;
export const PARAM_ROW_FINAL_VALUE_IMAGES = 5;
export const PARAM_ROW_FINAL_VALUE_ADJUNTS = 10;
export const PARAM_ROW_FINAL_VALUE_DETALLES_PARTES = 30;
export const CLAVE_ENCRIPTACION_COMUNICACION = 'Mqw^BUxy2M@+nr34';
export const CLAVE_ENCRIPTACION_BBDD = '234%yyljbKf';
export const DEBUG = true;
export const ERROR_LOG_FILE_SIZE_MB = 5;
export const MINUTES_TIME_SYNC = 5;

//Categorias de error
export const ERROR_CONSULTANDO_WS = 'ERROR_CONSULTANDO_WS';
export const ERROR_DESCONOCIDO_SERVIDOR = 'txErrorDesconocidoServidor';
export const NO_CONEXION =  'NO_CONEXION';
export const ERROR_DE_RED_NO_IDENTIFICADO =  'ERROR_DE_RED_NO_IDENTIFICADO';
export const ERROR_CATEGORIA_LOGIN_INCORRECTO = 'LOGIN_INCORRECTE';
export const ERROR_HA_DE_ACTUALITZAR = 'ERROR_HA_DE_ACTUALITZAR';
export const PRAGMA_USER_VERSION = 1;

export const DABATASE_CURRENT_VERSION = 1;

//Plataformas para update app
export const PLATAFORMA_UNKNOW = '1';
export const PLATAFORMA_ANDROID = '2';
export const PLATAFORMA_IOS = '3';
export const PATH_UPDATE = 'descarregarApp/descarregarApp.php?plataforma=';

//PARTES - CONFIGURACION
export const PARTES_CONFIG_ORDENAR_X_POSICION_ARBOL = 1;
export const PARTES_CONFIG_ORDENAR_X_CODIGO_Y_NOM_ELE = 2;

export const PARTES_CONFIG_MOSTRAR_X_HACER_Y_FUTUROS = 1;
export const PARTES_CONFIG_MOSTRAR_X_HACER_Y_PASADOS = 2;
export const PARTES_CONFIG_MOSTRAR_SOLO_X_HACER = 3;

//PARTE - VISTA COLAPSADO O EXPANDIDO
export const PARTES_CONFIG_MOSTRAR_EXPANDIDO = 1;
export const PARTES_CONFIG_MOSTRAR_COLAPSADO = 2;

//SyncImatgesElements
export const SYNC_IMATGES_ELEMENTS = 1;

//PARTES - BUSQUEDA
export const PARTES_BUSCAR_X_CODIGO_PARTE = 1;
export const PARTES_BUSCAR_X_CODIGO_ELEMENTO = 2;
export const PARTES_BUSCAR_X_NOMBRE_ELEMENTO = 3;
export const PARTES_BUSCAR_X_CODIGO_MODELO_PARTE = 4;
export const PARTES_BUSCAR_X_NOMBRE_MODELO_PARTE = 5;
export const PARTES_BUSCAR_X_CUALQUIERA= 6;

// PARTES - TIPOS DE ESTADO REALIZADOS
export const PARTES_TIPO_ESTADO_CORRECTO = 1;
export const PARTES_TIPO_ESTADO_INCORRECTO = 2;
export const PARTES_TIPO_ESTADO_APLAZADO = 3;
export const PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA = 4;

// PARTES - TIPOS DE ESTADO NO REALIZADOS
export const PARTES_TIPO_ESTADO_PLAN_FUTURO = 11;
export const PARTES_TIPO_ESTADO_PLAN_REALIZABLE = 12;
export const PARTES_TIPO_ESTADO_PLAN_PENDIENTE = 13;

// PARTES - COLORES ESTADOS REALIZADOS
export const PARTES_COLOR_ESTADO_CORRECTO = '#92D050';
export const PARTES_COLOR_ESTADO_INCORRECTO = '#C00000';
export const PARTES_COLOR_ESTADO_APLAZADO = '#B2A1C7';
export const PARTES_COLOR_ESTADO_APLAZADO_INCIDENCIA = '#8D8790';

// PARTES - COLORES DE ESTADO NO REALIZADOS
export const PARTES_COLOR_ESTADO_PLAN_FUTURO = '#1F497D';
export const PARTES_COLOR_ESTADO_PLAN_REALIZABLE = '#4A95F1';
export const PARTES_COLOR_ESTADO_PLAN_PENDIENTE = '#FFC000';
export const PARTES_COLOR_ESTADO_NULO = '#000000';

// PARTES - TIPOS DE RESPUESTA
export const PARTES_RESPUESTA_HECHO = 5;
export const PARTES_RESPUESTA_CORRECTO_INCORRECTO = 1;
export const PARTES_RESPUESTA_SI_NO = 2;
export const PARTES_RESPUESTA_NO_SI = 4;
export const PARTES_RESPUESTA_ENTERO = 7;
export const PARTES_RESPUESTA_ENTERO_NO_NEGATIVO = 6;
export const PARTES_RESPUESTA_DECIMAL = 9;
export const PARTES_RESPUESTA_DECIMAL_NO_NEGATIVO = 8;
export const PARTES_RESPUESTA_TIEMPO = 14;
export const PARTES_RESPUESTA_FECHA = 10;
export const PARTES_RESPUESTA_FECHA_HORA = 11;
export const PARTES_RESPUESTA_LISTA_OPCIONES = 3;
export const PARTES_RESPUESTA_IMAGEN_ADJUNTA = 12;
export const PARTES_RESPUESTA_PDF_ADJUNTO = 13;
export const PARTES_RESPUESTA_TEXTO = 17;
export const PARTES_RESPUESTA_TITULO = 15;
export const PARTES_RESPUESTA_SUBTITULO = 16;

//TIPUS FITXER
export const TIPUS_FITXER_1 = '1';
export const TIPUS_FITXER_4 = '4';
export const TIPUS_FITXER_5 = '5';
export const TIPUS_FITXER_6 = '6';

export const GEME_ADJUNT = '1';
export const GEME_IMATGE_ELEMENT = '2';
export const GEME_IMATGE_FASES = '3';
export const GEME_IMATGE_RESPOSTES_FASE = '4';
export const GEME_PDF_RESPOSTES_FASE = '5';
export const ARXIU_TECNIC_DOCUMENT = '6';
export const NORMAL_ADJUNT = '7';

export const IMAGE_TYPES: Array<string> = ['jpg','jpeg','png','bmp'];
export const FICHERO_NO_ENVIADO = 'Fichero no enviado';

export const MAX_FILE_SIZE = 10;//MB
export const TYPE_IMG = 1;
export const TYPE_FILE = 2;

//filtros para busqueda
export const FILTRO_BUSQUEDA = 1;
export const FILTRO_ARBOL = 2;

//Sincronizacion
export const SYNC_HOURS_CHECK = 24;
export const SYNC_HOURS_DELAY = 3;

//Paginas
export const INVENTARIO_PAGE = 'InventarioPage';
export const PARTES_PAGE = 'PartesPage';

//Update App
export const UPDATE_HOURS_ADVICE = 6;

//GALLERY TRICKS
export const GALLERY_REGEX_TOREPLACE_FROM_OPT1 = /(file)((.*?)(?=Download))/gi;
export const GALLERY_REPLACE_TO_OPT1 = '$1:///storage/emulated/0/';

export const GALLERY_REGEX_TOREPLACE_FROM_OPT2 = /download/gi;
export const GALLERY_REPLACE_TO_OPT2 = 'DCIM';

// Timeout consultas a Webservice (segundos)
export const TIMEOUT_CONSULTAS_WS_DEFECTO_SEG = 60;

//Manual de usuario
export const FILENAME_MANUAL_USUARIO = 'Manual_de_usuario_App_iDam_v_1.pdf';

//carpeta assets
export const PATH_ASSETS = 'public/assets/';
export const PATH_MANUAL_USUARIO = 'assets/manual/';


//Not planned parts
export const PARAM_INCLUDE_NOT_PLANNED_PARTS = 'incloureNoPlanificats';
export const PARAM_GET_DETALLS_PARTES_NO_PLAN_SYNC_APP  = 'GET_DETALLS_PARTES_NO_PLAN_SYNC_APP';
export const PARAM_MODELS_ELEMENTS_POT_NO_PLAN = 'modelsElementsPotNoPlan';
export const PARAM_GEN_PARTE_NO_PLAN_APP = 'GEN_PARTE_NO_PLAN_APP';
export const PARAM_ID_GEME_MODEL_ELEMENT = 'idModelElement';
export const PARAM_DATA_HORA_PLANIFICACIO = 'dataHoraPlanificacio';
export const PARAM_TEMPS_EXECUCIO_MINUTS = 'tempsExecucioMinuts';

//Element Operation
export const PARAM_GUARDAR_ELEMENT_APP = 'GUARDAR_ELEMENT_APP';
export const PARAM_ELEMENT = 'element';
// Variable used to indicate that the image is stored locally
export const LOCAL_IMAGE = 'LOCAL_IMAGE';

// Date Formats
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DETAILED_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ a';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const TIME_WITH_SECONDS_FORMAT = 'HH:mm:ss';
export const DATE_USER_FORMAT = 'DD/MM/YYYY';
