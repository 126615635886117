<ion-content fullscreen="true">

    <ion-item class="close-fake ion-text-center" lines="none">
        <ion-button (click)="close()" fill="clear" color="light">
            <ion-icon name="close" slot="start"></ion-icon>
            Back
        </ion-button>

        <ion-button (click)="zoom(true)" fill="clear" color="light">
            <ion-icon name="add" slot="start"></ion-icon>
            in
        </ion-button>

        <ion-button (click)="zoom(false)" fill="clear" color="light">
            <ion-icon name="remove" slot="start"></ion-icon>
            out
        </ion-button>
    </ion-item>

    <ion-slides #slider>
        <ion-slide>
            <div class="swiper-zoom-container">
                <img src="assets/img/{{ img }}.png">
            </div>
        </ion-slide>
    </ion-slides>

</ion-content>
