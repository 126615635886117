<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      {{ 'txMenuMenu' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="home-menu background-menu">
    <ion-row>
      <ion-col class="ion-text-left">
        <ion-chip (click)="_hp.cambiarProyecto()">
          <ion-label>{{_pg.proyectoSeleccionado?.nom}} </ion-label>
          <ion-icon name="lc-icon-edit" color="primary" class="icon-padding-en-chip"></ion-icon>
        </ion-chip>
      </ion-col>
      <ion-col class="ion-text-right">
        <label>
          <ion-icon name="lc-icon-contact" class="icono-menu-usuario-login icon-align-middle"></ion-icon>
          <div class="ml-1 align-middle-vertical">
            <span
              class="text-usuario ion-text-capitalize">{{(_pg.usuarioLoginModel ? _pg.usuarioLoginModel.login : '')}}</span>
          </div>
        </label>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="line-separtor-main">
        <span class="line-separtor-sub">
            {{nomModulGeme}}
        </span>
  </div>

  <ul class="flex-container alinea-menu">
    <li class="flex-item">
      <ion-item (click)="_hp.abrirPaginaClick(appRoutes.inventarioPage, false)" lines="none"
                class="icon-menu-options ion-text-center">
        <ion-label>
          <ion-icon name="lc-icon-inventario" class="icon-align-text-bottom"></ion-icon>
          <p style="font-family: sans-serif;" class="text-menu-options">{{ 'txInventarioMenu' | translate }}</p>
        </ion-label>
      </ion-item>
    </li>
    <li class="flex-item">
      <ion-item (click)="_hp.abrirPaginaClick(appRoutes.partesPage, false)" lines="none"
                class="icon-menu-options ion-text-center">
        <ion-label>
          <ion-icon name="lc-icon-parte" class="icon-align-text-bottom"></ion-icon>
          <p style="font-family: sans-serif;" class="text-menu-options">{{ 'txPartesMenu' | translate }}</p>
        </ion-label>
      </ion-item>
    </li>
  </ul>

  <div class="footer-config">

    <ul class="flex-container alinea-menu">
      <li class="flex-item">
        <ion-item (click)="_hp.abrirPaginaClick(appRoutes.configuracionPage, false);" lines="none"
                class="icon-menu-options ion-text-center">
          <ion-label>
            <ion-icon name="lc-icon-config" class="icon-align-text-bottom"></ion-icon>
            <p style="font-family: sans-serif;"
               class="text-menu-options">{{ 'txConfiguracionConfiguracion' | translate }}</p>
          </ion-label>
        </ion-item>
      </li>
      <li class="flex-item">
        <ion-item (click)="_hp.exitProcessMenu();" lines="none" class="icon-menu-options ion-text-center">
          <ion-label>
            <ion-icon name="lc-icon-sign-out" class="icon-align-text-bottom"></ion-icon>
            <p style="font-family: sans-serif;" class="text-menu-options">{{ 'txSalirStr' | translate }}</p>
          </ion-label>
        </ion-item>
      </li>
    </ul>
  </div>

  <div class="footer" (click)="mostrarModal()">
    <p class="sub-footer">
            <span style="padding-left: 1rem; font-size: 1.7rem;">
                <ion-icon *ngIf="!_pg.workingOffline" name="lc-icon-cloud" class="icon-align-text-bottom"></ion-icon>
                <ion-icon *ngIf="_pg.workingOffline" name="lc-icon-airplane" class="icon-align-text-bottom"></ion-icon>
              {{ (!_pg.workingOffline ? 'txTrabajoEnLinea' : 'txTrabajoSinConexion') | translate}}
            </span>

      <span *ngIf="_pg.workingOffline && _pg.usuarioLoginModel && _pg.usuarioLoginModel.ultimaSincronizacion > ''"
            class="ultima-sincronizacion">{{'txUltimaSincronizacion' | translate}} {{_html.getDateToHuman(_pg.usuarioLoginModel.ultimaSincronizacion)}}</span>

    </p>
  </div>
</ion-content>
