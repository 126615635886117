<table class="tabla-fixed">
    <tr>
        <td style="color: #000 !important; width:3em;text-align: left;vertical-align: top">
            <strong>{{ blocElement }}</strong>
        </td>        
        <td>
            <ng-container *ngFor="let ele of elements">        
                <element-individual [element]="ele"></element-individual>
            </ng-container>
        </td>
    </tr>
</table>