/* eslint-disable max-len */
/* eslint-disable eqeqeq */
import {Component, ViewChild} from '@angular/core';
import {
  ActionSheetController,
  IonContent,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  NavParams,
  Platform
} from '@ionic/angular';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {PostParams} from '../../interfaces/ajax.interface';
import * as Data from '../../config/constantes';
import {
  PARAM_GET_PARTE,
  PARAM_GET_PARTES_MODEL_ELEMENT_APP,
  PARAM_ID_MODEL_ELEMENT,
  PARAM_ID_PARTE,
  PARAM_ROW_FINAL,
  PARAM_ROW_FINAL_VALUE_RELATED,
  PARAM_ROW_INICI,
  PARAM_STRCONN,
  PARTES_TIPO_ESTADO_PLAN_FUTURO,
  TIMEOUT_CONSULTAS_WS_DEFECTO_SEG
} from '../../config/constantes';
import {WsUtilsProvider} from '../../services/utils-ws/ws-utils';
import {AjaxClientProvider, ErrorConsulta} from '../../services/ajax-client/ajax-client.provider';
import {FileProvider} from '../../services/file/file.provider';
import {NetworkProvider} from '../../services/network/network.provider';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {TreeViewProvider} from '../../services/tree-view/tree-view.provider';
import {GemeProvider} from '../../services/geme/geme.provider';
import {HelperProvider} from '../../services/helper/helper.provider';
import {ElementoTabProvider} from '../../services/elemento-tab/elemento-tab.provider';
import {SyncProvider} from '../../services/sync/sync.provider';
import {AppRoutes} from '../../config/app-routes';
import {Router} from '@angular/router';
import {NavigationUtilsService} from '../../services/navigation-utils/navigation-utils.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'page-lista-otros-partes',
  templateUrl: 'lista-otros-partes.html',
  styleUrls: ['lista-otros-partes.scss']
})
export class ListaOtrosPartesPage {

  screenWidth: any = 0;

  rowInici = 1;
  rowFinal = 0;

  hayMasElementos = true;

  parte: any = null;
  modelElement: any = null;
  consultaWSRealizada = false;
  public constants = Data;

  public fullData = {
    modelElement: {
      idModelElement: '',
      idElement: '',
      codiElement: '',
      nomElement: '',
      rutaElement: '',
      nomFitxerIconaElement: '',
      idModel: '',
      codiModel: '',
      nomModel: '',
      nomFrequenciaParte: '',
      srcIcona: ''
    },
    partes: []
  };


  @ViewChild(IonContent) content: IonContent;
  private backButtonListener?: Subscription;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public platform: Platform,
    public modalCtrl: ModalController,
    public _ui: UiMessagesProvider,
    public _wsUtils: WsUtilsProvider,
    private _ajax: AjaxClientProvider,
    private _messages: UiMessagesProvider,
    private loadingCtrl: LoadingController,
    public _fp: FileProvider,
    public _np: NetworkProvider,
    public actionSheetCtrl: ActionSheetController,
    private _translate: TranslateProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public _partesTabProvider: PartesTabProvider,
    public _etp: ElementoTabProvider,
    public _tv: TreeViewProvider,
    public menuCtrl: MenuController,
    //public viewctrl: ViewController,
    public _hp: HelperProvider,
    public _sync: SyncProvider,
    public _gemeProvider: GemeProvider,
    private router: Router,
    private navUtils: NavigationUtilsService
  ) {

    this.parte = navParams.get('parte');
    //para trabajar offline necesito modelElement del item
    //en modo online esta información se descarga
    this.modelElement = navParams.get('modelElement');
    // console.log('model_element: ' + this.modelElement);
    this.getLlistaPartes(false, true);
  }

  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();

    const elmnt = document.getElementById(this._partesTabProvider.elementoRelacionadoSeleccionado);

    if (elmnt) {
      elmnt.scrollIntoView({block: 'end'});
    }
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe();
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }
   /*** fin trabajo con el boton atras */

  getLlistaPartes(shouldBeReload: boolean, initial: boolean = false) {

    if (!this._pg.workingOffline) {
      this.getProcessOnline(shouldBeReload, initial);
    } else {
      this.getProcessOffline(initial);
    }
  }

  getParte(parte: any, idModelElement: any) {

    if (!this._pg.workingOffline) {
      this.getParteProcessOnline(parte, idModelElement);
    } else {
      this.getParteProcessOffline(parte);
    }
  }

  async getParteProcessOnline(parte: any, idModelElement: any) {
    const loading = await this._messages.showLoading('txPartesDescargandoParte');
    const params: PostParams[] = this.getParamsToGetParte(parte, idModelElement);
    this.descargarParteFromServer(PARAM_GET_PARTE, params)
      .then((res) => {
        if (res) {
          loading.dismiss();
          this.navUtils.set(AppRoutes.parteTabsPage, this.callbackReturnShowReportFunction);
          this.router.navigate(['/', AppRoutes.parteTabsPage]);
          this.modalCtrl.dismiss();
        } else {
          loading.dismiss();
        }
      })
      .catch(err => {
        // console.error(JSON.stringify(err));
        loading.dismiss();
      });
  }

  async getParteProcessOffline(parte: any) {
    const loading = await this._messages.showLoading('txRecuperandoParte');
    this._sync.getDetalleParte(parte.idParte)
      .then((res) => {
        if (res) {
          this.prepareReportToView(this._sync.localParte);
          this.navUtils.set(AppRoutes.parteTabsPage, this.callbackReturnShowReportFunction);
          this.router.navigate(['/', AppRoutes.parteTabsPage]);
          this.modalCtrl.dismiss();
        }
        loading.dismiss();
      });
  }

  // callback Return ShowReport Function...
  callbackReturnShowReportFunction() {
    return new Promise<void>((resolve, reject) => {
      // console.log('Data del modal:');
      // console.log(this._partesTabProvider.parte);

      this.getLlistaPartes(true);

      setTimeout(() => {
        this.getParte(this._partesTabProvider.parte, this.fullData.modelElement.idModelElement);
        resolve();
      }, 1000);
    });
  }

  private getParamsToGetItems(shoulBeReload: boolean) {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    };
    params.push(paramStrConn);

    //idModelElement
    if (this.parte.idModelElement != null && this.parte.idModelElement != '') {
      const idModelElement: PostParams = {
        nombre: PARAM_ID_MODEL_ELEMENT,
        valor: this.parte.idModelElement
      };
      params.push(idModelElement);
    }

    //rowInici
    const paramRowInici: PostParams = {
      nombre: PARAM_ROW_INICI,
      valor: shoulBeReload ? '1' : String(this.rowInici)
    };
    params.push(paramRowInici);

    //rowFinal
    this.rowFinal = this.rowFinal > 0 ? this.rowFinal : PARAM_ROW_FINAL_VALUE_RELATED;
    const paramRowFinal: PostParams = {
      nombre: PARAM_ROW_FINAL,
      valor: shoulBeReload ? String(this.rowFinal - PARAM_ROW_FINAL_VALUE_RELATED) : String(this.rowFinal)
    };
    params.push(paramRowFinal);

    // console.log('IMPRIMO PARAMETROS:: ' + JSON.stringify(params));

    return params;
  }

  private getParamsToGetParte(parte: any, idModelElementParam: any) {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    };
    params.push(paramStrConn);
    // console.log('this._wsUtils.getStrConn() --> ' + this._wsUtils.getStrConn());

    //idModelElement
    if (idModelElementParam != null && idModelElementParam != '') {
      const idModelElement: PostParams = {
        nombre: PARAM_ID_MODEL_ELEMENT,
        valor: idModelElementParam
      };
      params.push(idModelElement);
    }
    // console.log('parte.idModelElement --> ' + parte.idModelElement);

    //idParte
    if (parte.idParte) {
      const idParte: PostParams = {
        nombre: PARAM_ID_PARTE,
        valor: parte.idParte
      };
      params.push(idParte);
      // console.log('parte.idParte --> ' + parte.idParte);
    }

    return params;
  }

  descargarLlistaPartesFromServer(paramAccion: string, params: PostParams[], primeraBusqueda: boolean, shouldBeReload: boolean) {
    const promesa = new Promise((resolve, reject) => {
      if (primeraBusqueda && !shouldBeReload) {
        this.rowInici = 1;
      }
      if (primeraBusqueda || shouldBeReload) {
        this.fullData.partes = [];
      }
      this._ajax.consultarWS(paramAccion, params, 0)
        .subscribe((res: any) => {
            if (res.partes.length > 0) {
              this.fullData.modelElement = res.modelElement;
              this.agregarDatosVisualizacionElementsPartes(res)
                .then(() => {
                  this.fullData.partes.push(...res.partes);
                  if (!shouldBeReload) {
                    // console.log('ROW INICIO ANTES ....... ' + this.rowInici);
                    // console.log('ROW FINAL ANTES ....... ' + this.rowFinal);
                    this.rowInici += PARAM_ROW_FINAL_VALUE_RELATED;
                    this.rowFinal += PARAM_ROW_FINAL_VALUE_RELATED;
                  }
                  resolve(true);
                });
            } else {
              resolve(false);
            }
          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error('ERROR: ' + errConsulta.categoria + ' ' + errConsulta.categoria);
            resolve(false);
          });
    })
      .catch(err => {
        // console.error('error en descargarLlistaPartesFromServer: ' + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });

    return promesa;
  }


  descargarParteFromServer(paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {

      this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
        .subscribe((res: any) => {
            if (res != null) {
              this.prepareReportToView(res);
              resolve(true);
            } else {
              resolve(false);
            }

          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error('ERROR: ' + errConsulta.categoria + ' ' + errConsulta.categoria);
            resolve(false);
          });

    })
      .catch(err => {
        // console.error('error en descargarLlistaPartesFromServer: ' + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });

    return promesa;
  }

  prepareReportToView(parteParam: any) {
    this._partesTabProvider.parte = parteParam;
    this._partesTabProvider.estadoOriginal = parteParam.tipusEstatParteRealitzat;
    const dPlan = new Date(parteParam.dataHoraPlanificacio);
    const dInicial = new Date(parteParam.dataHoraInicialForqueta);
    const dFinal = new Date(parteParam.dataHoraFinalForqueta);
    const tieneIncidenciasAbiertasModelElement = parteParam.teIncidenciesObertes == 1;

    const codigoEstadoParte = this._gemeProvider.getTipoEstadoParte(parteParam.tipusEstatParteRealitzat, tieneIncidenciasAbiertasModelElement, dPlan, dInicial, dFinal);
    parteParam.esFuturo = codigoEstadoParte == PARTES_TIPO_ESTADO_PLAN_FUTURO;
    parteParam.srcImgEstadoParte = this._gemeProvider.getSrcImgEstadoParte(codigoEstadoParte);
    parteParam.srcImgEstadoParteToResult = this._gemeProvider.getSrcImgEstadoParteToResult(codigoEstadoParte);
    this._partesTabProvider.parte.srcImgEstadoParte = parteParam.srcImgEstadoParte;
    this._partesTabProvider.parte.srcImgEstadoParteToResult = parteParam.srcImgEstadoParteToResult;
    parteParam.txEstadoParte = this._translate.traducir(this._gemeProvider.getTextoEstadoParte(codigoEstadoParte));

    this._partesTabProvider.calcularEstadoParte(codigoEstadoParte, tieneIncidenciasAbiertasModelElement, dPlan, dInicial, dFinal);



    this._partesTabProvider.parteHasDifference = false;
  }

  doInfinite(infiniteScroll) {
    if (!this._pg.workingOffline) {
      // console.log('Begin async operation');
      this.descargarLlistaPartesFromServer(PARAM_GET_PARTES_MODEL_ELEMENT_APP, this.getParamsToGetItems(false), false, false)
        .then((hayMas: boolean) => {
          this.hayMasElementos = hayMas;

          // console.log('Async operation has ended');
          infiniteScroll.target.complete();
        });
    } else {
      // console.log('Begin async operation offline');
      // console.log('Async operation has ended');
      this.hayMasElementos = false;
      infiniteScroll.target.complete();
    }

  }

  async agregarDatosVisualizacionElementsPartes(elementsPartes: any) {
    await this._sync.getPartesResumen(this._pg.proyectoSeleccionado.idProjecte);

    if (!this._pg.workingOffline) {
      try {
        const route = `${this._fp.pathDestinoGemeIcones}/${elementsPartes.modelElement.nomFitxerLocalIconaElement}`;
        elementsPartes.modelElement.srcIcona = await this._pg.getImageToDB(route);
      } catch (e) {}
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < elementsPartes.partes.length; i++) {

      const dPlan = new Date(elementsPartes.partes[i].dataHoraPlanificacio);
      const dInicial = new Date(elementsPartes.partes[i].dataHoraInicialForqueta);
      const dFinal = new Date(elementsPartes.partes[i].dataHoraFinalForqueta);
      const tieneIncidenciasAbiertasModelElement = elementsPartes.partes[i].teIncidenciesObertes == 1;

      const status = this._sync.partesResumen[elementsPartes.partes[i].idParte];
      const statusParam = (status && status.tipusEstatParteRealitzat) ? status.tipusEstatParteRealitzat : elementsPartes.partes[i].tipusEstatParteRealitzat;

      const codigoEstadoParte = this._gemeProvider.getTipoEstadoParte(statusParam, tieneIncidenciasAbiertasModelElement, dPlan, dInicial, dFinal);
      elementsPartes.partes[i].esFuturo = codigoEstadoParte == PARTES_TIPO_ESTADO_PLAN_FUTURO;
      elementsPartes.partes[i].srcImgEstadoParte = this._gemeProvider.getSrcImgEstadoParte(codigoEstadoParte);
      elementsPartes.partes[i].txEstadoParte = this._translate.traducir(this._gemeProvider.getTextoEstadoParte(codigoEstadoParte));
    }
  }

  viewReport(parte: any, elemento: any) {
    this._partesTabProvider.elementoRelacionadoSeleccionado = elemento;
    this.getParte(parte, this.fullData.modelElement.idModelElement);
  }

  async getProcessOnline(shouldBeReload: boolean, initial: boolean = false) {
    if (!this._pg.workingOffline) {
      this.consultaWSRealizada = false;
      const loading = await this._messages.showLoading('txPartesDescargandoPartes');

      const params: PostParams[] = this.getParamsToGetItems(shouldBeReload);

      this.descargarLlistaPartesFromServer(PARAM_GET_PARTES_MODEL_ELEMENT_APP, params, true, shouldBeReload)
        .then((res) => {
          loading.dismiss();
          this.consultaWSRealizada = true;
        })
        .then()
        .catch(err => {
          // console.error(JSON.stringify(err));
          loading.dismiss();
        });
    } else {
      this.consultaWSRealizada = true;
    }
  }

  getProcessOffline(initial: boolean = false) {
    this._sync.getPartesListado(this._pg.proyectoSeleccionado.idProjecte).then(async (res: any) => {

      if (!this.parte.partes) {
        this.parte.partes = this.parte.ultimsPartes;
        delete this.parte.ultimsPartes;
      }
      this.fullData.modelElement = this.modelElement;
      this.fullData.modelElement.idModelElement = this.parte.idModelElement;
      this.fullData.modelElement.codiModel = this.parte.codiModel;
      this.fullData.modelElement.nomModel = this.parte.nomModel;
      this.fullData.modelElement.nomFrequenciaParte = this.parte.nomFrequenciaParte;
      //this.fullData.modelElement.srcIcona = this._htmlUtils.normalizarImageSrc(this._fp.pathDestinoGemeIcones + '/' + this.modelElement.nomFitxerLocalIconaElement);
      await this.agregarDatosVisualizacionElementsPartes(this.parte);
      this.fullData.partes.push(...this.parte.partes);
    }).then();
  }

  close(): void {
    this.modalCtrl.dismiss();
  }
}
