import {ParteAnotacionesPage} from './pages/parte-anotaciones/parte-anotaciones';
import {ParteDatosAdicionalesPage} from './pages/parte-datos-adicionales/parte-datos-adicionales';
import {ParteResultadoPage} from './pages/parte-resultado/parte-resultado';
import {ParteFasesPage} from './pages/parte-fases/parte-fases';
import {ParteInfoPage} from './pages/parte-info/parte-info';
import {ConfiguracionPage} from './pages/configuracion/configuracion';
import {ElementoAnotacionesPage} from './pages/elemento-anotaciones/elemento-anotaciones';
import {ElementoImagenesPage} from './pages/elemento-imagenes/elemento-imagenes';
import {ElementoDatosPage} from './pages/elemento-datos/elemento-datos';
import {ElementoTabsPage} from './pages/elemento-tabs/elemento-tabs';
import {InventarioPage} from './pages/inventario/inventario';
import {LoginPage} from './pages/login/login';
import {AppComponent} from './app.component';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CargarConfiguracionPage} from './pages/cargar-configuracion/cargar-configuracion';
import {HomePage} from './pages/home/home';
import {PartesPage} from './pages/partes/partes';
import {ParteTabsPage} from './pages/parte-tabs/parte-tabs';
import {AppRoutes} from './config/app-routes';
import {ConfiguracionInformacionPage} from "./pages/configuracion-informacion/configuracion-informacion";

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
  },
  {
    path: AppRoutes.cargarConfiguracionPage,
    component: CargarConfiguracionPage,
  },
  {
    path: AppRoutes.homePage,
    component: HomePage,
  },
  {
    path: AppRoutes.loginPage,
    component: LoginPage,
  },
  {
    path: AppRoutes.inventarioPage,
    component: InventarioPage,
  },
  {
    path: AppRoutes.elementoTabsPage,
    component: ElementoTabsPage,
    children: [
      {
        path: AppRoutes.elementoDatosPage,
        component: ElementoDatosPage,
      },
      {
        path: AppRoutes.elementoImagenesPage,
        component: ElementoImagenesPage,
      },
      {
        path: AppRoutes.elementoAnotacionesPage,
        component: ElementoAnotacionesPage,
      },
      {
        path: '',
        redirectTo: AppRoutes.elementoDatosPage,
        pathMatch: 'full'
      },
    ],
  },
  {
    path: AppRoutes.partesPage,
    component: PartesPage,
  },
  {
    path: AppRoutes.parteTabsPage,
    component: ParteTabsPage,
    children: [
      {
        path: AppRoutes.parteInfoPage,
        component: ParteInfoPage,
      },
      {
        path: AppRoutes.parteFasesPage,
        component: ParteFasesPage,
      },
      {
        path: AppRoutes.parteResultadoPage,
        component: ParteResultadoPage,
      },
      {
        path: AppRoutes.parteDatosAdicionalesPage,
        component: ParteDatosAdicionalesPage,
      },
      {
        path: AppRoutes.parteAnotacionesPage,
        component: ParteAnotacionesPage,
      },
      {
        path: '',
        redirectTo: AppRoutes.parteInfoPage,
        pathMatch: 'full'
      },
    ],
  },
  {
    path: AppRoutes.configuracionPage,
    component: ConfiguracionPage,
  },
  {
    path: AppRoutes.configurationInformationPage,
    component: ConfiguracionInformacionPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
