import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, type OnInit } from '@angular/core';
import { ElementData } from '../../../domains/element-data-domain';
import { FileProvider } from '@app/services/file/file.provider';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';

@Component({
  selector: 'element-individual',
  templateUrl: './element-individual.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementIndividualComponent implements OnInit {
  @Input() public element: ElementData;

  public phaseImage: string;

  constructor(
    private _fp: FileProvider,
    private _pg: PersistenciaGeneralProvider,
    private cdRef: ChangeDetectorRef,
    ){
    }

  async ngOnInit() {
    await this.downloadImages();
  }

  private async downloadImages() {

      const route = `${this._fp.pathDestinoGemeIcones}/${this.element.nomFitxerIconaElement}`;

      await this._pg.getImageToDB(route).then((image) => {
        this.phaseImage = image;
      }).catch((error) => {
        this.phaseImage = 'assets/imgs/no_image_ele.png';
      });
      this.cdRef.detectChanges(); // Force notify change in src
  }
}
