import {Component} from '@angular/core';
import {MenuController, ModalController, Platform, ToastController} from '@ionic/angular';
import {NetworkProvider} from '../../services/network/network.provider';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {HelperProvider} from '../../services/helper/helper.provider';
import * as Data from '../../config/constantes';
import {ConnectionStatusPage} from '../connection-status/connection-status';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {SyncProvider} from '../../services/sync/sync.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {StatusProvider} from '../../services/status/status.provider';
import {UpdateAppProvider} from '../../services/update-app/update-app.provider';
import {Subscription} from 'rxjs';

import {AppRoutes} from "../../config/app-routes";
import {App} from "@capacitor/app";
import {Router} from "@angular/router";

@Component({
  selector: 'page-home',
  templateUrl: 'home.html',
  styleUrls: ['home.scss'],
})
export class HomePage {


  public constants = Data;
  private backButtonListener?: Subscription;

  nomModulGeme: any;

  dismissing = false;
  spamming = false;
  lastBack: any = null;
  appRoutes = AppRoutes;

  constructor(
    private menuCtrl: MenuController,
    private toastCtrl: ToastController,
    private platform: Platform,
    public _pg: PersistenciaGeneralProvider,
    public _status: StatusProvider,
    public _hp: HelperProvider,
    public _html: HtmlUtilsProvider,
    public _ui: UiMessagesProvider,
    public _translate: TranslateProvider,
    public modalCtrl: ModalController,
    public _sync: SyncProvider,
    public _updateApp: UpdateAppProvider,
    public _np: NetworkProvider,
    private router: Router) {
    this.nomModulGeme = this._pg.nomModulGeme;
  }

  ionViewWillEnter() {
    this.menuCtrl.enable(true);
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.exitProcess();
    });
  }

  /*** fin trabajo con el boton atras */

  ionViewDidEnter() {

    this._updateApp.needUpdateAdvice();

    this.initializeBackButtonCustomHandler();

    this.validateTimeSync();
  }

  validateTimeSync() {
    this._status.validateTimeSyncAction();
  }

  async mostrarModal() {
    const modal = await this.modalCtrl.create({component: ConnectionStatusPage})
    modal.present();

    modal.onDidDismiss().then(
      ({data}) => {
        if (data) {
          // console.log("Data del modal:");
          // console.log(data);
        } else {
          // console.log("Se cerró el modal sin parametros");
        }

      }
    );
  }

  async exitProcess() {
    const closeDelay = 2000;
    const spamDelay = 500;

    if (((Date.now() - this.lastBack) < closeDelay) &&
      (Date.now() - this.lastBack) > spamDelay) {
      this._hp.exitApp()
    } else {
      if (!this.spamming) {
        const toast = await this.toastCtrl.create({
          message: this._translate.traducir("txPresioneNuevamenteSalir"),
          duration: closeDelay,
        });
        toast.onDidDismiss().then(() => this.spamming = false);
        const sub = this.router.events.subscribe(event => {
          toast.dismiss()
          sub.unsubscribe()
        })
        await toast.present();

      }

      this.spamming = true;
    }

    this.lastBack = Date.now();

  }

}
