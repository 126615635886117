import {Subscription} from 'rxjs';
import {Component} from '@angular/core';
import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {ParteCostesExternosPage} from '../parte-costes-externos/parte-costes-externos';
import {FormatLinkPipe} from '../../pipes/imagen/format-link.pipe';
import {NetworkProvider} from '../../services/network/network.provider';
import {WsUtilsProvider} from '../../services/utils-ws/ws-utils';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {
  ARXIU_TECNIC_DOCUMENT,
  FICHERO_NO_ENVIADO,
  GEME_ADJUNT,
  IMAGE_TYPES,
  MAX_FILE_SIZE,
  TYPE_FILE,
  TYPE_IMG
} from '../../config/constantes';
import {Camera, CameraOptions} from '@awesome-cordova-plugins/camera/ngx';
import {FileProvider} from '../../services/file/file.provider';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {DocumentViewer, DocumentViewerOptions} from '@awesome-cordova-plugins/document-viewer/ngx';
import {FileChooser} from '../../services/file-chooser/file-chooser.provider';
import {File} from "@capawesome/capacitor-file-picker";
import { Directory, Filesystem } from '@capacitor/filesystem';
import { PhotoService } from '@app/services/photo/photo.service';
import { AdjuntData, TipusAdjunt } from '@app/domains/adjunt-data-domain';


/**
 * Generated class for the ParteDatosAdicionalesPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-parte-datos-adicionales',
  templateUrl: 'parte-datos-adicionales.html',
  styleUrls: ['parte-datos-adicionales.scss']
})
export class ParteDatosAdicionalesPage {
  private backButtonListener?: Subscription;
  fitxerEnviar: any = {};
  respuesta = "";
  alertNameImage: HTMLIonAlertElement;

  constructor(public navCtrl: NavController,
              public modalCtrl: ModalController,
              public documentViewer: DocumentViewer,
              private fileChooser: FileChooser,
              public _np: NetworkProvider,
              public _ws: WsUtilsProvider,
              public _htmlUtils: HtmlUtilsProvider,
              public platform: Platform,
              private camera: Camera,
              public _fp: FileProvider,
              public alertCtrl: AlertController,
              public _translate: TranslateProvider,
              public _messages: UiMessagesProvider,
              public _pg: PersistenciaGeneralProvider,
              public _ui: UiMessagesProvider,
              public _ptp: PartesTabProvider,
              private photoService: PhotoService) {
  }

  ionViewDidEnter() {
    // console.log('ionViewDidEnter ParteDatosAdicionalesPage');
  }

  async showCosteExternoModal(costo, idx) {
    if (this._ptp.editando) {
      if (costo == null) {
        costo = {
          descripcio: "",
          costUnitari: null,
          quantitat: 1
        };
      }

      const modal = await this.modalCtrl.create({
        component: ParteCostesExternosPage,
        componentProps: {
          costUnitari: costo.costUnitari,
          quantitat: costo.quantitat,
          descripcio: costo.descripcio,
          indice: idx
        }
      })
      modal.present();
    }
  }

  showPdf(filename: string) {
    this._fp.showPdf(filename);
  }

  showPdfDocumentViewer(filename: string) {
    const options: DocumentViewerOptions = {
      title: 'PDF'
    }

    this.documentViewer.viewDocument(filename, 'application/pdf', options)
  }

  async goToFile(adjunt: any, preview: any) {
    const link = await this.getLink(adjunt);
    if (this.isImage(adjunt)) {
      // show device path or link, devicepath is set when adding a new image
      preview.src = adjunt.devicePath || link;
      if(adjunt.tamany === FICHERO_NO_ENVIADO) {
        const source = `${this._fp.pathDestinoTemp}/${adjunt.fitxerEnviar.contingutFitxer}.${adjunt.fitxerEnviar.extensioFitxer}`;
        const data = await this._pg.getFileOnIndexedDB(source, Directory.Data);
        preview.src = 'data:image/png;base64,' + data.data;
      }
      this.presentImage(preview);
    } else {
      if (!this._pg.workingOffline) {
        if (adjunt.tamany == FICHERO_NO_ENVIADO) {
          this.showPdf(link);
        } else {
          this._htmlUtils.getBrowserFullUrl(link);
        }
      } else {
        this.showPdf(this.getFilePath(adjunt));
      }
    }
  }

  getFilePath(adjunt: any) {
    const tipus = adjunt.idGemeAdjunt > '' ? GEME_ADJUNT : ARXIU_TECNIC_DOCUMENT;
    const filename = adjunt.nomFitxerLocal;

    let path = "";
    switch (tipus) {
      case GEME_ADJUNT: {
        path = this._fp.pathDestinoAdjunts + '/' + filename;
        break;
      }
      case ARXIU_TECNIC_DOCUMENT: {
        path = this._fp.pathDestinoArxiutecnic + '/' + filename;
        break;
      }
      default: {
        break;
      }
    }

    return path;
  }


  async getLink(adjunt: any) {
    if (adjunt.tamany == FICHERO_NO_ENVIADO) {
      return adjunt.nomFitxerLocal;
    }

    const tipus = adjunt.idGemeAdjunt > '' ? GEME_ADJUNT : ARXIU_TECNIC_DOCUMENT;

    const ifp = new FormatLinkPipe(this._np, this._pg, this._htmlUtils, this._fp, this._ws, this.platform);
    const link = await ifp.transformAsync(adjunt.nomFitxerLocal, tipus);
    return link;
  }

  getIcon(adjunt: any) {
    let result = "";

    if (!(this.isImage(adjunt))) {
      result = "lc-icon-clip";
    } else {
      result = "lc-icon-camera";
    }
    return result;
  }

  isImage(adjunt: any) {
    if (adjunt.tamany == FICHERO_NO_ENVIADO) {
      return IMAGE_TYPES.indexOf(adjunt.fitxerEnviar.extensioFitxer) > -1;
    }
    const fileExt = adjunt.nomFitxerLocal.split('.').pop();
    return IMAGE_TYPES.indexOf(fileExt) > -1;
  }

  showPreview(adjunt: any, selectorId: any) {
    if (this.isImage(adjunt)) {
      this.presentImage(selectorId);
    }
  }


  async presentImage(preview: HTMLImageElement) {
    this.alertNameImage = await this.alertCtrl.create({
      message: `<img src="${preview.src}" style="width: 100%; height: auto;"/>`,
      backdropDismiss: false,
      buttons: ['OK'],
      cssClass: 'alert-img'
    });

    this.alertNameImage.onDidDismiss().then(() => {
    });
    this.alertNameImage.present();
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      if (this._ptp.isOpenNameImage) {
        this.alertNameImage.dismiss();
      }
    });
  }

  async takePhoto() {

    let dataImage;
    let format;

    if (this.platform.is('mobile') || this.platform.is('hybrid')) {
      const types_allowed = ['image/png', 'image/jpeg', 'image/jpg'];
      const file = await this.fileChooser.open(types_allowed);

      if(!file) {return;}

      dataImage = file.data;
      format = file.name.split('.').pop();
    } else {
      const photo = await this.photoService.addNewToGallery();

      format = photo.format;
      dataImage = photo.base64String;
    }

    this.fitxerEnviar = {
      contingutFitxer: this.getFilename(TYPE_IMG),
      extensioFitxer: format
    };

    this.respuesta = this.fitxerEnviar.contingutFitxer + "." + this.fitxerEnviar.extensioFitxer;

    this._pg.saveOnIndexedDB(
      `${this._fp.pathDestinoTemp}/${this.respuesta}`,
      dataImage,
      Directory.Data
    ).then(async () => {
      this.presentPromptNom(TYPE_IMG);
    });
  }


  attachFile() {
    this.fileChooser.open()
      .then(async file => {
        await this.copyFile(file);
      })
      .catch(async (e) => {
        if (e.message == FileChooser.PICKER_CANCELLED_ERROR) {
          return;
        }
        const msg = this._translate.traducir("txOcurrioErrorAdjuntarArchivo");
        await this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
      });

  }

  /**
   * Obtains a file and copies it to the app's temp directory
   * this function affects sets the object that will be sent to the server in the view
   * the legacy code expects those properties to be set here
   *
   * @param file
   * @private
   */
  private async copyFile(file: File) {
    const size = this._fp.bytesToMb(file.size);
    if (size > MAX_FILE_SIZE) {
      const msg = this._translate.traducirWithParams("txAvisoNosePuedeSubirArchivo", {max: MAX_FILE_SIZE});
      await this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
      return;
    }

    const extension = file.name.split('.').pop();

    this.fitxerEnviar = {
      contingutFitxer: this.getFilename(IMAGE_TYPES.indexOf(extension) > -1 ? TYPE_IMG : TYPE_FILE),
      extensioFitxer: extension
    };

    this.respuesta = this.fitxerEnviar.contingutFitxer + "." + this.fitxerEnviar.extensioFitxer;

    try {
      this._pg.saveOnIndexedDB(`temp/${this.respuesta}`, file.data, Directory.Data)
      .then(() => {
        this.presentPromptNom(IMAGE_TYPES.indexOf(this.fitxerEnviar.extensioFitxer) > -1 ? TYPE_IMG : TYPE_FILE);
      }).catch((e) => {
        // console.log('error', e)
      });
    } catch (e) {
      throw new Error('Error writing file to disk');
    }

  }

  async eliminarAdjunto(idx: number) {
    event.stopPropagation();
    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txConfirmarEliminarAdjunto", "txYes", "txNo");

    alert.onDidDismiss().then(({data}) => {
      if (data.accepted) {
        this._ptp.parte.adjunts.splice(idx, 1);
        return true;
      }
      return false;
    });
  }

  private getFilename(type: number) {
    let result = "";
    const name = Math.random().toString(36).substring(7);

    switch (type) {
      case TYPE_IMG: {
        result = "imagen_adjunto_parte_" + this._ptp.parte.idParte + "_" + name;
        break;
      }
      case TYPE_FILE: {
        result = "archivo_adjunto_parte_" + this._ptp.parte.idParte + "_" + name;
        break;
      }
      default: {
        break;
      }
    }

    return result;
  }

  async presentPromptNom(type: any) {
    if (!this._ptp.editando) { return; }

    this._ptp.isOpenNameImage = true;
    this.alertNameImage = await this.alertCtrl.create({
      header: this._translate.traducir('txNombredelFichero'),
      inputs: [
        {
          name: 'nom',
          placeholder: this._translate.traducir('txNombredelFichero'),
          value: type == TYPE_IMG ? this._translate.traducir("txImagenStr") : this._translate.traducir("txArchivoStr")
        }
      ],
      backdropDismiss: false,
      buttons: [
        {
          text: this._translate.traducir('txCancelar'),
          role: 'cancel',
          handler: data => {
            // console.log('Cancel clicked');
            // console.log('imagen no agregada');
            this._ptp.isOpenNameImage = false;
          }
        },
        {
          text: this._translate.traducir('txAceptar'),
          handler: async (data) => {
            if (data.nom > '') {
              // const fullpath = await Filesystem.readFile({
              //   path: `temp/${this.fitxerEnviar.contingutFitxer}`,
              //   directory: Directory.Data
              // });

              // const fullpath = type == TYPE_IMG ? this._htmlUtils.normalizarImageSrcRandom(this._fp.pathDestinoTemp + "/" + this.respuesta)
              // : this._fp.pathDestinoTemp + "/" + this.respuesta;

              const nuevoAdjunto: AdjuntData = {
                idDocument: null,
                idGemeAdjunt: null,
                tipus: TipusAdjunt.ADJ,
                nom: data.nom > '' ? data.nom : type == TYPE_IMG ? "Imagen" : "File",
                // nomFitxerLocal: fullpath,
                nomFitxerLocal: `${this._fp.pathDestinoTemp}/${this.fitxerEnviar.contingutFitxer}`,
                devicePath: this._fp.pathDestinoTemp + "/" + this.respuesta,
                tamany: FICHERO_NO_ENVIADO,
                fitxerEnviar: this.fitxerEnviar
              };

              this._ptp.parte.adjunts.push(nuevoAdjunto);
              this._ptp.isOpenNameImage = false;
              this.alertNameImage.dismiss();
              return false;
            } else {
              this._messages.showToastDefault('txEspecificarNombreImagen');
              return false;
            }
          }
        }
      ]
    });
    this.initializeBackButtonCustomHandler();
    this.alertNameImage.onDidDismiss().then(() => {
      this.backButtonListener?.unsubscribe();
    });
    this.alertNameImage.present();
  }
}
