import { Component, Input } from '@angular/core';
import { TipusAccioCambiarFase } from '@app/domains/valor-observacions-fase-data-domain';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';
import { FaseActualProvider } from '@app/services/geme/fase-actual.provider';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'respuesta-fase-header',
  templateUrl: './respuesta-fase-header.component.html',
})
export class RespuestaFaseHeaderComponent {

  @Input() public respuestaPageActions: RespuestaPageActions;

  constructor(
    private modalCtrl: ModalController,
    public faseActual: FaseActualProvider,
    public _pg: PersistenciaGeneralProvider,
  ) { }

  clickCancelar() {
    this.modalCtrl.dismiss(TipusAccioCambiarFase.FASE_CANCEL);
  }

  public clickAceptar() {
    this.cerrarConParametros(TipusAccioCambiarFase.FASE_CURRENT);
  }

  cerrarConParametros(tipusAccio: TipusAccioCambiarFase) {
    if (this.respuestaPageActions.checkValorIsOk()) {
      this.respuestaPageActions.setValorFaseActual();
      this.modalCtrl.dismiss(tipusAccio);
    }
  }

}

