import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { URL_CONFIGURACION } from '../../config/constantes-cliente';
import { UiMessagesProvider } from '../../services/ui-messages/ui-messages';
import I18n from '@app/i18n/i18n';


@Component({
  selector: 'page-cargar-configuracion-modal',
  templateUrl: 'cargar-configuracion-modal.html',
  styleUrls: []
})
export class CargarConfiguracionModalPage {

  constructor(
    public _ui: UiMessagesProvider,
    private modalCtrl: ModalController,
    public i18n: I18n) {
  }

  url: string = URL_CONFIGURACION;

  ionViewDidEnter() {
    // console.log('ionViewDidEnter CargarConfiguracionModalPage');
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }

  cerrar() {
    if (this.url > '') {
      const data = {
        url: this.url
      }

      this.modalCtrl.dismiss(data);
    } else {
      this._ui.showToastDefault("txDebeCargarUrl");
    }
  }

}
