import {ChangeDetectorRef, Component} from '@angular/core';
import {AlertController, ModalController, NavParams, Platform} from '@ionic/angular';
import {Subject, Subscription} from 'rxjs';
import {ElementoTabProvider} from '@app/services/elemento-tab/elemento-tab.provider';
import {UiMessagesProvider} from '@app/services/ui-messages/ui-messages';
import {TranslateProvider} from '@app/services/translate/translate.provider';
import {ImagePickerViewController} from "@app/services/image-picker/image-picker.view-controller";
import {PersistenciaGeneralProvider} from '@app/services/persistencia-configuracion/persistencia-general.provider';
import {HtmlUtilsProvider} from '@app/services/html-utils/html-utils';
import {Image} from "@app/core/element/domain/image";
import {FileUtils} from "@app/image-picker/file-utils";
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileChooser } from '@app/services/file-chooser/file-chooser.provider';
import { PhotoService } from '@app/services/photo/photo.service';

@Component({
  selector: 'page-element-images-edit',
  styleUrls: ['element-images-edit.scss'],
  templateUrl: 'element-images-edit.html',
  providers: [ImagePickerViewController]
})
export class ElementImagesEditPage {
  images: Image[] = [];
  private backButtonListener?: Subscription;
  destroy$ = new Subject<void>();

  constructor(public navParams: NavParams,
              public htmlUtils: HtmlUtilsProvider,
              public _etp: ElementoTabProvider,
              public modalCtrl: ModalController,
              public platform: Platform,
              public _translate: TranslateProvider,
              public _pg: PersistenciaGeneralProvider,
              private fileChooser: FileChooser,
              public alertCtrl: AlertController,
              private _messages: UiMessagesProvider,
              private cdRef: ChangeDetectorRef,
              private imagePicker: ImagePickerViewController,
              private photoService: PhotoService) {
    this.images = htmlUtils.getParam(navParams, 'images');
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.imagePicker.handleErrors(this.imagePicker.error$, this.destroy$);
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.destroy$.next();
    this.backButtonListener?.unsubscribe();
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }
  /*** fin trabajo con el boton atras */

  cerrarConParametros() {
    this.changeElementsImageList();
    this.modalCtrl.dismiss(this.images);
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }

  async base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('method did not return a string');
        }
      };
      reader.readAsDataURL(blob);
    });
  }

  async takePhoto() {
    try {

      let fileName;
      let dataImage;

      if (this.platform.is('mobile') || this.platform.is('hybrid')) {
        const types_allowed = ['image/png', 'image/jpeg', 'image/jpg'];
        const file = await this.fileChooser.open(types_allowed);

        if(!file) {return;}

        fileName = file.name;
        dataImage = file.data;
      } else {
        const photo = await this.photoService.addNewToGallery();
        const name = Math.random().toString(36).substring(7);

        fileName = `imagen_adjunto_parte_${name}.${photo.format}`;
        dataImage = photo.base64String;
      }

      await Filesystem.writeFile({
        path: `images/${fileName}`,
        data: dataImage,
        directory: Directory.Data
      });

      this.images.push({
        idImatgeElement: null,
        nom: fileName,
        nomFitxerLocal: fileName
      });
      this.cdRef.detectChanges();
    } catch (e) {
      throw new Error('Error writing file to disk');
    }

  }

  /**
   * Shows user an alert to change the name of the image
   * the alert has a text input to change the name
   *
   * @param image
   */
  async changeName(image: Image): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: image.nom,
      inputs: [{
        type: 'text',
        name: 'name',
        placeholder: this._translate.traducir('txChangeNameImage'),
      }],
      buttons: [
        this._translate.traducir('txCancelar'),
        {
          text: this._translate.traducir('txAceptar'),
          handler: data => {
            image.nom = data.name;
          }
        }
      ],
    });
    await alert.present();
  }

  async deleteImage(image: Image) {
    const alert = await this._messages.showYesNoAlert({}, 'txAtencionStr',
      'txPreguntaConfirmaEliminacion', 'txYes', 'txNo');
    alert.onDidDismiss().then(async ({data}) => {
      if (data.accepted) {
        this.images.splice(this.images.indexOf(image), 1);
      }
    });
  }

  upClick(image: Image) {
    if (this.images.indexOf(image) > 0) {
      const index = this.images.indexOf(image);
      const aux = this.images[index - 1];
      this.images[index - 1] = this.images[index];
      this.images[index] = aux;
    }
  }

  downClick(image: Image) {
    if (this.images.indexOf(image) < this.images.length - 1) {
      const index = this.images.indexOf(image);
      const aux = this.images[index + 1];
      this.images[index + 1] = this.images[index];
      this.images[index] = aux;
    }
  }

  changeElementsImageList() {
    this._etp.elemento.imatges = this.images;
    this._etp.elemento.editatImatgesApp = 1;
  }
}
