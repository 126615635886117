import { Injectable } from '@angular/core';
import { PARAM_STRCONN, PARAM_ID_MODUL, PARAM_ID_PROJECTE, PARAM_FITXATECNICA, PAGE_OBTENER_MODUL, PARAM_ARXIUTECNIC, LINK_FITXATECNICA, LINK_ARXIUTECNIC, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG } from '../../config/constantes';
import { WsUtilsProvider } from '../utils-ws/ws-utils';
import { NetworkProvider } from '../network/network.provider';
import { PersistenciaGeneralProvider } from '../persistencia-configuracion/persistencia-general.provider';
import { PostParams } from '../../interfaces/ajax.interface';
import { ErrorConsulta, AjaxClientProvider } from '../ajax-client/ajax-client.provider';
import { UiMessagesProvider } from '../ui-messages/ui-messages';
import { Platform } from '@ionic/angular';
import {EventService} from "../events/event.service";


@Injectable({
  providedIn: 'root',
})
export class ExternalLinksProvider {

  urlFitxaTecnica = "";
  urlArxiuTecnic = "";
  baseUrl = "";

  constructor(public _pg: PersistenciaGeneralProvider,
              public _ui: UiMessagesProvider,
              public _ajax: AjaxClientProvider,
              public platform: Platform,
              public _np: NetworkProvider,
              public events: EventService,
              public _wsUtils: WsUtilsProvider) {
    // console.log('Hello ExternalLinksProvider Provider');

    this.events.subscribe('baseUrlOk:created', (baseUrlOk) => {
      this.baseUrl = baseUrlOk;
    });

  }

  openExternalLinksProcessFichaTecnica(){
    return new Promise( (resolve, reject) => {
        const params: PostParams[] = this.getParams(LINK_FITXATECNICA);
        // console.log("imprimo params de ficha tecnica: " + JSON.stringify(params));

        this._ajax.consultarWS(PARAM_FITXATECNICA, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG, PAGE_OBTENER_MODUL, "txSinConexion")
        .subscribe(
          (datos: any) => {
            this.urlFitxaTecnica = datos.url + this.getUrl(datos);
            this.events.publish('urlFitxaTecnica:created', this.urlFitxaTecnica);
            resolve(datos);
          },
          (errConsulta: ErrorConsulta) => {
            this._ui.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);

            this.urlFitxaTecnica = "";
            this.events.publish('urlFitxaTecnica:created', this.urlFitxaTecnica);

            resolve(false);
          }
      );
    });
  }

  openExternalLinksProcessArchivoTecnico(){
    return new Promise( (resolve, reject) => {
        const params: PostParams[] = this.getParams(LINK_ARXIUTECNIC);
        // console.log("imprimo params de archivo tecnico: " + JSON.stringify(params));

        this._ajax.consultarWS(PARAM_ARXIUTECNIC, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG, PAGE_OBTENER_MODUL, "txSinConexion")
        .subscribe(
          (datos: any) => {
            this.urlArxiuTecnic = datos.url + this.getUrl(datos);
            this.events.publish('urlArxiuTecnic:created', this.urlArxiuTecnic);
            resolve(datos);
          },
          (errConsulta: ErrorConsulta) => {
            this._ui.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);

            this.urlArxiuTecnic = "";
            this.events.publish('urlArxiuTecnic:created', this.urlArxiuTecnic);

            resolve(false);
          }
      );
    });
  }

  private getUrl(jsonobject: any){
    let result = "";
    if(jsonobject){
      let cont = 1;
      for (const key in jsonobject.parametresUrl) {
        if (jsonobject.parametresUrl.hasOwnProperty(key)) {
            // console.log(key + " -> " + jsonobject.parametresUrl[key]);
            result = result + (cont == 1 ? "?" : "&") + key + "=" + jsonobject.parametresUrl[key];
            cont++;
        }
      }
    }
    return result;
  }

  private getParams(paramLink: number){
    const params: PostParams[] = [];
        const paramStrLogin: PostParams = {
          nombre: PARAM_STRCONN,
          valor: this._wsUtils.getStrConn()
        }
        params.push(paramStrLogin);

        //idmodul
        const idModul: PostParams = {
          nombre: PARAM_ID_MODUL,
          valor: paramLink == LINK_FITXATECNICA ? String(this._pg.idModulFitxaTecnica) : String(this._pg.idModulArxiuTecnic)
        }
        params.push(idModul);

        //idProjecte
        const idProjecte = this._pg.proyectoSeleccionado.idProjecte;
        const paramIdProjecte: PostParams = {
          nombre: PARAM_ID_PROJECTE,
          valor: String(idProjecte)
        }
        params.push(paramIdProjecte);

        return params;
  }
}
