import { Injectable } from '@angular/core';
import { TranslateProvider } from '../translate/translate.provider';
import { PARTES_RESPUESTA_LISTA_OPCIONES } from '@app/config/constantes';
import { FaseEnParteData } from '@app/domains/fase-en-parte-data-domain';
import { ValorObsevacionsFaseData } from '@app/domains/valor-observacions-fase-data-domain';
import { ParteData } from '@app/domains/parte-data-domain';

@Injectable({
    providedIn: 'root',
})
export class FaseActualProvider {

    fase: FaseEnParteData; // Los datos de la fase, incluye observacions y valor originales (sin cambiar por el usuario)
    valorObservacionsEdicio: ValorObsevacionsFaseData; // valor y observacions editados, inicialmente son los que ya vienen en la fase
    indexFase: number;
    existsFaseNext: boolean;
    existsFasePrev: boolean;
    qTotalFases: number;
    tituloPrefix: string;
    titulo: string;
    dataHoraExecucioParte: string;
    idParte: number;

    constructor(private translate: TranslateProvider) {
    }

    setFaseActual(index: number, parte: ParteData) {
        const fases: FaseEnParteData[] = parte.fases;
        this.dataHoraExecucioParte = parte.dataHoraExecucio;
        this.idParte = parte.idParte;
        this.indexFase = index;
        this.fase = fases[index];
        this.valorObservacionsEdicio = {
            valor: this.fase.valor,
            observacions: this.fase.observacions,
            valorPreview: this.fase.valorPreview
        };
        this.qTotalFases = fases.length;
        this.existsFaseNext = index < fases.length - 1;
        this.existsFasePrev = index > 0;

        this.tituloPrefix = this.translate.traducir("txTituloRespuestaFasePrefix");
        const strOrdreCalc = String(this.indexFase + 1);
        const strOrdreFase = this.fase.strOrdre;
        let strFase;
        if (strOrdreCalc === strOrdreFase){
            strFase = strOrdreCalc;
        }else{
            strFase = "'" + strOrdreFase + "' " + strOrdreCalc;
        }
        this.titulo = this.translate.traducir("txTituloRespuestaFase") + " " + strFase + " " +
         this.translate.traducir("txTituloRespuestaTotal") + " " + String(this.qTotalFases);
    }

    getNomSeleccioOpcioEdicio(): string {
        let nom = null;
        if (PARTES_RESPUESTA_LISTA_OPCIONES === this.fase.tipusRespostaFase) {
            for (const op of this.fase.opcionsFases) {
                if (op.numeroOpcio === this.valorObservacionsEdicio.valor) {
                    nom = op.nom;
                }
            }
        }
        return nom;
    }

}
