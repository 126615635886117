import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ElementoTabProvider} from '@app/services/elemento-tab/elemento-tab.provider';
import {ImageModalPage} from '../image-modal/image-modal';
import {PersistenciaGeneralProvider} from '@app/services/persistencia-configuracion/persistencia-general.provider';
import {ElementImagesEditPage} from "@app/pages/element-images-edit/element-images-edit";
import {FileProvider} from "@app/services/file/file.provider";
import {HtmlUtilsProvider} from "@app/services/html-utils/html-utils";
import {FormatLinkPipe} from "@app/pipes/imagen/format-link.pipe";
import {GEME_IMATGE_ELEMENT, LOCAL_IMAGE} from "@app/config/constantes";


@Component({
  selector: 'page-elemento-imagenes',
  templateUrl: 'elemento-imagenes.html',
  styleUrls: ['elemento-imagenes.scss'],
})
export class ElementoImagenesPage {


  imagenes: any = [];
  idx: any = 0;
  urlImage: string;

  public unregisterBackButtonAction: any;

  sliderOpts = {
    zoom: false,
    slidesPerView: 1.5,
    spaceBetween: 20,
    centeredSlides: true
  };

  constructor(
    private modalController: ModalController,
    public _pg: PersistenciaGeneralProvider,
    public _etp: ElementoTabProvider,
    public modalCtrl: ModalController,
    public _fp: FileProvider,
    public _html: HtmlUtilsProvider,
    private formatLink: FormatLinkPipe) {
  }

  ionViewDidEnter() {
    this.imagenes = this._etp.elemento.imatges;
    this.imageSRC(this.imagenes[this.idx]);
  }

  async openPreview(img) {
    const modal = await this.modalController.create({
      component: ImageModalPage,
      componentProps: {
        img
      }
    });
    modal.present();
  }

  backClick() {
    if (this.idx > 0) {
      this.idx = this.idx - 1;
      this.imageSRC(this.imagenes[this.idx]);
    }
  }

  forwardClick() {
    if (this.idx < this.imagenes.length - 1) {
      this.idx = this.idx + 1;
      this.imageSRC(this.imagenes[this.idx]);
    }
  }

  validateShow() {
    return (this.imagenes.length > 0 && !this._pg.workingOffline) || (this.imagenes.length > 0 && this._pg.usuarioLoginModel.syncImatgesElements === 1 && this._pg.workingOffline);
  }

  async editImage() {
    if (this._etp.editando) {
      const modal = await this.modalCtrl.create({
        component: ElementImagesEditPage,
        componentProps: {
          images: JSON.parse(JSON.stringify(this.imagenes))
        }
      });
      modal.present();
      modal.onDidDismiss().then((data) => {
        if (data.data != null) {
          this.imagenes = data.data;
          if (this.idx > (this.imagenes.length - 1)) {
            this.backClick();
          }
          this.imageSRC(this.imagenes[this.idx]);
        }
      });
    }
  }

  async imageSRC(image: any) {
    if (image === undefined) {
      return;
    }

    const imageType = image.idImatgeElement ? GEME_IMATGE_ELEMENT : LOCAL_IMAGE;
    this.urlImage = await this.formatLink.transformAsync(image.nomFitxerLocal, imageType);
    // console.log('this.urlImage', imageType, image, this.urlImage);
  }
}
