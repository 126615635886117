import {Component, ViewChild} from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent, VersionReadyEvent } from '@angular/service-worker';

import {PersistenciaGeneralProvider} from './services/persistencia-configuracion/persistencia-general.provider';
import * as Data from './config/constantes';
import {DEBUG} from './config/constantes';
import * as DataClient from './config/constantes-cliente';


import {TranslateService} from '@ngx-translate/core';
import {NetworkProvider} from './services/network/network.provider';

import {FileProvider} from './services/file/file.provider';
import {TranslateProvider} from './services/translate/translate.provider';
import {ModalController, Platform} from '@ionic/angular';
import {StatusBar, Style} from '@capacitor/status-bar';
import {DEFAULT_LANGUAGE} from './config/default.language';
import {ExternalLinksProvider} from './services/external-links/external-links.provider';
import {StatusProvider} from './services/status/status.provider';
import {HelperProvider} from './services/helper/helper.provider';
import { SplashScreen } from '@capacitor/splash-screen';

//Pages
import {ConnectionStatusPage} from './pages/connection-status/connection-status';
import {Router} from '@angular/router';
import {AppRoutes} from './config/app-routes';
import {EventService} from './services/events/event.service';
import {FirebaseCrashlytics} from '@capacitor-firebase/crashlytics';
import {environment} from '../environments/environment';
import {FirebaseAnalytics} from '@capacitor-firebase/analytics';
import {filter, map} from 'rxjs/operators';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';

import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(SplashScreenComponent) splashScreenComponent: SplashScreenComponent;

  nomModulGeme: string;
  nomModulFitxaTecnica: string;
  idModulFitxaTecnica: number;
  nomModulArxiuTecnic: string;
  idModulArxiuTecnic: number;
  userLogin: string;
  proyectoActualStr = '';

  isDebug = false;

  public constants = Data;
  public constantsClient = DataClient;
  appRoutes = AppRoutes;

  public deferredPrompt = null;
  public showButton = false;
  constructor(
    public platform: Platform,
    private translateService: TranslateService,
    public _pg: PersistenciaGeneralProvider,
    public events: EventService,
    private _netProv: NetworkProvider,
    public _extLinks: ExternalLinksProvider, // needed to subscribe to events in its constructor
    public _hp: HelperProvider,
    public _fp: FileProvider,
    public _translate: TranslateProvider,
    public _status: StatusProvider,
    public modalCtrl: ModalController,
    private router: Router,
    private swUpdate: SwUpdate
  ) {
    this.hidenSplashScreen();
    this.checkForUpdate();
    this.initializeApp();
  }

  hidenSplashScreen() {
    SplashScreen.hide();
  }

  initializeApp(): void {
    // Init app variables
    this.initApplicationVariables();
    this.userLogin = this._pg.usuarioLoginModel != null ? this._pg.usuarioLoginModel.login : '';

    this.platform.ready().then(async () =>{
      // console.log('Iniciando App');
      if (this.platform.is('hybrid')){
        FirebaseCrashlytics.setEnabled({enabled: environment.production});
        FirebaseAnalytics.setEnabled({enabled: environment.production});
      }
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      try{
        await StatusBar.setStyle({style: Style.Default});
      }catch(err){
        // console.log('No fue posible aplicar StatusBar.setStyle ', err);
      }
      this.splashScreenComponent.onAnimationEnd();

      //statusbar
      try{
        await StatusBar.setOverlaysWebView({overlay: false});
        await StatusBar.setBackgroundColor({color: '#000000'});
        await StatusBar.setStyle({style: Style.Dark});
      }catch(err){
        // console.log('No fue posible aplicar estilos a StatusBar ', err);
      }

      //Idioma
      this.translateService.setDefaultLang(DEFAULT_LANGUAGE);
      this.translateService.use(DEFAULT_LANGUAGE);
      // console.log('getAppVersion');
      //seteo app version si está en web no lo hago también inicio logger
      await this.getAppVersion();
      // console.log('fin getAppVersion');

      // navigate to config page
      await this.router.navigate([`/${AppRoutes.cargarConfiguracionPage}`]);

      // Load global events
      this.initGlobalEvents();

      this.isDebug = DEBUG;
      //chequeo si existe configuración previa
      this.getPreviousConfig();
    });
  }

  checkForUpdate(): void {
    this.swUpdate.checkForUpdate().then((value: boolean) => {
      if(value){
        if(confirm(this._translate.traducir('txNewVersionIsAvailable'))) {
          window.location.reload();
        }
      }
    });
  }

  private initApplicationVariables(): void {
    this.nomModulGeme = this._pg.nomModulGeme;
    this.nomModulFitxaTecnica = this._pg.nomModulFitxaTecnica;
    this.idModulFitxaTecnica = this._pg.idModulFitxaTecnica;
    this.nomModulArxiuTecnic = this._pg.nomModulArxiuTecnic;
    this.idModulArxiuTecnic = this._pg.idModulArxiuTecnic;
  }

  private async getPreviousConfig() {
    await this._pg.getConfiguracioServidorFromDb();
    await this._netProv.ping();
    this.initApplicationVariables();
  }

  private async getAppVersion(){
    this._pg.versionApp = packageInfo.version;
  }

  private initGlobalEvents(): void {
    this.events.subscribe('nomModulGeme:created', (nomModulGeme) => {
      this.nomModulGeme = nomModulGeme;
    });

    this.events.subscribe('nomModulFitxaTecnica:created', (nomModulFitxaTecnica) => {
      this.nomModulFitxaTecnica = nomModulFitxaTecnica;
    });

    this.events.subscribe('idModulFitxaTecnica:created', (idModulFitxaTecnica) => {
      this.idModulFitxaTecnica = idModulFitxaTecnica;
    });

    this.events.subscribe('nomModulArxiuTecnic:created', (nomModulArxiuTecnic) => {
      this.nomModulArxiuTecnic = nomModulArxiuTecnic;
    });

    this.events.subscribe('idModulArxiuTecnic:created', (idModulArxiuTecnic) => {
      this.idModulArxiuTecnic = idModulArxiuTecnic;
    });

    this.events.subscribe('usuarioLogin:created', (usuarioLogin) => {
      this.userLogin = usuarioLogin;
    });

    this.events.subscribe('proyectoSeleccionadoMenu:created', (params: any) => {
      // console.log(' app component  proyectoSeleccionadoMenu:created ');
      // console.log(JSON.stringify(params));
      if (params[0] != null) {
        this.proyectoActualStr = params[0].nom;
      } else {
        // console.log('suscribe : proyectoSeleccionadoMenu:created  - app.components-> ' + params[0]);
      }
    });

    //setroot
    this.events.subscribe('setRoot:created', (url: string) => {
      this._hp.abrirPaginaClick(url, true);
    });
  }

  showStatus(): string {
    if (this._pg.workingOffline) {
      return this._translate.traducir('txTrabajoSinConexion');
    } else {
      return this._translate.traducir('txTrabajoEnLinea');
    }
  }

  showStatusIcon() {
    if (this._pg.workingOffline) {
      return 'lc-icon-airplane';
    }
    return 'lc-icon-cloud';
  }

  async mostrarModalStatus() {
    const modal = await this.modalCtrl.create({component: ConnectionStatusPage});
    modal.onDidDismiss().then(
      async ({data}) => {
        if (data) {
          // console.log('Data del modal:');
          // console.log(data);
          //this.ultimaSincronizacion = this._html.getDateToHuman(this._pg.usuarioLoginModel.ultimaSincronizacion);
          await this._status.cambiarModo(true);
        } else {
          // console.log('Se cerró el modal sin parametros');
        }

      }
    );
    await modal.present();
  }

}
