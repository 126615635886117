<ion-header>
    <ion-toolbar color="colorTema1">

      <ion-buttons slot="start">
        <ion-button
          class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button"
          (click)="cerrarSinParametros()">
          <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-title>{{ i18n.txConfiguracionTituloCargarConfModal | translate }}</ion-title>

    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    {{ i18n.txConfigurarMedianteUrlCargarConfModal | translate }}
    <ion-list>

        <ion-item>
            <ion-label position="floating">Url</ion-label>
            <ion-input [(ngModel)]="url" type="text"></ion-input>
        </ion-item>

    </ion-list>

        <ion-button expand="block" (click)="cerrar()">
            {{ i18n.txAccederCargarConfModal | translate }}
        </ion-button>

</ion-content>
