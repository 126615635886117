import { ElementRepositoryInterface } from "@app/core/element/domain/element.repository-interface";
import { UpdateWebService } from "@app/core/element/infrastructure/update.web-service";
import { ElementDAO } from "@app/core/element/domain/element.DAO";
import { Element } from "@app/core/element/domain/element";
import { Injectable } from "@angular/core";
import { ElementState } from "@app/core/element/domain/element-state";
import { GetElementsStateTypologiesWebService } from "@app/core/element/domain/get-elements-state-typologies.web-service";
import { ElementInfo } from "../domain/element-info";

@Injectable({
  providedIn: 'root'
})
export class ElementRepository implements ElementRepositoryInterface {

  constructor(
    private updateWS: UpdateWebService,
    private elementDAO: ElementDAO,
    private getElementStateService: GetElementsStateTypologiesWebService
  ) {}

  /**
   * Update an element, either online or offline.
   * @param element The element to be updated, including the project ID.
   * @param isOffline Indicates whether the update should be performed offline.
   * @returns A Promise that resolves to the updated element.
   */
  async updateElement(element: Element, isOffline: boolean): Promise<Element> {
    if (!isOffline) {
      return this.updateWS.execute(element);
    } else {
      const { idElement, idProjecte } = element;
      const elementInfoList: ElementInfo[] = await this.elementDAO.getElementInfos();
      const elementExists = elementInfoList.some((info) => info.idElement === idElement && info.idProjecte === idProjecte);
      
      if (!elementExists) {
        elementInfoList.push({ idElement, idProjecte });
        await this.elementDAO.saveElementInfos(elementInfoList);
      }

      return this.elementDAO.save(element);
    }
  }

  /**
   * Get the element state, either offline or by executing a web service.
   * @param ifOffline Indicates whether to retrieve the element state offline.
   * @returns A Promise that resolves to an array of ElementState.
   */
  async getElementState(ifOffline: boolean): Promise<ElementState[]> {
    if (ifOffline) {
      return this.elementDAO.getElementState();
    } else {
      return this.getElementStateService.execute().toPromise();
    }
  }

  /**
   * Get the IDs of modified elements.
   * @returns A Promise that resolves to an array of modified element IDs.
   */
  async getModifiedElementInfos(): Promise<ElementInfo[]> {
    return this.elementDAO.getElementInfos();
  }

  /**
   * Synchronize an element offline.
   * @param element The element to be synchronized.
   * @returns A Promise that resolves to the synchronized element.
   */
  async syncOfflineElement(element: Element): Promise<Element> {
    return this.updateWS.execute(element);
  }

  /**
   * Get a modified element by its ID.
   * @param elementId The ID of the modified element.
   * @returns A Promise that resolves to the modified element.
   */
  async getModifiedElement(elementInfo: ElementInfo): Promise<Element> {
    return this.elementDAO.get(elementInfo);
  }

  /**
   * Remove the successfully synced element IDs from the list of modified elements.
   * @param syncedIds An array of element IDs that have been successfully synced.
   */
  async removeModifiedElementIds(syncedIds: number[]): Promise<void> {
    const existingElementInfos = await this.getModifiedElementInfos();
    const remainingInfos = existingElementInfos.filter(info => !syncedIds.includes(info.idElement));
    await this.elementDAO.saveElementInfos(remainingInfos);
  }
}
