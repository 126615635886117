import {Subscription} from 'rxjs';
import {Component, NgZone} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {HelperProvider} from '@app/services/helper/helper.provider';
import {AppRoutes} from "@app/config/app-routes";
import {PersistenciaGeneralProvider} from "@app/services/persistencia-configuracion/persistencia-general.provider";
import {NetworkProvider} from "@app/services/network/network.provider";
import {ConfigurationInformationViewModel} from "./configuration-information.view-model";
import {GetPermissionUseCase} from "@app/core/permission/application/get-permission.use-case";
import {Permission} from "@app/core/permission/domain/permission";
import I18n from '@app/i18n/i18n';

@Component({
  selector: 'page-configuracion-informacion',
  templateUrl: 'configuracion-informacion.html',
  styleUrls: ['configuracion-informacion.scss'],
  providers: [ConfigurationInformationViewModel]
})
export class ConfiguracionInformacionPage {

  private backButtonListener?: Subscription;
  public appVersion: string;
  public url: string;
  public permissionVersion: number;
  public permissionDate: string;

  constructor(
    private _np: NetworkProvider,
    public _pg: PersistenciaGeneralProvider,
    private ngZone: NgZone,
    public platform: Platform,
    public _hp: HelperProvider,
    private modalCtrl: ModalController,
    private viewModel: ConfigurationInformationViewModel,
    private _gpu: GetPermissionUseCase,
    public i18n: I18n) {
  }

  ionViewWillEnter() {
    this.appVersion = this._pg.versionApp;
    this.url = this._np.urlBases[0];
  }

  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
    this.setPermissionData();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.ngZone.run(() => {
        this._hp.abrirPaginaClick(AppRoutes.configuracionPage, false);
      });
    });
  }

  async showChangeUrl() {
    this.viewModel.logOutAndReload();
  }

  async setPermissionData() {
    let permission: Permission = await this._gpu.execute();
    this.permissionVersion = permission.version;
    this.permissionDate = permission.date;
  }
}
