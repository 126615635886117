<ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-row>
        <ion-col>
            <ion-fab-button [disabled]="!faseActual.existsFasePrev" (click)="movePrev()" color="secondary" size="small"><ion-icon name="lc-icon-arrow-back"></ion-icon></ion-fab-button>
        </ion-col>
        <ion-col>
            <ion-fab-button [disabled]="!faseActual.existsFaseNext" (click)="moveNext()" color="secondary" size="small"><ion-icon name="lc-icon-arrow-forward"></ion-icon></ion-fab-button>
        </ion-col>
    </ion-row>
</ion-fab>
