import { Component } from '@angular/core';
import { ElementoTabProvider } from '../../services/elemento-tab/elemento-tab.provider';
import { FileProvider } from '../../services/file/file.provider';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';

@Component({
  selector: 'elemento-tab-encabezado',
  templateUrl: 'elemento-tab-encabezado.html',
  styleUrls: ['elemento-tab-encabezado.scss']
})
export class ElementoTabEncabezadoComponent {

  elementoPlanEmergencia: string;
  elementoCritico: string;

  imagenElementoNormalizada: string;

  constructor(public _etp: ElementoTabProvider,
              public _html: HtmlUtilsProvider,
              public _translate: TranslateProvider,
              public _fp: FileProvider,
              public _pg: PersistenciaGeneralProvider) {

    //elemento
    const route = `${this._fp.pathDestinoGemeIcones}/${this._etp.elemento.nomFitxerIconaElement}`;
    this._pg.getImageToDB(route).then((imageIcon) => {
      this.imagenElementoNormalizada = imageIcon;
    });

    //traduccion textos
    this.elementoPlanEmergencia = this._translate.traducir("txElementoPlanEmergencia");
    this.elementoCritico = this._translate.traducir("txElementoCritico");
  }

}
