import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ElementoTabProvider} from '@app/services/elemento-tab/elemento-tab.provider';
import {FileProvider} from '@app/services/file/file.provider';
import {HtmlUtilsProvider} from '@app/services/html-utils/html-utils';
import {TranslateProvider} from '@app/services/translate/translate.provider';
import {ElementDataStatePage} from "@app/pages/element-data-state/element-data-state";
import { ListenPermissionUseCase } from '@app/core/permission/application/listen-permission.use-case';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';
import { Directory } from '@capacitor/filesystem';

@Component({
  selector: 'page-elemento-datos',
  templateUrl: 'elemento-datos.html',
  styleUrls: ['elemento-datos.scss']
})
export class ElementoDatosPage {

  imagenElementoEstado: string;
  elementDate?: string;
  firstTime = true;

  constructor(
    public _fp: FileProvider,
    public _html: HtmlUtilsProvider,
    public _translate: TranslateProvider,
    public modalCtrl: ModalController,
    public _etp: ElementoTabProvider,
    public lpu: ListenPermissionUseCase,
    public _pg: PersistenciaGeneralProvider
    ) {
  }

  ionViewDidEnter() {
    this._html.normalizarImageSrcIndexed(this._fp.pathDestinoGemeEstats + "/" + this._etp.elemento.nomFitxerLocalEstatElement, Directory.Documents).then((imageIcon) => {
      this.imagenElementoEstado = imageIcon;
    });
  }

  async editState() {
    if (this._etp.editando) {
      // Store the date of the element in case to know it value before the user changes it
      // Each time this screen is opened elementDate is undefined, so it refreshes the value
      // Perfomr the check with a boolean to avoid because elementDate can be undefined
      if (this.firstTime) {
        this.elementDate = this._etp.elemento.dataHoraEstatElement;
        this.firstTime = false;
      }
      const modal = await this.modalCtrl.create({
        component: ElementDataStatePage,
        componentProps: {
          stateElement: this._etp.elemento.idTipologiaEstatElement,
          ogElementDate: this.elementDate,
          dateElement: this._etp.elemento.dataHoraEstatElement,
          observationsElement: this._etp.elemento.observacionsEstat
        }
      })
      modal.present();
    }
  }
}
