<ion-header>
    <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
    <respuesta-fase-content [respuestaPageActions]="this">
        <lc-input-number *ngIf="constants.PARTES_RESPUESTA_ENTERO === faseActual.fase.tipusRespostaFase" placeholder="txNumeroEntero" [decimal]="false" [negative]="true" [(value)]="valorInicial"></lc-input-number>
        <lc-input-number *ngIf="constants.PARTES_RESPUESTA_ENTERO_NO_NEGATIVO === faseActual.fase.tipusRespostaFase" placeholder="txNumeroEnteroNoNegativo" [decimal]="false" [negative]="false" [(value)]="valorInicial"></lc-input-number>
        <lc-input-number *ngIf="constants.PARTES_RESPUESTA_DECIMAL === faseActual.fase.tipusRespostaFase" placeholder="txNumeroDecimal" [decimal]="true" [negative]="true" [(value)]="valorInicial"></lc-input-number>
        <lc-input-number *ngIf="constants.PARTES_RESPUESTA_DECIMAL_NO_NEGATIVO === faseActual.fase.tipusRespostaFase" placeholder="txNumeroDecimalNoNegativo" [decimal]="true" [negative]="false" [(value)]="valorInicial"></lc-input-number>
    </respuesta-fase-content>
</ion-content>

<ion-footer>
    <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
