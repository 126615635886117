import { HttpLC } from '../http-lc/http-lc';
import { Injectable } from '@angular/core';
import { PostParams } from '../../interfaces/ajax.interface';
import { NetworkProvider } from '../network/network.provider';
import { PARAM_OP, NO_CONEXION, ERROR_DE_RED_NO_IDENTIFICADO, ERROR_DESCONOCIDO_SERVIDOR, ERROR_HA_DE_ACTUALITZAR, ERROR_CONSULTANDO_WS} from '../../config/constantes';
import { Observable } from 'rxjs';
import { TranslateProvider } from '../translate/translate.provider';
import { PersistenciaGeneralProvider } from '../persistencia-configuracion/persistencia-general.provider';
import { Platform } from '@ionic/angular';
import { UpdateAppProvider } from '../update-app/update-app.provider';

export class ErrorConsulta{
  categoria: string = null;
  mensaje: string = null;

  constructor(categoria, mensaje ) {
    this.categoria = categoria;
    this.mensaje= mensaje;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AjaxClientProvider {

  constructor(public httpLC: HttpLC,
              private _network: NetworkProvider,
              public _pg: PersistenciaGeneralProvider,
              private platform: Platform,
              public _update: UpdateAppProvider,
              private _translate: TranslateProvider) {
  }


  consultarWS(opParam: string, parameters: PostParams[], timeoutSeg: number, customUrl: String = '', customMsg: string = ''): Observable<any>{
    return new Observable((observer) => {

      if(!this._pg.workingOffline && !this._network.appOnline && !this._network.detectoDesconexion){
        const _thisCtx = this;
        setTimeout(async function() {
          await _thisCtx._network.ping();
          observer.complete();
        }, 5000);
      }else if (!this._network.appOnline){
        const errConsulta = this.getErrorMessage(NO_CONEXION, this._translate.traducir(customMsg > '' ? customMsg : "txNoConexionAjaxClient"));
          observer.error(errConsulta);
          observer.complete();
      }else{
        let url = this._network.urlConfiguracion ? this._network.urlConfiguracion : this._network.baseUrlOk;
        if(customUrl > ''){
          url = url + customUrl;
        }
        const data = new FormData();
        data.append(PARAM_OP, opParam);
        for (const param of parameters) {
          data.append(param.nombre, param.valor);
        }

        /*let options = {
          rejectUnauthorized: false ,
          insecure: true,
          headers: new HttpHeaders(
              { 'rejectUnauthorized': 'false',
              'Content-Type': 'application/json' })
          };*/
        // console.log("llamada url: " + url + " data: ");
        // console.log(data);
        const obsPost = this.httpLC.post(url, data, timeoutSeg);
        obsPost.subscribe(
          (res: any) => {
            if (res.error)  {
              const errConsulta = this.getErrorMessage(res.error.categoria, res.error.missatge > '' ? res.error.missatge : ERROR_DESCONOCIDO_SERVIDOR);
              // console.log('Error devuelto por WS', errConsulta.categoria + " " + errConsulta.mensaje);
              observer.error(errConsulta);
              observer.complete();

            }else{

              if(res.potActualitzar){
                this._update.potActualitzar = true;
              }
              observer.next(res);
              observer.complete();
            }
          },
          (err) => {
            if (err > ""){
              const errConsulta = new ErrorConsulta(ERROR_CONSULTANDO_WS, err); //err.statusText
              observer.error(errConsulta);
              observer.complete();
            }else{
              const errConsulta = new ErrorConsulta(ERROR_DE_RED_NO_IDENTIFICADO, "txSinConexion"); //err.statusText
              observer.error(errConsulta);
              observer.complete();
            }
          }
        )
      }
      return { unsubscribe() { } };
    });
  }

  private getErrorMessage(category: string, msg: string ){
    switch(category){
      case NO_CONEXION:  {
        return new ErrorConsulta(category, msg);
      }
      case ERROR_DE_RED_NO_IDENTIFICADO: {
        return new ErrorConsulta(category, msg);
      }
      case ERROR_HA_DE_ACTUALITZAR: {
        const mensaje = this._translate.traducir("txActualizarAppDebe");
        this._update.shouldUpdate();
        // console.log("retorno por ha de actualizar ---> ya seteo errorConsulta");
        return new ErrorConsulta(mensaje, mensaje);
      }
      default: {
        return new ErrorConsulta(category, msg);
      }
    }
  }

}
