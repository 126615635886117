// TODO: quitar esto que fue creado por App2u, creo que se podría dejar de utilizar pasando solamente http
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {catchError, timeout} from 'rxjs/operators';
import {FullResponseHandlerErrorMapper} from './full-response-handler-error.mapper';
import { InfrastructureError } from '@app/app2u/domain-utils/infrastructure.error';
import { AuthorizationError } from '@app/app2u/domain-utils/authorization.error';
import { HandlerParameters } from '@app/app2u/handlers/base-handler.parameter';

type ResponseTypes = any | InfrastructureError | AuthorizationError;

@Injectable({
  providedIn: 'root',
})
export class FullResponseHandler{

  constructor(protected http: HttpClient){
  }

  execute(params: HandlerParameters): Observable<ResponseTypes> {

    const requestOptions = {
      body: params.body,
      headers: params.header,
      params: params.urlParams,
      responseType: params.responseType,
      observe: 'response' as const,
    };

    return this.http.request(params.method, params.url, requestOptions);
  }

  get(params: HandlerParameters): Observable<ResponseTypes> {
    return this.execute(params);
  }

  post(params: HandlerParameters, timeoutSeg: number, timeoutErrorMsg: string, defualtErrorMsg: string): Observable<ResponseTypes> {
    if (timeoutSeg > 0) {
      return this.execute(params).pipe(timeout(1000 * timeoutSeg), catchError( err =>
        FullResponseHandlerErrorMapper.handleError(err, timeoutErrorMsg, defualtErrorMsg)));
    } else {
      return this.execute(params).pipe(catchError( err =>
        FullResponseHandlerErrorMapper.handleError(err, timeoutErrorMsg, defualtErrorMsg)));
    }
  }
}
