export namespace FileUtils {
  /**
   * Get the name of the file with the extension from a path.
   * If there are no slashes in the path, it returns the full path as the name.
   * @param path - The file path
   */
  export function getFileName(path: string): string {
    const pathParts = path.split('/');
    return pathParts[pathParts.length - 1];
  }

  /**
   * Get the extension of the file from a path.
   * If the file does not have an extension, it throws an error.
   * @param path - The file path
   */
  export function getExtension(path: string): string | null {
    const fileName = getFileName(path);
    const lastDotIndex = fileName.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName.slice(lastDotIndex + 1) : null;
  }
}
