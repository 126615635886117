import {HttpErrorResponse} from '@angular/common/http';
import {Observable, TimeoutError} from 'rxjs';

export class FullResponseHandlerErrorMapper {

  public static handleError(errorResponse: HttpErrorResponse, timeoutErrorMsg: string, defualtErrorMsg: string): Observable<never> | Observable<any> {
    if (errorResponse instanceof TimeoutError) {
      throw timeoutErrorMsg;
    } else {
      throw 'status_code: ' + errorResponse.status + ' - ' + defualtErrorMsg;
    }
  }
}
