<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-title>
            {{ i18n.txIdentificacionAccesoLogin | translate }}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="loginClick()">
                    <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
                </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ion-list>
        <ion-item class="ion-padding-top">
            <ion-label position="floating">{{ i18n.txUsuarioLogin | translate }}</ion-label>
            <ion-input [(ngModel)]="usuario" type="text" autofocus="true" (keyup.enter)="_htmlUtils.asignarFocus(passwordLogin)"></ion-input>
        </ion-item>


        <ion-item class="ion-margin-top">
            <ion-label position="floating">{{ i18n.txContrasenaLogin | translate }}</ion-label>
            <ion-input #passwordLogin [(ngModel)]="password" type="password" (keyup.enter)="loginClick()"></ion-input>
        </ion-item>

        <ion-item lines="none" class="ion-margin-top">
            <ion-label>{{ i18n.txRecordarUsuarioLogin | translate }}</ion-label>
            <ion-toggle [(ngModel)]="recordarUsuarioPass" (ionChange)="toggleValidarReset()" slot="end"></ion-toggle>
        </ion-item>

        <ion-item lines="none" class="ion-margin-top">            
            <ion-text slot="end"><small>{{i18n.txAppVersion | translate}} {{appVersion()}}</small></ion-text>
        </ion-item>
    </ion-list>



</ion-content>
