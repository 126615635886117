export class StructTree {
    idGemeElement: number;
    codiElement: string;
    nomElement: string;
    nomFitxerIcona: string;
    subMenus: StructTree[] = [];
    expanded: boolean;
    checked= false;
    iconSub: string;
    showIconSub = true;
    parents: number[] = [];

    constructor(
        idGemeElement: number,
        codiElement: string,
        nomElement: string,
        nomFitxerIcona: string,
        expanded: boolean
    ) {
        this.idGemeElement = idGemeElement;
        this.codiElement = codiElement;
        this.nomElement = nomElement;
        this.nomFitxerIcona = nomFitxerIcona;
        this.expanded = expanded;
        this.iconSub = this.getIcon();
    }

    toggle() {
        this.expanded = !this.expanded;
        this.iconSub = this.getIcon();
    }

    check() {
        const newState = !this.checked;
        this.checked = newState;
        this.checkRecursive(newState);
    }

    checkRecursive(state) {
        this.subMenus.forEach(d => {
            d.checked = state;
            d.checkRecursive(state);
        });
    }

    expandRecursive(parent) {
        this.subMenus.forEach(d => {
            d.expanded = this.parents.some(x => x === d.idGemeElement);
            d.getIcon();
            d.expandRecursive(parent);
        });
    }

    getIcon() {
        if (this.showIconSub) {
            if (this.expanded) {
                return 'remove-circle';
            }
            return 'add-circle';
        }
        return null;
    }
}
