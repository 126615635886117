import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncryptProvider{

constructor() {

}


 encryptWithXor(input: any, key: any){
    let keyXor = key
    let c = '';
    while (keyXor.length < input.length) {
      keyXor += keyXor;
    }
    for(let i=0; i<input.length; i++) {
      const value1 = input[i].charCodeAt(0);
      const value2 = keyXor[i].charCodeAt(0);

      const xorValue = value1 ^ value2;
      let xorValueAsHexString = xorValue.toString(16);
      if (xorValueAsHexString.length < 2) {
        xorValueAsHexString = "0" + xorValueAsHexString;
      }
      c += xorValueAsHexString;
    }
    return c;
  }

  decryptWithXor(input: any, key: any){
    let keyXor = key;
    let c = '';
    while (keyXor.length < input.length / 2) {
      keyXor += keyXor;
    }
    for(let i=0; i<input.length; i+=2){
      const hexValueString = input.substring(i, i+2);
      const value1 = parseInt(hexValueString, 16);
      const value2 = keyXor[i/2].charCodeAt(0);
      const xorValue = value1 ^ value2;
      c += String.fromCharCode(xorValue);
    }
    return c;
  }
}
