import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { FaseActualProvider } from '../../services/geme/fase-actual.provider';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';

@Component({
  templateUrl: 'respuesta-lista-opciones.html',
  styleUrls: ['respuesta-lista-opciones.scss']
})
export class RespuestaListaOpcionesPage implements RespuestaPageActions {

  valorEdicio: string;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    public translate: TranslateProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public faseActual: FaseActualProvider,
  ) {

    if (faseActual.valorObservacionsEdicio.valor > "") {
      this.valorEdicio = faseActual.valorObservacionsEdicio.valor;
    } else {
      this.valorEdicio = "-1";
      if (!this.faseActual.dataHoraExecucioParte && !faseActual.fase.editadaEnApp) {
        // La fase no ha sido editada antes por tanto si hay opción por defecto la asigno:
        this.faseActual.fase.opcionsFases.forEach(opt => {
          if (opt.seleccionat === "1") {
            this.valorEdicio = String(opt.numeroOpcio);
          }
        });
      }
    }

  }

  checkValorIsOk(): boolean {
    return true;
  }

  setValorFaseActual() {
    this.faseActual.valorObservacionsEdicio.valor = this.valorEdicio === "-1" ? null : this.valorEdicio;
  }

}
