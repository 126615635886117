import {Injectable} from "@angular/core";
import {PartDAO} from "@app/core/part/domain/part.DAO";
import {GetPartsWebService} from "@app/core/part/domain/get-parts.web-service";

@Injectable({
  providedIn: "root"
})
export class SyncNotPlannedPartsUseCase {
  constructor(
    private partDAO: PartDAO,
    private getPartWebService: GetPartsWebService,
  ) {
  }

  async execute(partsIDs: number[]): Promise<string[]> {
    if (!partsIDs || partsIDs.length <= 0) return [];
    const request = await this.getPartWebService.execute(partsIDs).toPromise();
    const parts = request.partesNoPlan;
    const images = request.imatgesFases;
    for (const part of parts) {
      await this.partDAO.save(part);
    }
    return images;
  }
}
