import {Component} from '@angular/core';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {ParteMaterialPage} from '../parte-material/parte-material';
import {ParteMaterialAddModalPage} from '../parte-material-add-modal/parte-material-add-modal';


@Component({
  selector: 'page-parte-info',
  templateUrl: 'parte-info.html',
  styleUrls: ['parte-info.scss']
})
export class ParteInfoPage {
  constructor(public navCtrl: NavController,
              public _ptp: PartesTabProvider,
              public _ui: UiMessagesProvider,
              public _translate: TranslateProvider,
              public modalCtrl: ModalController,
              public alertCtrl: AlertController) {

    // console.log("Parte: ", this._ptp.parte);
  }

  ionViewDidEnter() {
    // console.log('ionViewDidEnter ParteInfoPage');
  }

  async showMaterialModal(material: any, idx: number){
    if(this._ptp.editando){
      const modal = await this.modalCtrl.create({
        component: ParteMaterialPage,
        componentProps: {
          cantidad: material.quantitatUtilitzada,
          materialCodiDescripcio: material.codi + " - " + material.descripcio,
          indice: idx
        }
      })
      modal.present();
    }
  }

  async showAddMaterialModal(){
    if(this._ptp.editando){
      const modal = await this.modalCtrl.create({
        component: ParteMaterialAddModalPage,
        componentProps: {
          descripcion: null,
          respuesta: null
        }
      })
      modal.present();

      modal.onDidDismiss().then(
         ({data}) => {
          const dataReturn = data;
          if(dataReturn){
            if(this._ptp.parte.materials.some(i => i.idMaterial === dataReturn.idMaterial)){
              this._ui.showToastDefault("txMaterialYaExiste");
            }else{
              this._ptp.parteHasDifference = true;
              this._ptp.parte.materials.push(dataReturn);
              this.showMaterialModal(dataReturn, this._ptp.parte.materials.length - 1);
            }
            this._ptp.setDraft();
          }else{
            // console.log("Se cerró el modal sin parametros");
          }
        }
      );
    }
  }

}
