import {PartesTabEncabezadoComponent} from './components/partes-tab-encabezado/partes-tab-encabezado';
import {ParteMaterialAddModalPage} from './pages/parte-material-add-modal/parte-material-add-modal';
import {ParteAnotacionPage} from './pages/parte-anotacion/parte-anotacion';
import {RespuestaCorrectoIncorrectoPage} from './pages/respuesta-correcto-incorrecto/respuesta-correcto-incorrecto';
import {Chooser} from '@awesome-cordova-plugins/chooser/ngx';
import {ParteAnotacionesPage} from './pages/parte-anotaciones/parte-anotaciones';
import {ParteDatosAdicionalesPage} from './pages/parte-datos-adicionales/parte-datos-adicionales';
import {ParteResultadoPage} from './pages/parte-resultado/parte-resultado';
import {ParteFasesPage} from './pages/parte-fases/parte-fases';
import {ConfiguracionPage} from './pages/configuracion/configuracion';
import {ConfiguracionInformacionPage} from './pages/configuracion-informacion/configuracion-informacion';
import {PartesTreeviewModalPage} from './pages/partes-treeview-modal/partes-treeview-modal';
import {PartesSearchModalPage} from './pages/partes-search-modal/partes-search-modal';
import {ElementoTabEncabezadoComponent} from './components/elemento-tab-encabezado/elemento-tab-encabezado';
import {TreeViewComponent} from './components/tree-view/tree-view';
import {ElementoDatosDescripcionPage} from './pages/elemento-datos-descripcion/elemento-datos-descripcion';
import {ImageModalPage} from './pages/image-modal/image-modal';
import {SyncConfirmPage} from './pages/sync-confirm/sync-confirm';
import {CargarConfiguracionModalPage} from './pages/cargar-configuracion-modal/cargar-configuracion-modal';
import {LoginPage} from './pages/login/login';
import {HomePage} from './pages/home/home';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {APP_INITIALIZER, ErrorHandler, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouteReuseStrategy, RouterModule} from '@angular/router';

import {IonicModule, IonicRouteStrategy, Platform} from '@ionic/angular';
import {Network} from '@awesome-cordova-plugins/network/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {File} from '@awesome-cordova-plugins/file/ngx';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CommonModule, DatePipe, Location} from '@angular/common';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {FileTransfer} from '@awesome-cordova-plugins/file-transfer/ngx';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {IonicStorageModule, Storage} from '@ionic/storage-angular';
import {Insomnia} from '@awesome-cordova-plugins/insomnia/ngx';
import {CargarConfiguracionPage} from './pages/cargar-configuracion/cargar-configuracion';
import {DebugPage} from './pages/debug/debug';
import {ConnectionStatusPage} from './pages/connection-status/connection-status';
import {FormsModule} from '@angular/forms';
import {InventarioPage} from './pages/inventario/inventario';
import {ElementoTabsPage} from './pages/elemento-tabs/elemento-tabs';
import {ElementoImagenesPage} from './pages/elemento-imagenes/elemento-imagenes';
import {ElementoDatosPage} from './pages/elemento-datos/elemento-datos';
import {ElementoAnotacionesPage} from './pages/elemento-anotaciones/elemento-anotaciones';
import {ElementoAnotacionPage} from './pages/elemento-anotacion/elemento-anotacion';
import {PartesPage} from './pages/partes/partes';
import {PartesConfigModalPage} from './pages/partes-config-modal/partes-config-modal';
import {ParteTabsPage} from './pages/parte-tabs/parte-tabs';
import {ListaOtrosPartesPage} from './pages/lista-otros-partes/lista-otros-partes';
import {ParteInfoPage} from './pages/parte-info/parte-info';
import {DocumentViewer} from '@awesome-cordova-plugins/document-viewer/ngx';
import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import {ParteMaterialPage} from './pages/parte-material/parte-material';
import {LcInputNumberComponent} from './components/lc-input-number/lc-input-number';
import {ParteResultadoModalPage} from './pages/parte-resultado-modal/parte-resultado-modal';
import {ParteOperarioAddModalPage} from './pages/parte-operario-add-modal/parte-operario-add-modal';
import {ParteResultadoOperarioPage} from './pages/parte-resultado-operario/parte-resultado-operario';
import {RespuestaEnteroDecimalPage} from './pages/respuesta-entero-decimal/respuesta-entero-decimal';
import {ParteCostesExternosPage} from './pages/parte-costes-externos/parte-costes-externos';
import {SwipeTabDirective} from './directives/swipe-tab/swipe-tab.directive';
import {RespuestaHechoPage} from './pages/respuesta-hecho/respuesta-hecho';
import {RespuestaNoSiPage} from './pages/respuesta-no-si/respuesta-no-si';
import {RespuestaFechaHoraPage} from './pages/respuesta-fecha-hora/respuesta-fecha-hora';
import {RespuestaTiempoPage} from './pages/respuesta-tiempo/respuesta-tiempo';
import {RespuestaListaOpcionesPage} from './pages/respuesta-lista-opciones/respuesta-lista-opciones';
import {RespuestaImagenAdjuntaPage} from './pages/respuesta-imagen-adjunta/respuesta-imagen-adjunta';
import {RespuestaPdfAdjuntoPage} from './pages/respuesta-pdf-adjunto/respuesta-pdf-adjunto';
import {CargarConfiguracionModalQrPage} from './pages/cargar-configuracion-modal-qr/cargar-configuracion-modal-qr';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {infraProviders} from '@app/di/providers';
import {ElementDataStatePage} from '@app/pages/element-data-state/element-data-state';
import {
  GetElementStateTypologiesHttpWebService
} from '@app/core/element/infrastructure/get-element-state-typologies.http-web-service';
import {ElementImagesEditPage} from '@app/pages/element-images-edit/element-images-edit';
import {ParteCreateNotPlannedPage} from '@app/pages/parte-create-not-planned/parte-create-not-planned';
import {CustomDateSelectorComponent} from './components/custom-date-selector/custom-date-selector.component';
import { Sentry } from 'src/sentry';
import { PipesModule } from './pipes/pipes.module';
import { FormatLinkPipe } from './pipes/imagen/format-link.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { FormatLinkAsyncPipe } from './pipes/imagen/format-link-async.pipe';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { ElementIndividualComponent } from './components/geme/element-individual/element-individual.component';
import { ElementsBlocFaseComponent} from './components/geme/elements-bloc-fase/elements-bloc-fase.component';
import { RespuestaFaseContentComponent } from './components/geme/respuesta-fase-content/respuesta-fase-content.component';
import { RespuestaFaseHeaderComponent } from './components/geme/respuesta-fase-header/respuesta-fase-header.component';
import { RespuestaFaseFooterComponent } from './components/geme/respuesta-fase-footer/respuesta-fase-footer.component';
import { RespuestaTituloPage } from './pages/respuesta-titulo/respuesta-titulo';
import { RespuestaTextoPage } from './pages/respuesta-texto/respuesta-texto';
import { RespuestaSubtituloPage } from './pages/respuesta-subtitulo/respuesta-subtitulo';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');


// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    //Pages (Inventario)
    HomePage,
    CargarConfiguracionPage,
    CargarConfiguracionModalQrPage,
    LoginPage,
    DebugPage,
    CargarConfiguracionModalPage,
    SyncConfirmPage,
    ConnectionStatusPage,
    InventarioPage,
    ElementoTabsPage,
    ElementoImagenesPage,
    ElementoDatosPage,
    ElementoAnotacionesPage,
    ElementoAnotacionPage,
    ImageModalPage,
    ElementoDatosDescripcionPage,
    ElementDataStatePage,
    ElementImagesEditPage,
    //Pages (Partes)
    PartesPage,
    PartesConfigModalPage,
    PartesSearchModalPage,
    PartesTreeviewModalPage,
    ListaOtrosPartesPage,
    ParteTabsPage,
    ParteInfoPage,
    ParteFasesPage,
    ParteResultadoPage,
    ParteDatosAdicionalesPage,
    ParteAnotacionesPage,
    RespuestaCorrectoIncorrectoPage,
    ParteAnotacionPage,
    ParteMaterialAddModalPage,
    ParteMaterialPage,
    ParteResultadoModalPage,
    ParteOperarioAddModalPage,
    ParteResultadoOperarioPage,
    RespuestaEnteroDecimalPage,
    PartesTabEncabezadoComponent,
    ParteCostesExternosPage,
    ParteCreateNotPlannedPage,
    RespuestaHechoPage,
    RespuestaNoSiPage,
    RespuestaFechaHoraPage,
    RespuestaTiempoPage,
    RespuestaListaOpcionesPage,
    RespuestaImagenAdjuntaPage,
    RespuestaPdfAdjuntoPage,
    RespuestaTituloPage,
    RespuestaTextoPage,
    RespuestaSubtituloPage,
    //Page (Configuracion)
    ConfiguracionPage,
    ConfiguracionInformacionPage,
    //Components
    TreeViewComponent,
    ElementoTabEncabezadoComponent,
    LcInputNumberComponent,
    //Directives
    SwipeTabDirective,
    CustomDateSelectorComponent,
    FormatLinkAsyncPipe,
    SplashScreenComponent,
    ElementIndividualComponent,
    ElementsBlocFaseComponent,
    RespuestaFaseContentComponent,
    RespuestaFaseHeaderComponent,
    RespuestaFaseFooterComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      backButtonText: '',
      backButtonIcon: 'arrow-back',
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    FormsModule,
    RouterModule,
    PipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.errorHandler,
    },
    {
      provide: Sentry.traceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // this is intended to be empty, we do not load any function we just load the trace service
      },
      deps: [Sentry.traceService],
      multi: true,
    },
    Location,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    Network,
    File,
    FileOpener,
    FileTransfer,
    AppVersion,
    InAppBrowser,
    Device,
    Insomnia,
    Storage,
    Platform,
    Chooser,
    DocumentViewer,
    Camera,
    BarcodeScanner,
    infraProviders,
    DatePipe,
    GetElementStateTypologiesHttpWebService,
    FormatLinkPipe,
    SafeHtmlPipe
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
