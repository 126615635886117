<ion-content class="ion-no-padding">
    <partes-tab-encabezado [isEdit]="_ptp.editando"></partes-tab-encabezado>

    <ion-card class="card-no-margin-horiz">
        <ion-card-header>
            {{'txParteFases' | translate}}
        </ion-card-header>
        <ion-card-content>
            <ion-list>
                <ng-container *ngFor="let fase of _ptp.parte.fases; let i = index">
                    <ion-item
                        *ngIf="fase.blocElement > '' && (i === 0 || fase.blocElement !== _ptp.parte.fases[i - 1].blocElement) "
                        lines="none" class="phase">
                        <ion-label>
                            <elements-bloc-fase [blocElement]="fase.blocElement"
                                [elements]="_ptp.parte.blocsElements[fase.blocElement]"></elements-bloc-fase>
                        </ion-label>
                    </ion-item>
                    <ion-item lines="none" class="phase" (click)="goToRespuesta($event, fase, i, true)">
                        <ion-label>
                            <table class="tabla-fixed" [ngClass]="{'phase-in-block' : fase.blocElement}">
                                <tr>
                                    <td style="color: #000 !important; width:3em;text-align: left;vertical-align: top"
                                        rowspan="10">
                                        <strong>{{ fase.strOrdre }}</strong>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="fase.element">
                                            <element-individual [element]="fase.element"></element-individual>
                                        </ng-container>

                                        <label *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_TITULO" class="text-title-fase" style="color: #000 !important;">
                                          {{ fase.descripcio }}
                                        </label>

                                        <label *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_SUBTITULO" class="text-subtitle-fase" style="color: #000 !important;">
                                          {{ fase.descripcio }}
                                        </label>

                                        <label *ngIf="fase.tipusRespostaFase !== constants.PARTES_RESPUESTA_TITULO && fase.tipusRespostaFase !== constants.PARTES_RESPUESTA_SUBTITULO" style="color: #000 !important;">{{ fase.descripcio }}</label>
                                    </td>
                                </tr>
                                <tr *ngIf="fase.nomFitxerLocalImatge>''">
                                  <div class="image-with-loader">
                                    <ion-spinner class="mt-4" [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'" *ngIf="isLoading"></ion-spinner>
                                    <img
                                      [src]="fase.nomFitxerLocalImatge | formatLinkAsync:'3' | async"
                                      #preview
                                      (load)="onImageDidLoad()"
                                      onerror="this.onerror=null;this.src='assets/imgs/no_image.png';">
                                  </div>
                                </tr>
                                <tr>
                                    <td style="padding-top:8px;padding-left:13px" class="text-resultado-fase">
                                        <label
                                            [ngClass]="{'no-tablet': fase.tipusRespostaFase === constants.PARTES_RESPUESTA_CORRECTO_INCORRECTO && _ptp.editando}"
                                            *ngIf="fase.tipusRespostaFase !== constants.PARTES_RESPUESTA_PDF_ADJUNTO">{{
                                            'txPartesResultadoFase' | translate }}: </label>
                                        <ion-icon name="lc-icon-clip"
                                            [ngClass]="{'with-pdf': fase.valor > '' , 'without-pdf': (!(fase.valor > '')) } "
                                            *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_PDF_ADJUNTO"
                                            slot="start"></ion-icon>

                                        <span *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_HECHO">

                                            <span [ngClass]="{'no-tablet': _ptp.editando}">
                                                <span *ngIf="fase.valor === '1'"
                                                    style="color:#32a800">{{'txPartesRespuestaHecho' |
                                                    translate}}</span>
                                                <em *ngIf="fase.valor !== '1'"
                                                    class="no-value-color">{{'txPartesRespuestaNoHecho' |
                                                    translate}}</em>
                                            </span>
                                            <span id="hecho-visible" class="yes-tablet" *ngIf="_ptp.editando">
                                                <ion-radio-group [(ngModel)]="fase.valor" [compareWith]="compareValor">
                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="hecho">{{'txPartesRespuestaHecho' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorCorrecto"
                                                                color-checked="primary" value="1"></ion-radio>
                                                        </ion-item>
                                                    </ion-col>

                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="no-hecho">{{'txPartesRespuestaNoHecho' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorSinAsignar"
                                                                color-checked="primary" value=""></ion-radio>
                                                        </ion-item>
                                                    </ion-col>
                                                    <ion-col style="padding: 0px;">
                                                    </ion-col>
                                                </ion-radio-group>
                                            </span>
                                        </span>

                                        <span
                                            *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_CORRECTO_INCORRECTO">

                                            <span [ngClass]="{'no-tablet': _ptp.editando}">
                                                <span *ngIf="fase.valor === '1'"
                                                    style="color:#32a800">{{'txPartesEstadoCorrecto' |
                                                    translate}}</span>
                                                <span *ngIf="fase.valor === '0'"
                                                    style="color:#ff4242">{{'txPartesEstadoIncorrecto' |
                                                    translate}}</span>
                                                <em *ngIf="fase.valor !== '0' && fase.valor !== '1'"
                                                    class="no-value-color">{{'txPartesRespuestaSinValor' |
                                                    translate}}</em>
                                            </span>
                                            <span id="correcto-visible" class="yes-tablet" *ngIf="_ptp.editando">
                                                <ion-radio-group [(ngModel)]="fase.valor" [compareWith]="compareValor">
                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="correcto">{{'txPartesEstadoCorrecto' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorCorrecto"
                                                                color-checked="primary" value="1"></ion-radio>
                                                        </ion-item>
                                                    </ion-col>

                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="incorrecto">{{'txPartesEstadoIncorrecto' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorIncorrecto"
                                                                color-checked="primary" value="0"></ion-radio>
                                                        </ion-item>
                                                    </ion-col>

                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="sin-asignar">{{'txSinAsignarStr' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorSinAsignar"
                                                                color-checked="primary" value=""></ion-radio>
                                                        </ion-item>
                                                    </ion-col>
                                                </ion-radio-group>
                                            </span>

                                        </span>

                                        <span *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_SI_NO">
                                            <span [ngClass]="{'no-tablet': _ptp.editando}">
                                                <span *ngIf="fase.valor === '1'" style="color:#32a800">{{'txSiStr' |
                                                    translate}}</span>
                                                <span *ngIf="fase.valor === '0'" style="color:#ff4242">{{'txNoStr' |
                                                    translate}}</span>
                                                <em *ngIf="fase.valor !== '0' && fase.valor !== '1'"
                                                    class="no-value-color">{{'txPartesRespuestaSinValor' |
                                                    translate}}</em>
                                            </span>

                                            <span id="sino-visible" class="yes-tablet" *ngIf="_ptp.editando">
                                                <ion-radio-group [(ngModel)]="fase.valor" [compareWith]="compareValor">
                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="positivo">{{'txSiStr' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorCorrecto"
                                                                color-checked="primary" value="1"></ion-radio>
                                                        </ion-item>
                                                    </ion-col>

                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="negativo">{{'txNoStr' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorIncorrecto"
                                                                color-checked="primary" value="0"></ion-radio>
                                                        </ion-item>
                                                    </ion-col>
                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="sin-asignar">{{'txSinAsignarStr' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorSinAsignar"
                                                                color-checked="primary" value=""></ion-radio>
                                                        </ion-item>
                                                    </ion-col>
                                                </ion-radio-group>
                                            </span>
                                        </span>

                                        <span *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_NO_SI">
                                            <span [ngClass]="{'no-tablet': _ptp.editando}">
                                                <span *ngIf="fase.valor === '1'" style="color:#ff4242">{{'txSiStr' |
                                                    translate}}</span>
                                                <span *ngIf="fase.valor === '0'" style="color:#32a800">{{'txNoStr' |
                                                    translate}}</span>
                                                <em *ngIf="fase.valor !== '0' && fase.valor !== '1'"
                                                    class="no-value-color">{{'txPartesRespuestaSinValor' |
                                                    translate}}</em>
                                            </span>

                                            <span id="nosi-visible" class="yes-tablet" *ngIf="_ptp.editando">
                                                <ion-radio-group [(ngModel)]="fase.valor" [compareWith]="compareValor">
                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="negativo">{{'txSiStr' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorIncorrecto"
                                                                color-checked="primary" value="1"></ion-radio>
                                                        </ion-item>
                                                    </ion-col>

                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="positivo">{{'txNoStr' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorCorrecto"
                                                                color-checked="primary" value="0"></ion-radio>
                                                        </ion-item>
                                                    </ion-col>
                                                    <ion-col style="padding: 0px;">
                                                        <ion-item lines="none">
                                                            <ion-label class="sin-asignar">{{'txSinAsignarStr' |
                                                                translate}}</ion-label>
                                                            <ion-radio mode="md" (ionSelect)="parteHasDifference()"
                                                                slot="start" color="colorSinAsignar"
                                                                color-checked="primary" value=""></ion-radio>
                                                        </ion-item>
                                                    </ion-col>
                                                </ion-radio-group>
                                            </span>
                                        </span>

                                        <span
                                            *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_ENTERO ||
                                                    fase.tipusRespostaFase === constants.PARTES_RESPUESTA_ENTERO_NO_NEGATIVO  ||
                                                    fase.tipusRespostaFase === constants.PARTES_RESPUESTA_DECIMAL  ||
                                                    fase.tipusRespostaFase === constants.PARTES_RESPUESTA_DECIMAL_NO_NEGATIVO">
                                            <span *ngIf="fase.valor > ''" style="color:#333">{{fase.valor}}</span>
                                            <em *ngIf="(!(fase.valor > ''))"
                                                class="no-value-color">{{'txPartesRespuestaSinValor' | translate}}</em>
                                        </span>

                                        <span *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_TIEMPO">
                                            <span *ngIf="fase.valor > ''"
                                                style="color:#333">{{parseRespuestaTiempo(fase.valor)}}</span>
                                            <em *ngIf="(!(fase.valor > ''))"
                                                class="no-value-color">{{'txPartesRespuestaSinValor' | translate}}</em>
                                        </span>

                                        <span *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_FECHA ||
                                    fase.tipusRespostaFase === constants.PARTES_RESPUESTA_FECHA_HORA">
                                            <span *ngIf="fase.valor > ''" style="color:#333">{{fase.valor}}</span>
                                            <em *ngIf="(!(fase.valor > ''))"
                                                class="no-value-color">{{'txPartesRespuestaSinValor' | translate}}</em>
                                        </span>

                                        <span *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_TITULO ||
                                        fase.tipusRespostaFase === constants.PARTES_RESPUESTA_SUBTITULO">
                                            <span *ngIf="fase.valor > ''" style="color:#333">{{fase.valor}}</span>
                                            <span *ngIf="fase.valor > ''" style="color:#333">{{fase.valor}}</span>
                                            <em *ngIf="(!(fase.valor > ''))"
                                                class="no-value-color">{{'txPartesRespuestaSinValor' | translate}}</em>
                                        </span>

                                        <span *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_TEXTO">
                                            <span *ngIf="fase.valor > ''" style="color:#333">{{fase.valor}}</span>
                                            <em *ngIf="(!(fase.valor > ''))"
                                                class="no-value-color">{{'txPartesRespuestaSinValor' | translate}}</em>
                                        </span>

                                        <div
                                            *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_IMAGEN_ADJUNTA">
                                            <img *ngIf="fase.valor > '' && fase.fitxerEnviar" id="previewAdjuntoOpt1"
                                                #previewAdjuntoOpt1 src="{{fase.valorPreview}}"
                                                onerror="this.onerror=null;this.src='assets/imgs/no_image.png';">
                                            <img *ngIf="fase.valor > '' && !fase.fitxerEnviar" id="previewAdjuntoOpt3"
                                                #previewAdjuntoOpt3 [src]="fase.valor | formatLinkAsync:'4' | async"
                                                onerror="this.onerror=null;this.src='assets/imgs/no_image.png';">
                                            <em *ngIf="(!(fase.valor > ''))" class="no-value-color">{{'txSinImagen' |
                                                translate}}</em>
                                        </div>

                                        <span (click)="goToPdf(fase)"
                                            *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_PDF_ADJUNTO">
                                            <span class="with-pdf" *ngIf="fase.valor > ''">{{'txConPdfAsignado' |
                                                translate}}</span>
                                            <em *ngIf="(!(fase.valor > ''))" class="no-value-color">{{'txSinPdfAsignado'
                                                | translate}}</em>
                                        </span>

                                        <span
                                            *ngIf="fase.tipusRespostaFase === constants.PARTES_RESPUESTA_LISTA_OPCIONES">
                                            <span *ngIf="fase.valor > ''">{{fase.nomSeleccioOpcio}}</span>
                                            <em *ngIf="(!(fase.valor > ''))"
                                                class="no-value-color">{{'txPartesRespuestaSinValor' | translate}}</em>
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngIf="fase.observacions>''">
                                    <td style="color: #000 !important; padding-top:13px;padding-left:13px">
                                        <label><strong>{{ 'txPartesObservaciones' | translate}}:
                                            </strong>{{fase.observacions}}</label>
                                    </td>
                                </tr>
                            </table>
                        </ion-label>
                        <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="_ptp.editando"
                            (click)="goToRespuesta($event, fase, i, false)"></ion-icon>
                    </ion-item>
                </ng-container>
            </ion-list>
        </ion-card-content>
    </ion-card>
</ion-content>
