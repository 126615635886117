<ion-content class="ion-no-padding">
  <elemento-tab-encabezado></elemento-tab-encabezado>

  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      {{'txElementoDatosBasicos' | translate}}
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item lines="none">
          <ion-label>
            <table class="tabla-fixed">
              <tr>
                <td class="ion-text-left subtitle">
                  {{'txCodigoSinPuntosStr' | translate}}
                </td>
              </tr>
              <tr>
                <td class="ion-text-left">
                  <span *ngIf="_etp.elemento.codiElement > ''"
                        style="white-space: pre-wrap">{{ _etp.elemento.codiElement }}</span>
                  <span class="sin-valor" *ngIf="!(_etp.elemento.codiElement > '')"
                        style="white-space: pre-wrap">{{ 'txSinValorStr' | translate }}</span>
                </td>
              </tr>
            </table>
          </ion-label>
        </ion-item>

        <ion-item lines="none">
          <ion-label>
            <table class="tabla-fixed">
            <tr>
              <td class="ion-text-left subtitle">
                {{'txNombreStr' | translate}}
              </td>
            </tr>
            <tr>
              <td class="ion-text-left">
                <span style="white-space: pre-wrap" [innerHTML]="_etp.elemento.nomElement | safeHtml"></span>
              </td>
            </tr>
          </table>
          </ion-label>
        </ion-item>

        <ion-item lines="none">
          <ion-label>
            <table class="tabla-fixed">
              <tr>
                <td class="ion-text-left subtitle">
                  {{'txDescripcion' | translate}}
                </td>
              </tr>
              <tr>
                <td class="ion-text-left">
                  <span style="white-space: pre-wrap" [innerHTML]="_etp.elemento.descripcioElement | safeHtml"></span>
                </td>
              </tr>
            </table>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="(lpu.execute() | async).showObjectTypesElements" lines="none">
          <ion-label>
            <table class="tabla-fixed">
              <tr>
                <td class="ion-text-left subtitle">
                  {{'txTypeCharacteristics' | translate}}
                </td>
              </tr>
              <tr>
                <td class="ion-text-left">
                  <ion-item-group>
                    <ion-label [innerHTML]="_etp.elemento.nomTipologiaObjecte | safeHtml">
                    </ion-label>
                    <ion-item lines="none" *ngFor="let type of _etp.elemento.caracteristiquesObjecte">
                      <ion-label>
                        <div style="white-space: pre-wrap">
                          <span [innerHTML]="type.nomCaracteristica"></span>: <span [innerHTML]="type.valor | safeHtml"></span>
                        </div>
                      </ion-label>
                    </ion-item>
                    <span class="sin-valor" *ngIf="!(_etp.elemento.caracteristiquesObjecte.length > 0)"
                      style="white-space: pre-wrap">{{ 'txWithoutType' | translate }}</span>
                  </ion-item-group>

                </td>
              </tr>
            </table>
          </ion-label>
        </ion-item>

        <ion-item lines="none">
          <ion-label>
            <table class="tabla-fixed">
              <tr>
                <td class="ion-text-left subtitle">
                  {{'txRutaStr' | translate}}
                </td>
              </tr>
              <tr>
                <td class="ion-text-left">
                  <span style="white-space: pre-wrap" [innerHTML]="_etp.elemento.rutaElement | safeHtml"></span>
                </td>
              </tr>
            </table>
          </ion-label>
        </ion-item>

        <ion-item lines="none">
          <ion-label>
            <table class="tabla-fixed">
              <tr>
                <td class="ion-text-left subtitle">
                  {{'txEstadoStr' | translate}}
                </td>
              </tr>

              <div *ngIf="_etp.elemento.nomEstatElement > ''">
                <tr>
                  <td class="ion-text-left" id="imagen-estado">
                    <img class="icon-align-middle" [src]="imagenElementoEstado"
                         onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png' ; ">
                    <strong>{{_etp.elemento.txEstadoParteToResult | translate}}</strong>
                  </td>
                  <td class="ion-text-left">
                    <span style="white-space: pre-wrap">{{_etp.elemento.nomEstatElement}}</span>
                    <span *ngIf="_etp.elemento.dataHoraEstatElement > ''"
                          style="white-space: pre-wrap ">({{_etp.elemento.dataHoraEstatElement | dateFormat:true}})
                    </span>
                  </td>
                </tr>
                <tr *ngIf="_etp.elemento.observacionsEstat > ''">
                  <td class="ion-text-left" colspan="2">
                    <span style="white-space: pre-wrap" [innerHTML]="_etp.elemento.observacionsEstat | safeHtml"></span>
                  </td>
                </tr>
              </div>
              <tr *ngIf="!(_etp.elemento.nomEstatElement > '')">
                <td>
                  <div class="sin-asignar">{{'txSinAsignarStr' | translate}}</div>
                </td>
              </tr>
            </table>
          </ion-label>
          <ion-icon name="lc-icon-edit" slot="end" *ngIf="_etp.editando && _etp.permissions[3]" (click)="editState()"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

</ion-content>
