import {Component, ElementRef, ViewChild} from '@angular/core';
import {NavParams} from '@ionic/angular';

@Component({
  selector: 'page-image-modal',
  templateUrl: 'image-modal.html',
  styleUrls: ['image-modal.scss']
})
export class ImageModalPage {

  @ViewChild('slider', { read: ElementRef })slider: ElementRef;
  img: any;

  sliderOpts = {
    zoom: {
        maxRatio: 5
    }
  };

  constructor(private navParams: NavParams) {
  }

  ionViewDidEnter() {
    // console.log('ionViewDidEnter ImageModalPage');
  }

  ngOnInit() {
    this.img = this.navParams.get('img');
  }

  zoom(zoomIn: boolean) {
    const zoom = this.slider.nativeElement.swiper.zoom;
    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }

  close() {
    this.close();
  }

}
