import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PARTES_RESPUESTA_TITULO } from '@app/config/constantes';
import { PARTES_RESPUESTA_SUBTITULO } from '@app/config/constantes';
import { TipusAccioCambiarFase } from '@app/domains/valor-observacions-fase-data-domain';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';
import { FaseActualProvider } from '@app/services/geme/fase-actual.provider';
import { HtmlUtilsProvider } from '@app/services/html-utils/html-utils';
import { PartesTabProvider } from '@app/services/partes-tab/partes-tab.provider';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';


@Component({
  selector: 'respuesta-fase-content',
  templateUrl: './respuesta-fase-content.component.html',
  styleUrls: ['respuesta-fase-content.component.scss'],
})
export class RespuestaFaseContentComponent implements OnInit {

  private backButtonListener?: Subscription;
  public typeTitle = PARTES_RESPUESTA_TITULO;
  public typeSubtitle = PARTES_RESPUESTA_SUBTITULO;
  public isLoading = true;  // Initially set to true when image is being fetched

  @Input() public showTextObservations = true; // Por defecto se muestra el campo de texto de observaciones

  @Input() public respuestaPageActions: RespuestaPageActions;

  constructor(public _html: HtmlUtilsProvider,
      public _pg: PersistenciaGeneralProvider,
      private modalCtrl: ModalController,
      public platform: Platform,
      public _ptp: PartesTabProvider,
      public faseActual: FaseActualProvider,
      private cdRef: ChangeDetectorRef,
    ){}

  ngOnInit() {
    this.cdRef.detectChanges();
  }

  onImageDidLoad() {
    this.isLoading = false;
  }

   /*** trabajo con el boton atras */
   ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe();
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }

  public clickAceptar(){
    this.cerrarConParametros(TipusAccioCambiarFase.FASE_CURRENT);
  }

  movePrev(){
    if(this.faseActual.existsFasePrev && this.respuestaPageActions.checkValorIsOk()){
      this.cerrarConParametros(TipusAccioCambiarFase.FASE_PREV);
    }
  }

  moveNext(){
    if(this.faseActual.existsFaseNext && this.respuestaPageActions.checkValorIsOk()){
      this.cerrarConParametros(TipusAccioCambiarFase.FASE_NEXT);
    }
  }

  cerrarConParametros(tipusAccio: TipusAccioCambiarFase){
    if (this.respuestaPageActions.checkValorIsOk()){
      this.respuestaPageActions.setValorFaseActual();
      this.modalCtrl.dismiss(tipusAccio);
    }
  }

}

