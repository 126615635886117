<ion-header>
  <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
  <respuesta-fase-content [respuestaPageActions]="this">
    <ion-textarea [(ngModel)]="valorEdicio" rows="20" placeholder="{{'txElementStateEditObservations' | translate}}"></ion-textarea>
  </respuesta-fase-content>
</ion-content>

<ion-footer>
  <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
