<ion-content class="ion-no-padding">
  <elemento-tab-encabezado></elemento-tab-encabezado>

  <ion-card class="card-no-margin-horiz">
    <ion-card-header>
      <label>{{'txElementoAnotaciones' | translate}} ({{_etp.elemento.anotacions.length}})</label>
      <ion-button (click)="showAnotacionModal(null, -1)" color="light" class="ion-float-right" *ngIf="_etp.editando && _etp.permissions[1]">
        <ion-icon slot="icon-only" name="lc-icon-add"></ion-icon>
      </ion-button>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item *ngFor="let anotacion of filterBy('dataHoraInici'); let i = index"
                  (click)="showAnotacionModal(anotacion, i)">
          <ion-label>
            <label>{{ anotacion.text }}</label>
            <ion-grid>
              <ion-row>
                <ion-col size="12" class="text-detalle-elemento ion-padding-start">
                  <span>{{ 'txUsuario' | translate }}: </span>
                  <span>{{ anotacion.nomUsuari }} </span>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" class="text-detalle-elemento ion-padding-start">
                  <span>{{ 'txFechaInicioStr' | translate }}: </span>
                  <span>{{ anotacion.dataHoraInici | dateFormat:true:false}} </span>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="anotacion.dataHoraFinal > ''">
                <ion-col size="12" class="text-detalle-elemento ion-padding-start">
                  <span>{{ 'txFechaFinalStr' | translate }}: </span>
                  <span>{{ anotacion.dataHoraFinal | dateFormat:true:false}} </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-label>
          <ion-icon name="lc-icon-arrow-forward" slot="end" *ngIf="checkPermissions(anotacion)"></ion-icon>
        </ion-item>
        <ion-item lines="none" *ngIf="_etp.elemento.anotacions.length === 0">
          <ion-label class="text-no-existe">{{ 'txElementoSinAnotaciones' | translate}}</ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</ion-content>
