import { TranslateProvider } from './../translate/translate.provider';
import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ToastController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';


@Injectable({
  providedIn: 'root'
})
export class UiMessagesProvider {
  constructor(
    private toastCtrl: ToastController,
    private _translate: TranslateProvider,
    public actionSheetCtrl: ActionSheetController,
    private alertController: AlertController,
    private loadingController: LoadingController
  ) {
  }


  async showToastDefault(msgKey: string) {

    const msg = this._translate.traducir(msgKey);

    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 5000,
      position: 'bottom'
    });

    await toast.present();


  }

  async showToastWithCloseButton(msgKey: string) {

    const msg = this._translate.traducir(msgKey);

    const toast = await this.toastCtrl.create({
      message: msg,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
    return toast;
  }


  async showLoading(msgKey: string, durationMs?: number) {
    const msg = this._translate.traducir(msgKey);

    const loading = await this.loadingController.create({
      spinner: null,
      message: this.getHtmlMessage(msg),
      duration: durationMs,
    });
    await loading.present();
    return loading;
  }

  getHtmlMessage(msg: string): string{
    return `<div class="lc-loader-container"><div class="lc-loader-animation"></div><div>${msg}</div></div>`;
  }

  async showYesNoAlert(
    options: AlertOptions = {},
    titleParam: string,
    messageParam: string,
    btnYesParam: string,
    btnNoParam: string,
    secondMsg = '',
    showNoButton: boolean = true
  ): Promise<HTMLIonAlertElement> {
    const title: string = this._translate.traducir(titleParam);
    let message: string = this._translate.traducir(messageParam);
    const btnYes: string = this._translate.traducir(btnYesParam);
    const btnNo: string = btnNoParam > '' ? this._translate.traducir(btnNoParam) : '';

    if (secondMsg > '') {
      message += '<br><br>' + secondMsg;
    }

    const alertBtnNo = {
      text: btnNo,
      handler: () => ({ accepted: false })
    };

    const alert = await this.alertController.create({
      header: title,
      message,
      backdropDismiss: false, // Cancel the backdrop for confirmation required
      buttons: [
        {
          text: btnYes,
          handler: () => ({ accepted: true })
        },
        ...(showNoButton ? [alertBtnNo] : [])
      ],
      ...options,
    });

    await alert.present();
    return alert;
  }

  async showOkAlert(titleParam: string | undefined, messageParam: string, btnOkParam: string, options: AlertOptions = {}) {

    const title: string = this._translate.traducir(titleParam);
    const message: string = this._translate.traducir(messageParam);
    const btnYes: string = this._translate.traducir(btnOkParam);

    const alert = await this.alertController.create({
      header: title,
      message,
      buttons: [btnYes],
      ...options,
    });

    await alert.present();
    return alert;
  }

}
