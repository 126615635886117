import {Injectable} from '@angular/core';
import {StructTree} from '../../components/tree-view/struct';
import { HtmlUtilsProvider } from '../html-utils/html-utils';
import { FileProvider } from '../file/file.provider';
import { PersistenciaGeneralProvider } from '../persistencia-configuracion/persistencia-general.provider';

@Injectable({
  providedIn: 'root',
})
export class TreeViewProvider {

  elemetSelected: StructTree = null;
  parents: number[] = [];

  constructor(
    private _htmlUtils: HtmlUtilsProvider,
    private _fp: FileProvider,
    public _pg: PersistenciaGeneralProvider) {
    // console.log('Hello TreeViewProvider Provider');
  }

  isParent(paramId: number){
    return this.parents.some(x => x === paramId);
  }

  /**
   *  Builds a tree of items for the inventory and reuses it in a loop,
   *  if a parent exists builds the new tree inside it
   * */
  buildTree(elements: any[], parent?: StructTree): StructTree[] {
    const nodesTree: StructTree[] = [];

    elements.forEach(elem => {
      const expanded = elem.fills && elem.fills.length > 0;
      let main: StructTree;

      const route = `${this._fp.pathDestinoGemeIcones}/${elem.nomFitxerIcona}`;

      this._pg.getImageToDB(route).then((imageIcon) => {;
        main = new StructTree(elem.idGemeElement, elem.codiElement, elem.nomElement, imageIcon, expanded);
      }).catch((error) => {
        // Sin imagen
        main = new StructTree(elem.idGemeElement, elem.codiElement, elem.nomElement, undefined, expanded);
      }).finally(() => {
        if (parent) {
          main.toggle();
          main.parents.push(...parent.parents);
          main.parents.push(parent.idGemeElement);
          parent.subMenus.push(main);
        } else {
          nodesTree.push(main);
        }

        if (expanded) {
          this.buildTree(elem.fills, main);
        } else {
          main.showIconSub = false;
        }
      });
    });

    return nodesTree;
  }
}
