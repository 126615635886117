import {Injectable} from '@angular/core';
import {UiMessagesProvider} from '../ui-messages/ui-messages';
import {NetworkProvider} from '../network/network.provider';
import {PostParams} from '../../interfaces/ajax.interface';
import {PARAM_EXISTENTS, PARAM_GET_ICONES_ELE, PARAM_GET_ICONES_ESTATS_ELEMENTS, PARAM_GET_ICONES_ESTATS_INCIDENCIES, PARAM_GET_ICONES_TIPOLOGIES_INCIDENCIES, PARAM_GET_ICONES_TIPOLOGIES_TREBALL, PARAM_STRCONN} from '../../config/constantes';
import {WsUtilsProvider} from '../utils-ws/ws-utils';
import {AjaxClientProvider, ErrorConsulta} from '../ajax-client/ajax-client.provider';
import {FileProvider} from '../file/file.provider';
import {TranslateProvider} from '../translate/translate.provider';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ResourcesProvider {

  constructor(private _ui: UiMessagesProvider,
              private _ntProv: NetworkProvider,
              private _translate: TranslateProvider,
              private _ajax: AjaxClientProvider,
              private _fp: FileProvider,
              private _wsUtils: WsUtilsProvider,
              private loadingCtrl: LoadingController
              ) {
    // console.log('Hello ResourcesProvider Provider');
  }

  /**
  * Downloads remote data.
  */
  async downloadRemoteData(): Promise<void> {
    // Create a loading indicator
    const loading: HTMLIonLoadingElement = await this.loadingCtrl.create({});
    loading.spinner = null;
    await loading.present();
    // Download remote resources
    await this.downloadResources(loading);
    // Dismiss the loading indicator
    loading.dismiss();
  }


  /**
   * Downloads resources asynchronously.
   * @param {HTMLIonLoadingElement} loading - Loading controller for displaying progress.
   */
  private async downloadResources(loading: HTMLIonLoadingElement): Promise<void> {
    const resourcePromises = [
      this.downloadResourcesProcess(loading, this._fp.dirIcones, this._fp.pathDestinoGemeIcones, PARAM_GET_ICONES_ELE),
      this.downloadResourcesProcess(loading, this._fp.dirTipologies, this._fp.pathDestinoGemeTipologies, PARAM_GET_ICONES_TIPOLOGIES_TREBALL),
      this.downloadResourcesProcess(loading, this._fp.dirEstats, this._fp.pathDestinoGemeEstats, PARAM_GET_ICONES_ESTATS_ELEMENTS),
      this.downloadResourcesProcess(loading, this._fp.dirEstatsincidencies, this._fp.pathDestinoGemeEstatsincidencies, PARAM_GET_ICONES_ESTATS_INCIDENCIES),
      this.downloadResourcesProcess(loading, this._fp.dirIncidencies, this._fp.pathDestinoGemeIncidencies, PARAM_GET_ICONES_TIPOLOGIES_INCIDENCIES),
    ];

    await Promise.all(resourcePromises);
  }

  downloadResourcesProcess(loading: HTMLIonLoadingElement, dirListar: string, dirSynchronized: string, opParam: string){
    return new Promise( (resolve, reject) => {
      if(this._ntProv.appOnline){
        const msg = this._translate.traducir("txDescargandoRecursos");
        loading.message = this._ui.getHtmlMessage(msg);

        const params: PostParams[] = this.getParamsToResource([]);
        this._ajax.consultarWS(opParam, params, 0)
        .subscribe(
          (datos: any) => {
            this.synchronizeResources(datos, dirSynchronized, loading).then( () => resolve(true));
          },
          (errConsulta: ErrorConsulta) => {
            loading.dismiss();
            this._ui.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);
            resolve(false);
          }
      );
      }else{
        resolve(true);
      }
    });
  }

  synchronizeResources(recibido: any, paramPath: string, loading: HTMLIonLoadingElement, dismissLoading: boolean = false){
    return new Promise( (resolve, reject) => {
        const msg = this._translate.traducir("txSincronizandoRecursos");
        loading.spinner = null;
        loading.message = this._ui.getHtmlMessage(msg);
        this.agregarArchivos(recibido, paramPath)
            .then( () => {
              resolve(true);
            })
            .catch( (err) => {
              if(dismissLoading){
                loading.dismiss();
              }
              // console.error(JSON.stringify(err));
              resolve(false);
            });
    })
    .catch( (err) => {
      // console.error(JSON.stringify(err))
    });
  }


  synchronizeResourcesAdjunts(recibido: any, paramPath: string, loading: HTMLIonLoadingElement, dismissLoading: boolean = false){
    return new Promise( (resolve, reject) => {
        const msg = this._translate.traducir("txSincronizandoRecursos");
        loading.spinner = null;
        loading.message = this._ui.getHtmlMessage(msg);
        this.agregarArchivosAdjuntos(recibido, paramPath)
        .then( () => {
          resolve(true);
        })
        .catch( (err) => {
          if(dismissLoading){
            loading.dismiss();
          }
          // console.log(JSON.stringify(err));
          resolve(false);
        });
    })
    .catch( (err) => {
      // console.error(JSON.stringify(err));
    });
  }

  agregarArchivos(recibido, paramPath){

    return new Promise( (resolve, reject) =>{
          let cant = 1;
          // console.log("largo de arreglo con imgs: " +  recibido.afegir.length);
          if(recibido.afegir.length == 0){
            resolve(true);
            return;
          }
          recibido.afegir
          .forEach(file => {
              this._fp.writeImageFile(file.contingut, paramPath, file.nomFitxerLocal, true)
                      .then( () => {
                        if(cant == recibido.afegir.length){
                          // console.log("hago el return de agregarArchivos cant == recibido.afegir.length")
                          resolve(true);
                        }

                        cant++;
                      });

            });
            // resolve(true);
            //endFile
    })
    .catch( err => {
      // console.error(JSON.stringify(err, Object.getOwnPropertyNames(err)));
    });

  }


  agregarArchivosAdjuntos(recibido, paramPath){

    return new Promise( (resolve, reject) =>{

          // console.log("voy a imprimir recibido");
          // console.log(JSON.stringify(recibido));
          // console.log("voy a iterar en file--->");

          let cant = 1;

          // console.log("largo de arreglo con files: " +  recibido.length);

          if(recibido.length == 0){
            resolve(true);
            return;
          }

          recibido
          .forEach(file => {
              this._fp.writeImageFile(file.contingut, paramPath, file.nomFitxerLocal, true)
                      .then( () => {
                        // console.log("cant." + cant);
                        if(cant == recibido.length){
                          // console.log("hago el return de agregarArchivos cant == recibido.length")
                          resolve(true);
                        }

                        cant++;
                      });

            });
    })
    .catch( err => {
      // console.error(JSON.stringify(err, Object.getOwnPropertyNames(err)));
    });

  }

  getParamsToResource(fileList: any){
    const params: PostParams[] = [];

    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    };
    const paramExistents: PostParams = {
      nombre: PARAM_EXISTENTS,
      valor: fileList != null ? fileList.join() : []
    };

    params.push(paramStrConn);
    params.push(paramExistents);

    // console.log("getParamsToResource - imprimo parametros: " + JSON.stringify(params));
    return params;
  }

}
