<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
      <ion-buttons slot="start">
        <ion-button (click)="cerrarSinParametros()">
          <ion-icon slot="icon-only" name="lc-icon-arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
        <ion-title>{{'txArbolElementos' | translate}}
            <p class="subtitle hide-when-big-screen">{{_pg.proyectoSeleccionado.nom}}</p>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cerrarConParametros()">
                <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">

    <ion-item class="ion-padding">
        <ion-toggle class="toggle-active" slot="start" [(ngModel)]="incluirSubElementos"></ion-toggle>
        <ion-label>{{ 'txPartesArbolModalSubElementos' | translate }}</ion-label>
    </ion-item>


    <tree-view [nodesTree]="nodesTree"></tree-view>

</ion-content>
