import { Component, ViewChild } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { FaseActualProvider } from '../../services/geme/fase-actual.provider';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';
import { LcInputNumberComponent } from '../../components/lc-input-number/lc-input-number';
import * as Data from '../../config/constantes';

@Component({
  templateUrl: 'respuesta-entero-decimal.html',
})
export class RespuestaEnteroDecimalPage implements RespuestaPageActions {

  @ViewChild(LcInputNumberComponent) lcinput: LcInputNumberComponent;

  valorInicial: string;
  public constants = Data;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    public translate: TranslateProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public faseActual: FaseActualProvider,
  ) {

    if (faseActual.valorObservacionsEdicio.valor > "") {
      this.valorInicial = faseActual.valorObservacionsEdicio.valor;
    } else {
      this.valorInicial = "";
    }
  }

  checkValorIsOk(): boolean {
    return !this.lcinput.anyError();
  }

  setValorFaseActual() {
    if (!this.lcinput.anyError()) {
      this.faseActual.valorObservacionsEdicio.valor = this.lcinput.getValue() != null ? String(this.lcinput.getValue()) : '';
      return;
    }
    console.warn("Llamada a setValorFaseActual con error en lcinput");
    return '';
  }

}
