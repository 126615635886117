export class Proyecto{
    idProjecte: number;
    idGrupoGestio: number;
    abr: string;
    nom: string;
    permisos: Array<Permiso>;
    configSync: number;

    constructor(idProjecte, idGrupoGestio, abr, nom, permisos, configSync){
        this.idProjecte = idProjecte;
        this.idGrupoGestio = idGrupoGestio;
        this.abr = abr;
        this.nom = nom;
        this.permisos = permisos;
        this.configSync = configSync;
    }
}

export class Permiso{
    idPerfilPermisAuscultacio: number;
    idPerfilPermisComSir: number;
    idPerfilPermisDocuments: number;
    idPerfilPermisFitxaTecnica: number;
    idPerfilPermisGeme: number;
    idPerfilPermisGep: number;

    constructor( idPerfilPermisAuscultacio, idPerfilPermisComSir,
                 idPerfilPermisDocuments, idPerfilPermisFitxaTecnica,
                 idPerfilPermisGeme, idPerfilPermisGep){


            this.idPerfilPermisAuscultacio = idPerfilPermisAuscultacio;
            this.idPerfilPermisComSir = idPerfilPermisComSir;
            this.idPerfilPermisDocuments = idPerfilPermisDocuments;
            this.idPerfilPermisFitxaTecnica = idPerfilPermisFitxaTecnica;
            this.idPerfilPermisGeme = idPerfilPermisGeme;
            this.idPerfilPermisGep = idPerfilPermisGep;
    }

}

export interface ProjecteDB{
    idProjecte: number;
    idGrupoGestio: number;
    abr: string;
    nom: string;
}

export interface UsuariProjecteDB{
    idUsuari: number;
    idProjecte: number;
    permisos: string;
    configSync: number;
}
