import { Pipe, PipeTransform, inject } from '@angular/core';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {

  private _pg = inject(PersistenciaGeneralProvider);

  transform(date: string, addTime: boolean, withoutSeconds: boolean = true): string {
    if (!date) {
      return '';
    }

    const dateObj = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: addTime ? '2-digit' : undefined,
      minute: addTime ? '2-digit' : undefined,
      second: withoutSeconds ? undefined : '2-digit'
    };

    const formattedDate = dateObj.toLocaleDateString(this._pg.selectedLanguage, options);

    return formattedDate;
  }
}
