import {Injectable} from "@angular/core";
import {Permission} from "@app/core/permission/domain/permission";
import {PermissionService} from "@app/core/permission/domain/permission.service";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ListenPermissionUseCase {
  constructor(
    private service: PermissionService
  ) {
  }


  execute(): Observable<Permission> {
    return this.service.getPermission$()
  }
}
