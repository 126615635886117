import {Component} from '@angular/core';
import {AlertController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {ElementoTabProvider} from '../../services/elemento-tab/elemento-tab.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {PersistenciaGeneralProvider} from "../../services/persistencia-configuracion/persistencia-general.provider";
import {Subscription} from "rxjs";

@Component({
  selector: 'page-elemento-datos-descripcion',
  templateUrl: 'elemento-datos-descripcion.html',
})
export class ElementoDatosDescripcionPage {

  descripcioElement = "";

  private backButtonListener?: Subscription;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              public _htmlUtils: HtmlUtilsProvider,
              public alertCtrl: AlertController,
              public _etp: ElementoTabProvider,
              public modalCtrl: ModalController,
              public _htmlUtil: HtmlUtilsProvider,
              public platform: Platform,
              public _translate: TranslateProvider,
              public _ui: UiMessagesProvider,
              public _pg: PersistenciaGeneralProvider) {

    if (navParams.get("descripcioElement") && navParams.get("descripcioElement") > '') {
      this.descripcioElement = navParams.get("descripcioElement");
    }
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }

  /*** fin trabajo con el boton atras */

  cerrarConParametros() {
    this._etp.elemento.descripcioElement = this.descripcioElement;
    this.modalCtrl.dismiss();
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }
}
