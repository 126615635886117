<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-buttons slot="start">
            <ion-button class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button" (click)="cerrarSinParametros()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{'txConfirmarSincronizacion' | translate}}</ion-title>
        <ion-buttons slot="primary"	>
            <ion-button (click)="cerrarConParametros()">
                <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <p style="font-weight: bold">{{'txEnvioDeDatos' | translate}}</p>

    <p class="option">{{'txSeEnviaranModificacionesServidor' | translate}}</p>

    <div class="line-separtor-main"></div>

    <p style="font-weight: bold">{{'txDescargaSegunConfiguracion' | translate}} </p>
    <p class="option">{{ 'txPresasASincronizar' | translate }}</p>

    <span *ngFor="let proj of _pg.proyectosDisponibles">
        <span *ngIf="proj.configSync">
            <p style="padding-left: 3.5rem;">» {{proj.nom}}</p>
        </span>
    </span>

    <p class="option">{{ 'txSeDescargaranSiguientesDatos' | translate }}</p>
    <div style="padding-left: 2rem;">
        <p class="option">{{ 'txConfigSyncOpt2' | translate }}</p>
        <p class="option">{{ 'txConfigSyncOpt3' | translate }}</p>
        <p class="option" *ngIf="syncImatgesElements">» {{ 'txConfigSyncOpt4' | translate }}</p>
    </div>

</ion-content>
