import {Subscription} from 'rxjs';
import {Component, NgZone} from '@angular/core';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {HelperProvider} from '../../services/helper/helper.provider';
import {UpdateAppProvider} from '../../services/update-app/update-app.provider';
import {AppRoutes} from '../../config/app-routes';
import {Router} from '@angular/router';
import I18n from '@app/i18n/i18n';

@Component({
  selector: 'page-configuracion',
  templateUrl: 'configuracion.html',
  styleUrls: ['configuracion.scss']
})
export class ConfiguracionPage {


  syncImatgesElements = true;
  idiomas: any[] = [];
  private backButtonListener?: Subscription;

  constructor(
    private translateService: TranslateService,
    private ngZone: NgZone,
    public _pg: PersistenciaGeneralProvider,
    public platform: Platform,
    private _updateApp: UpdateAppProvider,
    public _hp: HelperProvider,
    private router: Router,
    public i18n: I18n) {
    this.idiomas = this._pg.availableLanguages;

    this.syncImatgesElements = this._pg.usuarioLoginModel.syncImatgesElements === 1 ? true : false;
  }


  ionViewDidEnter() {
    this._updateApp.needUpdateAdvice();
    this.syncImatgesElements = this._pg.usuarioLoginModel.syncImatgesElements === 1 ? true : false;
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe();
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.ngZone.run(() => {
        this._hp.abrirPaginaClick(AppRoutes.homePage, false);
      });
    });
  }

  /*** fin trabajo con el boton atras */

  seleccionarIdioma(event: any) {
    const lang = event.detail.value;

    this._pg.selectedLanguage = lang;
    this.translateService.use(lang);

    this.guardarPreferenciasUsuario(lang);
  }

  private async guardarPreferenciasUsuario(lang: string) {
    await this._pg.updateUsuariLanguage(this._pg.usuarioLoginModel.login, lang);
  }

  toggleChange() {
    this._pg.updateSyncConfig(this._pg.usuarioLoginModel.login, this.syncImatgesElements ? 1 : 0);
  }

  toggleChangeConfigSync(proj: any) {
    this._pg.updateUsuarisProjectesConfigSync(this._pg.usuarioLoginModel.idUsuari, proj.idProjecte, proj.configSync ? 1 : 0);
  }

  async showInformation() {
    await this.router.navigate(['/', AppRoutes.configurationInformationPage]);
  }

}
