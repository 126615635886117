/*TODO: quitar este fichero para desvincular de librer�as app2u */

import { HandlerBuilder } from '@app/app2u/handlers/base-handler.parameter';

export class CustomHandlerBuilder extends HandlerBuilder {

  constructor() {
    super(null);
    this.removeContentType();
  }

}
