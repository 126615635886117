export enum AppRoutes {
  cargarConfiguracionPage = 'cargar-configuracion',
  homePage = 'home',
  loginPage = 'login',
  inventarioPage = 'inventario',
  elementoTabsPage = 'elemento-tabs',
  elementoDatosPage = 'elemento-datos',
  elementoImagenesPage = 'elemento-imagenes',
  elementoAnotacionesPage = 'elemento-anotaciones',
  partesPage = 'partes',
  parteTabsPage = 'parte-tabs',
  parteInfoPage = 'parte-info',
  parteFasesPage = 'parte-fases',
  parteResultadoPage = 'parte-resultado',
  parteDatosAdicionalesPage = 'parte-datos-adicionales',
  parteAnotacionesPage = 'parte-anotaciones',
  configuracionPage = 'configuracion',
  configurationInformationPage = 'configuration-information',
}
