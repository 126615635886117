<div *ngIf="!isIos">
    <div *ngIf="!decimal && negative">
        <ion-list>
            <ion-item lines="inset" class="item-class">
                <ion-label position="floating">{{placeholder | translate}}</ion-label>
                <ion-input (keyup.enter)="enterFunction()" (blur)="onBlurMethod()" [(ngModel)]="value" type="tel"></ion-input>
            </ion-item>
            <p [hidden]="!errorNumeroEntero" class="validationError ion-padding">{{'txErrorNumeroEntero' | translate}}</p>
        </ion-list>
    </div>
    <div *ngIf="!decimal && !negative">
        <ion-list>
            <ion-item lines="inset" class="item-class">
                <ion-label position="floating">{{placeholder | translate}}</ion-label>
                <ion-input (keyup.enter)="enterFunction()" (blur)="onBlurMethod()" [(ngModel)]="value" type="tel"></ion-input>
            </ion-item>
            <p [hidden]="!errorNumeroEnteroNoNegativo" class="validationError ion-padding">{{'txErrorNumeroEnteroNoNegativo' | translate}}</p>
        </ion-list>
    </div>
    <div *ngIf="decimal && negative">
        <ion-list>
            <ion-item lines="inset" class="item-class">
                <ion-label position="floating">{{placeholder | translate}}</ion-label>
                <ion-input (keyup.enter)="enterFunction()" (blur)="onBlurMethod()" [(ngModel)]="value" type="tel"></ion-input>
            </ion-item>
            <p [hidden]="!errorNumerodecimalParam" class="validationError ion-padding">{{'txErrorNumeroDecimal' | translate}}</p>
        </ion-list>
    </div>
    <div *ngIf="decimal && !negative">
        <ion-list>
            <ion-item lines="inset" class="item-class">
                <ion-label position="floating">{{placeholder | translate}}</ion-label>
                <ion-input (keyup.enter)="enterFunction()" (blur)="onBlurMethod()" [(ngModel)]="value" type="tel"></ion-input>
            </ion-item>
            <p [hidden]="!errorNumerodecimalParamNoNegativo" class="validationError ion-padding">{{'txErrorNumeroDecimalNoNegativo' | translate}}</p>
        </ion-list>
    </div>
</div>
<div *ngIf="isIos">
    <div *ngIf="!decimal && negative">
        <ion-list>
            <ion-item lines="inset" class="item-class">
                <ion-label position="floating">{{placeholder | translate}}</ion-label>
                <ion-input (keyup.enter)="enterFunction()" (blur)="onBlurMethod()" [(ngModel)]="value" type="text" pattern="^[-+]?\d*$"></ion-input>
            </ion-item>
            <p [hidden]="!errorNumeroEntero" class="validationError ion-padding">{{'txErrorNumeroEntero' | translate}}</p>
        </ion-list>
    </div>
    <div *ngIf="!decimal && !negative">
        <ion-list>
            <ion-item lines="inset" class="item-class">
                <ion-label position="floating">{{placeholder | translate}}</ion-label>
                <ion-input (keyup.enter)="enterFunction()" (blur)="onBlurMethod()" [(ngModel)]="value" type="text" pattern="^\d+$"></ion-input>
            </ion-item>
            <p [hidden]="!errorNumeroEnteroNoNegativo" class="validationError ion-padding">{{'txErrorNumeroEnteroNoNegativo' | translate}}</p>
        </ion-list>
    </div>
    <div *ngIf="decimal && negative">
        <ion-list>
            <ion-item lines="inset" class="item-class">
                <ion-label position="floating">{{placeholder | translate}}</ion-label>
                <ion-input (keyup.enter)="enterFunction()" (blur)="onBlurMethod()" [(ngModel)]="value" type="text" pattern="^[-+]?\d*$"></ion-input>
            </ion-item>
            <p [hidden]="!errorNumerodecimalParam" class="validationError ion-padding">{{'txErrorNumeroDecimal' | translate}}</p>
        </ion-list>
    </div>
    <div *ngIf="decimal && !negative">
        <ion-list>
            <ion-item lines="inset" class="item-class">
                <ion-label position="floating">{{placeholder | translate}}</ion-label>
                <ion-input (keyup.enter)="enterFunction()" (blur)="onBlurMethod()" [(ngModel)]="value" type="text" pattern="^\d+$"></ion-input>
            </ion-item>
            <p [hidden]="!errorNumerodecimalParamNoNegativo" class="validationError ion-padding">{{'txErrorNumeroDecimalNoNegativo' | translate}}</p>
        </ion-list>
    </div>
</div>
