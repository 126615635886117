<ion-header>
    <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
    <respuesta-fase-content [respuestaPageActions]="this">
        <ion-list>
            <ion-radio-group [(ngModel)]="valorEdicio">
        <ion-item>
            <ion-label class="hecho">{{'txPartesRespuestaHecho' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" color="colorCorrecto" value="1"></ion-radio>
        </ion-item>

        <ion-item>
            <ion-label class="no-hecho">{{'txPartesRespuestaNoHecho' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" color="colorSinAsignar" value="0"></ion-radio>
        </ion-item>
    </ion-radio-group>
    </ion-list>
    </respuesta-fase-content>
</ion-content>

<ion-footer>
    <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
