import {PARAM_GET_CONFIG_SERV, PARAM_VERSIO, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG} from '../../config/constantes';
import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {
  IonContent,
  LoadingController,
  MenuController,
  ModalController,
  Platform
} from '@ionic/angular';
import {AjaxClientProvider, ErrorConsulta} from '../../services/ajax-client/ajax-client.provider';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {PostParams} from '../../interfaces/ajax.interface';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {NetworkProvider} from '../../services/network/network.provider';
import {CargarConfiguracionModalPage} from '../cargar-configuracion-modal/cargar-configuracion-modal';
import {ExternalLinksProvider} from '../../services/external-links/external-links.provider';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {FORZAR_URL_CONFIGURACION, URL_CONFIGURACION} from '../../config/constantes-cliente';
import {Router} from '@angular/router';
import {AppRoutes} from 'src/app/config/app-routes';
import {App} from '@capacitor/app';
import {CargarConfiguracionModalQrPage} from '../cargar-configuracion-modal-qr/cargar-configuracion-modal-qr';
import {Subscription} from 'rxjs';
import {HelperProvider} from '@app/services/helper/helper.provider';
import {EventService} from '@app/services/events/event.service';
import { PluginListenerHandle } from '@capacitor/core';
import { ConfiguracioServidorDB } from '@app/domains/configuracio-servidor-domain';
import I18n from '@app/i18n/i18n';

@Component({
  selector: 'page-cargar-configuracion',
  templateUrl: 'cargar-configuracion.html',
})
export class CargarConfiguracionPage {
  @ViewChild(IonContent) content: IonContent;

  showPage = false;

  private backButtonListener?: Subscription;
  private appUrlListener?: Promise<PluginListenerHandle> & PluginListenerHandle;

  constructor(
    private loadingCtrl: LoadingController,
    private menuCtrl: MenuController,
    private platform: Platform,
    private _ajax: AjaxClientProvider,
    public _pg: PersistenciaGeneralProvider,
    private _messages: UiMessagesProvider,
    private _np: NetworkProvider,
    private modalCtrl: ModalController,
    public _elp: ExternalLinksProvider,
    public _translate: TranslateProvider,
    private cd: ChangeDetectorRef,
    private helper: HelperProvider,
    private router: Router,
    private events: EventService,
    public i18n: I18n,
  ) {
    this.menuCtrl.enable(false);
  }

  ionViewDidEnter() {
    // Hide page when opening
    this.showPage = false;
    this.initializeBackButtonCustomHandler();

    this.platform.ready().then(() => {
      if (!this.appUrlListener) {
        this.appUrlListener = this.listenOpenUrl();
      }

      this._np.urlConfiguracion = URL_CONFIGURACION;
      this.recuperarConfiguracion(null, false);

      // this._pg.getConfiguracioServidorFromDb()
      //   .then((config: ConfiguracioServidorDB) => {
      //     console.log('getConfiguracioServidorFromDb', config);
      //     if (config && config.versioConfig > 0) {
      //       config.urlBase1 = URL_CONFIGURACION;
      //       this.recuperarConfiguracion(config, false);
      //     } else {
      //       if (FORZAR_URL_CONFIGURACION) {
      //         // console.log('fuerza configuracion');
      //         this._np.urlConfiguracion = URL_CONFIGURACION;
      //         this.recuperarConfiguracion(null, false);
      //       } else {
      //         // show page when configuration needs to be inputted by user
      //         this.showPage = true;
      //         this.cd.detectChanges();
      //       }
      //     }
      //   }).catch(err => JSON.stringify(err));
    });

  }

  appVersion(): string{
    return this._pg.versionApp;
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe();
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.helper.exitApp();
    });
  }


  async mostrarModal() {
    const modal = await this.modalCtrl.create({component: CargarConfiguracionModalPage});
    await modal.present();

    modal.onDidDismiss().then(
      ({data}) => {
        if (data) {
          // console.log(JSON.stringify(data));
          this._np.urlConfiguracion = data.url;
          this.recuperarConfiguracion(null, false);
        } else {
          // console.log('Se cerró el modal sin parametros');
        }

      }
    );
  }

  /**
   * This method opens a modal to scan qr and intercept the url it finds.
   */
  async carregarQr(): Promise<void> {
    const modal = await this.modalCtrl.create({component: CargarConfiguracionModalQrPage});
    await modal.present();
      // When the modal is closed, it takes the URL and parses it to configure the server
    modal.onDidDismiss().then(({data}) => {
        const qrEmptyMessage = 'txQrEmpty';
        if (data.url) {this.configServerWithUrl(new URL(data.url), qrEmptyMessage);};
      }
    );
  }

  async recuperarConfiguracion(configParam: any, accedeConfirma: boolean) {
    const msg = this._translate.traducir('txReintentandoConexion');

    const loading = await this.loadingCtrl.create({
      spinner: null,
      message: this._messages.getHtmlMessage(msg)
    });

    if (this._np.appOnline && !accedeConfirma) {
      loading.message = this._translate.traducir('txDescargandoConfigCargarConf');
      await loading.present();
      if (configParam != null) {
        // if the url is set load config
        if (this._elp.baseUrl > '') {
            this.checkSourceConfig(configParam, loading, false);
        } else {
          // if the url is not loaded wait for it
          const sub = this.events.subscribe('baseUrlOk:created', () => {
            this.checkSourceConfig(configParam, loading, false);
            // Only subcribe 1 time
            sub.unsubscribe();
          });
        }

      } else {
        await this.checkSourceConfig(configParam, loading, false);
      }
    } else {

      const alert = await this._messages.showYesNoAlert({}, 'txAtencionStr', 'txAccederModoSinConexion', 'txYes', 'txNo');
      alert.onDidDismiss().then(async ({data}) => {
        if (!data.accepted) {
          this.helper.exitApp();
          return false;
        }
        this._pg.workingOffline = true;
        await this.checkSourceConfig(configParam, loading, true);
      });
    }
  }


  async checkSourceConfig(configParam: any, loading: any, accedeOffline: boolean) {
    if (accedeOffline) {
      this.ingresar();
    } else if (configParam == null) {
      this._pg.getConfiguracioServidorFromDb().then((config: ConfiguracioServidorDB) => {
        this.getConfiguracionFromServer(config, loading);
      })
        .catch(err => {
          // console.error(JSON.stringify(err));
          loading.dismiss();
        });
    } else {
      await this.getConfiguracionFromServer(configParam, loading);
    }
  }

  async getConfiguracionFromServer(config: ConfiguracioServidorDB, loading: any) {
    const params: PostParams[] = [];

    if (config && config.versioConfig > 0) {

      const paramVersio: PostParams = {
        nombre: PARAM_VERSIO,
        valor: String(config.versioConfig)
      };

      params.push(paramVersio);

    } else {
      // console.log('no existe version de config. busco por 1ra vez');
    }

    await this.descargarConfiguracion(PARAM_GET_CONFIG_SERV, params)
      .then((res) => {
        loading.dismiss();

        if (res) {
          this.ingresar();
        } else {
          // console.log('La configuración no se ha cargado');

          if (config && config.versioConfig > 0) {
            // console.log('ingreso igual sin conexion porque ya había config. previa: version: ' + config.versioConfig);
            this.ingresar();
          } else {
            // show page when configuration needs to be inputted by user
            this.showPage = true;
            this.cd.detectChanges();
          }
        }
      })
      .then(async () => {
        await this._np.ping();
      })
      .catch(err => {
        // console.log(JSON.stringify(err));
        loading.dismiss();
      });
  }

  ingresar() {
    this.router.navigate([`/${AppRoutes.loginPage}`]);
  }


  //Descargar configuración
  private descargarConfiguracion(paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {


      this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
        .subscribe(async (res) => {
            if (res !== 'OK') {

              // console.log('this._pg.setAjustesConfiguracion(res)  ---- ' + JSON.stringify(res));
              //actualizo la configuración.
              this._pg.setAjustesConfiguracion(res);
              await this._pg.deleteConfiguracioServidor();

              const response = res;
              response.urlBase1 = URL_CONFIGURACION;
              await this._pg.insertConfiguracioServidor(response);

            } else {
              // console.log('Versión correcta y actualizada');
            }

            resolve(true);
          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastWithCloseButton(errConsulta.mensaje);
            // console.error('ERROR: ' + errConsulta.categoria + ' ' + errConsulta.categoria);
            resolve(false);
          });

    })
      .catch(err => {
        // console.error('error en cargar-onfiguracion: ' + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });


    return promesa;
  }

    /**
     * Listens for the 'appUrlOpen' event and processes the incoming URL to configure the server.
     *
     * @returns A listener handle for the 'appUrlOpen' event.
     */
  private listenOpenUrl(): Promise<PluginListenerHandle> & PluginListenerHandle {
    // Message to display when the link is empty
    const linkEmptyMessage = 'txLinkEmpty';

    // Set up a listener for the 'appUrlOpen' event
    return App.addListener('appUrlOpen', ({url}) => this.configServerWithUrl(new URL(url), linkEmptyMessage));
  }

  /**
   * Analyzes the URL and configures the application server based on whether there's a deeplink or not.
   *
   * @param url - The URL to analyze.
   * @param errorMessage - The message to display if the URL doesn't contain a valid configuration.
   */
  private configServerWithUrl(url: URL, errorMessage: string) {
    const deepLinkedURL = url.searchParams.get('link');

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    deepLinkedURL ? this.handleDeepLink(deepLinkedURL) : this.handleNormalUrl(url, errorMessage);
  }

  /**
   * Handles the case when a deeplink is present in the URL.
   *
   * @param deepLinkedURL - The deeplink URL to handle.
   */
  private handleDeepLink(deepLinkedURL: string) {
    const areEqual: boolean = deepLinkedURL === this._np.urlConfiguracion;

    // If the URL is the same as the one that is already set, show an alert
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    areEqual
    ? this._messages.showOkAlert('txAtencionStr', 'txUrlYaConfigurada', 'txAceptar')
    : this.saveUrl(deepLinkedURL);

  }

  /**
   * Handles the case when a normal URL is provided.
   *
   * @param url - The URL to handle.
   * @param errorMessage - The message to display if the URL is invalid.
   */
  private handleNormalUrl(url: URL, errorMessage: string) {
    const urlString: string = url.href;

    // If URL is valid, save it; otherwise, show an error message
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    urlString ? this.saveUrl(urlString) : this._messages.showToastDefault(errorMessage);
  }

  /**
   * Saves the provided URL as the application server configuration.
   *
   * @param url - The URL to save.
   */
  private saveUrl(url: string) {
    this._np.urlConfiguracion = url;
    this.recuperarConfiguracion(null, false);
  }
}
