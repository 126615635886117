import * as SentryAngular from '@sentry/angular-ivy';
import {BrowserTracing} from '@sentry/tracing';
import {environment} from 'src/environments/environment';

declare const RELEASE_VERSION: string;

export class Sentry {

  static init() {
    // console.log('Sentry init');
    SentryAngular.init({
      dsn: environment.SENTRY_DSN,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      integrations: [
        new SentryAngular.Replay(),
        new BrowserTracing({
          routingInstrumentation: SentryAngular.routingInstrumentation,
        })
      ],
      enabled: environment.production,
      release: RELEASE_VERSION
    });
  }

  static errorHandler = SentryAngular.createErrorHandler();

  static traceService = SentryAngular.TraceService;

  static captureException(exception: Error) {
    return SentryAngular.captureException(exception);
  }
}
