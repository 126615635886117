<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
            {{ 'txPartesMenu' | translate }}
            <p class="subtitle hide-when-big-screen">{{_pg.proyectoSeleccionado.nom}}</p>
        </ion-title>
    </ion-toolbar>

    <ion-grid class="header-grid" id="header">
        <ion-row class="ion-text-center">
            <ion-col size="3" class="header-left" (click)="toggleFavoritos()">
                <ion-icon [ngClass]="{'header-fav-on': favOn, 'header-fav-off': !favOn}" name="lc-icon-star"></ion-icon>
                <p class="header-text"> {{ favOn ? ('txPartesFavoritos' | translate) : 'txPartesTodos' | translate }}</p>
            </ion-col>
            <ion-col size="3" class="header-left" (click)="showSearchPage()">
                <ion-icon class="header-icon" name="lc-icon-search"></ion-icon>
                <p *ngIf="(!(valorSearch && valorSearch !== ''))" class="header-text">{{ 'txBuscar' | translate }}</p>
                <p style="padding: 0; margin: 0;">
                    <ion-chip *ngIf="valorSearch && valorSearch !== ''" style="font-size: 1.5rem;" (click)="quitarFiltros($event, constants.FILTRO_BUSQUEDA)">
                        <ion-icon name="lc-icon-close" color="primary" class="icon-padding-en-chip"></ion-icon>
                        <ion-label style="font-size: 1.1rem;">{{ 'txQuitarFiltrosStr' | translate }}</ion-label>
                    </ion-chip>
                </p>
            </ion-col>
            <ion-col size="3" class="header-left" (click)="showArbolPage()">
                <ion-icon class="header-icon" name="lc-icon-inventario"></ion-icon>
                <p *ngIf="(!(idGemeElement && idGemeElement > 0 ))" class="header-text">{{ 'txArbol' | translate }}</p>
                <p style="padding: 0; margin: 0;">
                    <ion-chip *ngIf="idGemeElement && idGemeElement > 0 " style="font-size: 1.5rem;" (click)="quitarFiltros($event, constants.FILTRO_ARBOL)">
                        <ion-icon name="lc-icon-close" color="primary" class="icon-padding-en-chip"></ion-icon>
                        <ion-label style="font-size: 1.1rem;">{{ 'txQuitarFiltrosStr' | translate }}</ion-label>
                    </ion-chip>
                </p>
            </ion-col>
            <ion-col size="3" class="header-right" (click)="showConfigPage()">
                <ion-icon class="header-icon" name="lc-icon-config"></ion-icon>
                <p class="header-text">{{ 'txOpciones' | translate }}</p>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-header>

<ion-content class="ion-no-padding">
    <ion-list class="ion-no-padding">
        <ion-item-group *ngFor="let item of elementos; trackBy: trackByFn">
            <ion-item-divider class="header-elements" (click)="colapsarDescolapsar(item)">
              <img slot="start" [src]="item.srcIcona" style="width: 2.286em;" onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';">
              <ion-label>
                <p style="padding-left:8px">
                  <strong><span *ngIf="item.codiElement && item.codiElement !== ''">{{item.codiElement}} - </span>
                    {{item.nomElement}}</strong>
                </p>
                <p style="padding-left:8px" class="ion-text-wrap">{{item.rutaElement}}</p>
              </ion-label>
              <ion-buttons slot="end" class="ion-padding-start">
              <ion-button style="font-size: 1.5rem;" size="small" color="dark" (click)="_etp.getElement(item.idElement)">
                <ion-icon slot="icon-only" name="lc-icon-info"></ion-icon>
              </ion-button>
            </ion-buttons>
            </ion-item-divider>
            <ion-item-sliding #slidingItem id="item{{parte.idModelElement}}" *ngFor="let parte of item.partes">
              <ion-item button="true" (click)="viewReport(parte, 'item'+parte.idModelElement, false)" [hidden]="ocultarSiColapsado(parte)" detail="false">
                <ion-label>
                  <label class="ion-text-wrap"><ion-icon *ngIf="parte.favoritos" name="lc-icon-star" color="colorFavoritos"></ion-icon>{{parte.codiModel}} - {{parte.nomModel}}</label>
                  <table class="tabla-fixed">
                    <tr>
                      <div>
                        <td class="codigo-parte" style="width:9em">
                          <span *ngIf="parte.esFuturo">{{'txPartesPartePropuesto' | translate}}</span>
                          <span *ngIf="!parte.esFuturo">{{'txPartesParteStr' | translate}}
                            {{parte.codiParte}}</span>
                        </td>
                        <td class="estado-parte ion-text-wrap">
                          <img class="icon-align-middle" [src]="parte.srcImgEstadoParte"> <span> {{parte.txEstadoParte | translate}}</span>
                        </td>
                      </div>
                      <td rowspan="2" class="element-buttons" (click)="eventoStopPropagation($event)">
                        <ion-button *ngIf="parte.esPotCrearNoPlan === 1" color="light" (click)="openCreateNotPlannedPart(item, parte)">
                          <ion-icon slot="icon-only" name="lc-icon-parte-np"></ion-icon>
                        </ion-button>
                        <ion-button color="light" (click)="openMenu($event,parte,item, 'item'+parte.idModelElement)">
                          <ion-icon slot="icon-only" name="lc-icon-more"></ion-icon>
                        </ion-button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="horquilla-parte">
                        {{parte.forqueta}}
                      </td>
                    </tr>
                  </table>
                </ion-label>
              </ion-item>
                <ion-item-options side="start" (ionSwipe)="swipeAddFavorite(slidingItem, parte)">
                    <ion-item-option expandable color="colorFondoFavoritos" (click)="swipeAddFavorite(slidingItem, parte)">
                        <ion-icon name="lc-icon-star"></ion-icon>
                        {{'txPartesFavoritos' | translate}}
                    </ion-item-option>
                </ion-item-options>
                <ion-item-options side="end" (ionSwipe)="swipeShowList(slidingItem, parte, item)">
                    <ion-item-option class="boton-sliding" color="secondary" (click)="swipeViewReport(slidingItem, parte, 'item'+parte.idModelElement)">
                        <ion-icon name="lc-icon-parte"></ion-icon>
                        {{'txPartesVer' | translate}}
                    </ion-item-option>
                    <ion-item-option class="boton-sliding" color="colorFondoFavoritos" (click)="swipeAddFavorite(slidingItem, parte)">
                        <ion-icon name="lc-icon-star"></ion-icon>
                        {{'txPartesFavoritos' | translate}}
                    </ion-item-option>
                    <ion-item-option class="boton-sliding" color="colorFondoLista" (click)="swipeShowList(slidingItem, parte, item)">
                        <ion-icon name="lc-icon-list"></ion-icon>
                        {{'txPartesOtrosPartes' | translate}}
                    </ion-item-option>
                  <ion-item-option *ngIf="parte.esPotCrearNoPlan === 1" class="boton-sliding" color="colorCrearParte" (click)="openCreateNotPlannedPart(item, parte)">
                        <ion-icon name="lc-icon-parte-np"></ion-icon>
                        {{'txPartesNotPlannedCreate' | translate}}
                    </ion-item-option>
                </ion-item-options>
            </ion-item-sliding>
        </ion-item-group>
    </ion-list>

    <label *ngIf="elementos.length === 0 && consultaWSRealizada && !existeParteLocal" class="text-no-existe">{{'txPartesNoEncontrados' | translate}} {{valorSearch && valorSearch.length > 0 ? '('+valorSearch+')' : ' '}}</label>
    <label *ngIf="existeParteLocal" class="text-no-existe">{{'txSeBuscoPorCodigoParte' | translate}} {{valorSearch && valorSearch.length > 0 ? '('+valorSearch+')' : ' '}}</label>

    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" [disabled]="!hayMasElementos">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <label *ngIf="(!hayMasElementos && !_pg.workingOffline) || (!hayMasElementos && _pg.workingOffline && rowFinalLocal > 0)" class="text-no-existe ion-float-right label-no-more-data">{{'txPartesNoMasElementos' | translate}}</label>
</ion-content>
