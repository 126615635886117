import {Subscription} from 'rxjs';
import {Component, OnDestroy, ViewChild} from '@angular/core';
import {
  AlertController,
  IonBackButtonDelegate,
  IonTabs,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  Platform
} from '@ionic/angular';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {SwipeTabDirective} from '../../directives/swipe-tab/swipe-tab.directive';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {PostParams} from '../../interfaces/ajax.interface';
//import { Base64 } from '@ionic-native/base64'; //TODO: (App2u)
import * as Data from '../../config/constantes';
import {
  DATE_TIME_FORMAT,
  FICHERO_NO_ENVIADO,
  PARAM_GUARDAR_PARTE_APP,
  PARTES_COLOR_ESTADO_NULO,
  PARTES_RESPUESTA_CORRECTO_INCORRECTO,
  PARTES_RESPUESTA_HECHO,
  PARTES_RESPUESTA_NO_SI,
  PARTES_RESPUESTA_SI_NO,
  PARTES_TIPO_ESTADO_APLAZADO,
  PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA,
  PARTES_TIPO_ESTADO_CORRECTO,
  PARTES_TIPO_ESTADO_INCORRECTO,
  TIMEOUT_CONSULTAS_WS_DEFECTO_SEG
} from '../../config/constantes';
import {WsUtilsProvider} from '../../services/utils-ws/ws-utils';
import {AjaxClientProvider, ErrorConsulta} from '../../services/ajax-client/ajax-client.provider';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {FileProvider} from '../../services/file/file.provider';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {DateFormatPipe} from '../../pipes/date-format/date-format.pipe';
import {GemeProvider} from '../../services/geme/geme.provider';
import {SyncProvider} from '../../services/sync/sync.provider';
import {NetworkProvider} from '../../services/network/network.provider';
import {AppRoutes} from "../../config/app-routes";
import {Router} from "@angular/router";
import {NavigationUtilsService} from "../../services/navigation-utils/navigation-utils.service";
import moment from "moment/moment";
import { Directory, Filesystem } from '@capacitor/filesystem';

@Component({
  selector: 'page-parte-tabs',
  templateUrl: 'parte-tabs.html',
  styleUrls: ['parte-tabs.scss'],
})
export class ParteTabsPage {


  @ViewChild(SwipeTabDirective) swipeTabDirective: SwipeTabDirective;
  @ViewChild('parteTabs') tabRef: IonTabs;
  @ViewChild(IonBackButtonDelegate) backButton: IonBackButtonDelegate;

  screenWidth: any = 0;
  infoPage: any;
  fasesPage: any;
  resultadoPage: any;
  datosAdicionalesPage: any;
  anotacionesPage: any;
  draftByOffline = false;

  private backButtonListener?: Subscription;
  public constants = Data;
  appRoutes = AppRoutes;

  constructor(public navCtrl: NavController,
              public _ui: UiMessagesProvider,
              public _html: HtmlUtilsProvider,
              public _wsUtils: WsUtilsProvider,
              public _ajax: AjaxClientProvider,
              public _ptp: PartesTabProvider,
              public _pg: PersistenciaGeneralProvider,
              public _translate: TranslateProvider,
              public _gemeProvider: GemeProvider,
              public alertCtrl: AlertController,
              public loadingCtrl: LoadingController,
              public menuCtrl: MenuController,
              public platform: Platform,
              public _fp: FileProvider,
              public _sync: SyncProvider,
              public _network: NetworkProvider,
              public _messages: UiMessagesProvider,
              private modalCtrl: ModalController,
              private router: Router,
              private navUtils: NavigationUtilsService
  ) {
    this.initFases();

  }

  transition($event) {
    this.swipeTabDirective.onTabInitialized($event.tab);
  }

  onTabChange(index: string) {
    this.tabRef.select(index);
  }

  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
    this.setBackButtonAction();
  }

  setBackButtonAction() {
    this.backButton.onClick = (ev) => {
      this.goBack()
      ev.preventDefault()
    }
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
     this.goBack()
    });
  }

  goBack(): void {
    if (this._ptp && this._ptp.editando && !this._ptp.isOpenWarnings && !this._ptp.isOpenObservacionesParte) {
      this.atrasEditando();
    } else if (this._ptp.isOpenDesbloqueando || this._ptp.isOpenWarnings || this._ptp.isOpenObservacionesParte) {
      return;
    } else {
      this.navUtils.navigateBack();
    }
  }

  async aceptar() {
    // Check if the new date is in the past else show error msg
    if (new Date(moment(this._ptp.parte.dataHoraExecucio, DATE_TIME_FORMAT).toISOString()) <= new Date()) {
      if (this.passChecks()) {

        const warnings = this.processWarnings();

        if (warnings > '') {
          this._ptp.isOpenWarnings = true;
          const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txParteAdvertenciaGuardar", "txYes", "txNo", warnings);

          alert.onDidDismiss().then(({data}) => {
            if (data.accepted) {
              this.guardarParteProcess();
            }
            this._ptp.isOpenWarnings = false;
          });
          alert.present();
        } else {
          this.guardarParteProcess();
        }

      } else {
        this._ui.showToastDefault("txResponderFase");
      }
    } else {
      this._ui.showToastDefault("txPartExecutionDateWarning");
    }
  }

  async copiarParte() {
    const fechaFormateada = this.transformDate(this._ptp.parte.parteAnterior.dataHoraExecucio, true, true);
    const msg = this._translate.traducirWithParams(this._ptp.parteHasDifference ? "txImportarParteEditado" : "txImportarParteNoEditado", {
      codiParte: this._ptp.parte.parteAnterior.codiParte,
      dataHoraExecucio: fechaFormateada
    });

    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", msg, "txYes", "txNo");
    alert.onDidDismiss().then(({data}) => {
      if (data.accepted) {
        this.copiarParteProcess();
        return false;
      }
      return false;
    });
    alert.present();
  }

  copiarParteProcess() {
    this._ptp.parte.observacionsVersioParte = this._ptp.parte.parteAnterior.observacions
    this.EstatGenerate();

    for (let a = 0; a < this._ptp.parte.fases.length; a++) {
      for (let b = 0; b < this._ptp.parte.parteAnterior.fases.length; b++) {
        if (a == b) {
          this._ptp.parte.fases[a].idFaseModel = this._ptp.parte.parteAnterior.fases[b].idFaseModelExecucio;
          this._ptp.parte.fases[a].valor = this._ptp.parte.parteAnterior.fases[b].valor;
          this._ptp.parte.fases[a].observacions = this._ptp.parte.parteAnterior.fases[b].observacions;
          this._ptp.parte.fases[a].nomSeleccioOpcio = this._ptp.parte.parteAnterior.fases[b].nomSeleccioOpcio;
        }
      }
    }

    this._ptp.parte.materials = this._ptp.parte.parteAnterior.materials;
    this._ptp.parte.costos = this._ptp.parte.parteAnterior.costos;

    for (let c = 0; c < this._ptp.parte.operaris.length; c++) {
      for (let d = 0; d < this._ptp.parte.parteAnterior.operaris.length; d++) {
        if (c == d) {
          this._ptp.parte.operaris[c].idOperari = this._ptp.parte.parteAnterior.operaris[d].idOperari;
          this._ptp.parte.operaris[c].tempsMinuts = this._ptp.parte.parteAnterior.operaris[d].temps;
          this._ptp.parte.operaris[c].descripcio = this._ptp.parte.parteAnterior.operaris[d].descripcio;
        }
      }
    }
  }

  private EstatGenerate() {

    switch (this._ptp.parte.parteAnterior.tipusEstatParteRealitzat) {
      case PARTES_TIPO_ESTADO_CORRECTO: {
        this._ptp.parte.colorEstadoParteToResult = this._gemeProvider.getColorEstadoParte(PARTES_TIPO_ESTADO_CORRECTO);
        this._ptp.parte.txEstadoParteToResult = this._translate.traducir(this._gemeProvider.getTextoEstadoParte(PARTES_TIPO_ESTADO_CORRECTO));
        this._ptp.parte.tipusEstatParteRealitzat = PARTES_TIPO_ESTADO_CORRECTO;
        this._ptp.parte.srcImgEstadoParteToResult = this._gemeProvider.getSrcImgEstadoParte(PARTES_TIPO_ESTADO_CORRECTO);
        break;
      }
      case PARTES_TIPO_ESTADO_INCORRECTO: {
        this._ptp.parte.colorEstadoParteToResult = this._gemeProvider.getColorEstadoParte(PARTES_TIPO_ESTADO_INCORRECTO);
        this._ptp.parte.txEstadoParteToResult = this._translate.traducir(this._gemeProvider.getTextoEstadoParte(PARTES_TIPO_ESTADO_INCORRECTO));
        this._ptp.parte.tipusEstatParteRealitzat = PARTES_TIPO_ESTADO_INCORRECTO;
        this._ptp.parte.srcImgEstadoParteToResult = this._gemeProvider.getSrcImgEstadoParte(PARTES_TIPO_ESTADO_INCORRECTO);
        break;
      }
      case PARTES_TIPO_ESTADO_APLAZADO: {
        this._ptp.parte.colorEstadoParteToResult = this._gemeProvider.getColorEstadoParte(PARTES_TIPO_ESTADO_APLAZADO);
        this._ptp.parte.txEstadoParteToResult = this._translate.traducir(this._gemeProvider.getTextoEstadoParte(PARTES_TIPO_ESTADO_APLAZADO));
        this._ptp.parte.tipusEstatParteRealitzat = PARTES_TIPO_ESTADO_APLAZADO;
        this._ptp.parte.srcImgEstadoParteToResult = this._gemeProvider.getSrcImgEstadoParte(PARTES_TIPO_ESTADO_APLAZADO);
        break;
      }
      case PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA: {
        this._ptp.parte.colorEstadoParteToResult = this._gemeProvider.getColorEstadoParte(PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA);
        this._ptp.parte.txEstadoParteToResult = this._translate.traducir(this._gemeProvider.getTextoEstadoParte(PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA));
        this._ptp.parte.tipusEstatParteRealitzat = PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA;
        this._ptp.parte.srcImgEstadoParteToResult = this._gemeProvider.getSrcImgEstadoParte(PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA);
        break;
      }

      default: {
        this._ptp.parte.colorEstadoParteToResult = PARTES_COLOR_ESTADO_NULO;
        this._ptp.parte.txEstadoParteToResult = this._translate.traducir("txPartesEstadoSinSeleccionar");
        this._ptp.parte.tipusEstatParteRealitzat = null;
        this._ptp.parte.srcImgEstadoParteToResult = "assets/imgs/geme/icoNinguno.png";
        break;
      }
    }
  }


  guardarParteProcess() {
    this.guardarParte()
      .then((res: any) => {

        if (res.resultat == 'OK') {
          this._ui.showToastDefault(this._pg.workingOffline ? "txParteEnviaraEnSync" : "txParteEnviadoExito");
          this._ptp.editando = false;
          if (!this.draftByOffline) {
            this._ptp.removeDraft();
          }
          const callback = this.navUtils.get(AppRoutes.parteTabsPage);
          callback().then(() => {
            this.navUtils.navigateBack()
          });
        }
      });
  }

  processWarnings() {
    let msg = "";

    for (let i = 0; i < this._ptp.parte.materials.length; i++) {
      if (!(this._ptp.parte.materials[i].quantitatUtilitzada)) {
        msg += "• "
        msg += this._translate.traducir("txParteFaltaCantidadMateria");
        msg += "<br>"
        break;
      }
    }

    for (let i = 0; i < this._ptp.parte.fases.length; i++) {
      if (this._ptp.parte.fases[i].tipusRespostaFase == PARTES_RESPUESTA_CORRECTO_INCORRECTO ||
        this._ptp.parte.fases[i].tipusRespostaFase == PARTES_RESPUESTA_NO_SI ||
        this._ptp.parte.fases[i].tipusRespostaFase == PARTES_RESPUESTA_SI_NO) {
        const result = this._ptp.parte.fases[i].valor == "0" || this._ptp.parte.fases[i].valor == "1" ? true : false;
        if (!result) {
          msg += "• "
          msg += this._translate.traducir("txParteFasesIncompletas");
          msg += "<br>"
          break;
        }
      } else if (this._ptp.parte.fases[i].tipusRespostaFase == PARTES_RESPUESTA_HECHO) {
        const result = this._ptp.parte.fases[i].valor == "1" ? true : false;
        if (!result) {
          msg += "• "
          msg += this._translate.traducir("txParteFasesIncompletas");
          msg += "<br>"
          break;
        }
      } else {
        const result = this._ptp.parte.fases[i].valor > '';
        if (!result) {
          msg += "• "
          msg += this._translate.traducir("txParteFasesIncompletas");
          msg += "<br>"
          break;
        }
      }
    }

    if (!this._ptp.parte.tipusEstatParteRealitzat) {
      msg += "• "
      msg += this._translate.traducir("txParteFaltaEstado");
      msg += "<br>"
    }

    return msg;
  }

  async editar() {
    if (this._ptp.esParteFuturo()) {
      this._ui.showToastDefault("txEsParteFuturo");
      return;
    }

    if (this._ptp.parteTieneIncidencias()) {
      this._ui.showToastDefault("txParteTieneIncidencias");
      return;
    }

    if (!this._ptp.getPermisoPuedeEditarPartes()) {

      const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txNoTienePermisoEditarParte", "txOk", null, null, false);
      alert.onDidDismiss().then(() => false);

      return;
    }

    if (this._ptp.parte.bloqueig == 1) {
      if (!this._ptp.isOpenDesbloqueando) {
        this._ptp.isOpenDesbloqueando = true;
        if (!this._ptp.getPermisoPuedeEditarBloqueigParte()) {

          const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txNoTienePermisoDesbloquearParte", "txOk", null, null, false);
          alert.onDidDismiss().then(({data}) => {
            this._ptp.isOpenDesbloqueando = false;
            return false;
          });

        } else {
          const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txPreguntDesbloquearParte", "txYes", "txNo");
          alert.onDidDismiss().then(({data}) => {
            if (data.accepted) {
              this._ptp.parte.bloqueig = 0;
              this._ptp.editando = true;
              this._ptp.isOpenDesbloqueando = false;
              return;
            }
            this._ptp.isOpenDesbloqueando = false;
            return;
          });
          return;
        }
      }
    } else {
      this._ptp.editando = true;
    }
  }

  async atrasEditando() {
    if (!this._ptp.isOpenAtrasEditando) {
      this._ptp.isOpenAtrasEditando = true;
      const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txSeguroCancelarEdicion", "txYes", "txNo");

      alert.onDidDismiss().then(({data}) => {
        this._ptp.isOpenAtrasEditando = false;
        if (data.accepted) {
          this._ptp.editando = false;
          this._ptp.removeDraft();
          this.navUtils.navigateBack()
        }
      });
    }
  }

  private passChecks() {
    let result = false;

    if (this._ptp.parte.tipusEstatParteRealitzat == this.constants.PARTES_TIPO_ESTADO_CORRECTO) {

      for (let i = 0; i < this._ptp.parte.fases.length; i++) {
        if (this._ptp.parte.fases[i].tipusRespostaFase == PARTES_RESPUESTA_CORRECTO_INCORRECTO ||
          this._ptp.parte.fases[i].tipusRespostaFase == PARTES_RESPUESTA_NO_SI ||
          this._ptp.parte.fases[i].tipusRespostaFase == PARTES_RESPUESTA_SI_NO) {
          result = this._ptp.parte.fases[i].valor == "0" || this._ptp.parte.fases[i].valor == "1" ? true : false;
          if (result) {return true;}
        } else if (this._ptp.parte.fases[i].tipusRespostaFase == PARTES_RESPUESTA_HECHO) {
          result = this._ptp.parte.fases[i].valor == "1" ? true : false;
          if (result) {return true;}
        } else {
          result = this._ptp.parte.fases[i].valor > '';
          if (result) {return true;}
        }
      }
      ;
    } else {
      result = true;
    }

    return result;
  }

  async prepararFicherosFase() {
    // let resolvedFinalArray =
    await Promise.all(this._ptp.parte.fases.map(async (value) => {
      if (value.fitxerEnviar) {
        const filePath: string = this._fp.pathDestinoTemp + "/" + value.valor;
        value.base64 = await this._fp.toBase64Image(filePath);
        return value;
      }
    }));
    return this._sync.prepararParte(this._ptp.parte);
  }

  async prepararFicherosAdjuntos() {
    // let resolvedFinalArray =
    await Promise.all(this._ptp.parte.adjunts.map(async (value) => { // map instead of forEach
      if (value.tamany === FICHERO_NO_ENVIADO) {
        const filePath: string = this._fp.pathDestinoTemp + "/" + value.fitxerEnviar.contingutFitxer + "." + value.fitxerEnviar.extensioFitxer;

        value.base64 = await this._fp.toBase64ImageIndicatingTheDirectory(filePath, Directory.Data);
        return value;
      }
    }));
    return true;
  }

  guardarParte() {
    return new Promise(async (resolve, reject) => {
      this.draftByOffline = false;
      const loading = await this._ui.showLoading("txGuardandoParte");
      this.prepararFicherosAdjuntos()
        .then(() => {
          this.prepararFicherosFase()
            .then(async () => {
              if (!this._pg.workingOffline) {
                if (this._network.appOnline) {
                  this.saveInServer(PARAM_GUARDAR_PARTE_APP, this._sync.paramsToPost)
                    .then((res: any) => {
                      loading.dismiss();
                      resolve(res)
                    })
                    .catch(err => {
                      // console.error(JSON.stringify(err))
                      loading.dismiss();
                      resolve(false)
                    });
                } else {


                  const msg = this._translate.traducir("txNoSePuedeEnviarParte");
                  const alert = await this._messages.showYesNoAlert({backdropDismiss: false}, "txAtencionStr", msg, "txYes", "txNo");
                  alert.onDidDismiss().then(({data}) => {
                    if (data.accepted) {
                      this.draftByOffline = true;
                      this._ptp.setDraft();
                      loading.dismiss();
                      const res = {
                        resultat: "OK"
                      }
                      resolve(res);
                    } else {
                      resolve(false);
                    }
                  });

                }
              } else {
                this._sync.setEditado(this._ptp.parte.idParte, this._pg.proyectoSeleccionado.idProjecte, this._ptp.parte.tipusEstatParteRealitzat)
                  .then(async () => {
                    await this._sync.setDetalleParte(this._ptp.parte.idParte, this._ptp.parte);
                    loading.dismiss();
                    const res = {
                      resultat: "OK"
                    }
                    resolve(res);
                  })
                  .catch(err => {
                    // console.log(JSON.stringify(err))
                    loading.dismiss();
                    resolve(false)
                  });
              }
            });
        })
    });
  }


  saveInServer(paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {

      this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
        .subscribe((res: any) => {
            resolve(res);
          },
          (errConsulta: ErrorConsulta) => {
            this._ui.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);
            resolve(false);
          });

    })
      .catch(err => {
        // console.error("error en descargarLlistaPartesFromServer: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });


    return promesa;
  }


  initFases() {
    this._ptp.parte.fases.forEach(fase => {
      if (fase.tipusRespostaFase == PARTES_RESPUESTA_CORRECTO_INCORRECTO ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_HECHO ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_NO_SI ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_SI_NO) {
        fase.valor = fase.valor == "1" || fase.valor == "0" ? fase.valor : "-1"
      }
    });
  }

  private transformDate(date: string, addTime: boolean, withoutSeconds: boolean = true): string {
    if (!date) {
      return '';
    }

    const dateObj = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: addTime ? '2-digit' : undefined,
      minute: addTime ? '2-digit' : undefined,
      second: withoutSeconds ? undefined : '2-digit'
    };

    const formattedDate = dateObj.toLocaleDateString(this._pg.selectedLanguage, options);

    return formattedDate;
  }
}
