import { Injectable } from '@angular/core';
import { File, FilePicker } from '@capawesome/capacitor-file-picker';

@Injectable({
  providedIn: 'root',
})
export class FileChooser {
  static readonly PICKER_CANCELLED_ERROR = 'pickFiles canceled.';
  /**
   * Return the uri of selected file
   * param types? List of accepted file types.
   * Example: `['image/png', 'application/pdf']`
   */
  open(types?: string[]): Promise<File> {
    if(types) {
      return FilePicker.pickFiles({
        multiple: false,
        readData: true,
        types,
      }).then(result => result.files[0]);
    }

    return FilePicker.pickFiles({
      multiple: false,
      readData: true,
    }).then(result => result.files[0]);
  }
}
