<ion-grid class="header-elements">
    <ion-row>
        <ion-col size="6">
            <h5 *ngIf="_ptp.parte.codiParte && _ptp.parte.codiParte !== ''">{{codigoStr}} {{_ptp.parte.codiParte}}</h5>
            <h5 *ngIf="_ptp.parte.codiParte === ''">{{partePropuestoStr}}</h5>
        </ion-col>
        <ion-col size="6">
            <div class="ion-text-right">{{_ptp.parte.forqueta}}</div>
            <div class="ion-text-right">{{fechaEstStr}} {{_ptp.parte.dataHoraPlanificacio | dateFormat:false}}</div>
        </ion-col>
    </ion-row>
    <ion-row class="header-elements">
        <ion-col size="10">
            <table class="tabla-fixed">
                <tr>
                    <td style="width:38px">
                        <img [src]="imagenElementoNormalizada" onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';">
                    </td>
                    <td>
                        <p style="padding-left:3px;margin: 0 0 2px">
                            <strong><span *ngIf="_ptp.parte.codiElement && _ptp.parte.codiElement !== ''">{{_ptp.parte.codiElement}}
                                - </span>
                            {{_ptp.parte.nomElement}}</strong>
                        </p>
                        <p style="padding-left:3px;margin: 0 0 2px" class="ion-text-wrap">{{_ptp.parte.rutaElement}}</p>
                    </td>
                </tr>
            </table>
        </ion-col>
        <ion-col size="2">
            <ion-button color="light" class="ion-float-right" [disabled]="isEdit" (click)="_etp.getElement(_ptp.parte.idElement)">
                <ion-icon slot="icon-only" name="lc-icon-info"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <h5>{{ _ptp.parte.codiModel + " - " + _ptp.parte.nomModel }}</h5>
        </ion-col>
        <ion-col size="2" *ngIf="imagenTipoTrabajoNormalizada>''">
            <img [src]="imagenTipoTrabajoNormalizada" onerror="this.onerror=null;this.src='assets/imgs/no_image_ele.png';" class="ion-float-right">
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <img class="icon-align-middle" style="padding-right: 1rem;" [src]="_ptp.parte.srcImgEstadoParte"><span [ngStyle]="{'color': _ptp.parte.colorEstadoParte }"><strong>{{_ptp.parte.txEstadoParte}}</strong></span>
        </ion-col>
        <ion-col>
            <span>{{ fechaEjecucionStr }}: </span>
            <span *ngIf="executionDate; else noDate"><strong>{{ executionDate | dateFormat: true : true }}</strong></span>
            <ng-template #noDate><span class="no-value">{{ 'txSinValorStr' | translate }}</span>
            </ng-template>
        </ion-col>
    </ion-row>
</ion-grid>
