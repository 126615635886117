import {Component} from '@angular/core';
import {AlertController, LoadingController, ModalController, Platform} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {AjaxClientProvider} from '../../services/ajax-client/ajax-client.provider';
import {FileProvider} from '../../services/file/file.provider';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {ResourcesProvider} from '../../services/resources/resources.provider';
import {SyncProvider} from '../../services/sync/sync.provider';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {WsUtilsProvider} from '../../services/utils-ws/ws-utils';


@Component({
  selector: 'page-sync-confirm',
  templateUrl: 'sync-confirm.html',
  styleUrls: ['sync-confirm.scss'],
})
export class SyncConfirmPage {

  syncImatgesElements = true;
  ultimaSincronizacion = '';

  downloadResult: any = null;

  private backButtonListener?: Subscription;

  constructor(
              public modalCtrl: ModalController,
              public platform: Platform,
              public _pg: PersistenciaGeneralProvider,
              public _messages: UiMessagesProvider,
              public _htmlUtils: HtmlUtilsProvider,
              public _ajax: AjaxClientProvider,
              public _sync: SyncProvider,
              public _wsUtils: WsUtilsProvider,
              public _translate: TranslateProvider,
              public _rp: ResourcesProvider,
              public _fp: FileProvider,
              public loadingCtrl: LoadingController,
              public alertCtrl: AlertController) {

                this.syncImatgesElements = this._pg.usuarioLoginModel.syncImatgesElements == 1;
                this.ultimaSincronizacion = this._pg.usuarioLoginModel.ultimaSincronizacion;
  }

    ionViewWillLeave() {
      this.backButtonListener?.unsubscribe();
    }

    async initializeBackButtonCustomHandler(): Promise<void> {
      this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
        if (this._sync.sincronizando) {
          return;
        }
        this.modalCtrl.dismiss();
      });
    }

    ionViewDidEnter() {
      this.syncImatgesElements = this._pg.usuarioLoginModel.syncImatgesElements == 1;
      // console.log('ionViewDidEnter SyncConfirmPage');
      this.initializeBackButtonCustomHandler();

      this._sync.pagina = 1;
      this._sync.paginaDetallePartes = 1;
      this._sync.paginaAdjuntos = 1;

      this._sync.partesResumenSync = [];
    }
     /*** fin trabajo con el boton atras */

  cerrarSinParametros(){
    this.modalCtrl.dismiss();
  }


  cerrarConParametros(){
    this._sync.syncConfirm(this.modalCtrl, this.loadingCtrl, true);
  }


}
