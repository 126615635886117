import {AlertButton, AlertInput} from '@ionic/core';
import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';
import {NetworkProvider} from '../network/network.provider';
import {PersistenciaGeneralProvider} from '../persistencia-configuracion/persistencia-general.provider';
import {TranslateProvider} from '../translate/translate.provider';
import {UiMessagesProvider} from '../ui-messages/ui-messages';
import {HtmlUtilsProvider} from '../html-utils/html-utils';
import {SyncConfirmPage} from '../../pages/sync-confirm/sync-confirm';
import {SyncProvider} from '../sync/sync.provider';
import {MINUTES_TIME_SYNC, SYNC_HOURS_CHECK, SYNC_HOURS_DELAY} from '../../config/constantes';
import {Proyecto} from '../../domains/proyecto-domain';
import {EventService} from '../events/event.service';


@Injectable({
  providedIn: 'root',
})
export class StatusProvider {

  mostrandoAlerCheckTime: any = false;

  constructor(public _np: NetworkProvider,
              public _pg: PersistenciaGeneralProvider,
              public alertCtrl: AlertController,
              public events: EventService,
              public _translate: TranslateProvider,
              public _ui: UiMessagesProvider,
              public _html: HtmlUtilsProvider,
              public _sync: SyncProvider,
              public loadingCtrl: LoadingController,
              public modalCtrl: ModalController) {
    // console.log('Hello StatusProvider Provider');
  }

  async cambiarModo(fromSync: boolean){

    if (!this.validaAccesoSinConexion()) {return;}

    //si la diferencia en minutos no supera el minimo (actualmente 5 min) no se debe sincronizar
    if(this._sync.minUpdate && this._html.getMinuteDiff(this._sync.minUpdate.getTime(), new Date().getTime()) < MINUTES_TIME_SYNC){
      this.actionToOffline(false);
    }else{
      //si esta trabajando online
      if(!this._pg.workingOffline){

        //si No hay conexión a internet alerto
        await this._np.ping();
        if(!this._np.appOnline){
          const msg = this._translate.traducir( 'txAvisoNoSePudoSincronizar') + this._html.getDateToHuman(this._pg.usuarioLoginModel.ultimaSincronizacion);
          const alert = await this._ui.showOkAlert('txAtencionStr', msg, 'txAceptar');

          alert.onDidDismiss().then(({data}) => {
            this.actionToOffline(fromSync);
          });

          alert.present();

          //si no existe sincronización previa alerto que debe sincronizar para cambiar modo
        } else if(!(this._pg.usuarioLoginModel.ultimaSincronizacion > '')){
          const msg = this._translate.traducir( 'txNecesitaSincronizar');
          const alert = await this._ui.showYesNoAlert({}, 'txAtencionStr', msg, 'txYes', 'txNo');

          alert.onDidDismiss().then(async ({data}) => {
            if(data.accepted){
              //responde OK entonces sincronizo y cambio de modo
              await this.validateSync(fromSync);
            }else{
              return;
            }
          });
        } else{
          //salida si por defecto si no cumple condiciones anteriores
          //pregunto si quiere sincronizar. Si responde que NO entonces solo cambio el modo
          const msg = this._translate.traducirWithParams( 'txUltimaSincronizacionDeseaSincronizar', {time: this._html.getDateToHuman(this._pg.usuarioLoginModel.ultimaSincronizacion)});
          const alert = await this._ui.showYesNoAlert({},'txAtencionStr', msg, 'txYes', 'txNo');

          alert.onDidDismiss().then(async ({data}) => {
            if(data.accepted){
              await this.validateSync(fromSync);
            }else{
              this.actionToOffline(false);
            }
          });
        }

      }else{
        if (this._np.appOnline) {
          // Execute the synchronization process
          await this._sync.sendLocalData();
        }
        // Perform the actionToOnline function regardless of the online status
        this.actionToOnline(fromSync);
      }
    }

    //notifico el cambio para que se refresquen los listados
    this.changeProjectAndPublish(this._pg.proyectoSeleccionado.idProjecte);
  }


  validateSync(fromSync: any){
    const anySync = this.checkAnyDamOffline();
    if(anySync){
      // console.log('app online. entonces sincronizo ....');
      this._sync.syncConfirm(null, this.loadingCtrl, false)
          .then(  ()=>{
            this.actionToOffline(fromSync);
          });
    }else{
      this.alertAgregarPresa('txOfflineModeStr');
    }
  }

  actionToOffline(fromSync: boolean){
    //valida que pueda cambiar a modo offline
    const exists = this._pg.proyectosDisponibles.find(p => p.configSync == 1 && p.idProjecte == this._pg.proyectoSeleccionado.idProjecte);
    if(exists){
      this._pg.workingOffline = !this._pg.workingOffline;
      if(fromSync){
        this._ui.showToastDefault('txSincronizacionExitosayModoOffline');
      }
    }else{
      this.checkDamAndValidateMode('txOfflineModeStr');
    }
  }

  actionToOnline(fromSync: boolean){
    if(fromSync){
      this._ui.showToastDefault('txSincronizacionExitosa');
    }else{
      this._pg.workingOffline = !this._pg.workingOffline;
    }
  }

  async mostrarModal(changeMode: boolean){
    const modal = await this.modalCtrl.create({component:SyncConfirmPage} );
    modal.present();

    modal.onDidDismiss().then(
      ({data}) => {
        if(data){
          this._sync.momentoRecordarAvisoSincronizacion = null;
          // this.ultimaSincronizacion = this._html.getDateToHuman(this._pg.usuarioLoginModel.ultimaSincronizacion);

          if(changeMode){
            this.cambiarModo(true);
          }

        }else{
          // console.log('Se cerró el modal sin parametros');
        }

      }
    );
  }

  checkAnyDamOffline(){
    for (let i = 0; i < this._pg.proyectosDisponibles.length; i++){
      if ( this._pg.proyectosDisponibles[i].configSync == 1 ){
        return true;
      }
    }
  }

  cambiarProyectoOffline(){
    this.doRadioProyectos(this._pg.proyectosDisponibles.filter(p => p.configSync == 1), 'txSeleccionePresaTrabajoOffline')
        .then( async (data) => {
          // console.log('imprimo valor data de la promesa.... ' + data);
          if(!data || this._pg.proyectoSeleccionado == null){
            await this._ui.showOkAlert('txAtencionStr', 'txNoSeHaCambiadoModoOffline', 'txAceptarConfiguracion');
            return false;
          }else{
            await this.guardarProyectoSeleccionado(this._pg.proyectoSeleccionado);
            this._pg.workingOffline = !this._pg.workingOffline;
            this._ui.showToastDefault('txSeHaCambiadoModoOffline');
          }
        });
  }

  async sincronizarAction(changeMode: boolean){
    if(!this._np.appOnline){
      await this._ui.showOkAlert('txAtencionStr', 'txNoSePuedeSincronizar', 'txAceptar');
      return;
    }

    this.checkDamAndValidateSync('txSynchronizeStr', changeMode);
  }

  /*
    Método que según si corresponde mostrar advertencia visualiza un yes no alert para sincronizar o posponer
  */
  async validateTimeSyncAction(){
    if(!this.mostrandoAlerCheckTime && this._sync.checkTimeConnection()){
      this.mostrandoAlerCheckTime = true;

      const msg = this._translate.traducirWithParams( 'txSyncTimeMessage', { hours: SYNC_HOURS_CHECK});
      const msgRecordarmelo = this._translate.traducirWithParams( 'txRecordarmelo', { hours: SYNC_HOURS_DELAY});
      const alert = await this._ui.showYesNoAlert({backdropDismiss: false}, 'txAtencionStr', msg, 'txSincronizar', msgRecordarmelo);
      alert.onDidDismiss().then(({data}) => {
        if(data.accepted){
          this.sincronizarAction(false);
        }else{
          this._sync.momentoRecordarAvisoSincronizacion = this._html.getDateToMoment(this._html.generateManualDate()).add(SYNC_HOURS_DELAY, 'hours');
          // console.log('momentoRecordarAvisoSincronizacion  --> ' + this._sync.momentoRecordarAvisoSincronizacion);
        }
        this.mostrandoAlerCheckTime = false;
      });
    }
  }

  async modalSync(){

      const alert = await this._ui.showYesNoAlert({backdropDismiss: false}, 'txAtencionStr', 'txNecesitaSincronizar', 'txSincronizar', 'txCancelar');
      alert.onDidDismiss().then(({data}) => {
        if(data.accepted){
          this.sincronizarAction(true);
        }
      });
  }


 //#region cambiar proyecto
 doRadioProyectos(proyectos: Array<Proyecto>, titulo: any) {

  return new Promise( async (resolve, reject)=>{

    let alertInputs: AlertInput[] = [];

    if(!this._pg.workingOffline){
      alertInputs =  proyectos.map((proy, index) => ({
        type: 'radio',
          label: proy.nom,
          value: String(proy.idProjecte),
          checked: (this._pg.proyectoSeleccionado != null && this._pg.proyectoSeleccionado.idProjecte ==proy.idProjecte ? true : (this._pg.proyectoSeleccionado == null && index == 0 ? true : false))
      }));
    }else{
      alertInputs = proyectos.filter(p => p.configSync == 1).map((proy, index) =>  ({
          type: 'radio',
          label: proy.nom,
          value: String(proy.idProjecte),
          checked: (this._pg.proyectoSeleccionado != null && this._pg.proyectoSeleccionado.idProjecte ==proy.idProjecte ? true : (this._pg.proyectoSeleccionado == null && index == 0 ? true : false))
        }));
    }

    const alertButtons: AlertButton[] = [
      {
        text: this._translate.traducir('txCancelarAlertSeleccionarProyectoLogin'),
        handler: data => {
          // console.log('cancel');
          resolve(false);
        }
      },
      {
        text: this._translate.traducir('txAceptarAlertSeleccionarProyectoLogin'),
        handler: data => {
          if(data != null){

            if(!this._pg.workingOffline){
              this.changeProjectAndPublish(data);
              resolve(true);
            }else{
              this._sync.getArbre(data).then( ()=>{
                if(this._sync.localArbre && this._sync.localArbre.length > 0){
                  this.changeProjectAndPublish(data);
                  resolve(true);
                }else{
                  this.modalSync();
                  resolve(false);
                }
              });
            }
          }
        }
      }
    ];

    const alert = await this.alertCtrl.create({
      header: this._translate.traducir(titulo),
      inputs: alertInputs,
      buttons: alertButtons,
    });

    alert.present().then((data) => {
      // console.log(JSON.stringify(data));
    });
  });

}

changeProjectAndPublish(data: any){
  this._pg.proyectoSeleccionado =  this._pg.proyectosDisponibles.find(x => x.idProjecte == data);
  this.events.publish('proyectoSeleccionadoMenu:created', [this._pg.proyectoSeleccionado, false]);
}


async guardarProyectoSeleccionado(proyecto: Proyecto){

  if(proyecto != null && proyecto.idGrupoGestio != null){
    await this._pg.updateProjecteSeleccionat(proyecto.idProjecte, this._pg.usuarioLoginModel.idUsuari);
  }
}

async checkDamAndValidateMode(paramText: string){
  const anySync = this.checkAnyDamOffline();
  if(anySync){
    await this.cambiarProyectoOffline();
  }else{
    this.alertAgregarPresa(paramText);
  }
}


checkDamAndValidateSync(paramText: string, changeMode: boolean){
  const anySync = this.checkAnyDamOffline();
  if(anySync){
    this.mostrarModal(changeMode);
  }else{
    this.alertAgregarPresa(paramText);
  }
}

async alertAgregarPresa(paramText: string){
  const param = this._translate.traducir(paramText);
  const msg = this._translate.traducirWithParams( 'txMsgSincronizarAlMenosUnaPresa', { accion: param});
  await this._ui.showOkAlert('txAtencionStr', msg, 'txAceptarConfiguracion');
}


validaAccesoSinConexion(){
  if(!this._np.appOnline && this._pg.workingOffline){
    this._ui.showToastDefault('txSinConexion');
    return false;
  }

  return true;
}
//#endregion

}
