import { FitxerEnviarData } from "./fitxer-enviar-data-domain";

export interface AdjuntData {
    idDocument?:     number;
    idGemeAdjunt?:   number;
    tipus:          TipusAdjunt;
    nom:            string;
    nomFitxerLocal: string;
    tamany:         string; // Por ejemplo "5 MB"
    fitxerEnviar?:  FitxerEnviarData;
    devicePath?:    string;
    base64?:        string;
}

export enum TipusAdjunt{
    ADJ = "ADJ",
    DOC = 'DOC',
}
