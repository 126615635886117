import { Injectable } from '@angular/core';
import { Proyecto, Permiso, ProjecteDB, UsuariProjecteDB } from '../../domains/proyecto-domain';
import { UsuariDB, Usuario, UsuariModelElementFavoritoDB } from '../../domains/usuario-domain';
import { EventService } from '../events/event.service';
import { StorageProvider } from '../storage/storage.provider';
import { ConfiguracioServidorDB } from '@app/domains/configuracio-servidor-domain';
import { PARAM_ID_PROJECTE } from '../../config/constantes';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root',
})
export class PersistenciaGeneralProvider {
  static TABLA_CONFIGURACIO_SERVIDOR = 'ConfiguracioServidor';
  static TABLA_USUARIS = 'Usuaris';
  static TABLA_PROJECTES = 'Projectes';
  static TABLA_USUARIS_PROJECTES = 'UsuarisProjectes';
  static TABLA_USUARIS_MODELS_ELEMENTS = 'UsuarisModelsElements';

  configuracionList = [];

  availableLanguages = [];
  urlBases = [];
  defaultLanguaje: any = null;
  selectedLanguage: string = null;
  nomModulGeme: any = null;
  usuarioLoginModel: Usuario;
  versionApp = '';

  nomModulFitxaTecnica: any = null;
  nomModulArxiuTecnic: any = null;

  idModulFitxaTecnica: any = null;
  idModulArxiuTecnic: any = null;

  proyectosDisponibles: Array<Proyecto> = [];
  proyectoSeleccionado: Proyecto;

  idiomaInglesKey = 'txIdiomaIngles';
  idiomaEspanolKey = 'txIdiomaEspanol';

  workingOffline = false;

  idNotPlanned = 0;

  favs: Array<number> = [];

  constructor(
    public events: EventService,
    private storage: StorageProvider

  ) {

  }


  /***********************************************************************************************
   *
   *   Tabla ConfiguracioServidor
   *
   ************************************************************************************************/
  async insertConfiguracioServidor(configuracion: ConfiguracioServidorDB) {
    await this.saveOnIndexedDB(PersistenciaGeneralProvider.TABLA_CONFIGURACIO_SERVIDOR, JSON.stringify(configuracion), Directory.Documents, Encoding.UTF16);
  }

  async deleteConfiguracioServidor() {
    await this.storage.remove(PersistenciaGeneralProvider.TABLA_CONFIGURACIO_SERVIDOR);
  }

  async getConfiguracioServidorFromDb(): Promise<ConfiguracioServidorDB> {
    let strConf = null;
    try {
      const response = await this.getFileOnIndexedDB(PersistenciaGeneralProvider.TABLA_CONFIGURACIO_SERVIDOR, Directory.Documents, Encoding.UTF16);
      strConf = response?.data;
    } catch (err) {
      // console.log('response ERROR', err)
    }
    if (strConf) {
      const conf: ConfiguracioServidorDB = JSON.parse(strConf);
      this.setAjustesConfiguracion(conf);
      return conf;
    } else {
      // console.warn('No hay configuración guardada');
      return null;
    }
  }


  setAjustesConfiguracion(config: ConfiguracioServidorDB) {

    // console.log('voy a persistir en memoria la sig. config. ' + JSON.stringify(config));

    //si existe configuración
    if (config && config.versioConfig > 0) {

      //inicializo antes de cargar
      this.availableLanguages = [];
      this.urlBases = [];

      //se recuperan los idiomas disponibles y se itera para armar el formato necesario
      const languajes = config.idiomesDisponibles.split(',');
      languajes.forEach(element => {

        let lang = {};

        switch (element) {
          case 'ES': {
            lang = { value: 'es', label: this.idiomaEspanolKey };
            this.availableLanguages.push(lang);
            break;
          }
          case 'EN': {
            lang = { value: 'en', label: this.idiomaInglesKey };
            this.availableLanguages.push(lang);
            break;
          }
          default: {
            lang = { value: 'es', label: this.idiomaEspanolKey };
            this.availableLanguages.push(lang);
            break;
          }
        }
      });


      //se pareparan las urls_bases obtenidas para guardar en storage
      if (config.urlBase1.length > 0) { this.urlBases.push(config.urlBase1); }

      if (config.urlBase2.length > 0) { this.urlBases.push(config.urlBase2); }

      if (config.urlBase3.length > 0) { this.urlBases.push(config.urlBase3); }

      if (config.urlBase4.length > 0) { this.urlBases.push(config.urlBase4); }

      if (config.urlBase5.length > 0) { this.urlBases.push(config.urlBase5); }


      //seteo para guardar elementos
      if (this.defaultLanguaje === null) { this.setMemoryLanguage(config.idiomaDefecte); };


      this.nomModulGeme = config.nomModulGeme;
      this.events.publish('nomModulGeme:created', this.nomModulGeme);

      this.nomModulFitxaTecnica = config.nomModulFitxaTecnica;
      this.events.publish('nomModulFitxaTecnica:created', this.nomModulFitxaTecnica);

      this.idModulFitxaTecnica = config.idModulFitxaTecnica;
      this.events.publish('idModulFitxaTecnica:created', this.idModulFitxaTecnica);

      this.nomModulArxiuTecnic = config.nomModulArxiuTecnic;
      this.events.publish('nomModulArxiuTecnic:created', this.nomModulArxiuTecnic);

      this.idModulArxiuTecnic = config.idModulArxiuTecnic;
      this.events.publish('idModulArxiuTecnic:created', this.idModulArxiuTecnic);

    } else {
      // console.log('no existen idiomas para cargar');
    }
  }

  setUsuarioMemoria() {
    this.events.publish('usuarioLogin:created', this.usuarioLoginModel.login);
  }

  setMemoryLanguage(lang: string) {
    this.defaultLanguaje = lang.toLowerCase();
    this.selectedLanguage = this.defaultLanguaje;
  }


  resetAjustesConfiguracionUsuario() {
    this.configuracionList = [];

    this.availableLanguages = [];
    this.urlBases = [];
    this.defaultLanguaje = null;
    this.nomModulGeme = null;
    this.nomModulFitxaTecnica = null;
    this.nomModulArxiuTecnic = null;
    this.idModulFitxaTecnica = null;
    this.idModulArxiuTecnic = null;

    //solo se está usando y tiene valor en local

    this.usuarioLoginModel = null;

    //limpio proyectos del uusuario
    this.proyectosDisponibles = [];

    this.events.unsubscribe('urlFitxaTecnica:created');
    this.events.unsubscribe('urlArxiuTecnic:created');
    this.events.unsubscribe('baseUrlOk:created');
  }


  agregarProyectosDispnibles(proyectos: any) {
    // console.log("agregarProyectosDispnibles2", JSON.stringify(proyectos));

    this.proyectosDisponibles = [];

    for (const idProjecte in proyectos) {
      // console.log("idProjecte: ", JSON.stringify(idProjecte));
      if (proyectos.hasOwnProperty(idProjecte)){
        // console.log("idProjecte: ", idProjecte);
        const permExtrac = proyectos[idProjecte];
        const permisos = this.agregarPermisos(permExtrac.permisos);
        const p = new Proyecto(parseInt(idProjecte), permExtrac.idGrupGestio, permExtrac.abr, permExtrac.nom, permisos, 0);
        // console.log("Projecte: ", JSON.stringify(p));
        this.proyectosDisponibles.push(p);
      }
    }

    this.proyectosDisponibles.sort((a, b) => (a.nom > b.nom) ? 1 : ((b.nom > a.nom) ? -1 : 0));
    // console.log("agregarProyectosDispnibles proyectosDisponibles: ", JSON.stringify(this.proyectosDisponibles));

  }

  async agregarProyectosDispniblesOffLine(idUsuari: number) {
    // console.log('agregarProyectosDispniblesOffLine --> idUsuari: ' + idUsuari);
    await this.selectProjectesDisponibles(idUsuari);
  }

  async actualizarProyectosSicorresponde() {
    for(const p of this.proyectosDisponibles){
      await this.insertProjecte(p);
    };
  }

  async refrescarProyectosUsuario(idUsuari: number, proyectos: any) {
    for (const idProjecte in proyectos) {
      if (proyectos.hasOwnProperty(idProjecte)){
        const permExtrac = proyectos[idProjecte];
        await this.insertOrUpdateUsuarisProjectes(idUsuari, parseInt(idProjecte), permExtrac.permisos);
      }
    }
  }

  agregarPermisos(permisos: any) {
    const permisosArr = permisos.split('|');

    const idPerfilPermisAuscultacio = permisosArr[0];
    const idPerfilPermisComSir = permisosArr[1];
    const idPerfilPermisDocuments = permisosArr[2];
    const idPerfilPermisFitxaTecnica = permisosArr[3];
    const idPerfilPermisGeme = permisosArr[4];
    const idPerfilPermisGep = permisosArr[5];

    return new Permiso(idPerfilPermisAuscultacio, idPerfilPermisComSir, idPerfilPermisDocuments,
      idPerfilPermisFitxaTecnica, idPerfilPermisGeme, idPerfilPermisGep);

  }




  /***********************************************************************************************
 *
 *   Tabla Usuaris
 *
 ************************************************************************************************/

  async insertUsuari(usuario: UsuariDB) {
    try {
      //al guardar el usuario también lo dejo disponible en memoria
      this.usuarioLoginModel = usuario;
      this.setUsuarioMemoria();

      await this.saveUsuariToDB(usuario);
    } catch (error) {
      // console.error('error de catch insert stat---- ' + JSON.stringify(error));
    }
  }

  async updateUsuariLanguage(login: string, idioma: string) {
    const usuarioEncontrado = await this.getUsuariByLogin(login);
    if (!usuarioEncontrado) {
      // console.error(`No se encontró el usuario login: ${login}`);
      return;
    }
    usuarioEncontrado.idioma = idioma;
    this.saveUsuariToDB(usuarioEncontrado);
  }

  async updateFilterConfig(partesTipusOrdre: number, partesTipusMostrar: number, partesTipusView: number) {
    try {
      this.usuarioLoginModel.partesTipusOrdre = partesTipusOrdre;
      this.usuarioLoginModel.partesTipusMostrar = partesTipusMostrar;
      this.usuarioLoginModel.partesTipusView = partesTipusView;

      const usuarioEncontrado = await this.getUsuariByLogin(this.usuarioLoginModel.login);
      if (!usuarioEncontrado) {
        // console.error(`No se encontró el usuario login: ${this.usuarioLoginModel.login}`);
        return;
      }
      usuarioEncontrado.partesTipusOrdre = partesTipusOrdre;
      usuarioEncontrado.partesTipusMostrar = partesTipusMostrar;
      usuarioEncontrado.partesTipusView = partesTipusView;
      await this.saveUsuariToDB(usuarioEncontrado);
    } catch (error) {
      // console.error('error de catch UPDATE updateFilterConfig---- ' + JSON.stringify(error));
    }
  }

  async updateSyncConfig(login: string, syncImatgesElements: number) {
    try {
      const usuariEncontrado = await this.getUsuariByLogin(login);
      if (!usuariEncontrado) {
        // console.error(`No se encontró el usuario login: ${login}`);
      }
      usuariEncontrado.syncImatgesElements = syncImatgesElements;
      this.saveUsuariToDB(usuariEncontrado);
    } catch (error) {
      // console.error('error de catch UPDATE language---- ' + JSON.stringify(error));
    }
  }

  async updateUltimaSincronizacion(login: string, ultimaSincronizacion: string) {
    try {
      const usuariEncontrado = await this.getUsuariByLogin(login);
      if (!usuariEncontrado) {
        // console.error(`No se encontró el usuario login: ${login}`);
      }
      usuariEncontrado.ultimaSincronizacion = ultimaSincronizacion;
      this.saveUsuariToDB(usuariEncontrado);
    } catch (error) {
      // console.error('error de catch UPDATE ultimaSincronizacion---- ' + JSON.stringify(error));
    }
  }

  async getUsuariByLogin(login: string): Promise<UsuariDB> {
    const usuaris = await this.getUsuarisFromDb();
    let usuarioEncontrado: UsuariDB = null;
    for (const u of usuaris) {
      if (u.login === login) {
        usuarioEncontrado = u;
      }
    }
    return usuarioEncontrado;
  }

  async getUsuariByUserAndPass(login: string, password: string): Promise<UsuariDB> {

    this.usuarioLoginModel = null;
    const usuaris = await this.getUsuarisFromDb();
    let usuarioEncontrado = null;
    for (const usuari of usuaris) {
      if (usuari.login === login.toLowerCase() && usuari.passwordXOR === password) {
        usuarioEncontrado = usuari;
      }
    }

    if (usuarioEncontrado) {
      this.usuarioLoginModel = usuarioEncontrado;

      //al guardar el usuario también lo dejo disponible en memoria y genero el listener para observarlo
      this.setUsuarioMemoria();
    }
    return this.usuarioLoginModel;
  }

  private async getUsuarisFromDb(): Promise<UsuariDB[] | null> {
    let strUsuaris = null;
    try {
      const response = await this.getFileOnIndexedDB(PersistenciaGeneralProvider.TABLA_USUARIS, Directory.Documents, Encoding.UTF16);
      strUsuaris = response?.data;
    } catch (err) {
      // console.log('response ERROR', err)
    }
    if (!strUsuaris) {
      // console.warn(`Tabla ${PersistenciaGeneralProvider.TABLA_USUARIS} no contiene datos`);
      return [];
    }
    const usuaris: UsuariDB[] = JSON.parse(strUsuaris);
    if (!usuaris) {
      // console.warn(`No fue posible convertir a objeto JSON la tabla ${PersistenciaGeneralProvider.TABLA_USUARIS} str: ${strUsuaris}`);
      return [];
    }
    if (!usuaris) {
      return [];
    }
    return usuaris;
  }

  private async getUsuariFromDB(idUsuari: number): Promise<UsuariDB | null> {
    const usuaris = await this.getUsuarisFromDb();
    const usuari = usuaris.filter((u: UsuariDB) => u.idUsuari === idUsuari);
    return (usuari) ? usuari[0] : null;
  }

  private async saveUsuarisToDB(usuaris: UsuariDB[]) {
    await this.saveOnIndexedDB(PersistenciaGeneralProvider.TABLA_USUARIS, JSON.stringify(usuaris), Directory.Documents, Encoding.UTF16);
  }

  private async saveUsuariToDB(usuari: UsuariDB) {
    usuari.login = usuari.login.toLowerCase();
    let usuaris = await this.getUsuarisFromDb();
    if (!usuaris) {
      usuaris = [];
    }
    let index = -1;
    for (let i = 0; i < usuaris.length; i++) {
      if (usuaris[i].idUsuari === usuari.idUsuari) {
        index = i;
      }
    }
    if (index > -1) {
      usuaris[index] = usuari;
    } else {
      usuaris.push(usuari);
    }
    this.saveUsuarisToDB(usuaris);
  }

  async updateProjecteSeleccionat(idProjecteSeleccionat: number, idUsuari: number) {
    const usuari: UsuariDB | null = await this.getUsuariFromDB(idUsuari);
    if (usuari) {
      usuari.idProjecteSeleccionat = idProjecteSeleccionat;
      await this.saveUsuariToDB(usuari);
    }
  }



  /***********************************************************************************************
  *
  *   Tabla UsuarisProjectes
  *
  ************************************************************************************************/

  private async getUsuarisProjectesFromDB(): Promise<UsuariProjecteDB[]>{
    let strUsuarisProjectes = null;
    try {
      const response = await this.getFileOnIndexedDB(PersistenciaGeneralProvider.TABLA_USUARIS_PROJECTES, Directory.Documents);
      strUsuarisProjectes = response?.data;
    } catch (err) {
      // console.log('response ERROR', err)
    }
    if (!strUsuarisProjectes) {
      // console.warn(`Tabla ${PersistenciaGeneralProvider.TABLA_USUARIS_PROJECTES} no contiene datos`);
      return [];
    }
    const usuarisProjectes: UsuariProjecteDB[] = JSON.parse(strUsuarisProjectes);
    if (!usuarisProjectes) {
      // console.warn(`No fue posible convertir a objeto JSON la tabla ${PersistenciaGeneralProvider.TABLA_USUARIS_PROJECTES} str: ${strUsuarisProjectes}`);
      return [];
    }
    if (!usuarisProjectes) {
      return [];
    }
    return usuarisProjectes;
  }

  private async saveUsuarisProjectesToDB(usuarisProjectes: UsuariProjecteDB[]){
    try {
      await this.saveOnIndexedDB(PersistenciaGeneralProvider.TABLA_USUARIS_PROJECTES, JSON.stringify(usuarisProjectes), Directory.Documents, Encoding.UTF16);
    } catch (error) {
    }
  }

  async insertOrUpdateUsuarisProjectes(idUsuari: number, idProjecte: number, permisos: any){
    const usuarisProjectes = await this.getUsuarisProjectesFromDB();
    let index = -1;
    for(let i = 0; i < usuarisProjectes.length; i++){
      if (usuarisProjectes[i].idUsuari === idUsuari && usuarisProjectes[i].idProjecte === idProjecte){
        index = i;
      }
    }
    if (index > -1){
      usuarisProjectes[index].permisos = permisos;
    }else{
      const usuariProjecte: UsuariProjecteDB = {
        idProjecte,
        idUsuari,
        permisos,
        configSync: 0,
      };
      usuarisProjectes.push(usuariProjecte);
    }
    await this.saveUsuarisProjectesToDB(usuarisProjectes);
  }

  async updateUsuarisProjectesConfigSync(idUsuari: number, idProjecte: number, configSync: number) {
    const usuarisProjectes = await this.getUsuarisProjectesFromDB();
    let index = -1;
    for(let i = 0; i < usuarisProjectes.length; i++){
      if (usuarisProjectes[i].idUsuari === idUsuari && usuarisProjectes[i].idProjecte === idProjecte){
        index = i;
      }
    }
    if (index > -1){
      usuarisProjectes[index].configSync = configSync;
    }else{
      // console.warn(`No se encontró idProjecte ${idProjecte} idUsuari ${idUsuari} en tabla ${PersistenciaGeneralProvider.TABLA_USUARIS_PROJECTES} asignando configSync`);
    }
    await this.saveUsuarisProjectesToDB(usuarisProjectes);
  }

  async selectProjectesDisponibles(idUsuari: number) {
    const usuarisProjectesTotal = await this.getUsuarisProjectesFromDB();
    const projectes = await this.getProjectesFromDB();
    this.proyectosDisponibles = [];
    // console.log("selectProjectesDisponibles", JSON.stringify(projectes), JSON.stringify(usuarisProjectesTotal), JSON.stringify(usuarisProjectesTotal) );
    for(const up of usuarisProjectesTotal){
      if (up.idUsuari === idUsuari){
        const projectesTrobats = projectes.filter( pr => pr.idProjecte === up.idProjecte);
        if (projectesTrobats){
          const projecte = projectesTrobats[0];
          const p = new Proyecto(projecte.idProjecte, projecte.idGrupoGestio, projecte.abr, projecte.nom, up.permisos, up.configSync);
          this.proyectosDisponibles.push(p);
        }
      }
    }
    this.proyectosDisponibles.sort((a, b) => (a.nom > b.nom) ? 1 : ((b.nom > a.nom) ? -1 : 0));
  }


  /***********************************************************************************************
  *
  *   Tabla Projectes
  *
  ************************************************************************************************/

  async getProjectesFromDB(): Promise<ProjecteDB[]>{
      let strProjectes = null;
      try {
        const response = await this.getFileOnIndexedDB(PersistenciaGeneralProvider.TABLA_PROJECTES, Directory.Documents, Encoding.UTF16);
        strProjectes = response?.data;
      } catch (err) {
        // console.log('response ERROR', err)
      }
      if (!strProjectes) {
        // console.warn(`Tabla ${PersistenciaGeneralProvider.TABLA_PROJECTES} no contiene datos`);
        return [];
      }
      const projectes: ProjecteDB[] = JSON.parse(strProjectes);
      if (!projectes) {
        // console.warn(`No fue posible convertir a objeto JSON la tabla ${PersistenciaGeneralProvider.TABLA_PROJECTES} str: ${strProjectes}`);
        return [];
      }
      if (!projectes) {
        return [];
      }
      return projectes;
  }

  private async saveProjectesToDB(projectes: ProjecteDB[]){
    await this.saveOnIndexedDB(PersistenciaGeneralProvider.TABLA_PROJECTES, JSON.stringify(projectes), Directory.Documents, Encoding.UTF16);
  }

  async insertProjecte(projecte: ProjecteDB) {
    const projectes = await this.getProjectesFromDB();
    let index = -1;
    for(let i = 0; i < projectes.length; i++){
      if (projectes[i].idProjecte === projecte.idProjecte){
        index = i;
      }
    }
    if (index > -1){
      projectes[index] = projecte;
    }else{
      projectes.push(projecte);
    }
    await this.saveProjectesToDB(projectes);
  }

  /***********************************************************************************************
  *
  *   Tabla UsuarisModelsElements
  *
  ************************************************************************************************/

  private async getUsuarisModelsElementsFromDB(): Promise<UsuariModelElementFavoritoDB[]>{
    let str = null;
    try {
      const response = await this.getFileOnIndexedDB(PersistenciaGeneralProvider.TABLA_USUARIS_MODELS_ELEMENTS, Directory.Documents, Encoding.UTF16);
      str = response?.data;
    } catch (err) {
      // console.log('response ERROR', err)
    }
    if (!str) {
      // console.warn(`Tabla ${PersistenciaGeneralProvider.TABLA_USUARIS_MODELS_ELEMENTS} no contiene datos`);
      return [];
    }
    const arr: UsuariModelElementFavoritoDB[] = JSON.parse(str);
    if (!arr) {
      // console.warn(`No fue posible convertir a objeto JSON la tabla ${PersistenciaGeneralProvider.TABLA_USUARIS_MODELS_ELEMENTS} str: ${str}`);
      return [];
    }
    if (!arr) {
      return [];
    }
    return arr;
  }

  private async saveUsuarisModelsElementsToDB(usuarisModelsElements: UsuariModelElementFavoritoDB[]){
    await this.saveOnIndexedDB(PersistenciaGeneralProvider.TABLA_USUARIS_MODELS_ELEMENTS, JSON.stringify(usuarisModelsElements), Directory.Documents, Encoding.UTF16);
  }

  async insertUsuarisModelsElements(favorito: UsuariModelElementFavoritoDB) {
    const arr = await this.getUsuarisModelsElementsFromDB();
    let index = -1;
    for(let i = 0; i < arr.length; i++){
      if (arr[i].idUsuari === favorito.idUsuari && arr[i].idModelElement === favorito.idModelElement){
        index = i;
      }
    }
    if (index > -1){
      arr[index].favorit = favorito.favorit;
    }else{
      arr.push(favorito);
    }
    await this.saveUsuarisModelsElementsToDB(arr);
  }

  async getFavsDB(idUsuari: number) {
    const arr = await this.getUsuarisModelsElementsFromDB();
    this.favs = [];
    for (const um of arr){
      if (um.idUsuari === idUsuari && um.favorit === 1){
        this.favs.push(um.idModelElement);
      }
    }
  }

  getValueIdNotPlanned() {
    this.idNotPlanned = this.idNotPlanned - 1;
    return this.idNotPlanned;
  }

  /***********************************************************************************************
  *
  *   Tabla Imagenes
  *
  ************************************************************************************************/
  async getImageToDB(filename: string) {
    try {
      const result = await Filesystem.readFile({
        path: filename,
        directory: Directory.Documents
      });
      if(filename.includes('.svg')) {
        const resultSvg = `data:image/svg+xml;base64,${result.data}`;
        return resultSvg;
      }

      return `data:image/png;base64,${result.data}`;
    } catch (error) {
      throw new Error("image not found");
    }
  }

  async saveOnIndexedDB(path: string,data: string,  directory: Directory, encoding?: Encoding) {
    if(encoding) {
      return await Filesystem.writeFile({
        path,
        data,
        directory,
        encoding
      });
    }

    return await Filesystem.writeFile({
      path,
      data,
      directory,
    });
  }

  async removeFileOnIndexedDB(path: string, directory: Directory) {
    return await Filesystem.deleteFile({
      path,
      directory
    });
  }

  async getFileOnIndexedDB(path: string, directory: Directory, encoding?: Encoding) {
    if(encoding) {
      return await Filesystem.readFile({
        path,
        directory,
        encoding
      });
    }

    return await Filesystem.readFile({
      path,
      directory
    });
  }


}
