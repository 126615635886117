import {Component} from '@angular/core';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {ElementoTabProvider} from '@app/services/elemento-tab/elemento-tab.provider';
import {UiMessagesProvider} from '@app/services/ui-messages/ui-messages';
import {TranslateProvider} from '@app/services/translate/translate.provider';
import {PersistenciaGeneralProvider} from "@app/services/persistencia-configuracion/persistencia-general.provider";
import {Subscription} from "rxjs";
import {ElementState} from "@app/core/element/domain/element-state";
import moment from "moment";
import {ElementRepository} from "@app/core/element/infrastructure/element.repository";
import {HtmlUtilsProvider} from "@app/services/html-utils/html-utils";
import { DATE_FORMAT, DETAILED_DATE_TIME_FORMAT, TIME_WITH_SECONDS_FORMAT } from '@app/config/constantes';

@Component({
  selector: 'page-element-data-state',
  styleUrls: ['element-data-state.scss'],
  templateUrl: 'element-data-state.html',
})
export class ElementDataStatePage {
  stateElementId: number;
  selectedState: ElementState;
  dateElement = "";
  selectedDate = moment(new Date()).format();
  observations = "";
  stateTypesList: Promise<ElementState[]>;
  ogElementDate = "";
  private backButtonListener?: Subscription;

  urlParams = {
    stateElement: "stateElement",
    dateElement: "dateElement",
    observationsElement: "observationsElement",
    ogElementDate: "ogElementDate"
  }

  constructor(public navParams: NavParams,
              public htmlUtils: HtmlUtilsProvider,
              public _etp: ElementoTabProvider,
              public modalCtrl: ModalController,
              public platform: Platform,
              public _translate: TranslateProvider,
              public _ui: UiMessagesProvider,
              public _pg: PersistenciaGeneralProvider,
              public elementRepo: ElementRepository) {

    this.stateElementId = htmlUtils.getParam(navParams, this.urlParams.stateElement);
    this.selectedDate = htmlUtils.getDateParam(navParams, this.urlParams.dateElement);
    this.observations = htmlUtils.getParam(navParams, this.urlParams.observationsElement);
    this.ogElementDate = htmlUtils.getParam(navParams, this.urlParams.ogElementDate);
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
    this.downloadStateTypologies();
    this.stateTypesList.then((states: ElementState[]) => {
      for (const state of states) {
        if (state.id == this.stateElementId) {
          this.selectedState = state;
          break;
        }
      }
    });
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  downloadStateTypologies() {
    this.stateTypesList = this.elementRepo.getElementState(this._pg.workingOffline);
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }

  // End of work with the back button

  /**
  * Closes with parameters.
  * This method is responsible for closing an element with specific parameters.
  */
  cerrarConParametros() {
    // Ensure that has selected date and state
    if (!this.selectedState || !this.selectedDate) {
      this._ui.showToastDefault(this._translate.traducir('txStateEditEmptyInputs'));
      return;
    }

    const selectedIsOlderOrEqualThanCurrent = moment(this.selectedDate).format() <= moment(this.ogElementDate).format();

    if (!!this.ogElementDate && selectedIsOlderOrEqualThanCurrent) {
      // Show a toast message indicating the wrong date
      this._ui.showToastDefault(this._translate.traducir('txStateEditWrongDate'));
      return;
    }

    // Update element properties based on selectedState and selectedDate
        this._etp.elemento.nomEstatElement = this.selectedState.nom;
        this._etp.elemento.dataHoraEstatElement = this.sanitizeDataHoraInici(this.selectedDate);
        this._etp.elemento.idTipologiaEstatElement = this.selectedState.id;
        this._etp.elemento.nomFitxerLocalEstatElement = this.selectedState.nomFitxerLocal;
        this._etp.elemento.observacionsEstat = this.observations;
        this._etp.elemento.editatEstatApp = 1;

    // Dismiss the modal
        this.modalCtrl.dismiss();
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }

  public sanitizeDataHoraInici(fecha: string): string {
    let date = moment(fecha).format(DATE_FORMAT);
    let time = moment(fecha).format(TIME_WITH_SECONDS_FORMAT);
    return [date, time].join(' ');
  }

  compareStates(state1: ElementState, state2: ElementState) {
    return state1 && state2 ? state1.id === state2.id : state1 === state2;
  }

  dateChange(data: string) {
    this.selectedDate = moment(data, DETAILED_DATE_TIME_FORMAT).format();
  }
}
