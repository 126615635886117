<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
        <ion-title>
          {{ 'txInventarioMenu' | translate }}
          <p class="subtitle hide-when-big-screen">{{_pg.proyectoSeleccionado.nom}}</p>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <tree-view [executeEvent]="getElement" [nodesTree]="nodesTree"></tree-view>
</ion-content>
