import { ChangeDetectorRef, Component } from '@angular/core';
import { AlertController, ModalController, NavController, NavParams, Platform } from '@ionic/angular';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { UiMessagesProvider } from '../../services/ui-messages/ui-messages';
import { TranslateProvider } from '../../services/translate/translate.provider';
import { PersistenciaGeneralProvider } from '../../services/persistencia-configuracion/persistencia-general.provider';
import { HtmlUtilsProvider } from '../../services/html-utils/html-utils';
import { FaseActualProvider } from '../../services/geme/fase-actual.provider';
import { RespuestaPageActions } from '@app/interfaces/geme/respuesta-page-actions.interface';
import { MAX_FILE_SIZE, TIPUS_FITXER_4 } from '../../config/constantes';
import { FileChooser } from '../../services/file-chooser/file-chooser.provider';
import { File } from "@capawesome/capacitor-file-picker";
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileProvider } from '../../services/file/file.provider';
import { FormatLinkPipe } from '../../pipes/imagen/format-link.pipe';
import { NetworkProvider } from '../../services/network/network.provider';
import { WsUtilsProvider } from '../../services/utils-ws/ws-utils';
import { FitxerEnviarData } from '@app/domains/fitxer-enviar-data-domain';
import { TipusAccioCambiarFase } from '@app/domains/valor-observacions-fase-data-domain';
import { PhotoService } from '../../services/photo/photo.service';


@Component({
  templateUrl: 'respuesta-imagen-adjunta.html',
  styleUrls: ['respuesta-imagen-adjunta.scss']
})
export class RespuestaImagenAdjuntaPage implements RespuestaPageActions {

  valorEdicio: string;

  fitxerEnviar: FitxerEnviarData;
  fueModificado = false;
  imagenPreview = "";

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    public translate: TranslateProvider,
    public _pg: PersistenciaGeneralProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public faseActual: FaseActualProvider,
    public platform: Platform,
    private camera: Camera,
    public alertCtrl: AlertController,
    public _traductor: TranslateProvider,
    public _fp: FileProvider,
    public _html: HtmlUtilsProvider,
    private cdRef: ChangeDetectorRef,
    public _ui: UiMessagesProvider,
    public _np: NetworkProvider,
    public _ws: WsUtilsProvider,
    public fileChooser: FileChooser,
    private modalCtrl: ModalController,
    private photoService: PhotoService
  ) {
    if (this.faseActual.valorObservacionsEdicio.valorPreview > '') {
      this.imagenPreview = this.faseActual.valorObservacionsEdicio.valorPreview;
    }
    if (faseActual.valorObservacionsEdicio.valor > "") {
      this.valorEdicio = faseActual.valorObservacionsEdicio.valor;
      if (!this.imagenPreview){
        this.initialize();
      }
    }
    if (!(this.imagenPreview > '')) {
      this.takePhotoAndSave();
    }
  }

  async initialize() {
    const ifp = new FormatLinkPipe(this._np, this._pg, this._html, this._fp, this._ws, this.platform);
      const imgResult = await ifp.transform(this.valorEdicio, TIPUS_FITXER_4);
      if (imgResult !== undefined) {
        this.imagenPreview = imgResult;
    }
  }

  async takePhotoAndSave() {
    try {

      let dataImage;
      let format;

      if (this.platform.is('mobile') || this.platform.is('hybrid')) {
        const types_allowed = ['image/png', 'image/jpeg', 'image/jpg'];
        const file = await this.fileChooser.open(types_allowed);

        if(!file) {return;}

        dataImage = file.data;
        format = file.name.split('.').pop();
      } else {
        const photo = await this.photoService.addNewToGallery();

        format = photo.format;
        dataImage = photo.base64String;
      }

      this.fitxerEnviar = {
        contingutFitxer: this.getFilename(),
        extensioFitxer: format
      };

      this.valorEdicio = this.getFilename() + "." + format;
      this.fueModificado = true;

      Filesystem.writeFile({
        path: this._fp.pathDestinoTemp + "/" + this.valorEdicio,
        data: dataImage,
        directory: Directory.Data
      }).then(async () => {
        //this.readPictureFile(); -> commentado
        this.imagenPreview = await this._html.normalizarImageSrcRandomAsync(this._fp.pathDestinoTemp + "/" + this.valorEdicio, Directory.Data);
        this.cdRef.detectChanges();
      });
    } catch (e) {
      console.error('Error taking photo', e);
    }
  }

  attachPicture() {
    this.fileChooser.open()
      .then(file =>
        this.copyImageFromFileSelector(file)
      )
      .catch(async (e) => {
        if (e.message === FileChooser.PICKER_CANCELLED_ERROR) {
          return;
        }
        const msg = this._traductor.traducir("txOcurrioErrorAdjuntarArchivo");
        await this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
      });
  }

  /**
   * Obtains a file and copies it to the app's temp directory
   * this function affects sets the object that will be sent to the server in the view
   * the legacy code expects those properties to be set here
   *
   * @param file
   * @private
   */
  private async copyImageFromFileSelector(file: File) {
    const size = this._fp.bytesToMb(file.size);

    if (size > MAX_FILE_SIZE) {
      const msg = this._traductor.traducirWithParams("txAvisoNosePuedeSubirArchivo", { max: MAX_FILE_SIZE });
      this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
      return;
    }

    const extension = file.name.split('.').pop();

    this.fitxerEnviar = {
      contingutFitxer: this.getFilename(),
      extensioFitxer: extension
    };

    this.fueModificado = true;

    // Check if the file is an image
    if (this.fitxerEnviar.extensioFitxer !== "jpg"
      && this.fitxerEnviar.extensioFitxer !== "png"
      && this.fitxerEnviar.extensioFitxer !== "jpeg"
      && this.fitxerEnviar.extensioFitxer !== "webp"
      && this.fitxerEnviar.extensioFitxer !== "bmp") {

      const msg = this._traductor.traducir("txSoloImagen");
      await this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
      return;
    }

    this.valorEdicio = this.getFilename() + "." + extension;
    // Write the file into the temp directory
    this._fp.writeFileBase(file.data, this.valorEdicio)
      .then(async () => {
        this.imagenPreview = await this._html.normalizarImageSrcRandomAsync(this._fp.pathDestinoTemp + "/" + this.valorEdicio, Directory.Data);
        this.cdRef.detectChanges();
      })
      .catch(async (e) => {
        // console.error("error en copyFile: " + JSON.stringify(e, Object.getOwnPropertyNames(e)));
        const msg = this._traductor.traducir("txOcurrioErrorAdjuntarArchivo");
        await this._ui.showOkAlert("txAtencionStr", msg, "txAceptar");
      });
  }

  eliminar() {
    this.confirmarEliminar();
  }

  async confirmarEliminar() {
    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txPreguntaConfirmaEliminacion", "txYes", "txNo");
    alert.onDidDismiss().then((res) => {
      if (res.data.accepted) {
        this._fp.deleteFileBase(this._fp.pathDestinoTemp + "/" + this.valorEdicio);
        this.valorEdicio = null;
        this.fueModificado = true;
        this.imagenPreview = null;
        this.fitxerEnviar = null;
        this.setValorFaseActual();
        this.modalCtrl.dismiss(TipusAccioCambiarFase.FASE_CURRENT);
        return;
      }
      return;
    });
  }

  private getFilename() {
    return "imagen_fase_parte_" + this.faseActual.idParte + "_" + this.faseActual.fase.idFaseModel;
  }

  checkValorIsOk(): boolean {
    return true;
  }

  setValorFaseActual() {
    this.faseActual.valorObservacionsEdicio.valor = this.valorEdicio ? this.valorEdicio : null;
    this.faseActual.valorObservacionsEdicio.fitxerModificat = this.fueModificado;
    this.faseActual.valorObservacionsEdicio.valorPreview = this.imagenPreview;
    this.faseActual.valorObservacionsEdicio.fitxerEnviar = this.fitxerEnviar;
  }
}
