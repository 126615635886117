import {Component} from '@angular/core';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {ElementoTabProvider} from '@app/services/elemento-tab/elemento-tab.provider';
import {UiMessagesProvider} from '@app/services/ui-messages/ui-messages';
import {PersistenciaGeneralProvider} from "@app/services/persistencia-configuracion/persistencia-general.provider";
import moment from "moment/moment";
import {Subscription} from "rxjs";
import {HtmlUtilsProvider} from "@app/services/html-utils/html-utils";
import {DATE_FORMAT, TIME_FORMAT} from "@app/config/constantes";

@Component({
  selector: 'page-elemento-anotacion',
  templateUrl: 'elemento-anotacion.html',
  styleUrls: ['elemento-anotacion.scss']
})
export class ElementoAnotacionPage {


  indice = -1;

  tipoRespuesta: any;
  text: any;
  dataInici = moment().format();
  dataFinal = moment().format();

  idAnotacioElemento: any = null;
  nomUsuari: any;
  editatApp: any;

  withFinalDate = false;

  private backButtonListener?: Subscription;

  constructor(public htmlUtils: HtmlUtilsProvider,
              private navParams: NavParams,
              public _etp: ElementoTabProvider,
              public modalCtrl: ModalController,
              public platform: Platform,
              public _ui: UiMessagesProvider,
              public _pg: PersistenciaGeneralProvider) {

    this.tipoRespuesta = htmlUtils.getParam(this.navParams, "tipoRespuesta");
    this.idAnotacioElemento = htmlUtils.getParam(this.navParams,"idAnotacioElemento");
    this.nomUsuari = htmlUtils.getParam(this.navParams,"nomUsuari");
    this.editatApp = htmlUtils.getParam(this.navParams,"editatApp");

    // Initial Date
    this.dataInici = htmlUtils.getDateParam(this.navParams,"dataHoraInici");

    // Final date
    this.dataFinal = htmlUtils.getDateParam(this.navParams,"dataHoraFinal");
    // Check if the date was previously saved or generated now
    if (this.dataFinal == Date()) {
      this.withFinalDate = true;
    }

    this.text = htmlUtils.getParam(this.navParams,"text");
    this.indice = htmlUtils.getParam(this.navParams,"indice");
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()

    const backDrop: any = document.getElementsByTagName('ion-picker-cmp');
    if (backDrop.length > 0) {
      for (let i = 0; i < backDrop.length; i++) {
        backDrop[i].style.display = 'none';
      }
    }
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }

  /*** fin trabajo con el boton atras */

  cerrarConParametros(elimina: boolean) {

    this._etp.elemento.editatAnotacionsApp = 1;
    if (elimina) {
      this.confirmarEliminar(this.indice);
    } else {
      if (this.text && this.text != '' && this.dataInici) {
        const anotacion = {
          idAnotacioElemento: this.idAnotacioElemento,
          dataHoraInici: this.sanitizeDataHoraInici(this.dataInici),
          dataHoraFinal: this.withFinalDate ? this.sanitizeDataHoraInici(this.dataFinal) : null,
          text: this.text,
          nomUsuari: this.nomUsuari,
          editatApp: this.editatApp,
        }

        if (this.indice >= 0) {
          this._etp.elemento.anotacions[this.indice] = anotacion;
        } else {
          this._etp.elemento.anotacions.push(anotacion);
        }
        this.modalCtrl.dismiss();
      } else {
        this._ui.showToastDefault('txMensajeCamposObligatorios');
      }
    }
  }

  cerrarSinParametros() {
    this.modalCtrl.dismiss();
  }

  async confirmarEliminar(idx: number) {

    const alert = await this._ui.showYesNoAlert({}, "txAtencionStr", "txPreguntaConfirmaEliminacion", "txYes", "txNo");

    alert.onDidDismiss().then(({data}) => {
      if (data.accepted) {
        this._etp.elemento.anotacions.splice(idx, 1);
        // alert.dismiss();
        this.cerrarSinParametros();
        return false
      }
      return false;
    });
  }

  public sanitizeDataHoraInici(fecha: string): string {
    let date = moment(fecha).format(DATE_FORMAT);
    let time = moment(fecha).format(TIME_FORMAT);
    return [date, time].join(' ');
  }

  setInitialFinalValue() {
    this.dataFinal = moment().format();
  }

  public eliminateFinalDate() {
    this.withFinalDate = false;
  }
}
