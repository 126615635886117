import {Subscription} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingController, NavController, Platform} from '@ionic/angular';
import {StructTree} from '../../components/tree-view/struct';
import {NetworkProvider} from '../../services/network/network.provider';
import {AjaxClientProvider, ErrorConsulta} from '../../services/ajax-client/ajax-client.provider';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import {WsUtilsProvider} from '../../services/utils-ws/ws-utils';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {FileProvider} from '../../services/file/file.provider';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {TreeViewProvider} from '../../services/tree-view/tree-view.provider';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {PostParams} from '../../interfaces/ajax.interface';
import {
  PARAM_GET_ARBRE_PROJECTE,
  PARAM_GET_ELEMENT_APP,
  PARAM_ID_ELEMENT,
  PARAM_ID_PROJECTE,
  PARAM_STRCONN,
  TIMEOUT_CONSULTAS_WS_DEFECTO_SEG
} from '../../config/constantes';
import {ElementoTabProvider} from '../../services/elemento-tab/elemento-tab.provider';
import {HelperProvider} from '../../services/helper/helper.provider';
import {SyncProvider} from '../../services/sync/sync.provider';
import {UpdateAppProvider} from '../../services/update-app/update-app.provider';
import {AppRoutes} from "../../config/app-routes";
import {EventService} from "../../services/events/event.service";
import { ElementInfo } from '@app/core/element/domain/element-info';


@Component({
  selector: 'page-inventario',
  templateUrl: 'inventario.html',
  styleUrls: ['inventario.scss']
})
export class InventarioPage implements OnInit, OnDestroy {

  screenWidth: any = 0;
  nodesTree: Array<StructTree> = [];
  getElement: Function;
  private backButtonListener?: Subscription;
  private reloadSubscription?: Subscription;

  constructor(
    public navCtrl: NavController,
    public _np: NetworkProvider,
    public _ajax: AjaxClientProvider,
    public _messages: UiMessagesProvider,
    public _wsUtils: WsUtilsProvider,
    public _pg: PersistenciaGeneralProvider,
    public _fp: FileProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public _tv: TreeViewProvider,
    public _ptp: PartesTabProvider,
    public _etp: ElementoTabProvider,
    public _hp: HelperProvider,
    public _sync: SyncProvider,
    public _updateApp: UpdateAppProvider,
    public platform: Platform,
    public loadingCtrl: LoadingController,
    public events: EventService) {

  }

  ngOnInit(): void {
    this._etp.elementCanBeEdited = true;
  }

  ngOnDestroy(): void {
    this._etp.elementCanBeEdited = false;
  }

  ionViewWillEnter(): void {
    this.reload();
    this.reloadSubscription = this.events.subscribe('reloadCurrentPage', () => this.reload())
  }

  reload(): void {
    this.getElementos();
    this._tv.elemetSelected = null
    this._tv.parents = [];
    this.getElement = this.getElementFunction.bind(this);
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
    this.reloadSubscription?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this._hp.abrirPaginaClick(AppRoutes.homePage, false);
    });
  }

  /*** fin trabajo con el boton atras */

  ionViewDidEnter() {
    this._updateApp.needUpdateAdvice();

    this.initializeBackButtonCustomHandler();
  }

  getElementos() {
    if (!this._pg.workingOffline) {
      // console.log("voy por online -> inventario");
      this.getProcessOnline();
    } else {
      // console.log("voy por offline -> inventario");
      this.getProcessOffline();
    }
  }

  async getProcessOnline() {
    const loading = await this._messages.showLoading("txDescargandoElementos");

    const params: PostParams[] = [];

    //strConn
    const paramStrLogin: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    }
    params.push(paramStrLogin);

    //idProjecte
    const paramIdProjecte: PostParams = {
      nombre: PARAM_ID_PROJECTE,
      valor: String(this._pg.proyectoSeleccionado.idProjecte)
    }
    params.push(paramIdProjecte);

    this.descargarElementos(PARAM_GET_ARBRE_PROJECTE, params)
      .then((res) => {
        loading.dismiss();
      })
      .catch(err => {
        // console.error(JSON.stringify(err))
        loading.dismiss();
      });
  }

  getProcessOffline() {
    this._sync.getArbre(this._pg.proyectoSeleccionado.idProjecte).then((res: any) => {
      this.nodesTree = this._tv.buildTree(this._sync.localArbre);
    });
  }

  descargarElementos(paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {
      if (!this._pg.workingOffline) {
        this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
          .subscribe((res: any) => {

              // console.log("ELEMENTOS");

              this.nodesTree = this._tv.buildTree(res.arbre);

              resolve(true);

            },
            (errConsulta: ErrorConsulta) => {
              this._messages.showToastDefault(errConsulta.mensaje);
              // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);
              resolve(false);
            });
      } else {
        resolve(true);
      }
    })
      .catch(err => {
        // console.error("error en descargarLlistaPartesFromServer: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });


    return promesa;
  }

  /**************************************** */

  getElementFunction(item: any) {
    if (!this._pg.workingOffline) {
      this.getElementProcessOnline(item);
    } else {
      this.getElementProcessOffline(item);
    }
  }

  async getElementProcessOnline(item: any) {
    const loading = await this._messages.showLoading("txPartesDescargandoElemento");
    const params: PostParams[] = this.getParamsToGetElement(item);
    // console.log("PARAMETROS ELEMENTO: " + JSON.stringify(params));
    this.descargarElementoFromServer(PARAM_GET_ELEMENT_APP, params)
      .then((res) => {
        if (res) {
          loading.dismiss();
          this.gotoInfo();
        } else {
          loading.dismiss();
        }
      })
      .catch(err => {
        // console.error(JSON.stringify(err))
        loading.dismiss();
      });
  }

  async getElementProcessOffline(item: any) {
    const loading = await this._messages.showLoading("txPartesDescargandoElemento");
    const elementInfo: ElementInfo = {
      idElement: item.idGemeElement,
      idProjecte: this._pg.proyectoSeleccionado.idProjecte
    }
    this._sync.getElement(elementInfo).then(() => {
      this._etp.elemento = this._sync.localElement;
      loading.dismiss();
      this.gotoInfo();
    })
      .catch((err: any) => {
        loading.dismiss();
      });
  }

  private getParamsToGetElement(item: any) {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    }
    params.push(paramStrConn);
    // console.log("this._wsUtils.getStrConn() --> " + this._wsUtils.getStrConn());

    //idElement
    if (item.idGemeElement != null && item.idGemeElement != "") {
      const idElement: PostParams = {
        nombre: PARAM_ID_ELEMENT,
        valor: item.idGemeElement
      }
      params.push(idElement);
    }

    return params;
  }

  private descargarElementoFromServer(paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {

      this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
        .subscribe((res: any) => {
            if (res != null) {
              this._etp.elemento = res;
              resolve(true);
            } else {
              resolve(false);
            }

          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);
            resolve(false);
          });

    })
      .catch(err => {
        // console.log("error en descargarLlistaPartesFromServer: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });


    return promesa;
  }

  private gotoInfo() {
    this._hp.abrirPaginaClick(AppRoutes.elementoTabsPage, true)
  }

}
