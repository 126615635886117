import {Pipe, PipeTransform} from '@angular/core';
import {NetworkProvider} from '../../services/network/network.provider';
import {WsUtilsProvider} from '../../services/utils-ws/ws-utils';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';
import {HtmlUtilsProvider} from '../../services/html-utils/html-utils';
import {FileProvider} from '../../services/file/file.provider';
import {
  ARXIU_TECNIC_DOCUMENT,
  GEME_ADJUNT,
  GEME_IMATGE_FASES,
  GEME_IMATGE_RESPOSTES_FASE,
  GEME_PDF_RESPOSTES_FASE,
  LOCAL_IMAGE
} from '../../config/constantes';
import { Platform } from '@ionic/angular';
import { Directory } from '@capacitor/filesystem';


@Pipe({
  name: 'formatLink',
})
export class FormatLinkPipe implements PipeTransform {

  constructor(public _np: NetworkProvider,
              public _pg: PersistenciaGeneralProvider,
              public _htmlUtils: HtmlUtilsProvider,
              public _fp: FileProvider,
              public _wsUtils: WsUtilsProvider,
              public platform: Platform) {

  }

  async transform(file: string, tipus: string) {
    let url = "";
    //if(this._np.appOnline){
    if (tipus === LOCAL_IMAGE) {
      return this._htmlUtils.normalizarImageSrc(file);
    }
    if (!this._pg.workingOffline) {
      url = `${this._np.baseUrlOk}?op=GET_FITXER_APP&strConn=${this._wsUtils.getStrConn()}&tipus=${tipus}&fitxer=${file}`;
    } else {

      switch (tipus) {
        case GEME_ADJUNT: {
          url = await this._htmlUtils.normalizarImageSrc(this._fp.pathDestinoAdjunts + '/' + file);
          break;
        }
        case ARXIU_TECNIC_DOCUMENT: {
          url = await this._htmlUtils.normalizarImageSrc(this._fp.pathDestinoArxiutecnic + '/' + file);
          break;
        }
        case GEME_IMATGE_FASES: {
          url = await this._htmlUtils.normalizarImageSrc(this._fp.pathDestinoImatgesfases + '/' + file);
          break;
        }
        case GEME_IMATGE_RESPOSTES_FASE: {
          url = await this._htmlUtils.normalizarImageSrc(this._fp.pathDestinoImatgesrespostesfases + '/' + file);
          break;
        }
        case GEME_PDF_RESPOSTES_FASE: {
          this._fp.showPdf(this._fp.pathDestinoPdfsrespostesfases + '/' + file);
          return;
        }
        default: {
          url = this._htmlUtils.normalizarImageSrcRandom(this._fp.pathDestinoGemeImatgeElement + '/' + file);
          break;
        }
      }

      // console.log("file offline ----> ", url);
    }
    return url;
  }

  async transformAsync(file: string, tipus: string) {
    let url = "";

    if (tipus === LOCAL_IMAGE) {
      return await this._htmlUtils.normalizarImageSrcIndexed(`images/${file}`, Directory.Data);
    }

    if (!this._pg.workingOffline) {
      url = `${this._np.baseUrlOk}?op=GET_FITXER_APP&strConn=${this._wsUtils.getStrConn()}&tipus=${tipus}&fitxer=${file}`;
    } else {

      switch (tipus) {
        case GEME_ADJUNT: {
          url = await this._htmlUtils.normalizarImageSrcIndexed(this._fp.pathDestinoAdjunts + '/' + file, Directory.Documents);
          break;
        }
        case ARXIU_TECNIC_DOCUMENT: {
          url = await this._htmlUtils.normalizarImageSrcIndexed(this._fp.pathDestinoArxiutecnic + '/' + file, Directory.Documents);
          break;
        }
        case GEME_IMATGE_FASES: {
          url = await this._htmlUtils.normalizarImageSrcIndexed(this._fp.pathDestinoImatgesfases + '/' + file, Directory.Documents);
          break;
        }
        case GEME_IMATGE_RESPOSTES_FASE: {
          url = await this._htmlUtils.normalizarImageSrcIndexed(this._fp.pathDestinoImatgesrespostesfases + '/' + file, Directory.Documents);
          break;
        }
        case GEME_PDF_RESPOSTES_FASE: {
          this._fp.showPdf(this._fp.pathDestinoPdfsrespostesfases + '/' + file);
          return;
        }
        default: {
          let urlImage = '';
          if(this._fp.pathDestinoGemeImatgeElement.length > 0) {
            urlImage = this._fp.pathDestinoGemeImatgeElement + '/' + file;
          } else {
            urlImage = this._fp.dirImatgeElement + '/' + file;
          }
          url = await this._htmlUtils.normalizarImageSrcRandomAsync(urlImage, Directory.Documents);
          break;
        }
      }
    }
    return url;
  }
}
