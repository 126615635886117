<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ i18n.txConfiguracionConfiguracion | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list>

    <ion-item>
      <ion-label>{{i18n.txAppVersion | translate}}</ion-label>
      <ion-text>{{appVersion}}</ion-text>
    </ion-item>
    <ion-item>
      <ion-label class="ion-text-wrap">
        {{ i18n.txServerConnection | translate }}
        <br>
        <ion-text>{{url}}</ion-text>
      </ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-label>{{i18n.txPermissionProtocol | translate}}</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>{{i18n.txPermissionVersion | translate}}</ion-label>
      <ion-text>{{permissionVersion}}</ion-text>
    </ion-item>
    <ion-item lines="none">
      <ion-label>{{i18n.txPermissionVersionDate | translate}}</ion-label>
      <ion-text>{{permissionDate | date:'dd/MM/yyyy HH:mm'}}</ion-text>
    </ion-item>

  </ion-list>

  <!-- <ion-button class="ion-margin-top" expand="block" color="primary" (click)="showChangeUrl()">
    {{ i18n.txChangeServer | translate }}
  </ion-button> -->

</ion-content>
