<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ i18n.txConfiguracionConfiguracion | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list lines="none">
    <ion-item>
      <ion-label>{{ i18n.txSeleccionarIdiomaConfiguracion | translate }}</ion-label>
      <ion-select [(ngModel)]="_pg.selectedLanguage" [okText]="i18n.txAceptarConfiguracion | translate"
        [cancelText]="i18n.txCancelarConfiguracion | translate" (ionChange)="seleccionarIdioma($event)">
        <ion-select-option *ngFor="let item of idiomas" [value]="item.value">
          {{ item.label | translate }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>

  <div class="line-separtor-main"></div>

  <ion-list lines="none">
    <ion-item>
      <ion-label>{{ i18n.txConfigSyncOpt1 | translate }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-label class="option ion-text-wrap">{{ i18n.txConfigSyncOpt2 | translate }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-label class="option ion-text-wrap">{{ i18n.txConfigSyncOpt3 | translate }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-toggle slot="start" [(ngModel)]="syncImatgesElements" (ionChange)="toggleChange()"></ion-toggle>
      <ion-label>{{ i18n.txConfigSyncOpt4 | translate }}</ion-label>
    </ion-item>
  </ion-list>

  <div class="line-separtor-main"></div>

  <ion-list lines="none">
    <ion-item>
      <ion-label>{{ i18n.txPresasASincronizar | translate }}</ion-label>
    </ion-item>
    <ion-item *ngFor="let proj of _pg.proyectosDisponibles">
      <ion-toggle slot="start" [(ngModel)]=" proj.configSync" (ionChange)="toggleChangeConfigSync(proj)"></ion-toggle>
      <ion-label>{{proj.nom}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-button class="ion-margin-top" expand="block" color="primary" (click)="showInformation()">{{ i18n.txAbout |
    translate }}</ion-button>

</ion-content>