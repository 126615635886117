import { Injectable } from '@angular/core';
import { GetElementStateTypologiesHttpWebService } from '@app/core/element/infrastructure/get-element-state-typologies.http-web-service';
import { ElementDAO } from '@app/core/element/domain/element.DAO';
import { PermissionService } from '@app/core/permission/domain/permission.service';
import { TranslateProvider } from '@app/services/translate/translate.provider';

@Injectable({
  providedIn: 'root',
})
export class SyncElementStateTypologiesUseCase {
  constructor(
    private elementDAO: ElementDAO,
    private getElementStateService: GetElementStateTypologiesHttpWebService,
    private service: PermissionService,
    public _translate: TranslateProvider
  ) {}

  async execute(
    loading: HTMLIonLoadingElement,
    updateLoading: (loading: HTMLIonLoadingElement, msg: string) => void
  ): Promise<void> {
    // we must only sync when the server allows it
    try {
      // Await the promise to get the Permission object
      const permission = await this.service.get();

      // Now you can check the editElements property
      if (!permission || !permission.editElements) {
        return;
      }

      const msg = this._translate.traducir('txDownloadElemStateTypo');
      updateLoading(loading, msg);

      const request = await this.getElementStateService.execute().toPromise();
      await this.elementDAO.saveElementState(request);
    } catch (error) {
      // console.error(error);
      // Handle the error appropriately
    }
  }
}
