import {Observable, Subject} from 'rxjs';
import {
  ImageControllerService,
  ImageError, ImageErrorCode,
  PromptLabels,
  Source
} from '@app/image-picker/image-controller.service';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertController} from '@ionic/angular';
import {takeUntil} from 'rxjs/operators';

/**
 * Handles the use of the ImagePicker Service to simplify and unify code related to the view layer
 * this way the view layer does not need to know about the ImageControllerService
 */
@Injectable({
  providedIn: 'root'
})
export class ImagePickerViewController {
  private _error$ = new Subject<string>();
  error$ = this._error$.asObservable();
  private currentAlert?: HTMLIonAlertElement;

  constructor(
    private translateService: TranslateService,
    private alertController: AlertController,
  ) {
  }

  async openImagePicker(source?: Source): Promise<string> {
    try {
      const imageController = new ImageControllerService();
      return await imageController.pickImage(source, this.cameraLabels);
    } catch (error) {
      this._error$.next(this.mapImageErrorToMessage(error));
    }
  }

  openCamera(): Promise<string> {
    return this.openImagePicker(Source.CAMERA);
  }

  openGallery(): Promise<string> {
    return this.openImagePicker(Source.GALLERY);
  }

  get cameraLabels(): PromptLabels {
    return {
      labelCancel: this.translateService.instant('txCancelar'),
      labelHeader: this.translateService.instant('txChooseCameraOrGallery'),
      labelGallery: this.translateService.instant('txGallery'),
      labelCamera: this.translateService.instant('txCamera'),
    };
  }

  /**
   * Maps an error to a human readable message
   *
   * @param error Error to map
   * @private
   */
  private mapImageErrorToMessage(error: ImageError | Error): string {
    if (!(error instanceof ImageError) || error.code === undefined) {
      return this.translateService.instant('txImageUnknownError');
    }
    switch (error.code) {
      case ImageErrorCode.NOT_FOUND:
        return this.translateService.instant('txImageNotFound');
      case ImageErrorCode.NOT_SAVED:
        return this.translateService.instant('txImageCouldNotBeSaved');
      case ImageErrorCode.NOT_LOADED:
        return this.translateService.instant('txImageCouldNotBeLoaded');
      default:
        return this.translateService.instant('txImageUnknownError');
    }
  }

  /**
   * Shows an alert with the given error message unles provided wth
   *
   * @param error$ Observable that emits the error message
   * @param destroy$ Observable that emits when the component is destroyed
   * @param errorHandler
   */
  handleErrors(error$: Observable<string>, destroy$: Observable<any>, errorHandler?: (error: string) => void): void {
    if (!errorHandler)
      {errorHandler = this.defaultErrorHandler.bind(this);}
    error$.pipe(takeUntil(destroy$)).subscribe(errorHandler);
  }

  /**
   * Default error handler that shows an alert with the given error message
   *
   * @param error
   */
  async defaultErrorHandler(error: string): Promise<void> {
    await this.currentAlert?.dismiss();

    this.currentAlert = await this.alertController.create({
      header: this.translateService.instant('txError'),
      message: error,
      buttons: [this.translateService.instant('txOk')]
    });
    await this.currentAlert.present();
  }
}
