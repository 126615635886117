import { Component } from "@angular/core";
import { ModalController } from '@ionic/angular';
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";

@Component({
  selector: 'page-cargar-configuracion-modal-qr',
  template: '',
  styleUrls: []
})
export class CargarConfiguracionModalQrPage {

  url = "";

  constructor(
    public barcodeScanner: BarcodeScanner,
    public modalCtrl: ModalController) {
    this.Scan();
  }

  Scan(): void {
    this.barcodeScanner.scan()
      .then(result => {
        this.url = result.text;
        const data = {
          url: this.url
        };
        this.modalCtrl.dismiss(data);
      })
      .catch(error => {
        // console.log(error);
        this.modalCtrl.dismiss();
      });
  }

}
