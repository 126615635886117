import {Subscription} from 'rxjs';
import {Component} from '@angular/core';
import {ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import * as Data from '../../config/constantes';
import {
  PARTES_COLOR_ESTADO_NULO,
  PARTES_TIPO_ESTADO_APLAZADO,
  PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA,
  PARTES_TIPO_ESTADO_CORRECTO,
  PARTES_TIPO_ESTADO_INCORRECTO
} from '../../config/constantes';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {GemeProvider} from '../../services/geme/geme.provider';
import {PersistenciaGeneralProvider} from '../../services/persistencia-configuracion/persistencia-general.provider';

@Component({
  selector: 'page-parte-resultado-modal',
  templateUrl: 'parte-resultado-modal.html',
})
export class ParteResultadoModalPage {

  estados: any[] = [];
  public constants = Data;
  private backButtonListener?: Subscription;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              public _ptp: PartesTabProvider,
              public _translate: TranslateProvider,
              public _gemeProvider: GemeProvider,
              public _pg: PersistenciaGeneralProvider,
              //public viewCtrl: ViewController,
              public platform: Platform,
              private modalCtrl: ModalController,
              ) {

        //SIN SELECCIONAR
        this.estados.push({
          colorEstadoParte: PARTES_COLOR_ESTADO_NULO,
          txEstadoParte: this._translate.traducir("txPartesEstadoSinSeleccionar"),
          codigoTipoEstadoParte: null,
          srcImgEstadoParte: "assets/imgs/geme/icoNinguno.png"
        });

        //correcto
        this.estados.push({
          colorEstadoParte: this._gemeProvider.getColorEstadoParte(PARTES_TIPO_ESTADO_CORRECTO),
          txEstadoParte: this._translate.traducir(this._gemeProvider.getTextoEstadoParte(PARTES_TIPO_ESTADO_CORRECTO)),
          codigoTipoEstadoParte: PARTES_TIPO_ESTADO_CORRECTO,
          srcImgEstadoParte: this._gemeProvider.getSrcImgEstadoParte(PARTES_TIPO_ESTADO_CORRECTO)
        });

        //incorrecto
        this.estados.push({
          colorEstadoParte: this._gemeProvider.getColorEstadoParte(PARTES_TIPO_ESTADO_INCORRECTO),
          txEstadoParte: this._translate.traducir(this._gemeProvider.getTextoEstadoParte(PARTES_TIPO_ESTADO_INCORRECTO)),
          codigoTipoEstadoParte: PARTES_TIPO_ESTADO_INCORRECTO,
          srcImgEstadoParte: this._gemeProvider.getSrcImgEstadoParte(PARTES_TIPO_ESTADO_INCORRECTO)
        });

        //aplazado
        this.estados.push({
          colorEstadoParte: this._gemeProvider.getColorEstadoParte(PARTES_TIPO_ESTADO_APLAZADO),
          txEstadoParte: this._translate.traducir(this._gemeProvider.getTextoEstadoParte(PARTES_TIPO_ESTADO_APLAZADO)),
          codigoTipoEstadoParte: PARTES_TIPO_ESTADO_APLAZADO,
          srcImgEstadoParte: this._gemeProvider.getSrcImgEstadoParte(PARTES_TIPO_ESTADO_APLAZADO)
        });

        //aplazado por incidencia
        if(_ptp.parte.teIncidenciesObertes == 0 && _ptp.estadoOriginal == this.constants.PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA){
          this.estados.push({
            colorEstadoParte: this._gemeProvider.getColorEstadoParte(PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA),
            txEstadoParte: this._translate.traducir(this._gemeProvider.getTextoEstadoParte(PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA)),
            codigoTipoEstadoParte: PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA,
            srcImgEstadoParte: this._gemeProvider.getSrcImgEstadoParte(PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA)
          });
        }
  }

  /*** trabajo con el boton atras */
  ionViewDidEnter() {
    this.initializeBackButtonCustomHandler();
  }

  ionViewWillLeave() {
    this.backButtonListener?.unsubscribe()
  }

  async initializeBackButtonCustomHandler(): Promise<void> {
    this.backButtonListener = this.platform.backButton.subscribeWithPriority(101, () => {
      this.modalCtrl.dismiss();
    });
  }
   /*** fin trabajo con el boton atras */

  cerrarConParametros(estado){

    const data = {
      estadoSeleccionado: estado,
    }

    this.modalCtrl.dismiss(data);
  }

  cerrarSinParametros(){
    this.modalCtrl.dismiss();
  }

}
