import {Injectable} from "@angular/core";
import {from} from 'rxjs';
import {switchMap} from "rxjs/operators";

import {AjaxClientProvider} from "@app/services/ajax-client/ajax-client.provider";
import {Image} from "@app/core/element/domain/image";
import {Annotation} from "@app/core/element/domain/annotation";
import {PARAM_ELEMENT, PARAM_GUARDAR_ELEMENT_APP, PARAM_STRCONN} from "@app/config/constantes";
import {PostParams} from "@app/interfaces/ajax.interface";
import {WsUtilsProvider} from "@app/services/utils-ws/ws-utils";
import {Element} from "@app/core/element/domain/element";
import {FileProvider} from "@app/services/file/file.provider";
import {Directory, Filesystem} from "@capacitor/filesystem";
import { FileUtils } from "@app/image-picker/file-utils";

@Injectable({
    providedIn: 'root'
  })
export class UpdateWebService {

  constructor(private ajax: AjaxClientProvider,
              private _wsUtils: WsUtilsProvider,
              private fp: FileProvider) {}

  ImageToBase64(path: string): Promise<string> {
    return Filesystem.readFile({
      path: `images/${path}`,
      directory: Directory.Data
    }).then(data => data.data);
  }

  async serializeElement(param: Element): Promise<Record<string, any>> {

    if (param.editatAnotacionsApp == undefined) {
      param.editatAnotacionsApp = 0;
    }

    if (param.editatEstatApp == undefined) {
      param.editatEstatApp = 0;
    }

    if (param.editatImatgesApp == undefined) {
      param.editatImatgesApp = 0;
    }

    let element = {
      idElement: param.idElement,
      idTipologiaEstatElement: param.idTipologiaEstatElement,
      dataHoraEstatElement: param.dataHoraEstatElement,
      observacionsEstat: param.observacionsEstat,
      editatEstatApp: param.editatEstatApp,
      imatges: [],
      editatImatgesApp: param.editatImatgesApp,
      anotacions: param.anotacions.map(this.serializeAnnotation),
      editatAnotacionsApp: param.editatAnotacionsApp,
    }
    let image: any;
    for (image in param.imatges) {
      element.imatges.push(await this.serializeImage(param.imatges[image]));
    }
    return element;
  }

  async serializeImage(param: Image): Promise<Record<string, any>> {
    const extension = FileUtils.getExtension(param.nomFitxerLocal);
    if (param.idImatgeElement == null) {
      let path = param.nomFitxerLocal;
      let base64 = await this.ImageToBase64(path);
      return {
        idImatgeElement: null,
        nom: param.nom,
        fitxerEnviar: {contingutFitxer: base64, extensioFitxer: extension}
      }
    } else {
      return {
        idImatgeElement: param.idImatgeElement,
        nom: param.nom
      }
    }
  }

  serializeAnnotation(param: Annotation): Record<string, any> {
    let annotation = {
      dataHoraInici: param.dataHoraInici,
      dataHoraFinal: param.dataHoraFinal,
      text: param.text
    }
    if(param.idAnotacioElement == null) {
      annotation['idAnotacioElement'] = null;
    } else {
      annotation['idAnotacioElement'] = param.idAnotacioElement;
    }
    if(param.editatApp){
      annotation['editatApp'] = param.editatApp;
    }
    return annotation;
  }

  async getParams(element: Element): Promise<PostParams[]> {
    return [
      {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      },
      {
        nombre: PARAM_ELEMENT,
        valor: JSON.stringify(await this.serializeElement(element))
      }
    ]
  }

  execute(element: Element): Promise<Element> {
    return from(this.getParams(element)).pipe(switchMap((params: PostParams[]) =>
      this.ajax.consultarWS(PARAM_GUARDAR_ELEMENT_APP, params, 0)
    )).toPromise();
  }
}
