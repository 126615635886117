import {GetPartsWebService} from "@app/core/part/domain/get-parts.web-service";
import {Observable} from "rxjs";
import {ParteDetallsList} from "@app/core/part/domain/part";
import {AjaxClientProvider} from "@app/services/ajax-client/ajax-client.provider";
import {PostParams} from "@app/interfaces/ajax.interface";
import {
  PARAM_GET_DETALLS_PARTES_NO_PLAN_SYNC_APP,
  PARAM_MODELS_ELEMENTS_POT_NO_PLAN,
  PARAM_ROW_FINAL,
  PARAM_ROW_INICI,
  PARAM_STRCONN
} from "@app/config/constantes";
import {Injectable} from "@angular/core";
import {WsUtilsProvider} from "@app/services/utils-ws/ws-utils";


@Injectable()
export class GetPartHttpWebService implements GetPartsWebService {
  constructor(
    private http: AjaxClientProvider,
    private wsUtils: WsUtilsProvider,
  ) {
  }

  getParams(partIDs: number[]): PostParams[] {
    return [
      {
        nombre: PARAM_STRCONN,
        valor: this.wsUtils.getStrConn()
      },
      {
        nombre: PARAM_ROW_INICI,
        valor: String(1)
      },
      {
        nombre: PARAM_ROW_FINAL,
        valor: String(partIDs.length)
      },
      {
        nombre: PARAM_MODELS_ELEMENTS_POT_NO_PLAN,
        valor: String(partIDs)
      }
    ]
  }


  execute(partIDs: number[]): Observable<ParteDetallsList> {
    const operation = PARAM_GET_DETALLS_PARTES_NO_PLAN_SYNC_APP;
    const params = this.getParams(partIDs);

    return this.http.consultarWS(operation, params, 0);
  }
}
