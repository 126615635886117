import { Component, Input } from '@angular/core';
import { TranslateProvider } from '../../../services/translate/translate.provider';
import { HtmlUtilsProvider } from '../../../services/html-utils/html-utils';
import { FileProvider } from '@app/services/file/file.provider';
import { PersistenciaGeneralProvider } from '@app/services/persistencia-configuracion/persistencia-general.provider';
import { BlocsElementEnParteData } from '@app/domains/blocs-element-en-parte-data-domain';

@Component({
  selector: 'elements-bloc-fase',
  templateUrl: './elements-bloc-fase.component.html'
})
export class ElementsBlocFaseComponent {

  @Input() public elements: BlocsElementEnParteData[];
  @Input() public blocElement: string;
  constructor(
              public _html: HtmlUtilsProvider,
              public _translate: TranslateProvider,
              public _fp: FileProvider,
              public _pg: PersistenciaGeneralProvider,
              ){}

}
