import { StorageProvider } from './../storage/storage.provider';
import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import moment from 'moment';
import * as Data from '../../config/constantes';
import {
  FICHERO_NO_ENVIADO,
  PARAM_EXISTENTS,
  PARAM_FITXERS_ADJUNTS,
  PARAM_FIXTERS_ARXIU_TECNIC,
  PARAM_GET_DETALLS_PARTES_SYNC_APP,
  PARAM_GET_ELEMENTS_PROJECTE_SYNC_APP,
  PARAM_GET_FITXERS_PARTES_SYNC_APP,
  PARAM_GET_IMGS_ELE_SYNC_APP,
  PARAM_GET_LLISTA_PARTES_SYNC_APP,
  PARAM_GET_MATERIALS_SYNC_APP,
  PARAM_GET_OPERARIS_SYNC_APP,
  PARAM_GUARDAR_PARTE_APP,
  PARAM_ID_GRUPO_GESTIO,
  PARAM_ID_MODEL_ELEMENT,
  PARAM_ID_PARTE,
  PARAM_ID_PROJECTE,
  PARAM_IMATGES_FASES,
  PARAM_IMATGES_RESPOSTES_FASES,
  PARAM_INCLUDE_NOT_PLANNED_PARTS,
  PARAM_PARTE,
  PARAM_PARTES,
  PARAM_PDP_RESPOSTES_FASES,
  PARAM_PROJECTES,
  PARAM_ROW_FINAL,
  PARAM_ROW_FINAL_VALUE_ADJUNTS,
  PARAM_ROW_FINAL_VALUE_DETALLES_PARTES,
  PARAM_ROW_FINAL_VALUE_IMAGES,
  PARAM_ROW_INICI,
  PARAM_STRCONN,
  PARTES_RESPUESTA_CORRECTO_INCORRECTO,
  PARTES_RESPUESTA_HECHO,
  PARTES_RESPUESTA_NO_SI,
  PARTES_RESPUESTA_SI_NO,
  SYNC_HOURS_CHECK,
  TIMEOUT_CONSULTAS_WS_DEFECTO_SEG
} from '../../config/constantes';
import { PersistenciaGeneralProvider } from '../persistencia-configuracion/persistencia-general.provider';
import { HtmlUtilsProvider } from '../html-utils/html-utils';
import { PostParams } from '../../interfaces/ajax.interface';
import { WsUtilsProvider } from '../utils-ws/ws-utils';
import { PartesTabProvider } from '../partes-tab/partes-tab.provider';
import { AjaxClientProvider, ErrorConsulta } from '../ajax-client/ajax-client.provider';
import { UiMessagesProvider } from '../ui-messages/ui-messages';
import { TranslateProvider } from '../translate/translate.provider';
import { FileProvider } from '../file/file.provider';
import { ResourcesProvider } from '../resources/resources.provider';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { SyncNotPlannedPartsUseCase } from "@app/core/part/application/sync-not-planned-parts.use-case";
import { GetPermissionUseCase } from "@app/core/permission/application/get-permission.use-case";
import { SyncElementStateTypologiesUseCase } from "@app/core/element/application/sync-element-state-typologies.use-case";
import { PartRepository } from "@app/core/part/infrastructure/part.repository";
import { ElementRepository } from "@app/core/element/infrastructure/element.repository";
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Element } from '@app/core/element/domain/element';
import { ElementInfo } from '@app/core/element/domain/element-info';
import { Directory, Encoding } from '@capacitor/filesystem';


@Injectable({
  providedIn: 'root',
})
export class SyncProvider {

  localArbre: any;
  localElement: any;
  partesListado = [];
  partesResumen = [];
  partesResumenSync = [];
  localParte: any;
  localMaterials: any;
  localOperaris: any;
  paramsToPost: PostParams[];
  constants = Data;

  minUpdate: any = null;
  momentoRecordarAvisoSincronizacion: any = null;

  partesIds: string[] = [];

  arrayImatgesFases = [];
  arrayImatgesRespostesFases = [];
  arrayPdfsRespostesFases = [];
  arrayFitxersAdjunts = [];
  arrayFitxersArxiuTecnic = [];
  adjuntsMassaGran: any = null;

  rowIniciAdjunts = 1;
  rowFinalAdjunts: number = PARAM_ROW_FINAL_VALUE_ADJUNTS;

  rowInici = 1;
  rowFinal: number = PARAM_ROW_FINAL_VALUE_IMAGES;

  rowIniciDetallePartes = 1;
  rowFinalDetallePartes: number = PARAM_ROW_FINAL_VALUE_DETALLES_PARTES;

  pagina: any = 1;
  paginaDetallePartes: any = 1;
  paginaAdjuntos: any = 1;

  downloadResult: any = null;
  sincronizando = false;

  constructor(public platform: Platform,
    public alertCtrl: AlertController,
    public _pg: PersistenciaGeneralProvider,
    public _ptp: PartesTabProvider,
    public _wsUtils: WsUtilsProvider,
    public _htmlUtils: HtmlUtilsProvider,
    public _ui: UiMessagesProvider,
    public _ajax: AjaxClientProvider,
    public _messages: UiMessagesProvider,
    public _fp: FileProvider,
    public _rp: ResourcesProvider,
    public _translate: TranslateProvider,
    public storage: StorageProvider,
    public insomnia: Insomnia,
    private getPermissionUseCase: GetPermissionUseCase,
    private syncNotPlannedPartsUseCase: SyncNotPlannedPartsUseCase,
    private syncElementsUseCase: SyncElementStateTypologiesUseCase,
    private partRepo: PartRepository,
    private elementRepo: ElementRepository) {
    // console.log('Hello SyncProvider Provider');
  }


  //#region Arbre
  async setArbre(idProjecte: any, arbre: any) {
    try {
      const path = 'arbre_' + this._pg.usuarioLoginModel.idUsuari + '_' + idProjecte;
      const data = JSON.stringify(arbre);
      const directorySaveOnIndexedDB = Directory.Documents;
      try {
        await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
      } catch (errorSave) {
        console.error('error save', errorSave);
      }
    } catch (error) {}
  }

  async removeArbre(idProjecte: any) {
    const directorySaveOnIndexedDB = Directory.Documents;
    const path = 'arbre_' + this._pg.usuarioLoginModel.idUsuari + '_' + idProjecte;
    await this._pg.removeFileOnIndexedDB(path, directorySaveOnIndexedDB);
  }

  async getArbre(idProjecte: any) {
      const path = 'arbre_' + this._pg.usuarioLoginModel.idUsuari + '_' + idProjecte;
      const directorySaveOnIndexedDB = Directory.Documents;
      try {
        const data = await this._pg.getFileOnIndexedDB(path, directorySaveOnIndexedDB, Encoding.UTF8);
        this.localArbre = JSON.parse(data.data);
      } catch (errorSave) {
        this.localArbre = [];
        return false;
      }
    return true;
  }

  //#endregion

  //#region Elements

  /**
  * Stores an element in indexedDB.
  * @param element The element to store.
  */
  async setElement(element: Element): Promise<void> {
    try {
      await this.saveElementOnIndexedDB(element);
    } catch (error) {}
  }

  private async saveElementOnIndexedDB(element: Element) {
    const { idElement, idProjecte } = element;
    const path = this.getElementStorageKey(idProjecte, idElement);
    const data = JSON.stringify(element);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      console.error('error save', errorSave);
    }
  }

  /**
  * Removes an element from indexedDB.
  * @param idProjecte The project ID of the element.
  * @param idElement The ID of the element to remove.
  */
  async removeElement(idProjecte: number, idElement: number) {
    const key = this.getElementStorageKey(idProjecte, idElement);

    const directorySaveOnIndexedDB = Directory.Documents;
    await this._pg.removeFileOnIndexedDB(key, directorySaveOnIndexedDB);
  }

  /**
  * Retrieves an element from indexedDB
  * @param elementInfo Information about the element to retrieve.
  * @returns A promise that resolves to `true` if the element is found, `false` otherwise.
  */
  getElement(elementInfo: ElementInfo): Promise<unknown> {
    const { idElement, idProjecte } = elementInfo;
    const key = this.getElementStorageKey(idProjecte, idElement);

    return new Promise((resolve, reject) => {

      this.getElementFromIndexedDB(elementInfo).then((data) => {
        if (data) {
          this.localElement = data;
          resolve(true);
        } else {
          this.localElement = null;
          resolve(false);
        }
      });
    });
  }

  async getElementFromIndexedDB(elementInfo: ElementInfo) {
    const {  idProjecte, idElement } = elementInfo;
    const path = this.getElementStorageKey(idProjecte, idElement);
    const directorySaveOnIndexedDB = Directory.Documents;
    const data = await this._pg.getFileOnIndexedDB(path, directorySaveOnIndexedDB, Encoding.UTF8);
    return JSON.parse(data.data);
  }

  /**
   * Generates a storage key for an element based on user and project information.
   * @param idProjecte The project ID of the element.
   * @param idElement The ID of the element.
   * @returns The storage key for the element.
   */
  private getElementStorageKey(idProjecte: number, idElement: number) {
    return `element_${this._pg.usuarioLoginModel.idUsuari}_${idProjecte}_${idElement}`;
  }

  //#endregion


  //#region partes listado
  async setPartesListado(idProjecte: any, partes: any) {
    try {
      this.saveListadoPartesOnIndexedDB(idProjecte, partes);
    } catch (error) { }
  }

  private async saveListadoPartesOnIndexedDB(idProjecte: any, partes: any) {
    const path = 'llista_partes_' + idProjecte;
    const data = JSON.stringify(partes);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      console.error('error save llista partes', errorSave);
    }
  }

  async removePartesListado(idProjecte: any) {
    const directorySaveOnIndexedDB = Directory.Documents;
    await this._pg.removeFileOnIndexedDB('llista_partes_' + idProjecte, directorySaveOnIndexedDB);
  }

  getPartesListado(idProjecte: any) {
    const promesa = new Promise((resolve, reject) => {
      this.getLlistaPartesFromIndexedDB(idProjecte).then((data) => {
        if (data) {
          this.partesListado = data;
          resolve(true);
        }
      });
    });

    return promesa;
  }


  async getLlistaPartesFromIndexedDB(idProjecte: string) {
    const directorySaveOnIndexedDB = Directory.Documents;
    const data = await this._pg.getFileOnIndexedDB('llista_partes_' + idProjecte, directorySaveOnIndexedDB, Encoding.UTF8);
    return JSON.parse(data.data);
  }
  //#endregion

  //#region partes resumen
  async setPartesResumen(idProjecte: any, resumen: any) {
    try {
      await this.setPartedResumenOnIndexedDB(idProjecte, resumen);
    } catch(error) {}
  }

  private async setPartedResumenOnIndexedDB(idProjecte: string, resumen: any) {
    const path = 'sync_partes_' + idProjecte;
    const data = JSON.stringify(resumen);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      console.error('error save', errorSave);
    }
  }

  async removePartesResumen(idProjecte: any) {
    this.removePartesResumenFromIndexedDB(idProjecte);
  }

  private async removePartesResumenFromIndexedDB(idProjecte: any) {
    const directorySaveOnIndexedDB = Directory.Documents;
    await this._pg.removeFileOnIndexedDB('sync_partes_' + idProjecte, directorySaveOnIndexedDB);
  }

  async getPartesResumen(idProjecte: any): Promise<boolean> {

    const data = await this.getPartesResumenFromIndexedDB(idProjecte);

    if (data) {
      this.partesResumen = data;
      return true;
    } else {
      // If data is falsy, log the message and set partesResumen to an empty array
      this.partesResumen = [];
      return false;
    }

  }

  async getPartesResumenFromIndexedDB(idProjecte: string) {
    const directorySaveOnIndexedDB = Directory.Documents;
    const path = 'sync_partes_' + idProjecte;
    try {
      const data = await this._pg.getFileOnIndexedDB(path, directorySaveOnIndexedDB, Encoding.UTF8);

      return JSON.parse(data.data);
    } catch (error) {
      return null;
    }
  }
  //#endregion

  //#region detalles partes
  async setDetalleParte(idParte: any, parte: any) {
    try {
      this.saveDetailPartOnIndexedDB(idParte, parte);
    } catch(error) {
    }
  }

  async removeDetalleParte(idParte: string) {
      this._pg.removeFileOnIndexedDB('parte_' + idParte, Directory.Documents);
  }

  async getDetalleParte(idParte: any) {
    try {
        const directorySaveOnIndexedDB = Directory.Documents;
        const data = await this._pg.getFileOnIndexedDB('parte_' + idParte, directorySaveOnIndexedDB, Encoding.UTF8);
        this.localParte = JSON.parse(data.data);
        return true;
    } catch (error) {
      this.localParte = null;
      return false;
    }
}

  //#endregion

  //#region Operaris
  async setOperaris(idProjecte: any, operaris: any) {
    try {
      await this.saveOperarisOnIndexedDB(idProjecte, operaris);
    } catch (error) {}
  }

  private async saveOperarisOnIndexedDB(idProjecte: any, operaris: any) {
    const path = 'operaris_' + idProjecte;
    const data = JSON.stringify(operaris);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      console.error('error save operaris', errorSave);
    }
  }

  async removeOperaris(idProjecte: any) {
    const directorySaveOnIndexedDB = Directory.Documents;
    await this._pg.removeFileOnIndexedDB('operaris_' + idProjecte, directorySaveOnIndexedDB);
  }

  getOperaris(idProjecte: any) {
    const promesa = new Promise<void>((resolve, reject) => {
      this.getOperarisFromIndexedDB(idProjecte).then((data) => {
        if (data) {
          this.localOperaris = data;
        } else {
          this.localOperaris = null;
        }
      });
    });

    return promesa;
  }

  async getOperarisFromIndexedDB(idProjecte: string) {
    const directorySaveOnIndexedDB = Directory.Documents;
    const data = await this._pg.getFileOnIndexedDB('operaris_' + idProjecte, directorySaveOnIndexedDB, Encoding.UTF8);
    return JSON.parse(data.data);
  }

  //#endregion

  //#region Materials
  setMaterials(idGrupGestio: any, materials: any) {
    try{
      this.setMaterialsOnIndexedDB(idGrupGestio, materials);
    } catch (error) {}
  }

  private async setMaterialsOnIndexedDB(idGrupGestio: any, materials: any) {
    const path = 'materials_' + idGrupGestio;
    const data = JSON.stringify(materials);
    const directorySaveOnIndexedDB = Directory.Documents;
    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      console.error('error save', errorSave);
    }
  }

  async removeMaterials(idGrupGestio: any) {
    const directorySaveOnIndexedDB = Directory.Documents;
    await this._pg.removeFileOnIndexedDB('materials_' + idGrupGestio, directorySaveOnIndexedDB);
  }

  getMaterials(idGrupGestio: any) {
    const promesa = new Promise((resolve, reject) => {

      this.getMaterialsFromIndexedDB(idGrupGestio).then((data) => {
        if (data) {
          this.localMaterials = data;
        } else {
          this.localMaterials = null;
        }
      });
    });

    return promesa;
  }

  async getMaterialsFromIndexedDB(idGrupGestio: string) {
    const directorySaveOnIndexedDB = Directory.Documents;
    const data = await this._pg.getFileOnIndexedDB('materials_' + idGrupGestio, directorySaveOnIndexedDB, Encoding.UTF8);
    return JSON.parse(data.data);
  }

  //#endregion

  //#region sync lista
  syncResumenList(idProjecte: any, nuevoResumen: any): Promise<Object> {
    return new Promise((resolve, reject) => {
      this.getPartesResumen(idProjecte)
        .then(async (res: any) => {

          if (this.partesResumen) {
            //console.log("this.partesResumen -> " + JSON.stringify(this.partesResumen));
            // console.log("this.partesResumen count - idProjecte -> " + idProjecte + " - cantidad " + Object.keys(this.partesResumen).length);
            //es la nueva lista que descargue
            const keysNuevoResumen = Object.keys(nuevoResumen);

            //la lista que ya existía de una sincronización anterior
            const keysPartesResumen = Object.keys(this.partesResumen);

            //si el parte (del nuevo listado)  no existe en la lista anterior lo agrego para descargar
            //o si existe pero idVersio es distino al que estaba almacenado
            for (let i = 0; i < keysNuevoResumen.length; i++) {
              if (!this.partesResumen[keysNuevoResumen[i]] || nuevoResumen[keysNuevoResumen[i]].idVersio != this.partesResumen[keysNuevoResumen[i]].idVersio) {
                this.partesResumenSync.push(keysNuevoResumen[i]);
              }
            }

            // console.log("cantidad de partes a pedir: " + this.partesResumenSync.length);
            //recupero los partes que no se usan, es la sustraccion de la nueva lista con la lista anterior
            const toRemove = keysPartesResumen.filter(item => keysNuevoResumen.indexOf(item) < 0);

            // console.log("cantidad de partes a eliminar: " + toRemove.length);
            //elimino los partes
            for (let j = 0; j < toRemove.length; j++) {
              await this.removeDetalleParte(toRemove[j]);
            }

          }

          resolve(true);
        });
    });
  }

  //#endregion

  //#region check time connection and sync action

  /*
    Método que verifica si se debe mostrar alerta de sincronización por tiempo transcurrido
    devueve true si corresponde
  */
  checkTimeConnection() {
    if (this._pg.workingOffline && this.hasDiffereceTimeSync()) {
      return true;
    }
    return false;
  }

  /*
    Método que calcula si hay diferencia en tiempo. retorna true si corresponde advertir
  */
  private hasDiffereceTimeSync() {

    if (this.momentoRecordarAvisoSincronizacion > '') {
      const isBefore = moment(this.momentoRecordarAvisoSincronizacion).isBefore();
      // console.log("isBefore -> " + isBefore);
      return isBefore;
    }

    const nowDate = this._htmlUtils.getDateToMoment(this._htmlUtils.generateManualDate());
    const syncDate = this._htmlUtils.getDateToMoment(this._pg.usuarioLoginModel.ultimaSincronizacion);
    const duration = moment.duration(nowDate.diff(syncDate));
    const hours = duration.asHours();
    // console.log("hours -> " + hours);

    return hours > SYNC_HOURS_CHECK;
  }

  //#endregion

  //#region enviar partes al servidor


  /**
   * Sends all reports asynchronously, updates listings once all submissions are complete.
   */
  private async sendReportsCompleteProcess() {
    // Send all reports asynchronously, wait for all submissions to complete.
    await this.enviarPartesProcess();

    // console.log("for collectDetallesPartesData");
    // If the 'partesIds' array contains elements, iterate through it.
    // For each element, download and save it to the device.
    if (this.partesIds.length > 0) {
      for (let i = 0; i < this.partesIds.length; i++) {
        await this.collectDetallesPartesData([this.partesIds[i]]);

      }

      // Iterate through the available projects and download their listings.
      for (let j = 0; j < this._pg.proyectosDisponibles.length; j++) {
        // console.log("iteramos proyectosDisponibles array. ...- " + this._pg.proyectosDisponibles[j].idProjecte);
        await this.getListado(this._pg.proyectosDisponibles[j].idProjecte);
      }

    }
  }


  /**
   * Synchronize modified elements stored in offline storage.
   * Remove IDs from the list if synchronization is successful or
   * keep them for future attempts if synchronization fails.
   */
  private async sendModifiedElementsProcess(): Promise<void> {
    // Retrieve the list of modified element IDs stored in offline storage.
    const modifiedElementInfos: ElementInfo[] = await this.elementRepo.getModifiedElementInfos();

    if (modifiedElementInfos.length > 0) {
      // Create an array to track successfully synced element IDs.
      const successfullySyncedIds: number[] = [];

      // Iterate through the list of modified element IDs.
      for (let elementInfo of modifiedElementInfos) {
        // Get the element associated with an ID.
        const element = await this.elementRepo.getModifiedElement(elementInfo);
        // Attempt to synchronize the element.
        const syncResult = await this.elementRepo.syncOfflineElement(element);
        // If synchronization is successful, add the ID to the successfully synced IDs array.
        if (syncResult) { successfullySyncedIds.push(elementInfo.idElement); }
      }
      // Remove the successfully synced IDs from the list of modified elements.
      await this.elementRepo.removeModifiedElementIds(successfullySyncedIds);
    }
  }

  /**
   * Sends local data (reports and elements) saved in IndexedDB when the app is offline.
   * Shows a loading message in the process.
   */
  async sendLocalData(): Promise<void> {
    // Display a loading indicator while sending data
    const loading = await this._ui.showLoading('txSendingItems');
    // Send reports and modified elements
    await this.sendReportsCompleteProcess();

    await this.sendModifiedElementsProcess();

    // Dismiss the loading indicator
    loading.dismiss();
  }

  collectDetallesPartesData(partesList: any) {
    return new Promise((resolve, reject) => {

      const params: PostParams[] = [];

      //strConn
      const paramStrLogin: PostParams = {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      };
      params.push(paramStrLogin);

      //rowInici
      const paramRowInici: PostParams = {
        nombre: PARAM_ROW_INICI,
        valor: "1"
      };
      params.push(paramRowInici);

      //rowFinal
      const paramRowFinal: PostParams = {
        nombre: PARAM_ROW_FINAL,
        valor: "1"
      };
      params.push(paramRowFinal);

      //partesList
      const paramPartes: PostParams = {
        nombre: PARAM_PARTES,
        valor: partesList != null ? partesList.join() : []
      };

      params.push(paramPartes);

      this.descargarDetallesPartes(PARAM_GET_DETALLS_PARTES_SYNC_APP, params)
        .then(async (res: any) => {
          // console.log('descargarDetallesPartes', res)
          await this.guardarDetallesPartes(res);
          resolve(true);

        })
        .catch(err => {
          // console.error(JSON.stringify(err));
          resolve(false);
        });
    });
  }

  async guardarDetallesPartes(detalles: any) {
    for (let i = 0; i < detalles.partes.length; i++) {
      await this.setDetalleParte(detalles.partes[i].idParte, detalles.partes[i]);
    }

    this.acumularFiles(detalles);
  }

  acumularFiles(detalles: any) {
    this.arrayImatgesFases.push(...detalles.imatgesFases);
    this.arrayImatgesRespostesFases.push(...detalles.imatgesRespostesFases);
    this.arrayPdfsRespostesFases.push(...detalles.pdfsRespostesFases);
    this.arrayFitxersAdjunts.push(...detalles.fitxersAdjunts);
    this.arrayFitxersArxiuTecnic.push(...detalles.fitxersArxiuTecnic);
  }

  descargarDetallesPartes(paramAccion: string, params: PostParams[]): Promise<any> {
    try {
      return this._ajax.consultarWS(paramAccion, params, 0)
        .toPromise()
        .catch(
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR DESCARGANDO DETALLE DE PARTES: " + errConsulta.categoria + " " + errConsulta.categoria);
            // console.error("PARAMETROS CON ERROR: " + JSON.stringify(params));
            return false;
          });
    } catch (err) {
      // console.error("error en descargarImagenes: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
    }
  }

  //este es el método que se comunica con el servidor y se descara el resumen de un determinado proyecto.
  async getListado(idProjecte: any) {
    await this.descargarListaPartes(idProjecte);
    return true;
  }

  /**
  * This method is invoked in the initial method.
  * First, it retrieves all the IDs of the edited parts.
  * Then, it sends the parts.
  */
  private async enviarPartesProcess() {
    await this.recuperarPartesIdsterateProcess();

    if (this.partesIds.length > 0) {
      const errorIds: number[] = await Promise.all(this.partesIds.map(async id => {
        const response = await this.sendReport(id);
        // If the report has been uploaded correctly, delete it from the storage
        if (response.resultat === 'OK') {
          await this.removeDetalleParte(id);
          return null;
        }
        return Number(id);
      }));
      // Save new list in case everything has not been uploaded correctly
      this.partRepo.saveIdList(errorIds.filter(id => id !== null));
    }
  }


  getParamsToGetParte(idParte: any, idModelElement: any) {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    };
    params.push(paramStrConn);
    // console.log("this._wsUtils.getStrConn() --> " + this._wsUtils.getStrConn());

    //idModelElement
    if (idModelElement != null && idModelElement != "") {
      const idModelElementParam: PostParams = {
        nombre: PARAM_ID_MODEL_ELEMENT,
        valor: idModelElement
      };
      params.push(idModelElementParam);
    }
    // console.log("parte.idModelElement --> " + idModelElement);

    //idParte
    const idParteParam: PostParams = {
      nombre: PARAM_ID_PARTE,
      valor: idParte
    };
    params.push(idParteParam);
    // console.log("parte.idParte --> " + idParte);

    return params;
  }

  descargarParteFromServer(paramAccion: string, params: PostParams[]) {
    return new Promise((resolve, reject) => {

      this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
        .subscribe((res: any) => {
          if (res != null) {
            // console.log('descargar parte from server', res)
            resolve(res);
          } else {
            resolve(false);
          }

        },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: " + errConsulta.categoria + " " + errConsulta.categoria);
            resolve(false);
          });

    })
      .catch(err => {
        // console.error("error en descargarLlistaPartesFromServer: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });
  }


  //método que según los proyectos dispnibles para el usuario
  //busca los partes que están editados y los guarda en this.partesIds para despues iterarlos
  async recuperarPartesIdsterateProcess() {
    this.partesIds = [];
    for (let j = 0; j < this._pg.proyectosDisponibles.length; j++) {
      await this.getPartesResumen(this._pg.proyectosDisponibles[j].idProjecte);
      if (this.partesResumen) {
        const keysPartesResumen = Object.keys(this.partesResumen);

        for (let i = 0; i < keysPartesResumen.length; i++) {
          if (this.partesResumen[keysPartesResumen[i]] && this.partesResumen[keysPartesResumen[i]].editado) {
            this.partesIds.push(keysPartesResumen[i]);
          }
        }
      }
    }
    let noPlanIds = await this.partRepo.getIdList();
    noPlanIds.forEach(id => {
      this.partesIds.push(id.toString());
    });
  }

  /**
  * This method prepares the report and sends it to the server.
  */
  private async sendReport(parte: string) {
    await this.getDetalleParte(parte);
    this.prepararParte(this.localParte);
    return this.saveInServer(PARAM_GUARDAR_PARTE_APP, this.paramsToPost).toPromise();
  }

  /**
  * This method saves data in the server.
  * @param paramAccion - The action parameter for the server request.
  * @param params - The parameters to be sent in the request.
  * @returns An observable containing the server response.
  */
  saveInServer(paramAccion: string, params: PostParams[]): Observable<{ resultat: string }> {
    return this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG).pipe(
      catchError((errConsulta: ErrorConsulta) => {
        this._ui.showToastDefault(errConsulta.mensaje);
        return throwError(errConsulta);
      })
    );
  }

  //Este método como dice su nombre prepara el parte para enviarlo al servidor
  //el parte que se guarda en el dispositivo tiene mas información que la que se debe enviar
  //en este punto hacemos eso. normalizamos el parte a la estructura que espera el WS
  prepararParte(parte: any) {
    const params: PostParams[] = [];

    //StrConn
    const paramStrConn: PostParams = {
      nombre: PARAM_STRCONN,
      valor: this._wsUtils.getStrConn()
    };
    params.push(paramStrConn);

    //limpio el parte para enviar solo lo que corresponda
    const parteEnviar = this._htmlUtils.getClone(parte);

    //Solo setea fecha al momento del envío en caso de no tener valor
    if (parteEnviar.dataHoraExecucio == null || parteEnviar.dataHoraExecucio == "") {
      parteEnviar.dataHoraExecucio = this._htmlUtils.generateManualDate();
    }

    if (!(parteEnviar.observacionsVersioParte.length > '')) {
      parteEnviar.observacionsVersioParte = null;
    }

    if (parteEnviar.idParte < 0) {
      parteEnviar.idParte = null;
    }

    delete parteEnviar.permisos;
    delete parteEnviar.idElement;
    delete parteEnviar.codiElement;
    delete parteEnviar.nomElement;
    delete parteEnviar.nomFitxerIconaElement;
    delete parteEnviar.rutaElement;
    delete parteEnviar.codiModel;
    delete parteEnviar.nomModel;
    delete parteEnviar.nomFitxerLocalTipologiaTreball;
    delete parteEnviar.teIncidenciesObertesModel;
    delete parteEnviar.bloqueig;
    delete parteEnviar.codiParte;
    delete parteEnviar.dataHoraPlanificacio;
    delete parteEnviar.forqueta;
    delete parteEnviar.infoExecucio;
    delete parteEnviar.dataHoraInicialForqueta;
    delete parteEnviar.dataHoraFinalForqueta;
    delete parteEnviar.numeroRevisio;
    delete parteEnviar.esFuturo;
    delete parteEnviar.srcImgEstadoParte;
    delete parteEnviar.txEstadoParte;
    delete parteEnviar.codigoEstadoParte;
    delete parteEnviar.colorEstadoParte;
    delete parteEnviar.eines;
    delete parteEnviar.esPotCrearNoPlan;
    delete parteEnviar.noPlanId;
    delete parteEnviar.idVersioParte;
    parteEnviar.fases.forEach(fase => {
      if (fase.tipusRespostaFase == PARTES_RESPUESTA_CORRECTO_INCORRECTO ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_HECHO ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_NO_SI ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_SI_NO) {
        fase.valor = fase.valor == "0" || fase.valor == "1" ? fase.valor : null;
      }

      delete fase.descripcio;
      delete fase.tipusRespostaFase;
      delete fase.nomSeleccioOpcio;
      delete fase.opcionsFases;
      delete fase.nomFitxerLocalImatge;
      delete fase.element;

      if (fase.fitxerEnviar) {
        const finalBase64Src = fase.base64.replace("data:image/*;charset=utf-8;base64,", "");
        fase.fitxerEnviar.contingutFitxer = finalBase64Src;
        delete fase.valorPreview;
        delete fase.base64;
        fase.valor = null;
      }
    });
    parteEnviar.materials.forEach(material => {
      delete material.codi;
      delete material.descripcio;
      delete material.unitat;
      delete material.quantitatPrevista;

      if (!(material.quantitatUtilitzada)) {
        material.quantitatUtilitzada = null;
      }
    });

    if (parteEnviar.incidencies) {
      parteEnviar.incidencies.forEach(incidencia => {
        delete incidencia.codi;
        delete incidencia.dataHoraInici;
        delete incidencia.idElement;
        delete incidencia.codiElement;
        delete incidencia.nomElement;
        delete incidencia.rutaElement;
        delete incidencia.nomEstat;
      });
    }

    parteEnviar.operaris.forEach(operari => {
      delete operari.descripcio;
    });
    parteEnviar.adjunts.forEach(adj => {
      delete adj.Tipus;

      if (adj.tamany == FICHERO_NO_ENVIADO) {
        const finalBase64Src = adj.base64.replace("data:image/*;charset=utf-8;base64,", "");
        adj.fitxerEnviar.contingutFitxer = finalBase64Src;
        delete adj.base64;

        delete adj.nomFitxerLocal;
        delete adj.tamany;
      }
    });
    parteEnviar.anotacions.forEach(anot => {
      delete anot.nomUsuari;
    });

    if (parteEnviar.tipusEstatParteRealitzat != this.constants.PARTES_TIPO_ESTADO_APLAZADO_INCIDENCIA) {
      parteEnviar.incidencies = [];
    }


    //parte a enviar
    const paramParte: PostParams = {
      nombre: PARAM_PARTE,
      valor: JSON.stringify(parteEnviar)
    };
    params.push(paramParte);

    this.paramsToPost = params;

    parteEnviar.fases.forEach(fase => {
      if (fase.tipusRespostaFase == PARTES_RESPUESTA_CORRECTO_INCORRECTO ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_HECHO ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_NO_SI ||
        fase.tipusRespostaFase == PARTES_RESPUESTA_SI_NO) {
        fase.valor = fase.valor == "0" || fase.valor == "1" ? fase.valor : null;
      }
    });
  }


  //metodo que sincroniza listados de partes y determina cuales ids son los que se deben pedir al servidor
  //este método se invocar de varios lados, entonces acá pondremos la lógica sobre si el proyecto debe sincronizar
  //de acuerdo a la configuración existent
  async descargarListaPartes(idProjecte: any): Promise<any> {
    try {
      const params: PostParams[] = [];

      //strConn
      const paramStrLogin: PostParams = {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      };
      params.push(paramStrLogin);

      //idProjecte
      const paramIdProjecte: PostParams = {
        nombre: PARAM_ID_PROJECTE,
        valor: idProjecte
      };
      params.push(paramIdProjecte);

      const permission = await this.getPermissionUseCase.execute();

      if (permission && permission.createNotPlannedReports) {
        const paramGet: PostParams = {
          nombre: PARAM_INCLUDE_NOT_PLANNED_PARTS,
          valor: "1"
        };
        params.push(paramGet);
      }
      //verificamos que el proyecto este disponible para sincronizar
      if (this._pg.proyectosDisponibles.find(x => x.idProjecte == idProjecte).configSync == 1) {
        try {
          const res = await this._ajax.consultarWS(PARAM_GET_LLISTA_PARTES_SYNC_APP, params, 0)
            .toPromise();
          await this.syncResumenList(idProjecte, res.resumen);
          await this.setPartesListado(idProjecte, res.elementsPartes);
          await this.setPartesResumen(idProjecte, res.resumen);
          const images = await this.syncNotPlannedPartsUseCase.execute(res.resumenNoPlanificats);
          this.arrayImatgesFases.push(...images);
          return res;
        } catch (errConsulta) {
          this._messages.showToastDefault(errConsulta.mensaje);
          // console.error("ERROR: descargarListaPartes" + errConsulta.categoria + " " + errConsulta.categoria);
          return true;
        }

      } else {
        //si no sincroniza seteamos en vacíos el listado y el resumen
        await this.setPartesListado(idProjecte, []);
        await this.setPartesResumen(idProjecte, []);
        return true;
      }

    } catch (err) {
      // console.error("error en descargarListaPartes: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
    }
  }

  //#endregion

  //seteo parte editado en listado
  setEditado(idParte: any, idProjecte: any, tipusEstatParteRealitzat: any) {
    return new Promise((resolve, reject) => {
      this.getPartesResumen(idProjecte).then(async () => {

        if (this.partesResumen[idParte] != null) {
          this.partesResumen[idParte].editado = true;
          this.partesResumen[idParte].tipusEstatParteRealitzat = tipusEstatParteRealitzat;
          await this.setPartesResumen(idProjecte, this.partesResumen);
          resolve(true);
        } else {
          const msg = this._translate.traducir("txProblemaResumenPartes");
          await this._ui.showOkAlert("txAtencionStr", msg, "txAceptar", { backdropDismiss: false });
          resolve(false);
        }
      });
    });
  }


  resetArraysList() {
    this.arrayImatgesFases = [];
    this.arrayImatgesRespostesFases = [];
    this.arrayPdfsRespostesFases = [];
    this.arrayFitxersAdjunts = [];
    this.arrayFitxersArxiuTecnic = [];
    this.adjuntsMassaGran = null;
  }


  /*
  *
  *    en esta parte se encuentran los métodos que hacen la sincronización
  *
  * /*/

  //region to sync
  async collectDataIteration(loading: HTMLIonLoadingElement) {
    this.downloadResult = null;

    for (let i = 0; i < this._pg.proyectosDisponibles.length; i++) {
      if (this._pg.proyectosDisponibles[i].configSync == 1) {
        //sincroniza datos sobre elementos
        await this.collectData(loading, String(this._pg.proyectosDisponibles[i].idProjecte), this._pg.proyectosDisponibles[i].nom);

        //sincroniza datos sobre listado de partes y resumen
        await this.collectPartesListData(loading, String(this._pg.proyectosDisponibles[i].idProjecte), this._pg.proyectosDisponibles[i].nom);

        //sincroniza datos sobreoperarios
        await this.collectOperarisData(loading, String(this._pg.proyectosDisponibles[i].idProjecte), this._pg.proyectosDisponibles[i].nom);
      }
    }

    await this.syncElementsUseCase.execute(loading, this.updateLoading.bind(this));

    //si esta activado en la sincronización sincroniza las imágenes de los elementos
    if (this._pg.usuarioLoginModel.syncImatgesElements == 1) {
      await this.collectImagesData(loading);
    }

    //sincroniza datos sobre materiales
    await this.collectMaterialsData(loading);

    //sincroniza archivos adjuntos de partes
    await this.collectAdjuntFiles(loading);

    this.sincronizando = false;
    loading.dismiss();

    //si adjuntsMassaGran tiene valor entonces en la sincronización
    //se dio el caso de ficheros superaran el máximo definido en el servidor.
    // console.log("finalice descarga verifico si hay archivos excluidos");
    // console.log("this.adjuntsMassaGran ----> " + JSON.stringify(this.adjuntsMassaGran));

    //si hay archivos excluidos de la descarga aviso en un mensaje
    if (this.adjuntsMassaGran) {
      const msg = this._translate.traducirWithParams("txAvisoArchivosGrandes", { size: this._htmlUtils.getMbFromBytes(this.adjuntsMassaGran.maxBytes) });
      await this._ui.showOkAlert("txAtencionStr", msg, "txAceptar", { backdropDismiss: false });
    }
  }

  async collectAdjuntFiles(loading: HTMLIonLoadingElement) {
    this.sincronizando = true;
    const imatgesFases = new Set(this.arrayImatgesFases);
    const imatgesRespostesFases = new Set(this.arrayImatgesRespostesFases);
    const pdfsRespostesFases = new Set(this.arrayPdfsRespostesFases);
    const fitxersAdjunts = new Set(this.arrayFitxersAdjunts);
    const fitxersArxiuTecnic = new Set(this.arrayFitxersArxiuTecnic);

    await this.collectAdjuntData(loading, imatgesFases, imatgesRespostesFases, pdfsRespostesFases, fitxersAdjunts, fitxersArxiuTecnic);
  }

  //#endregion collect data

  //Descarga de elementos de partes para un determinado proyecto
  collectData(loading: HTMLIonLoadingElement, idProjecte: string, nom: string) {
    return new Promise((resolve, reject) => {
      this.sincronizando = true;
      // console.log("DESCARGANDO PRESA: " + idProjecte);
      const msg = this._translate.traducir("txDescargandoElementos");
      this.updateLoading(loading, msg + " - " + nom);

      const params: PostParams[] = [];

      //strConn
      const paramStrLogin: PostParams = {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      };
      params.push(paramStrLogin);

      //idProjecte
      const paramIdProjecte: PostParams = {
        nombre: PARAM_ID_PROJECTE,
        valor: idProjecte
      };
      params.push(paramIdProjecte);

      this.descargarElementos(idProjecte, PARAM_GET_ELEMENTS_PROJECTE_SYNC_APP, params)
        .then((res) => {
          resolve(true);
        })
        .catch(err => {
          // console.error(JSON.stringify(err));
          this.sincronizando = false;
          resolve(false);
        });
    });
  }

  collectImagesData(loading: HTMLIonLoadingElement, rowIniciParam: number = 1, rowFinalParam: number = PARAM_ROW_FINAL_VALUE_IMAGES) {
    return new Promise((resolve, reject) => {
      this.sincronizando = true;
      const msg = this._translate.traducir("txDescargandoImagenes");
      const pag = this._translate.traducir("txPagina");

      const params: PostParams[] = [];

      //strConn
      const paramStrLogin: PostParams = {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      };
      params.push(paramStrLogin);

      //rowInici
      const paramRowInici: PostParams = {
        nombre: PARAM_ROW_INICI,
        valor: String(rowIniciParam)
      };
      params.push(paramRowInici);

      //rowFinal
      const paramRowFinal: PostParams = {
        nombre: PARAM_ROW_FINAL,
        valor: String(rowFinalParam)
      };
      params.push(paramRowFinal);

      //projectes
      const paramProjectes: PostParams = {
        nombre: PARAM_PROJECTES,
        valor: this._pg.proyectosDisponibles.filter(p => p.configSync == 1).map(s => s.idProjecte).join(',')
      };
      params.push(paramProjectes);


      //fileList
      const paramExistents: PostParams = {
        nombre: PARAM_EXISTENTS,
        valor: ''
      };

      // console.log("paramExistents --> " + JSON.stringify(paramExistents));
      params.push(paramExistents);

      this.descargar(PARAM_GET_IMGS_ELE_SYNC_APP, params)
        .then((res: any) => {
          this._rp.synchronizeResources(res, this._fp.pathDestinoGemeImatgeElement, loading, true)
            .then(() => {
              const totalpages = Math.ceil(res.quantitat / PARAM_ROW_FINAL_VALUE_IMAGES);
              this.updateLoading(loading, msg + " - " + pag + " " + this.pagina++ + (totalpages > 0 ? "/" + totalpages : ""));

              if (res.quantitat > this.rowFinal) {
                this.rowInici += PARAM_ROW_FINAL_VALUE_IMAGES;
                this.rowFinal += PARAM_ROW_FINAL_VALUE_IMAGES;

                this.collectImagesData(loading, this.rowInici, this.rowFinal)
                  .then(() => {
                    resolve(true);
                  })
                  .catch(err => {
                    // console.error("collectImagesData -> " + JSON.stringify(err));
                    this.sincronizando = false;
                    resolve(false);
                  });
              } else {
                this.pagina = 1;
                this.rowInici = 1;
                this.rowFinal = PARAM_ROW_FINAL_VALUE_IMAGES;
                resolve(true);
              }
            });

        })
        .catch(err => {
          // console.error(JSON.stringify(err));
          this.sincronizando = false;
          resolve(false);
        });
    });
  }

  async collectPartesListData(loading: HTMLIonLoadingElement, idProjecte: string, nom: string) {
    this.sincronizando = true;
    // console.log("listado de partes presa: " + idProjecte);
    const msg = this._translate.traducir("txDescargandoListadoPartes");
    this.updateLoading(loading, msg + " - " + nom);
    try {
      await this.descargarListaPartes(idProjecte);

      //partesResumenSync se carga en descargarListaPartes(contiene un listado de ids)
      if (this.partesResumenSync.length > 0) {
        try {
          await this.collectDetallesPartesDataSync(loading, nom, this.partesResumenSync);
          return true;
        } catch (err) {
          this.sincronizando = false;
          // console.error("error en collectPartesListData >> collectDetallesPartesData-> " + JSON.stringify(err));
          return false;
        }
      } else {
        return true;
      }

    } catch (err) {
      this.sincronizando = false;
      // console.error(JSON.stringify(err));
      return false;
    }
  }


  collectDetallesPartesDataSync(loading: HTMLIonLoadingElement, presa: string, partesList: any, rowIniciParam: number = 1, rowFinalParam: number = PARAM_ROW_FINAL_VALUE_DETALLES_PARTES) {
    return new Promise((resolve, reject) => {
      const msg = this._translate.traducir("txDescargandoDetallePartes");
      const pag = this._translate.traducir("txPagina");

      const params: PostParams[] = [];

      //strConn
      const paramStrLogin: PostParams = {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      };
      params.push(paramStrLogin);

      //rowInici
      const paramRowInici: PostParams = {
        nombre: PARAM_ROW_INICI,
        valor: String(rowIniciParam)
      };
      params.push(paramRowInici);

      //rowFinal
      const paramRowFinal: PostParams = {
        nombre: PARAM_ROW_FINAL,
        valor: String(rowFinalParam)
      };
      params.push(paramRowFinal);

      //partesList
      const paramPartes: PostParams = {
        nombre: PARAM_PARTES,
        valor: partesList != null ? partesList.join() : []
      };

      params.push(paramPartes);

      this.descargarDetallesPartes(PARAM_GET_DETALLS_PARTES_SYNC_APP, params)
        .then(async (res: any) => {

          // console.log("GET_DETALLS_PARTES_SYNC_APP res ", res);
          await this.guardarDetallesPartes(res);

          const totalpages = Math.ceil(res.quantitat / PARAM_ROW_FINAL_VALUE_DETALLES_PARTES);
          this.updateLoading(loading, msg + " - " + presa + " - " + pag + " " + this.paginaDetallePartes++ + (totalpages > 0 ? "/" + totalpages : ""));

          if (res.quantitat > this.rowFinalDetallePartes) {
            this.rowIniciDetallePartes += PARAM_ROW_FINAL_VALUE_DETALLES_PARTES;
            this.rowFinalDetallePartes += PARAM_ROW_FINAL_VALUE_DETALLES_PARTES;

            this.collectDetallesPartesDataSync(loading, presa, partesList, this.rowIniciDetallePartes, this.rowFinalDetallePartes)
              .then(() => {
                resolve(true);
              })
              .catch(err => {
                // console.error("collectDetallesPartesDataSync -> " + JSON.stringify(err));
                resolve(false);
              });
          } else {
            this.rowIniciDetallePartes = 1;
            this.paginaDetallePartes = 1;
            this.rowFinalDetallePartes = PARAM_ROW_FINAL_VALUE_DETALLES_PARTES;
            resolve(true);
          }

        })
        .catch(err => {
          // console.error(JSON.stringify(err));
          resolve(false);
        });
    });
  }

  collectOperarisData(loading: HTMLIonLoadingElement, idProjecte: string, nom: string) {
    return new Promise((resolve, reject) => {
      this.sincronizando = true;
      // console.log("DESCARGANDO OPERARIOS: " + idProjecte);
      const msg = this._translate.traducir("txDescargandoOperarios");
      this.updateLoading(loading, msg + " - " + nom);

      const params: PostParams[] = [];

      //strConn
      const paramStrLogin: PostParams = {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      };
      params.push(paramStrLogin);

      //idProjecte
      const paramIdProjecte: PostParams = {
        nombre: PARAM_ID_PROJECTE,
        valor: idProjecte
      };
      params.push(paramIdProjecte);

      this.descargarOperarios(idProjecte, PARAM_GET_OPERARIS_SYNC_APP, params)
        .then((res) => {
          resolve(true);
        })
        .catch(err => {
          // console.error(JSON.stringify(err));
          this.sincronizando = false;
          resolve(false);
        });
    });
  }

  collectMaterialsData(loading: HTMLIonLoadingElement) {
    return new Promise((resolve, reject) => {
      this.sincronizando = true;
      const idGrupGestio = String(this._pg.proyectoSeleccionado.idGrupoGestio);
      // console.log("DESCARGANDO MATERIALES: " + idGrupGestio);

      const msg = this._translate.traducir("txDescargandoMateriales");
      this.updateLoading(loading, msg);

      const params: PostParams[] = [];

      //strConn
      const paramStrLogin: PostParams = {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      };
      params.push(paramStrLogin);

      //idGrupoGestio
      const paramIdGrupGestio: PostParams = {
        nombre: PARAM_ID_GRUPO_GESTIO,
        valor: String(this._pg.proyectoSeleccionado.idGrupoGestio)
      };
      params.push(paramIdGrupGestio);

      this.descargarMateriales(idGrupGestio, PARAM_GET_MATERIALS_SYNC_APP, params)
        .then((res) => {
          resolve(true);
        })
        .catch(err => {
          // console.error(JSON.stringify(err));
          this.sincronizando = false;
          resolve(false);
        });
    });
  }

  //#endregion collect data

  //#region descarga
  descargarElementos(idProjecte: any, paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {
      this._ajax.consultarWS(paramAccion, params, TIMEOUT_CONSULTAS_WS_DEFECTO_SEG)
        .subscribe(async (res: any) => {
          // console.log("ELEMENTOS", res);
          await this.setArbre(idProjecte, res.arbre);

          Object.keys(res.elements).forEach(async key => {
            await this.setElement(res.elements[key]);
          });

          resolve(true);
        },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR: descargarElementos" + errConsulta.categoria + " " + errConsulta.categoria);
            resolve(false);
          });
    })
      .catch(err => {
        // console.error("error en descargarElementos: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });
    return promesa;
  }

  descargar(paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {
      this._ajax.consultarWS(paramAccion, params, 0)
        .subscribe((res: any) => {
          // console.log("IMAGENES", res);
          resolve(res);
        },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR DESCARGANDO IMAGENES: " + errConsulta.categoria + " " + errConsulta.categoria);
            // console.error("PARAMETROS CON ERROR: " + JSON.stringify(params));
            // console.error("paramAccion_paramAccion: " + paramAccion);
            resolve(false);
          });
    })
      .catch(err => {
        // console.error("error en descargarImagenes: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });
    return promesa;
  }

  descargarOperarios(idProjecte: any, paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {
      this._ajax.consultarWS(paramAccion, params, 0)
        .subscribe(async (res: any) => {
          // console.log("OPERARIOS", res);
          await this.setOperaris(idProjecte, res);
          resolve(true);
        },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR DESCARGANDO OPERARIOS: " + errConsulta.categoria + " " + errConsulta.categoria);
            resolve(false);
          });
    })
      .catch(err => {
        // console.error("error en descargarOperarios: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });
    return promesa;
  }

  descargarMateriales(idGrupGestio: any, paramAccion: string, params: PostParams[]) {
    const promesa = new Promise((resolve, reject) => {
      // console.log("parametros: " + JSON.stringify(params));
      this._ajax.consultarWS(paramAccion, params, 0)
        .subscribe(
          (res: any) => {
            // console.log("MATERIALES", res);
            this.setMaterials(idGrupGestio, res);
            resolve(true);
          },
          (errConsulta: ErrorConsulta) => {
            this._messages.showToastDefault(errConsulta.mensaje);
            // console.error("ERROR descargarMateriales: " + errConsulta.categoria + " " + errConsulta.categoria);
            // console.error("paramAccion: " + paramAccion);
            // console.error("PARAMETROS CON ERROR: " + JSON.stringify(params));
            resolve(false);
          }
        );
    })
      .catch(err => {
        // console.error("error en descargarMateriales: " + JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });
    return promesa;
  }

  //#endregion descarga

  //#region private methods
  // private switchOffLineMode(){
  //   let alert = this.alertCtrl.create({
  //     enableBackdropDismiss: false
  //   });
  //   let msg = this._translate.traducir( "txDeseaModoOffline");
  //   this._messages.showYesNoAlert(alert, "txAtencionStr", msg, "txYes", "txNo");
  //   alert.onDidDismiss((res) => {
  //         if(res){
  //           this._pg.workingOffline = true;
  //           return false;
  //         }
  //   });
  // }

  private updateLoading(loading: HTMLIonLoadingElement, msg: string) {
    loading.spinner = null;
    loading.message = this._ui.getHtmlMessage(msg);
  }

  async updateSincronizacionDate() {
    await this._pg.updateUltimaSincronizacion(this._pg.usuarioLoginModel.login, this._htmlUtils.generateManualDate());
  }

  //#endregion private methods


  //#region adjunts
  collectAdjuntData(loading: HTMLIonLoadingElement, imatgesFases: any, imatgesRespostesFases: any, pdfsRespostesFases: any, fitxersAdjunts: any, fitxersArxiuTecnic: any, rowIniciParam: number = 1, rowFinalParam: number = PARAM_ROW_FINAL_VALUE_ADJUNTS) {
    return new Promise((resolve, reject) => {
      const msg = this._translate.traducir("txDescargandoFicherosAdicionales");
      const pag = this._translate.traducir("txPagina");

      const params: PostParams[] = [];

      //strConn
      const paramStrLogin: PostParams = {
        nombre: PARAM_STRCONN,
        valor: this._wsUtils.getStrConn()
      };
      params.push(paramStrLogin);

      //rowInici
      const paramRowInici: PostParams = {
        nombre: PARAM_ROW_INICI,
        valor: String(rowIniciParam)
      };
      params.push(paramRowInici);

      //rowFinal
      const paramRowFinal: PostParams = {
        nombre: PARAM_ROW_FINAL,
        valor: String(rowFinalParam)
      };
      params.push(paramRowFinal);

      //projectes
      const paramProjectes: PostParams = {
        nombre: PARAM_PROJECTES,
        valor: this._pg.proyectosDisponibles.filter(p => p.configSync == 1).map(s => s.idProjecte).join(',')
      };
      params.push(paramProjectes);


      //paramImatgesFases
      const paramImatgesFases: PostParams = {
        nombre: PARAM_IMATGES_FASES,
        valor: imatgesFases != null && imatgesFases.size > 0 ? Array.from(imatgesFases).join() : ""
      };

      // console.log("paramExistents --> " + JSON.stringify(paramImatgesFases));
      params.push(paramImatgesFases);

      //imatgesRespostesFases
      const paramImatgesRespostesFases: PostParams = {
        nombre: PARAM_IMATGES_RESPOSTES_FASES,
        valor: imatgesRespostesFases != null && imatgesRespostesFases.size > 0 ? Array.from(imatgesRespostesFases).join() : ""
      };

      // console.log("paramImatgesRespostesFases --> " + JSON.stringify(paramImatgesRespostesFases));
      params.push(paramImatgesRespostesFases);

      //pdfsRespostesFases
      const paramPdfsRespostesFases: PostParams = {
        nombre: PARAM_PDP_RESPOSTES_FASES,
        valor: pdfsRespostesFases != null && pdfsRespostesFases.size > 0 ? Array.from(pdfsRespostesFases).join() : ""
      };

      // console.log("paramPdfsRespostesFases --> " + JSON.stringify(paramPdfsRespostesFases));
      params.push(paramPdfsRespostesFases);

      //fitxersAdjunts
      const paramFitxersAdjunts: PostParams = {
        nombre: PARAM_FITXERS_ADJUNTS,
        valor: (fitxersAdjunts != null && fitxersAdjunts.size > 0) ? Array.from(fitxersAdjunts).join() : ""
      };

      // console.log("paramFitxersAdjunts --> " + JSON.stringify(paramFitxersAdjunts));
      params.push(paramFitxersAdjunts);

      //fitxersArxiuTecnic
      const paramFitxersArxiuTecnic: PostParams = {
        nombre: PARAM_FIXTERS_ARXIU_TECNIC,
        valor: fitxersArxiuTecnic != null && fitxersArxiuTecnic.size > 0 ? Array.from(fitxersArxiuTecnic).join() : ""
      };

      // console.log("paramFitxersArxiuTecnic --> " + JSON.stringify(paramFitxersArxiuTecnic));
      params.push(paramFitxersArxiuTecnic);

      this.descargar(PARAM_GET_FITXERS_PARTES_SYNC_APP, params)
        .then((res: any) => {
          this._rp.synchronizeResourcesAdjunts(res.imatgesFases, this._fp.pathDestinoImatgesfases, loading, false)
            .then(() => {
              this._rp.synchronizeResourcesAdjunts(res.imatgesRespostesFases, this._fp.pathDestinoImatgesrespostesfases, loading, false)
                .then(() => {
                  this._rp.synchronizeResourcesAdjunts(res.pdfsRespostesFases, this._fp.pathDestinoPdfsrespostesfases, loading, false)
                    .then(() => {
                      this._rp.synchronizeResourcesAdjunts(res.fitxersAdjunts, this._fp.pathDestinoAdjunts, loading, false)
                        .then(() => {
                          this._rp.synchronizeResourcesAdjunts(res.fitxersArxiuTecnic, this._fp.pathDestinoArxiutecnic, loading, false)
                            .then(() => {

                              //verifico si viene valor para adjuntsMassaGran
                              //seteo solo si aún no lo tengo cargado
                              if (this.adjuntsMassaGran == null) {
                                this.adjuntsMassaGran = res.adjuntsMassaGran;
                              }

                              const totalpages = Math.ceil(res.quantitat / PARAM_ROW_FINAL_VALUE_ADJUNTS);
                              this.updateLoading(loading, msg + " - " + pag + " " + this.paginaAdjuntos++ + (totalpages > 0 ? "/" + totalpages : ""));

                              if (res.quantitat > this.rowFinalAdjunts) {
                                this.rowIniciAdjunts += PARAM_ROW_FINAL_VALUE_ADJUNTS;
                                this.rowFinalAdjunts += PARAM_ROW_FINAL_VALUE_ADJUNTS;

                                this.collectAdjuntData(loading, imatgesFases, imatgesRespostesFases, pdfsRespostesFases, fitxersAdjunts, fitxersArxiuTecnic, this.rowIniciAdjunts, this.rowFinalAdjunts)
                                  .then(() => {
                                    resolve(true);
                                  })
                                  .catch(err => {
                                    // console.error("collectImagesData -> " + JSON.stringify(err));
                                    resolve(false);
                                  });
                              } else {
                                this.paginaAdjuntos = 1;
                                this.rowIniciAdjunts = 1;
                                this.rowFinalAdjunts = PARAM_ROW_FINAL_VALUE_ADJUNTS;
                                resolve(true);
                              }
                            })
                            .catch(err => {
                              // console.error("fitxersArxiuTecnic -> " + JSON.stringify(err));
                              resolve(false);
                            });
                        })
                        .catch(err => {
                          // console.error("fitxersAdjunts -> " + JSON.stringify(err));
                          resolve(false);
                        });
                    })
                    .catch(err => {
                      // console.error("pdfsRespostesFases -> " + JSON.stringify(err));
                      resolve(false);
                    });
                })
                .catch(err => {
                  // console.error("imatgesRespostesFases -> " + JSON.stringify(err));
                  resolve(false);
                });
            })
            .catch(err => {
              // console.error("imatgesFases -> " + JSON.stringify(err));
              resolve(false);
            });
        })
        .catch(err => {
          // console.error(JSON.stringify(err));
          resolve(false);
        });
    });
  }


  //#endregion

  //#region sync confirm

  /**
   * Initiates the synchronization process with confirmation.
   * @param {ModalController} modalCtrl - Modal controller for handling views.
   * @param {LoadingController} loadingCtrl - Loading controller for displaying progress.
   * @param {boolean} needCloseView - Indicates whether to close the view after synchronization.
   * @returns {Promise<boolean>} A promise that resolves to `true` if synchronization succeeds, otherwise `false`.
   */
  syncConfirm(modalCtrl: ModalController, loadingCtrl: LoadingController, needCloseView: boolean): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      // Keep the device awake during synchronization.
      this.callKeepAwake();

      // Initialize synchronization start time.
      this.minUpdate = new Date();
      this.sincronizando = true;


      // Update synchronization date information.
      this.updateSincronizacionDate();

      // Reset arrays/lists before synchronization.
      this.resetArraysList();

      try {
        // Synchronize, send, and receive reports.
        await this.sendLocalData();

        // Show loading spinner with a message.
        const loading = await this._messages.showLoading("txDescargandoElementos");

        // Synchronize parts, elements, listings, image files, and report files.
        await this.collectDataIteration(loading);


        // Complete synchronization and cleanup.
        this.sincronizando = false;

        if (needCloseView) {
          modalCtrl.dismiss(true);
        }

        // Allow the device to sleep again.
        this.callAllowSleepAgain();

        resolve(true);
      } catch (err) {
        // Handle synchronization errors and display a message.
        this._messages.showToastDefault("txErrorAlSincronizar");
        // console.error("Synchronization error -> " + JSON.stringify(err));
        this.sincronizando = false;

        // Allow the device to sleep again.
        this.callAllowSleepAgain();

        resolve(false);
      }
    });
  }


  async callAllowSleepAgain() {
    if(this.platform.is("hybrid")) {
      await this.insomnia.allowSleepAgain()
        .then(
          // () => console.log('allowSleepAgain success'),
          // () => console.log('allowSleepAgain error')
        );
    }
  }

  async callKeepAwake() {
    if(this.platform.is("hybrid")) {
      await this.insomnia.keepAwake()
        .then(
          // () => console.log('keepAwake success'),
          // () => console.log('keepAwake error')
        );
    }
  }

  //#endregion

  private async saveDetailPartOnIndexedDB(idParte: string, parte: any) {
    const path = 'parte_' + idParte;
    const data = JSON.stringify(parte);
    const directorySaveOnIndexedDB = Directory.Documents;

    try {
      await this._pg.saveOnIndexedDB(path, data, directorySaveOnIndexedDB, Encoding.UTF8);
    } catch (errorSave) {
      console.error('error save', errorSave);
    }
  }

}
