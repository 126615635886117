<ion-header>
    <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
    <respuesta-fase-content [respuestaPageActions]="this">
        <ion-list>
            <ion-item>
                <ion-toolbar>
                    <ion-buttons slot="end">
                        <ion-button (click)="eliminar()">
                            <ion-icon size="large" name="lc-icon-trash"></ion-icon>
                        </ion-button>
                        <ion-button (click)="attachPicture()">
                            <ion-icon size="large" name="lc-icon-clip"></ion-icon>
                        </ion-button>
                        <ion-button (click)="takePhotoAndSave()">
                            <ion-icon size="large" name="lc-icon-camera"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-item>

            <ion-item *ngIf="imagenPreview">
                <ion-label>
                    <img [src]="imagenPreview">
                </ion-label>
            </ion-item>
        </ion-list>
    </respuesta-fase-content>
</ion-content>

<ion-footer>
    <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
