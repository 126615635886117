import {Component} from '@angular/core';
import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {PartesTabProvider} from '../../services/partes-tab/partes-tab.provider';
import {ParteResultadoModalPage} from '../parte-resultado-modal/parte-resultado-modal';
import {TranslateProvider} from '../../services/translate/translate.provider';
import {ParteResultadoOperarioPage} from '../parte-resultado-operario/parte-resultado-operario';
import {ParteOperarioAddModalPage} from '../parte-operario-add-modal/parte-operario-add-modal';
import {UiMessagesProvider} from '../../services/ui-messages/ui-messages';
import * as Data from '../../config/constantes';
import moment from "moment/moment";
import {DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT} from "../../config/constantes";

/**
 * Generated class for the ParteResultadoPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-parte-resultado',
  templateUrl: 'parte-resultado.html',
  styleUrls: ['parte-resultado.scss'],
})
export class ParteResultadoPage {
  parteResultado: any;
  public constants = Data;
  public alertObservacionesParte;
  executionDate: string;

  locale = 'es';
  readonly MONDAY = 1;

  constructor(public navCtrl: NavController,
              public _ptp: PartesTabProvider,
              public modalCtrl: ModalController,
              public alertCtrl: AlertController,
              public _translate: TranslateProvider,
              public _ui: UiMessagesProvider,
              public platform: Platform) {
  }

  ionViewDidEnter() {
    this.locale = this._translate.currentLang;
    this.executionDate =  this._ptp.parte.dataHoraExecucio === '' ? moment().format() : moment(this._ptp.parte.dataHoraExecucio).format();
  }

  async showResultadoModal(){
    if(this._ptp.editando){
      const modal = await this.modalCtrl.create({component: ParteResultadoModalPage})
      modal.present();

      modal.onDidDismiss().then(
        ({data}) => {
          const dataReturn = data;
          if(dataReturn){
            this._ptp.parteHasDifference = true;

            this._ptp.parte.colorEstadoParteToResult = dataReturn.estadoSeleccionado.colorEstadoParte
            this._ptp.parte.txEstadoParteToResult = dataReturn.estadoSeleccionado.txEstadoParte
            this._ptp.parte.tipusEstatParteRealitzat = dataReturn.estadoSeleccionado.codigoTipoEstadoParte
            this._ptp.parte.srcImgEstadoParteToResult = dataReturn.estadoSeleccionado.srcImgEstadoParte;
            this._ptp.setDraft();
          }else{
            // console.log("Se cerró el modal sin parametros");
          }
        }
      );
    }
  }

  async presentPromptObservations() {
    if(this._ptp.editando){
      this._ptp.isOpenObservacionesParte = true;
      this.alertObservacionesParte = await this.alertCtrl.create({
        cssClass: 'alert-observations-part',
        header: this._translate.traducir('txPartesObservacionesDelParte'),
        inputs: [
          {
            id: 'alert-observations-part-input',
            type: 'textarea',
            name: 'observacionsVersioParte',
            placeholder:  this._translate.traducir('txPartesObservaciones'),
            value: this._ptp.parte.observacionsVersioParte
          }
        ],
        buttons: [
          {
            text: this._translate.traducir('txCancelar'),
            role: 'cancel',
            handler: data => {
              // console.log('Cancel clicked');
              this._ptp.isOpenObservacionesParte = false;
            }
          },
          {
            text: this._translate.traducir('txAceptar'),
            handler: data => {
              if (data.observacionsVersioParte && data.observacionsVersioParte.length > 0) {
                this._ptp.parte.observacionsVersioParte = data.observacionsVersioParte;
              }
              this._ptp.parteHasDifference = true;
              this._ptp.isOpenObservacionesParte = false;
              this.alertObservacionesParte.dismiss();
              return false;
            }
          }
        ]
      });
      this.alertObservacionesParte.present();
    }
  }

  //operarios
  async showEditTime(operario: any, idx: number){
    if(this._ptp.editando){
      const modal = await this.modalCtrl
                      .create({
                        component: ParteResultadoOperarioPage,
                        componentProps: {
                                  respuesta: operario.tempsMinuts ? operario.tempsMinuts : "",
                                  operariDescripcio: operario.descripcio,
                                  indice: idx
                              }
                      });
      modal.present();

      modal.onDidDismiss().then(
        ({data}) => {
          if(data){
            this._ptp.parteHasDifference = true;
            this._ptp.parte.operaris[idx].tempsMinuts = data.respuesta;
          }else{
            // console.log("Se cerró el modal sin parametros");
          }
        }
      );
    }
  }

  async showAddOperarioModal(){
    if(this._ptp.editando){
      let modal;
      modal = await this.modalCtrl.create({
        component: ParteOperarioAddModalPage,
        componentProps: {
          descripcion: null,
          respuesta: null
        }
      })
      modal.present();

      modal.onDidDismiss().then(
        ({data}) => {
          const dataReturn = data;
          if(dataReturn){
            if(this._ptp.parte.operaris.some(i => i.idOperari === dataReturn.idOperari)){
              this._ui.showToastDefault("txOperarioYaExiste");
            }else{
              this._ptp.parteHasDifference = true;

              this._ptp.parte.operaris.push(dataReturn);
              this.showEditTime(dataReturn, this._ptp.parte.operaris.length - 1);
            }
            this._ptp.setDraft();

          }else{
            // console.log("Se cerró el modal sin parametros");
          }
        }
      );
    }
  }

  public executionDateChange(data: string) {
    this._ptp.parte.dataHoraExecucio = this.formatDate(data);
    this.executionDate = moment(data, DATE_TIME_FORMAT).format()
  }

  formatDate(data: string) {
    let date = moment(data).format(DATE_FORMAT);
    let time = moment(data).format(TIME_FORMAT);
    return [date, time].join(' ');
  }
}
