import {Injectable} from "@angular/core";
import {HelperProvider} from "../../services/helper/helper.provider";
import {NetworkProvider} from "../../services/network/network.provider";


@Injectable()
export class ConfigurationInformationViewModel {

  constructor(
    private _hp: HelperProvider,
    private _np: NetworkProvider,) {
  }


  public logOutAndReload() {
    this._hp.cerrarSesion();
  }

}
