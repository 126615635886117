<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>

        <ion-title>{{'txPartesParteStr' | translate}}
            <p class="subtitle hide-when-big-screen" >{{_pg.proyectoSeleccionado.nom}}</p>
        </ion-title>

        <ion-buttons slot="end">
            <ion-button *ngIf="_ptp.editando && _ptp.parte.parteAnterior" (click)="copiarParte()">
                <ion-icon slot="icon-only" name="lc-icon-copy"></ion-icon>
            </ion-button>
            <ion-button *ngIf="!_ptp.editando" (click)="editar()">
                <ion-icon slot="icon-only" name="lc-icon-edit"></ion-icon>
            </ion-button>
            <ion-button *ngIf="_ptp.editando" (click)="aceptar()">
                <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
  <ion-tabs swipeTab tabsHideOnSubPages="true" (ionTabsDidChange)="transition($event)" (tabChange)="onTabChange($event)" #parteTabs>
    <ion-tab-bar>
      <ion-tab-button tab="parte-info">
        <ion-label>
          {{'txParteInfomacionRecursos' | translate}}
        </ion-label>
      </ion-tab-button>
      <ion-tab-button tab="parte-fases">
        <ion-label>
          {{'txParteFases' | translate}}
        </ion-label>
      </ion-tab-button>
      <ion-tab-button tab="parte-resultado">
        <ion-label>
          {{'txParteResultado' | translate}}
        </ion-label>
      </ion-tab-button>
      <ion-tab-button tab="parte-datos-adicionales">
        {{'txParteDatosAdicionales' | translate}} {{(_ptp.parte.costos.length > 0 || _ptp.parte.adjunts.length > 0) ? '(*)' : '' }}
      </ion-tab-button>
      <ion-tab-button tab="parte-anotaciones">
        {{'txParteAnotaciones' | translate}} ({{_ptp.parte.anotacions.length}})
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>

