import { Directory, Filesystem, WriteFileResult } from '@capacitor/filesystem';

export enum FileErrorCode {
  CREATE_DIRECTORY = 'create_directory_error',
  READ_FILE = 'read_file_error',
  STORE_FILE = 'store_file_error',
  DELETE_FILE = 'delete_file_error'
}
export class FileError extends Error {
  constructor(public code: FileErrorCode) {
    super(code);
  }
}

/**
 * Wrapper for Capacitor Filesystem plugin.
 */
export class FileManagerNativeController {
  static readonly APP_DIRECTORY = Directory.Data;

  /**
   * Creates a directory in the app directory if it does not exist.
   * The creation is recursive meaning that all parent directories are created if they do not exist.
   * @param path relative to the app directory
   */
  async createDirectory(path: string): Promise<void> {
    const exists = await this.existsDirectory(path);
    if (exists) {
      return;
    }
    try {
      await Filesystem.mkdir({
        path,
        directory: FileManagerNativeController.APP_DIRECTORY,
        recursive: true,
      });
    } catch (error) {
      throw new FileError(FileErrorCode.CREATE_DIRECTORY);
    }
  }

  /**
   * Checks if a directory exists in the app directory.
   * @param path relative to the app directory
   */
  async existsDirectory(path: string): Promise<boolean> {
    try {
      await Filesystem.readdir({
        path,
        directory: FileManagerNativeController.APP_DIRECTORY,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Stores a file in the app directory and returns the full native path.
   * We need to return the full native path because the path returned by the capacitor plugin is relative to the app directory.
   * And the app directory is different for each platform.
   * @param data base 64 encoded data
   * @param path relative to the app directory
   */
  async storeFile(data: string, path: string): Promise<string> {
    try {
      const file: WriteFileResult = await Filesystem.writeFile({
        path: path,
        data,
        directory: FileManagerNativeController.APP_DIRECTORY,
      });
      return file.uri;
    } catch (error) {
      throw new FileError(FileErrorCode.STORE_FILE);
    }
  }

  /**
   * Reads a file from a full native path.
   * @param path full native path
   */
  async readFile(path: string): Promise<string> {
    try {
      const file = await Filesystem.readFile({
        path,
      });
      return file.data;
    } catch (error) {
      throw new FileError(FileErrorCode.READ_FILE);
    }
  }

  /**
   * Deletes a file from the app directory.
   * @param path relative to the app directory
   */
  async deleteFile(path: string): Promise<void> {
    try {
      await Filesystem.deleteFile({
        path: path,
        directory: FileManagerNativeController.APP_DIRECTORY,
      });
    } catch (error) {
      throw new FileError(FileErrorCode.DELETE_FILE);
    }
  }
}
