import {Provider} from "@angular/core";
import {ProtocolDAO} from "@app/core/permission/domain/protocol.DAO";
import {PermissionIndexedDBDAO} from "@app/core/permission/infrastructure/permission.local-storage-DAO";
import {GetPartsWebService} from "@app/core/part/domain/get-parts.web-service";
import {GetPartHttpWebService} from "@app/core/part/infrastructure/get-part.http-web-service";
import {PartDAO} from "@app/core/part/domain/part.DAO";
import {PartStorageDAO} from "@app/core/part/infrastructure/part.storage-DAO";
import {ElementDAO} from "@app/core/element/domain/element.DAO";
import {ElementStorageDAO} from "@app/core/element/infrastructure/element.storage-DAO";
import {GetElementsStateTypologiesWebService} from "@app/core/element/domain/get-elements-state-typologies.web-service";
import {
  GetElementStateTypologiesHttpWebService
} from "@app/core/element/infrastructure/get-element-state-typologies.http-web-service";
import I18n from "@app/i18n/i18n";

export const infraProviders: Provider[] = [
  {provide: ProtocolDAO, useClass: PermissionIndexedDBDAO},
  {provide: GetPartsWebService, useClass: GetPartHttpWebService},
  {provide: PartDAO, useClass: PartStorageDAO},
  {provide: ElementDAO, useClass: ElementStorageDAO},
  {provide: GetElementsStateTypologiesWebService, useClass: GetElementStateTypologiesHttpWebService},
  {provide: I18n, useClass: I18n}
];
