import {environment} from '../../environments/environment';

export const FORZAR_URL_CONFIGURACION = true;
//export const URL_CONFIGURACION
// Desarrollo:  "https://2647536e9f3c906e98e0a913e8e31452.lafcarr.com/WSApp/";
// iDam: "https://idam.lafcarr.com/WSApp/";
// iDam2 (CH Duero): "https://idam2chdueroapp.lafcarr.com/WSApp/"
export const URL_CONFIGURACION = environment.urlConfiguracion;
export const DEFAULT_LANGUAGE = 'ES';
export const MOSTRAR_ACCESO_FT = false;
export const MOSTRAR_ACCESO_AT = false;
