import {PartDAO} from "@app/core/part/domain/part.DAO";
import {PartRepositoryInterface} from "@app/core/part/domain/part.repository-interface";
import {Part} from "@app/core/part/domain/part";
import {Injectable} from "@angular/core";
import {CreateNotPlannedPartWebService} from "@app/core/part/infrastructure/createNotPlannedPart.web-service";

@Injectable(
  {
    providedIn: 'root'
  }
)
export class PartRepository implements PartRepositoryInterface {

  constructor(private partDAO: PartDAO,
              private createNotPlannedWS: CreateNotPlannedPartWebService) {
  }

  createNotPlannedPart(part: Part): Promise<Part> {
    return this.createNotPlannedWS.execute(part).toPromise();
  }

  saveIdList(idList: number[]): Promise<number[]> {
    return this.partDAO.saveIdList(idList);
  }

  async getIdList(): Promise<number[]> {
    let idList = await this.partDAO.getIdList();
    if (idList) {
      return idList;
    } else {
      return Promise.resolve([]);
    }
  }

  getNotPlannedPart(partID: number): Promise<Part> {
    return this.partDAO.get(partID);
  }
}
