<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-buttons slot="start">
            <ion-button class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button" (click)="cerrarSinParametros()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{'txEstadoActual' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <p>{{'txEstadoActual' | translate}}:</p>
    <span style="font-size: 1.7rem;">
        <ion-icon *ngIf="!_pg.workingOffline" name="lc-icon-cloud" class="icon-align-text-bottom"></ion-icon>
        <ion-icon *ngIf="_pg.workingOffline" name="lc-icon-airplane" class="icon-align-text-bottom"></ion-icon>
        {{ (!_pg.workingOffline ? 'txTrabajoEnLinea' : 'txTrabajoSinConexion') | translate}}
    </span>
    <p *ngIf="!_pg.workingOffline" [ngClass]="{'conectado': _np.appOnline , 'desconectado': !_np.appOnline }">{{ 'txTrabajoEnLinea' | translate}}</p>

    <p *ngIf="_pg.workingOffline && _pg.usuarioLoginModel.ultimaSincronizacion > ''" style="padding-top: 4rem; margin-bottom:-0.5rem;" class="ultima-sincronizacion">{{'txUltimaSincronizacion' | translate}} {{_html.getDateToHuman(_pg.usuarioLoginModel.ultimaSincronizacion)}}</p>
    <ion-button expand="block" style="text-transform:none;" color="colorTemaOffline" [ngClass]="{'no-working-offline': _pg.workingOffline, 'working-online' : !_pg.workingOffline }" (click)="sincronizarBtn(false)">
        <span class="translated-paragraph">{{ 'txSincronizarAhora' | translate}}</span>
    </ion-button>

    <ion-button expand="block" [color]="_pg.workingOffline ? 'colorTema1' : 'colorTemaOffline'" [ngClass]="{'no-working-offline': !_pg.workingOffline }" style="text-transform:none" (click)="sincronizarBtn(true)">
        <ion-icon style="padding-right:0.5rem;" *ngIf="!_pg.workingOffline" name="lc-icon-airplane" class="icon-align-text-bottom"></ion-icon>
        <ion-icon style="padding-right:0.5rem;" *ngIf="_pg.workingOffline" name="lc-icon-cloud" class="icon-align-text-bottom"></ion-icon>
        {{ (!_pg.workingOffline ? 'txCambiarAModoSinConexión' : 'txCambiarAFuncionamientoEnlinea' ) | translate}}
    </ion-button>
</ion-content>
