<ion-header>
    <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
        <ion-buttons slot="start">
            <ion-button class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button" (click)="cerrarSinParametros()">
              <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{'txSeleccionarOperario' | translate}}</ion-title>
        <!-- <ion-buttons end>
            <button ion-button icon-only (click)="cerrarConParametros()">
              <ion-icon name="lc-icon-check"></ion-icon>
          </button>
        </ion-buttons> -->
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-searchbar class="search-bar" [placeholder]="'txBuscar' | translate" slot="fixed" (search)="onInput($event)" [(ngModel)]="valorSearch"></ion-searchbar>
    <ion-list class="contenido">
        <ion-item *ngFor="let op of operarios" (click)="cerrarConParametros(op)">
          <ion-label>
            <p>{{op.descripcio}}</p>
          </ion-label>
        </ion-item>
    </ion-list>

    <label *ngIf="operarios.length==0 && consultaWSRealizada" class="text-no-existe">{{'txOperariosNoEncontrados' | translate}}</label>

    <ion-infinite-scroll (ionInfinite)="doInfinite($event) " [disabled]="!hayMasElementos ">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <label *ngIf="!hayMasElementos" class="text-no-existe ion-float-right">{{'txOperariosNoMasElementos' | translate}}</label>
</ion-content>
