import {Injectable} from '@angular/core';
import {PersistenciaGeneralProvider} from '../persistencia-configuracion/persistencia-general.provider';
import {EncryptProvider} from '../encrypt/encrypt.provider';
import {CLAVE_ENCRIPTACION_COMUNICACION} from '../../config/constantes';
import moment from 'moment';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {Md5} from 'md5-typescript';
import {DEFAULT_LANGUAGE} from '../../config/constantes-cliente';
import {Platform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class WsUtilsProvider {

  constructor(public _pg: PersistenciaGeneralProvider,
              private device: Device,
              private _encrypt: EncryptProvider,
              public platform: Platform) {
    // console.log('Hello UtilsWsProvider Provider');
  }

  // TODO: Quitar logs
  getStrLogin(usuario: string, password: string){
      // console.log("getStrLogin - getStrLogin");
      let deviceSerial;
      let deviceName;
      if (this.platform.is("hybrid")){
        deviceSerial = this.device.serial.substring(0, 100);
        deviceName = `${this.device.platform} ${this.device.version} (${this.device.manufacturer})`;
      }else{
        deviceSerial = navigator.userAgent.substring(0, 100);
        deviceName = "PWA";
      }
      const strLogin = `${this._pg.versionApp}|${this.getDateToStrLogin()}|${DEFAULT_LANGUAGE}||${deviceSerial}|${deviceName}|${password}|${usuario}`;

      return  this._encrypt.encryptWithXor(strLogin , CLAVE_ENCRIPTACION_COMUNICACION);
  }

  getStrConn(){
    const strConn = `${this.getDateToStrLogin()}|${this._pg.usuarioLoginModel.idioma}|${this._pg.usuarioLoginModel.idConnexioUsuariApp}`;
    return  this._encrypt.encryptWithXor(strConn , CLAVE_ENCRIPTACION_COMUNICACION);
}

  getDateToStrLogin(){
    const processDate = new Date();

    const finalDate = moment(processDate).format('DD/MM/YYYYTHH:mm:ssZZ');
    return finalDate;
  }

  getMd5Password(password){
    const e = Md5.init(password);
    return e;
  }

}
