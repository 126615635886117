<ion-header>
    <respuesta-fase-header [respuestaPageActions]="this"></respuesta-fase-header>
</ion-header>

<ion-content class="ion-padding">
    <respuesta-fase-content [respuestaPageActions]="this">
        <ion-list>
            <ion-radio-group [(ngModel)]="valorEdicio">
                <ion-item *ngFor="let option of faseActual.fase.opcionsFases">
            <ion-label>{{option.nom}}</ion-label>
            <ion-radio mode="md" slot="start" value="{{option.numeroOpcio}}"></ion-radio>
        </ion-item>

        <ion-item>
            <ion-label class="sin-asignar">{{'txSinAsignarStr' | translate}}</ion-label>
            <ion-radio mode="md" slot="start" value="-1"></ion-radio>
        </ion-item>
    </ion-radio-group>

    </ion-list>
    </respuesta-fase-content>
</ion-content>

<ion-footer>
    <respuesta-fase-footer [respuestaPageActions]="this"></respuesta-fase-footer>
</ion-footer>
