import {ImagePickerNativeController} from '@app/image-picker/image-picker.native-controller';
import {FileManagerNativeController} from '@app/image-picker/file-manager.native-controller';
import {FileUtils} from '@app/image-picker/file-utils';

/**
 * Source of the image
 * Camera and Gallery are represented with a bit mask to allow for multiple sources
 */
export enum Source {
  CAMERA = 1 << 1,
  GALLERY = 1 << 2
}


export enum ImageErrorCode {
  NOT_FOUND = 'image_not_found_error',
  NOT_SAVED = 'image_not_saved_error',
  NOT_LOADED = 'image_not_loaded_error',

}

export class ImageError extends Error {
  constructor(public code: ImageErrorCode) {
    super(code);
  }
}

export interface PromptLabels {
  labelCancel: string;
  labelGallery: string;
  labelCamera: string;
  labelHeader: string;
}


/**
 * Class that handles the image picking process.
 * It can be used to pick an image from the camera, the gallery or by prompting the user to choose.
 * It can also be used to store the image in the default directory.
 */
export class ImageControllerService {
  private static readonly IMAGE_DIRECTORY = 'images';

  private static readonly USER_CANCELLED_ERROR = 'User cancelled photos app';

  constructor(private fileManager: FileManagerNativeController = new FileManagerNativeController(),
              private imagePicker: ImagePickerNativeController = new ImagePickerNativeController()) {
  }

  /**
   * Picks an image from the camera, the gallery or by prompting the user to choose.
   *
   * @param source source of the image
   * @param labels labels for the image picker
   * @param store if true, the image will be saved in the default directory
   */
  async pickImage(source: Source = Source.CAMERA | Source.GALLERY,
                  labels: PromptLabels,
                  store = true): Promise<string> {
    let imagePath: string;
    this.imagePicker.translations = labels;
    try {
      // Open the image picker
      if (source === Source.CAMERA) {
        imagePath = await this.imagePicker.getImageFromCamera();
      } else if (source === Source.GALLERY) {
        imagePath = await this.imagePicker.getImageFromGallery();
      } else {
        imagePath = await this.imagePicker.getImageByPrompt();
      }
    } catch (e) {
      // If the user cancelled the image picker, we do not want to throw an error
      if (e.message === ImageControllerService.USER_CANCELLED_ERROR) {
        return;
      }
      throw new ImageError(ImageErrorCode.NOT_LOADED);
    }

    if (store) {
      await this.createImageDirectory();
      return this.storeFile(imagePath);
    }

    return imagePath;
  }

  async storeImage(imagePath: string): Promise<string> {
    await this.createImageDirectory();
    return this.storeFile(imagePath);
  }

  /**
   * Creates the default directory where to store the images if it does not exist
   */
  private createImageDirectory(): Promise<void> {
    return this.fileManager.createDirectory(ImageControllerService.IMAGE_DIRECTORY);
  }

  /**
   * Stores the image in the default directory
   *
   * @private
   * @param source full path of the image to save
   */
  private async storeFile(source: string): Promise<string> {
    const name = FileUtils.getFileName(source);
    const destination = `${ImageControllerService.IMAGE_DIRECTORY}/${name}`;
    try {
      const fileData = await this.fileManager.readFile(source);
      return this.fileManager.storeFile(fileData, destination);
    } catch (e) {
      throw new ImageError(ImageErrorCode.NOT_SAVED);
    }
  }
}
