<ion-header>
  <ion-toolbar [color]="_pg.workingOffline ? 'colorTemaOffline' : 'colorTema1'">
    <ion-buttons slot="start">
      <ion-button
        class="back-button bar-button bar-button-md back-button-md bar-button-default bar-button-default-md show-back-button"
        (click)="cerrarSinParametros()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'txElementState' | translate}}</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="cerrarConParametros()">
        <ion-icon slot="icon-only" name="lc-icon-check"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <app-custom-date-selector [(date)]="selectedDate" [ngClass]="'center'" [text]="'txDateSelection'" [id]="'open-modal'" [clearButton]="false"></app-custom-date-selector>

  <ion-item>
     <ion-select interface="action-sheet"
                 class="element-state-selector"
                 [(ngModel)]="selectedState"
                 placeholder="{{'txElementStateEdit' | translate}}"
                 [compareWith]="compareStates"
                 [cancelText]="'txCancelar'|translate">
       <ion-select-option *ngFor="let stateType of (stateTypesList | async)" [value]="stateType">{{ stateType.nom }}</ion-select-option>
     </ion-select>
  </ion-item>

  <ion-item>
    <ion-textarea [(ngModel)]="observations" rows="20" placeholder="{{'txElementStateEditObservations' | translate}}"></ion-textarea>
  </ion-item>
</ion-content>
