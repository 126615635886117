import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class TranslateProvider {

  constructor(private translate: TranslateService) {
    // console.log('Hello TranslateProvider Provider');
  }

  traducir(key: string){
    return this.translate.instant(key);
  }


  traducirWithParams(key: string, params: any){
    return this.translate.instant(key, params);
  }

  get currentLang() {

    return this.translate.currentLang;
  }

}
